import React from 'react'

import { Template } from '@level'
import { useProject } from '@app/hooks'
import { TeamAvatar } from '@app/components'
import { useTemplate } from '../context'

const PreviewSMSTemplate = (props) => {
  const { template } = useTemplate()
  const { Project: { smsProvider } } = useProject()

  return template ? (
    <Template
      config={template.previewConfig}
      type={template.type}
      avatar={<TeamAvatar shape="circle" />}
      defaultFrom={smsProvider?.config?.defaultFrom}
      {...props}
    />
  ) : null
}

export {
  PreviewSMSTemplate,
}
