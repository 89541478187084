import {
  Routes,
  Route,
} from 'react-router-dom'

import { PublicProfileProvider } from '@app/hooks'

import {
  useCleanPaste, renderTemplate, getTemplateVariables, useLocalStorage,
} from '@level'

import './try.scss'

import { EmailBuilder } from './build'
import { TemplateTest } from './test'
import { TemplateDeploy } from './deploy'
import { TryLayout } from './layout'
import { TemplateNew } from './new'

const Try = () => {
  const [template, setTemplate] = useLocalStorage('template-try', null)

  const updateTemplate = (update) => {
    const t = { ...template, ...update, import: true }
    const rendered = renderTemplate({ ...t, config: t.previewConfig, mode: 'preview-html' })
    t.previewTemplateVariables = getTemplateVariables(rendered)
    setTemplate(t)
  }
  // getData is used by template builders to get the template data. Adding that back to the props
  // allows reused app components which rely on the query provider hooks to work as normal.
  const props = {
    template,
    getData: () => ({ template }),
  }

  useCleanPaste()

  return (
    <PublicProfileProvider>
      <Routes>
        <Route path="*" element={<TemplateNew updateTemplate={updateTemplate} />} />
        <Route path="build/*" element={<TryLayout template={template} updateTemplate={updateTemplate}><EmailBuilder {...props} onChange={updateTemplate} /></TryLayout>} />
        <Route path="test" element={<TryLayout template={template} updateTemplate={updateTemplate}><TemplateTest {...props} /></TryLayout>} />
        <Route path="deploy/*" element={<TryLayout template={template} updateTemplate={updateTemplate}><TemplateDeploy {...props} /></TryLayout>} />
        <Route path="new" element={<TemplateNew updateTemplate={updateTemplate} />} />
      </Routes>
    </PublicProfileProvider>
  )
}

export { Try }
