import React from 'react'
import PropTypes from 'prop-types'
import { useMaxSize } from '../../hooks/useMaxSize'
import { spaceToSize } from '../../helpers'

const ScrollPanel = React.forwardRef(function ScrollPanel({
  rootRef,
  withinParent,
  footerRef: footerRefProp,
  limitHeight,
  footer,
  as: Tag = 'div',
  offsetSpace = 0,
  ...props
}, ref) {
  const fref = React.useRef()
  const footerRef = footerRefProp || fref
  const { contentRef } = useMaxSize({
    contentRef: ref, rootRef, footerRef, offsetSpace, withinParent, limitHeight,
  })

  const main = (
    <Tag
      ref={contentRef}
      {...props}
    />
  )

  if (!footer) return main
  return (
    <>
      {main}
      <div ref={footerRef}>{footer}</div>
    </>
  )
})

ScrollPanel.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  style: PropTypes.object,
  footerRef: PropTypes.object,
  limitHeight: PropTypes.bool,
  offsetSpace: spaceToSize.propTypes.space,
  rootRef: PropTypes.object,
  footer: PropTypes.any,
  withinParent: PropTypes.bool,
}

export {
  ScrollPanel,
}
