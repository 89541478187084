import React from 'react'
import * as Ariakit from '@ariakit/react'
import PropTypes from 'prop-types'
import { useMaxSize } from '../../hooks/useMaxSize'
import './tabs.scss'

const Tab = ({ text, children, ...props }) => (
  <Ariakit.Tab className="level-tab" {...props}>
    {text || children}
  </Ariakit.Tab>
)

Tab.propTypes = {
  ...Ariakit.Tab.propTypes,
  text: PropTypes.string,
}

const TabList = ({ theme = 'default', ...props }) => <Ariakit.TabList className="level-tab-list" {...props} data-theme={theme} />

TabList.propTypes = {
  theme: PropTypes.string,
}

const Tabs = ({ theme = 'default', children, ...props }) => {
  return <div data-theme={theme}><Ariakit.TabProvider {...props}>{children}</Ariakit.TabProvider></div>
}

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.string,
}

// Passes the panel index as a prop
const TabPanels = ({
  scroll = false,
  ...props
}) => {
  const { ref } = useMaxSize({ withinParent: true })

  return (
    <div
      ref={scroll ? ref : null}
      className="level-tab-panels"
      {...props}
    />
  )
}

TabPanels.propTypes = {
  children: PropTypes.node.isRequired,
  scroll: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  style: PropTypes.object,
}

// Uses the selected index to conditionally render the panel contents
const TabPanel = (props) => {
  return (
    <Ariakit.TabPanel className="level-tab-panel" {...props} />
  )
}

TabPanel.propTypes = {
  ...Ariakit.TabPanel.propTypes,
  index: PropTypes.number,
}

export {
  Tab, Tabs, TabList, TabPanel, TabPanels,
}
