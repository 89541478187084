import React from 'react'
import {
  Table, Stack, Text,
  useTable,
} from '@level'

import { useProject, useTeam } from '@app/hooks'
import { ToggleActive, Token } from './_cells'

const ApiTokensTable = () => {
  const { isProviderTeam } = useTeam()
  const { tokens } = useProject()

  const columnNames = React.useMemo(() => [
    { active: { header: 'Enabled', cell: ToggleActive } },
    'name',
    {
      testMode: {
        header: 'Environment',
        cell: ({ getValue }) => (getValue() ? 'Sandbox' : 'Production'),
      },
    },
    { token: { cell: Token, width: '100%', canDelete: !isProviderTeam } },
  ], [])

  const table = useTable({
    columnNames,
    data: tokens,
  })

  return (
    <Table
      table={table}
      blankSlate={(
        <Stack space={5} gap={5}>
          <Text tag="h4">No Api Tokens</Text>
          <Text color="neutral-400">You must have API tokens to use Enveloop‘s API to send messages.</Text>
        </Stack>
      )}
    />
  )
}

export {
  ApiTokensTable,
}
