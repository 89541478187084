import React from 'react'

function useInterval(callback, delay, cancelOnUnmount = true) {
  const savedCallback = React.useRef()

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  React.useEffect(() => {
    const tick = () => { savedCallback.current() }

    if (delay !== null) {
      const id = setInterval(tick, delay)

      // Clean up the interval when the component unmounts or if delay changes
      return () => {
        if (cancelOnUnmount) {
          clearInterval(id)
        }
      }
    }
  }, [delay, cancelOnUnmount])
}

export { useInterval }
