import React from 'react'
import PropTypes from 'prop-types'

import {
  Stack, Text, Shelf, Spacer, Button,
} from '@level'

import { MessageIcon } from '../icon'

const MessageHeader = ({
  close,
  message,
  nextMessage,
  prevMessage,
}) => (
  <>
    <Shelf gap={4} space={[7, 7, 0]} valign="center">
      <MessageIcon name={message.type} size={3} />
      <Text tag="h3">To: {message.to}</Text>
      <Spacer grow />
      <Button theme="wrapper" label="close" icon={{ name: 'x-light', size: 4 }} onClick={close} />
    </Shelf>
    <Shelf space={7} className="message-log-item-meta" align="split" valign="top">
      <Shelf gap={6}>
        <Stack gap={2}>
          {message.subject ? <Text><strong>Subject:</strong> {message.subject}</Text> : null}
          <Text><strong>From:</strong> {message.from}</Text>
        </Stack>
      </Shelf>
      <Shelf>
        {message.templateLink ? <Button icon="pen" to={message.templateLink} size={4} text="Edit Template" /> : null}
        <Spacer space={4} />
        <Button onClick={prevMessage} icon="angle-left" label="previous message" disabled={!prevMessage} />
        <Button onClick={nextMessage} icon="angle-right" label="next message" disabled={!nextMessage} />
      </Shelf>
    </Shelf>
  </>
)

MessageHeader.propTypes = {
  message: PropTypes.shape({
    to: PropTypes.string,
    subject: PropTypes.string,
    from: PropTypes.string,
    type: PropTypes.string,
    templateLink: PropTypes.string,
  }).isRequired,
  close: PropTypes.func.isRequired,
  nextMessage: PropTypes.func,
  prevMessage: PropTypes.func,
}

export {
  MessageHeader,
}
