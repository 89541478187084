import React from 'react'

import {
  Shelf,
  FilterPill,
  DateFilterPill,
  useSearchState,
  titleize,
} from '@level'

import { TemplateFilter } from './template'
import { DateFilter } from './date'
import { ActionFilter } from './action'
import { TypeFilter } from './type'
import { StatusFilter } from './status'
import { FromFilter } from './from'
import { ToFilter } from './to'

const FilterPills = () => {
  const [params] = useSearchState()

  return (
    <Shelf gap={4} className="message-filter-pills">
      <FilterPill name="type" content={<TypeFilter />} />
      <FilterPill name="status" content={<StatusFilter />} display={params.status?.split(',').map(titleize).join(', ')} />
      <FilterPill name="to" content={<ToFilter />} display={`"${params.to}"`} />
      <FilterPill name="action" content={<ActionFilter />} clear={['action', 'openedAt', 'clickedAt', 'openedAtOp', 'clickedAtOp']} />
      <FilterPill name="template" content={<TemplateFilter />} display={params.template?.split(',').join(', ')} clear={['TemplateId', 'template']} />
      <FilterPill name="from" content={<FromFilter />} display={`"${params.from}"`} />
      <DateFilterPill name="date" content={<DateFilter />} clear={['date', 'createdAt']} />
    </Shelf>
  )
}

export {
  FilterPills,
}
