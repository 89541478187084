import { Navigate } from 'react-router-dom'
import { rootUrl } from '@app/helpers'
import { useSendAuthLink, useAuthToken } from '@app/hooks'

import {
  Form, Input, Shelf, Column, Layout, Button, SubmitButton, Icon, Text, Stack,
  useSearchState,
} from '@level'

import { WelcomeSkeleton } from '../welcome/skeleton'
import '../welcome/welcome-card.scss'

const NewSession = () => {
  const token = useAuthToken()
  const { sent, sendLink } = useSendAuthLink()
  const [{ email: defaultEmail }] = useSearchState()

  const onSubmit = ({ data: { email } }) => {
    sendLink(email)
  }

  // User shouldn't be here if they're signed in
  if (token) return <Navigate to={rootUrl()} />

  return (
    <Layout className="level-layout-welcome">
      <Shelf className="welcome-page" nowrap>
        <Column className="welcome-form-wrapper" grow width="50%">
          <div className="level-welcome-card">
            <Icon name="enveloop-logomark" width={10} height={null} />
            <Stack gap={10}>
              <Stack gap={4}>
                <Text tag="h1">Hi! Welcome to Enveloop.</Text>
                <Text size={3}>Sign in or create an account.</Text>
              </Stack>
              <Stack gap={5}>
                {import.meta.env.VITE_GOOGLE_OAUTH_URL
                  ? (
                    <Button
                      icon="google"
                      to={import.meta.env.VITE_GOOGLE_OAUTH_URL}
                      text="Sign In With Google"
                      type="subtle"
                    />
                  ) : null}
                {import.meta.env.VITE_GITHUB_OAUTH_URL
                  ? (
                    <Button
                      icon="github"
                      to={import.meta.env.VITE_GITHUB_OAUTH_URL}
                      text="Sign In With Github"
                      type="subtle"
                    />
                  ) : null}
                {import.meta.env.VITE_APPLE_OAUTH_URL
                  ? (
                    <Button
                      icon="apple"
                      to={import.meta.env.VITE_APPLE_OAUTH_URL}
                      text="Sign In With Apple"
                      type="subtle"
                    />
                  ) : null}
              </Stack>
              <div style={{ position: 'relative' }}>
                <div className="or-divider">or</div>
              </div>
              {sent ? (
                <div>Email sent to {sent}</div>
              ) : (
                <Form onSubmit={onSubmit}>
                  <Stack gap={5}>
                    <Input name="email" placeholder="Email Address" defaultValue={defaultEmail} label={false} />
                    <SubmitButton text="Send Link" grow />
                  </Stack>
                </Form>
              )}
            </Stack>
          </div>
        </Column>
        <Column className="welcome-skeleton-wrapper">
          <div className="welcome-skeleton">
            <WelcomeSkeleton />
          </div>
        </Column>

      </Shelf>
    </Layout>
  )
}

export {
  NewSession,
}
