import React from 'react'

import {
  Card, CardHeader, CardSection, Column, Stack, Text, Notice, Shelf,
  DataShelf, DataStack, Button, Icon, Link, Grid, SettingsGrid,
} from '@level'

import {
  useProject,
  useTeam,
} from '@app/hooks'

import {
  sanitizeSecret,
  providerLabels,
  providerDocs,
} from './helpers'

const BlankSlate = () => {
  const { Team: { senderIdentities }, url: teamUrl } = useTeam()

  return (
    <Stack gap={9}>
      <Notice
        space={6}
        theme="info"
        icon={{
          space: 5, name: 'circle-info', size: 6, gap: 4,
        }}
      >
        <Shelf align="split" gap={5}>
          <div>
            <Text tag="h4" size={2}>Enveloop is a fully-featured mail provider.</Text>
            It‘s fast and easy to configure sending email from your your domain or email address.
          </div>
          <Button theme="menu" to={teamUrl('mail-settings')}>Mail Settings</Button>
        </Shelf>
      </Notice>
      <Card space={8}>
        <Stack gap={9}>
          <Stack gap={5}>
            <Text tag="h4">Configure a third-party email provider</Text>
            { senderIdentities.length ? (
              <Notice space={[5, 6]}>
                <Text>
                  Your team is configured for email delivery. To override your team‘s settings,
                  configure one of the third-party mail providers below.
                </Text>
              </Notice>
            ) : null }
          </Stack>
          <Grid gap={5} columnMax={150} fill>
            <Card>
              <Stack gap={5} align="center">
                <Icon name="integration-mailgun" size={7} />
                <Text tag="h5">Mailgun</Text>
                <Button to="./configure/mailProvider?provider=mailgun" text="Setup" theme="primary" />
              </Stack>
            </Card>
            <Card>
              <Stack gap={5} align="center">
                <Icon name="integration-postmark" size={7} />
                <Text tag="h5">Postmark</Text>
                <Button to="./configure/mailProvider?provider=postmark" text="Setup" theme="primary" />
              </Stack>
            </Card>
            <Card>
              <Stack gap={5} align="center">
                <Icon name="integration-sendgrid" size={7} />
                <Text tag="h5">Sendgrid</Text>
                <Button to="./configure/mailProvider?provider=sendgrid" text="Setup" theme="primary" />
              </Stack>
            </Card>
            <Card align="center">
              <Stack gap={5} align="center">
                <Icon name="integration-smtp" size={7} />
                <Text tag="h5">SMTP</Text>
                <Button to="./configure/mailProvider?provider=smtp" text="Setup" theme="primary" />
              </Stack>
            </Card>
          </Grid>
          <Column style={{ fontSize: '12px', paddingBottom: '10px' }}>
            <Text color="neutral-400">
              Don‘t see your provider? <Link to="mailto:support@enveloop.com">Send us a request</Link> and we‘ll see about getting it added to our lineup.
            </Text>
          </Column>
        </Stack>
      </Card>
    </Stack>
  )
}

const MailProvider = () => {
  const { Project: { mailProvider } } = useProject()

  return (
    <SettingsGrid>
      <Stack gap={5}>
        <Text tag="h3">Email Integration</Text>
        <Text>
          Configure and manage a third-party email provider for this
          project. <strong>This will override your team‘s mail settings.</strong>
        </Text>
        <Text>
          Have questions? Check out our <Link target="_blank" to={providerDocs.email}>email integrations docs</Link>.
        </Text>
      </Stack>
      { mailProvider ? (
        <Card space={0}>
          <CardHeader space={6}>
            <Grid templateColumns="1fr auto">
              <Text tag="h4" icon={{ name: `integration-${mailProvider.provider}`, size: 3, space: 6 }}>{ providerLabels[mailProvider.provider] }</Text>
              <Button text="Manage" to={`./configure/mailProvider?provider=${mailProvider.provider}`} />
            </Grid>
          </CardHeader>
          <CardSection space={8}>
            <DataStack gap={8}>
              { Object.entries(mailProvider.config).map(([key, value]) => (
                <DataShelf key={key} title={key}>{ sanitizeSecret(key, value) }</DataShelf>
              )) }
            </DataStack>
          </CardSection>
        </Card>
      ) : <BlankSlate />}
    </SettingsGrid>
  )
}

export {
  MailProvider,
}
