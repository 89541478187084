import React from 'react'
import PropTypes from 'prop-types'

import { Text, formatDateTime } from '@level'
import { MessageIcon } from '../icon'

const cellProps = {
  getValue: PropTypes.func.isRequired,
}

const TruncateCell = ({ getValue }) => <Text title={getValue()} truncate>{getValue()}</Text>

TruncateCell.propTypes = cellProps

const TypeCell = ({ getValue }) => (
  <div className="message-icon">
    <MessageIcon name={getValue()} size={2} label={getValue()} />
  </div>
)

TypeCell.propTypes = cellProps

const DateCell = ({ getValue }) => (
  <TruncateCell getValue={() => formatDateTime(getValue(), { utc: true })} />
)

DateCell.propTypes = cellProps

const StatusCell = ({ getValue }) => {
  const status = getValue()
  if (status) {
    return (
      <div className="message-icon">
        <MessageIcon name={status} size={1} label={status} />
      </div>
    )
  }
  return null
}

StatusCell.propTypes = cellProps

const ActionCell = ({ row }) => {
  let action = null
  const { clickedAt, openedAt } = row.original
  if (openedAt) action = 'opened'
  if (clickedAt) action = 'clicked'
  if (action) {
    return (
      <div className="message-icon">
        <MessageIcon name={action} size={1} label={action} />
      </div>
    )
  }
  return null
}

ActionCell.propTypes = {
  row: PropTypes.object.isRequired,
}

const TemplateCell = ({ getValue }) => (
  <TruncateCell getValue={() => getValue()?.slug || '--'} />
)

TemplateCell.propTypes = cellProps

export {
  TruncateCell,
  TypeCell,
  DateCell,
  StatusCell,
  ActionCell,
  TemplateCell,
}
