import React from 'react'
import PropTypes from 'prop-types'

import {
  RadioInputWrapper, Shelf,
} from '@level'

const ColorPicker = ({ name, colors }) => (
  <Shelf gap={5}>
    { colors.map((value) => (
      <RadioInputWrapper className="theme-gallery-color" value={value} name={name} key={value} label={value}>
        <div data-color={value} />
      </RadioInputWrapper>
    )) }
  </Shelf>
)

ColorPicker.propTypes = {
  name: PropTypes.string.isRequired,
  colors: PropTypes.array.isRequired,
}

export {
  ColorPicker,
}
