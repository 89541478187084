import React from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'

import {
  Text, Table, Grid, Preview, Template, Card, Icon,
  useChannel, useTable, templateUpdateText, templateStatusText,
} from '@level'

import './indexList.scss'

const nameCell = ({ row: { original }, cell }) => {
  const channel = useChannel('hover/template')
  return (
    <Grid
      templateColumns="70px 1fr"
      gap={5}
      valign="center"
    >
      <div
        className="template-index-preview-wrapper"
        onMouseEnter={() => channel.postMessage(original.id)}
        onMouseLeave={() => channel.postMessage(null)}
        style={{ height: '35px' }}
      >
        <Preview zoom={original.type === 'sms' ? 0.3 : 0.09}>
          <Template
            height="1000px"
            type={original.type}
            mode="preview"
            config={original.previewConfig}
            hooks={{ watermark: cell.column.columnDef.watermark }}
          />
        </Preview>
        <Icon
          className="template-zoom"
          name="magnifying-glass-plus"
          size={4}
          color="neutral-400"
        />
      </div>
      <Text size={2} tag="strong">{original.name}</Text>
    </Grid>
  )
}

const typeCell = ({ cell: { renderValue } }) => (
  <div className="level-preview-card-badge" data-type={renderValue()}>{renderValue()}</div>
)

const statusCell = ({ row: { original } }) => {
  const { color, text } = templateStatusText(original)
  return <Text color={color}>{text}</Text>
}

const dateCell = ({ row: { original } }) => (
  <Text nowrap color="neutral-400" size={1}>{templateUpdateText(original)}</Text>
)

const HoverTemplate = ({ templates, watermark }) => {
  const [templateId, setTemplateId] = React.useState(false)
  useChannel('hover/template', ({ data }) => setTemplateId(data), [])
  const template = templates?.find(({ id }) => id === templateId)
  return template ? (
    <Card
      style={{
        width: '40vw',
        position: 'fixed',
        top: '130px',
        right: '20px',
        zIndex: '1',
        borderRadius: '8px',
      }}
      animate="pop"
      shadow
    >
      <div style={{
        overflow: 'hidden',
        borderRadius: '5px',
        height: '400px',
        maxHeight: '80vh',
      }}
      >
        <Preview zoom={template.type === 'sms' ? 1 : 0.65}>
          <Template
            height="1000px"
            type={template.type}
            mode="preview"
            config={template.previewConfig}
            hooks={{ watermark }}
          />
        </Preview>
      </div>
    </Card>
  ) : null
}

const TemplateList = ({
  templates, watermark, refresh, ActionButton, path,
}) => {
  const actionCell = React.useCallback(({ row: { original } }) => (
    <ActionButton template={original} refresh={refresh} />
  ), [refresh])

  const columns = React.useMemo(() => {
    const cols = [
      { name: { header: 'Name', cell: nameCell, watermark } },
    ]
    // Shared sections do not need a type column
    if (path !== 'shared-section') {
      cols.push({ type: { cell: typeCell, width: '1%' } })
    }
    return [
      ...cols,
      { published: { header: 'Status', cell: statusCell } },
      { date: { cell: dateCell, width: '1%' } },
      {
        action: {
          header: '',
          cell: actionCell,
          type: 'display',
          width: '1%',
          refresh,
        },
      },
    ]
  }, [refresh])

  const table = useTable({
    data: templates,
    columnNames: columns,
    enableRowSelection: true,
    singleRowSelection: true,
  })
  const selection = table.getSelectedRowModel().rows[0]?.original
  if (selection) {
    return <Navigate to={`${path}/${selection.slug}`} />
  }
  return (
    <div style={{ position: 'relative' }}>
      <Table className="template-index-list" table={table} theme="white" />
      <HoverTemplate templates={templates} watermark={watermark} />
    </div>
  )
}

TemplateList.propTypes = {
  templates: PropTypes.array.isRequired,
  watermark: PropTypes.bool.isRequired,
  refresh: PropTypes.func.isRequired,
  ActionButton: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
}

export {
  TemplateList,
}
