import React from 'react'
import PropTypes from 'prop-types'

import { Input } from '../Input'
import { Form } from '../Form'
import { Link } from '../Link'

import './quick-edit.scss'

const QuickEditInput = ({
  name,
  value,
  onSubmit,
  onChange,
  ...rest
}) => {
  const [showInput, setShowInput] = React.useState(false)

  if (showInput) {
    return (
      <Form focus="first" onSubmit={onSubmit}>
        <Input
          name={name}
          className="level-quick-edit-input"
          label={false}
          type="text"
          defaultValue={value}
          onChange={(e) => onChange({ [name]: e.target.value })}
          onBlur={() => setShowInput(false)}
          submitOnBlur
        />
      </Form>
    )
  }
  return (
    <Link
      theme="quick-edit"
      onClick={() => setShowInput(true)}
      onFocus={() => setShowInput(true)}
      text={value}
      role="button"
      title={`edit ${name}`}
      {...rest}
    />
  )
}

QuickEditInput.propTypes = {
  ...Input.propTypes,
  onSubmit: PropTypes.func.isRequired,
}

export {
  QuickEditInput,
}
