const cleanPercentage = (percentage) => {
  const tooLow = !Number.isFinite(+percentage) || percentage < 0
  const tooHigh = percentage > 100
  if (tooLow) return 0
  return tooHigh ? 100 : +percentage
}

const toPercentage = (count, total) => (
  total ? Math.floor(cleanPercentage((count / total) * 100) * 10) / 10 : 0
)

const toCleanPercentage = (...args) => cleanPercentage(toPercentage(...args))

// Return a value bounded with a minimum and maximum value
const minMax = (val, min, max) => {
  if (val < min) return min
  if (max < val) return max
  return val
}

// Given a number, return a sensible step size for rounding it dividing it into smaller parts
const getStepResolution = (num) => {
  const brackets = [[50, 10], [100, 25], [1000, 100], [10000, 500], [20000, 1000]].reverse()
  let resolution
  for (let i = 0; i < brackets.length; i += 1) {
    const [bracket, step] = brackets[i]
    if (num < bracket) resolution = step
  }
  return resolution || 1000
}

const getChartHigh = (num = 0) => {
  const res = getStepResolution(num)
  let highest = Math.ceil(num / res) * res
  while (highest <= num) {
    highest += res
  }
  return highest
}

const getChartSteps = (num, stepCount = 5) => {
  const step = num / stepCount
  return [...Array.from({ length: stepCount }, (_, i) => (
    Math.round((num - i * step) * 100) / 100)), 0]
}

const abbreviateNumber = (number) => {
  const suffixes = ['', 'K', 'M', 'B']
  let tier = 0
  let num = number

  for (; num >= 1000 && tier < suffixes.length - 1; num /= 1000) {
    tier += 1
  }

  return num.toFixed(1).replace(/\.0$/, '') + suffixes[tier]
}

export {
  toPercentage,
  cleanPercentage,
  toCleanPercentage,
  minMax,
  getChartSteps,
  getChartHigh,
  abbreviateNumber,
}
