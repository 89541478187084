import React from 'react'

import { RadioFilter } from '@level'
import { MessageIcon } from '../icon'
import { operators } from '../queries'

const TypeFilter = () => {
  const options = [
    { label: 'All', value: '', icon: <MessageIcon name="all" /> },
    { value: 'email', icon: <MessageIcon name="email" /> },
    { value: 'sms', icon: <MessageIcon name="sms" /> },
  ]

  return (
    <RadioFilter
      name="type"
      operators={operators}
      options={options}
    />
  )
}

export {
  TypeFilter,
}
