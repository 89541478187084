import React from 'react'
import {
  Main, Body, Card, Stack,
  Input, Form, ButtonShelf, SubmitButton,
  Text, Shelf, Column, AvatarInput,
  toParams,
} from '@level'

import {
  useFormQuery, useProfile,
} from '@app/hooks'

import { AccountInvitations } from './invitations'

const updateProfileQuery = (attributes) => `mutation {
  profile: updateProfile(attributes: { ${toParams(attributes)} }) {
    id
  }
}`

const AccountSettings = () => {
  const { Profile, invitations, refresh } = useProfile()
  const { onSubmit: updateProfile } = useFormQuery({
    upload: 'avatar',
    query: updateProfileQuery,
    onSuccess: () => refresh(),
  })
  return (
    <Main>
      <Body scroll>
        <Stack gap={9} style={{ maxWidth: '700px' }}>
          <Stack gap={6}>
            { invitations.length ? <AccountInvitations /> : null }
            <Text tag="h3">Account Settings ({Profile.email})</Text>
            <Card>
              <Form onSubmit={updateProfile}>
                <Stack gap={9}>
                  <Shelf gap={7}>
                    <Column grow>
                      <Stack gap={5}>
                        <Input label="Your Name" name="name" defaultValue={Profile.name} autoComplete={false} required />
                        <Input label="Phone Number" name="phone" defaultValue={Profile.phone} autoComplete={false} />
                      </Stack>
                    </Column>
                    <AvatarInput
                      object={Profile}
                      name="avatar"
                      shape="circle"
                      label="Your Avatar"
                    />
                  </Shelf>
                  <ButtonShelf>
                    <SubmitButton text="Update Profile" />
                  </ButtonShelf>
                </Stack>
              </Form>
            </Card>
          </Stack>
        </Stack>
      </Body>
    </Main>
  )
}

export {
  AccountSettings,
  updateProfileQuery,
}
