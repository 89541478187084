import PropTypes from 'prop-types'
import { Button } from '../Button'
import { useCopyText } from '../../hooks'
import './copy-button.scss'

const CopyButton = ({
  text, copyRef, label, onCopy, className = '', ...rest
}) => {
  const { copyText, copied } = useCopyText()

  const onClick = () => {
    let copy = copyRef?.current || text
    if (onCopy) {
      const result = onCopy()
      if (result) copy = result
    }
    copyText(copy)
  }

  return (
    <Button
      data-copied={copied || null}
      icon={{
        name: copied ? 'clipboard-check' : 'copy',
        size: rest.size || 3,
      }}
      onClick={onClick}
      tip="Copy to clipboard"
      className={`level-copy-button ${className || ''}`}
      text={label}
      theme="wrapper"
      {...rest}
    />
  )
}

CopyButton.propTypes = {
  text: PropTypes.string,
  copyRef: PropTypes.object,
  label: PropTypes.string,
  onCopy: PropTypes.func,
  theme: PropTypes.string,
  className: PropTypes.string,
}

export {
  CopyButton,
}

