import React from 'react'
import { useMessages } from './useMessages'

const useDialog = () => {
  const { publish, clear } = useMessages({ channel: 'dialog' })
  const sendDialog = async ({
    onConfirm: confirm,
    onCancel: cancel,
    onAtlCancel: altCancel,
    ...rest
  }) => (
    new Promise((resolve, reject) => {
      const onConfirm = confirm ? async (...args) => {
        const result = await confirm(...args)
        if (result?.errors) {
          throw result.errors
        } resolve(result)
        return result
      } : confirm

      const onCancel = async () => {
        if (cancel) await cancel()
        return reject()
      }

      publish({
        onConfirm, onCancel, ...rest,
      })
    }).catch(() => {})
  )

  React.useEffect(() => () => clear(), [])

  return sendDialog
}

export {
  useDialog,
}
