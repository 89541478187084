import React from 'react'

const clamp = (value, min, max) => {
  if (value < min) return min
  if (value > max) return max
  return value
}

const getBounds = (ref, gutter) => {
  if (!ref.current) return null
  const rect = ref.current.getBoundingClientRect()
  return {
    left: rect.left,
    width: ref.current.offsetWidth - gutter,
    top: rect.top,
    height: ref.current.offsetHeight - gutter,
  }
}

const clampPosition = (event, ref, gutter) => {
  const {
    left, top, width, height,
  } = getBounds(ref, gutter)
  const x = clamp(event.clientX - left, 0, width)
  const y = clamp(event.clientY - top, 0, height)
  const xPercent = Math.round(100 * (x / (width)))
  const yPercent = Math.round(100 * (y / (height)))
  return {
    x, y, xPercent, yPercent,
  }
}

// Ideally mousedown should be handled on the drag event.
const usePointerPosition = ({ ref, onMove } = {}) => {
  // Ref to store whether the mouse is down
  const isMouseDown = React.useRef(false)
  const isMouseOver = React.useRef(false)
  const onMoveFn = React.useRef(onMove)

  React.useEffect(() => {
    const handleMouseUp = () => {
      isMouseDown.current = false
      document.body.removeEventListener('mouseup', handleMouseUp)
      document.body.style.userSelect = null
      document.body.style.webkitUserSelect = null
    }

    const handleMouseDown = (event) => {
      isMouseOver.current = true
      isMouseDown.current = true
      document.body.addEventListener('mouseup', handleMouseUp)
      document.body.style.userSelect = 'none'
      document.body.style.webkitUserSelect = 'none'
      if (ref.current) onMoveFn.current?.(event)
    }

    const handleMouseMove = (event) => {
      if (isMouseDown.current && isMouseOver.current) {
        if (ref.current) onMoveFn.current?.(event)
      }
    }

    ref.current?.addEventListener('mousedown', handleMouseDown)
    document.addEventListener('mousemove', handleMouseMove)
    /* ref.current.addEventListener('mouseenter', handleMouseEnter); */
    /* ref.current.addEventListener('mouseleave', handleMouseLeave) */

    return () => {
      ref.current?.removeEventListener('mousedown', handleMouseDown)
      /* ref.current.removeEventListener('mouseleave', handleMouseLeave) */
      document.body.removeEventListener('mouseup', handleMouseUp)
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [ref])

  React.useEffect(() => {
    onMoveFn.current = onMove
  }, [onMove])

  return {
    isMouseOver,
    isMouseDown,
  }
}

const useDragPosition = ({ ref, onMove: onMoveCallback, gutter = 0 } = {}) => {
  const onMoveFn = React.useRef(onMoveCallback)

  const onMove = React.useCallback((event) => {
    onMoveFn.current(clampPosition(event, ref, gutter))
  }, [onMoveFn])

  return usePointerPosition({ ref, onMove })
}

export {
  usePointerPosition,
  useDragPosition,
}
