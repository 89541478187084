import React from 'react'
import {
  Main, Drawer,
  TemplateConfigProvider, TemplateConfigIndex,
  ScrollPanel,
  FileDrop,
  useTemplateConfig, useCleanPaste, renderTemplate,
} from '@level'

import { useUploadImage, useTeam } from '@app/hooks'
import { useTemplate } from '../context'
import { useSharedSections } from '../../sharedSections/provider'
import { SharedSectionPanel } from '../../sharedSections/panel'
import { PreviewEmailTemplate } from './previewEmailTemplate'

const TemplateDrawer = () => (
  <Drawer>
    <ScrollPanel>
      <TemplateConfigIndex />
    </ScrollPanel>
  </Drawer>
)

const TemplateBuilderMain = () => {
  const { dropImage, ...props } = useTemplateConfig()

  if (!dropImage) {
    return (
      <Main drawer={<TemplateDrawer />}>
        <PreviewEmailTemplate {...props} mode="builder" />
      </Main>
    )
  }

  return (
    <Main drawer={<TemplateDrawer />}>
      <FileDrop type="image" onDrop={dropImage}>
        <PreviewEmailTemplate {...props} mode="builder" />
      </FileDrop>
    </Main>
  )
}

const EmailTemplateBuilder = () => {
  const { template, channel, ...rest } = useTemplate()
  const { sharedSections, saveToSharedSection } = useSharedSections()
  const uploadImage = useUploadImage()
  const { Team } = useTeam()
  const [imageHeights, setImageHeights] = React.useState({})

  // Any keyboard paste events will be set to plain text
  useCleanPaste()

  // Message is received and acted on templates/context
  const onChange = React.useCallback(async ({ config: previewConfig }) => {
    const body = renderTemplate({ config: previewConfig, type: 'email' })
    const config = previewConfig ? {
      ...previewConfig,
      body,
    } : previewConfig
    channel.postMessage({ update: { config } })
  }, [template?.id])

  // const { Profile: { email } } = useProfile()
  // const reloadTemplate = (msg) => {
  //   const { user: userEmail, templateId } = msg.data

  //   const templateMatch = template.id === templateId.toString()
  //   const userMatch = email === userEmail
  //   const updateTemplate = templateMatch && !userMatch

  //   if (updateTemplate) {
  //     // TODO: reload template
  //   }
  // }

  // useTeamChannel({ event: 'templateUpdated', handler: reloadTemplate })
  if (!template) return null

  return (
    <TemplateConfigProvider
      {...rest}
      {...template}
      imageHeights={imageHeights}
      setImageHeights={setImageHeights}
      config={template.previewConfig}
      onChange={onChange}
      uploadImage={uploadImage}
      saveToSharedSection={saveToSharedSection}
      SharedSectionPanel={SharedSectionPanel}
      sharedSections={sharedSections}
      channel={channel}
      watermark={Team.plan.watermark}
    >
      <TemplateBuilderMain />
    </TemplateConfigProvider>

  )
}

export {
  EmailTemplateBuilder,
  TemplateBuilderMain,
}
