import { postFormData } from '@level/helpers'
import React from 'react'
import { useAuthedHeaders } from './Auth'

const useUploadFile = ({ url = `${import.meta.env.VITE_API_BASE_URL}/upload` }) => {
  const headers = useAuthedHeaders()

  return React.useCallback(async (file) => {
    const result = await postFormData({
      headers,
      url,
      data: { file },
    })
    return result.json()
  }, [])
}

export {
  useUploadFile
}
