const defaults = {
  variables: { config: {} },
}

const blank = {
  name: '',
  config: () => ({
    ...defaults,
    body: '',
  }),
}

const newAccount = {
  name: 'New Account',
  config: ({ Team = { name: 'CompanyName' } }) => ({
    ...defaults,
    body: `Welcome to ${Team.name}!
Thanks for signing up. You can access your account here:

{{{ account_url }}}
`,
  }),
}

const userInvite = {
  name: 'User Invite',
  config: ({ Team = { name: 'CompanyName' }, Project = { name: 'ProductName' } }) => ({
    ...defaults,
    body: `${Team.name}: {{ sender }} has invited you to collaborate on ${Project.name}. Click on the link below to start collaborating.

{{{ invite_url }}}
`,
  }),
}

const confirmAccount = {
  name: 'Confirm Account',
  config: ({ Team = { name: 'CompanyName' } }) => ({
    ...defaults,
    body: `${Team.name}: Please take a moment to verify your account.

{{{ verify_url }}}`,
  }),
}

const forgotPassword = {
  name: 'Forgot Password',
  config: ({ Team = { name: 'CompanyName' } }) => ({
    ...defaults,
    body: `${Team.name}: Forgot your password? Tap the link below to reset your password:

{{{ reset_url }}}`,
  }),
}

const magicLink = {
  name: 'Magic Link',
  config: ({ Team = { name: 'CompanyName' } }) => ({
    ...defaults,
    body: `${Team.name}: Tap the link below to login to your account:

{{{ url }}}`,
  }),
}

const orderConfirmation = {
  name: 'Order Confirmation',
  config: ({ Team = { name: 'CompanyName' } }) => ({
    ...defaults,
    body: `${Team.name}: Your order (#{{{orderNumber}}}) is confirmed! Tap the link below to view your receipt:

{{{ receipt_url }}}`,
  }),
}

const templates = [
  blank,
  newAccount,
  userInvite,
  confirmAccount,
  forgotPassword,
  magicLink,
  orderConfirmation,
]

export {
  templates,
}
