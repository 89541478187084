import { rootUrl } from '@app/helpers'
import { ActAsUser, actAsUserRoute, useUpdateNotice } from '@app/hooks'
import { ClipboardProvider, NotFound } from '@level'
import { Navigate, Route, Routes } from 'react-router-dom'
import { AccountIndex } from './account'
import { AdminIndex } from './admin'
import { AuthIndex, AuthProvider } from './auth'
import { Invitation } from './invitations/Invitation'
import { TeamsIndex } from './teams'
import { VerifyIdentity } from './teams/mailSettings/verified'
import { TemplateView } from './templates/templateView'
import { Try } from './try'

const AppIndex = () => {
  useUpdateNotice()

  return (
    <AuthProvider>
      <ClipboardProvider>
        <Routes>
          <Route path={rootUrl()}>
            <Route path={actAsUserRoute} element={<ActAsUser />} />
            <Route path="try/*" element={<Try />} />
            <Route path="account/*" element={<AccountIndex />} />
            <Route path="admin/*" element={<AdminIndex />} />
            <Route path="teams/*" element={<TeamsIndex />} />
            <Route path="template/*" element={<TemplateView />} />
            <Route path="auth/*" element={<AuthIndex />} />
            <Route path="invitation/*" element={<Invitation />} />
            <Route path="invites/*" element={<Navigate to="/account" />} />
            <Route path="verifysuccess/*" element={<VerifyIdentity success />} />
            <Route path="verifyfailure/*" element={<VerifyIdentity />} />
            <Route index element={<Navigate to="teams" />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </ClipboardProvider>
    </AuthProvider>
  )
}

export {
  AppIndex
}
