import PropTypes from 'prop-types'
import { Text } from '../Text'

const TruncateCell = ({ getValue }) => <Text title={getValue()} truncate>{getValue()}</Text>

TruncateCell.propTypes = {
  getValue: PropTypes.func.isRequired,
}

export {
  TruncateCell
}
