import React from 'react'
import PropTypes from 'prop-types'

import { useNavigate } from 'react-router-dom'
import {
  sortColors, addToTheme, templateColors, sectionColors, copyObject, uniqueArray,
} from '../../../../helpers'
import { Tooltip } from '../../../Tooltip'
import { Block } from '../../../Block'
import { Stack } from '../../../Stack'
import { Link } from '../../../Link'
import { Grid } from '../../../Grid'
import { Icon } from '../../../Icon'
import { Swatch } from '../../../Swatch'
import { Button, SubmitButton } from '../../../Button'
import { Form } from '../../../Form'
import { Text } from '../../../Text'
import { Input, HiddenInput } from '../../../Input'
import { ColorInput } from '../../../Input/Color'
import {
  useFieldArray, useFormRef, useOnPaste, useChannel,
} from '../../../../hooks'
import { useCopyTheme } from '../useCopyTheme'

const themeToFields = (theme) => (
  theme ? Object.values(theme) : null
)

const getConfigType = (config) => (config.sections ? 'Template' : 'Section')

const getConfigColors = (config) => (
  getConfigType(config) === 'Section'
    ? sectionColors({ section: config })
    : templateColors({ config })
)

const colorProps = ({ value, from, key }) => {
  let name = ''
  if (from === 'template') {
    name = {
      color: 'text',
      backgroundColor: 'templateBg',
      contentBackgroundColor: 'contentBg',
      linkColor: 'linkColor',
    }[key]
  }
  return { name, value, type: 'color' }
}

const getColorFields = (config) => (
  getConfigColors(config).map((c) => (colorProps(c)))
)

const ThemeSwatches = ({ config, theme }) => {
  const colors = React.useMemo(() => (
    theme
      ? uniqueArray(Object.values(theme).map(({ value }) => value))
      : sortColors(getConfigColors(config).map(({ value }) => value))
  ), [theme, config])
  return (
    <Grid gap={1} columns="auto" style={{ overflow: 'hidden' }}>
      {colors.map((color) => (
        <Swatch color={color} key={color} size={29} />
      ))}
    </Grid>
  )
}

ThemeSwatches.propTypes = {
  config: PropTypes.object,
  theme: PropTypes.object,
}

const EditTheme = ({
  config, onChange, close, hasTheme,
}) => {
  const formRef = useFormRef()
  const { setValue } = formRef
  const { fields, append, remove } = useFieldArray({ name: 'theme' })
  const channel = useChannel('hover/theme')

  const resetTheme = () => {
    onChange({ reset: true }, formRef)
    close()
  }

  const colors = getConfigColors(config)

  // If there is no theme, we will generate one from their template's colors
  React.useEffect(() => {
    if (fields.length === 0 && !config.variables._theme) {
      const newFields = getColorFields(config)
      append(newFields)
      setValue('theme', newFields, { shouldDirty: true })
      onChange({ theme: newFields }, formRef)
    }
  }, [])

  if (!fields.length) {
    return (
      <Stack gap={8}>
        <Block flush={7} space={[6, 8]} style={{ backgroundColor: '#F5F6F7', borderTop: '1px solid #DEDFE2', borderBottom: '1px solid #DEDFE2' }}>
          <Stack gap={5} space={[5, 0]}>
            <Text tag="h4" color="text-light">Empty Theme</Text>
            <Text tag="p">Tap the plus button below to add a color. You can also copy and paste themes from other templates.</Text>
          </Stack>
        </Block>
        <Grid templateColumns="auto 1fr auto auto" align="split" gap={4}>
          <Button tip="Add color" icon="plus" onClick={() => append({ name: '', value: '', type: 'color' })} />
          <span />
          <Button text="Cancel" theme="text" onClick={resetTheme} />
          <SubmitButton text={hasTheme.current ? 'Remove Theme' : 'Save'} theme={hasTheme.current ? 'destructive' : 'primary'} />
        </Grid>
      </Stack>
    )
  }

  return (
    <Stack gap={8}>
      <Block flush={7} space={[6, 8]} style={{ backgroundColor: '#F5F6F7', borderTop: '1px solid #DEDFE2', borderBottom: '1px solid #DEDFE2' }}>
        <Stack gap={4}>
          <Grid templateColumns="1.3fr 1fr 16px" valign="center" gap={3}>
            <Text className="level-label">Color</Text>
            <Text className="level-label">Variable Name</Text>
            <span />
          </Grid>
          <Stack gap={3}>
            {fields.map((field, index) => (
              <div key={field.id}>
                <HiddenInput type="hidden" name={`theme.${index}.type`} />
                <Grid templateColumns="1.3fr 1fr 16px" valign="center" gap={4}>
                  <ColorInput
                    colors={colors}
                    type="text"
                    label={false}
                    name={`theme.${index}.value`}
                    defaultValue={fields[index]?.value}
                    placeholder="#c0ff33"
                    showNone={false}
                    swatchProps={{
                      onMouseEnter: () => {
                        const c = formRef.getValues(`theme.${index}.value`)
                        channel.postMessage(c)
                      },
                      onMouseLeave: () => channel.postMessage(null),
                    }}
                    required
                  />
                  <Input
                    type="text"
                    label={false}
                    name={`theme.${index}.name`}
                    placeholder="varName"
                    spellCheck={false}
                    autoComplete="off"
                    required
                    validate={(value, { theme }) => {
                      if (value.match(/^[0-9]*?$/)) {
                        return 'Variable name cannot begin with a number'
                      }
                      if (!value.match(/^[a-zA-Z_][a-zA-Z0-9_]*$/)) {
                        return 'Variable name must be alphanumeric'
                      }
                      if (theme.filter(({ name }) => name === value).length > 1) {
                        return 'Variable names must be unique'
                      }
                      return true
                    }}
                  />
                  <Button icon="trash" label="remove" theme="destructive-ghost" space={[5, 1]} onClick={() => remove(index)} />
                </Grid>
              </div>
            ))}
          </Stack>
        </Stack>
      </Block>
      <Grid templateColumns="auto 1fr auto auto" align="split" gap={4}>
        <Button tip="Add Color" icon="plus" onClick={() => append({ name: '', value: '', type: 'color' })} />
        <span />
        <Button text="Cancel" theme="text" onClick={resetTheme} />
        <SubmitButton text={hasTheme.current ? 'Save Changes' : 'Create Theme'} />
      </Grid>
    </Stack>
  )
}

EditTheme.propTypes = {
  config: PropTypes.object,
  onChange: PropTypes.func,
  close: PropTypes.func,
  hasTheme: PropTypes.object,
}

const fieldsToTheme = ({ theme }) => {
  if (!theme || theme?.length === 0) return null
  return addToTheme(theme)._theme
}

const ThemeForm = ({
  onSubmit, fields, close, config, hasTheme,
}) => {
  const initialConfig = React.useRef(copyObject(config))
  const saved = React.useRef(false)
  const type = getConfigType(config)
  const currentFields = React.useRef(fields)
  const [reset, setReset] = React.useState({})
  useOnPaste(({ name }) => {
    if (name === 'theme') { window.setTimeout(() => setReset({}), 100) }
  })

  const onChange = (data, formRef, publish = false) => {
    config.body = copyObject(initialConfig.current.body)
    config.variables = copyObject(initialConfig.current.variables)
    if (type === 'Template') config.sections = copyObject(initialConfig.current.sections)
    if (data.reset) {
      // Reset to initial _theme
      onSubmit({
        _theme: initialConfig.current.variables._theme || null,
        localOnly: !saved.current,
      })
    } else if (formRef) {
      const { errors } = formRef.formState
      if (!errors?.theme?.length) {
        const _theme = fieldsToTheme(data)
        currentFields.current = data.theme
        onSubmit({ _theme, localOnly: !publish })
        if (publish) saved.current = true
      }
    }
  }
  const handleSubmit = ({ data, formRef }) => {
    onChange(data, formRef, true)
    close()
  }

  // on unmount, revert if not saved
  React.useEffect(() => () => {
    if (!saved.current) {
      onChange({ reset: true })
    }
  }, [saved])
  return (
    <Form
      onSubmit={handleSubmit}
      onChange={onChange}
      mode="onChange"
      reset={reset}
      shouldFocusError={false}
      defaultValues={{ theme: fields }}
    >
      <EditTheme config={config} close={close} onChange={onChange} hasTheme={hasTheme} />
    </Form>
  )
}

ThemeForm.propTypes = {
  config: PropTypes.object,
  hasTheme: PropTypes.object,
  close: PropTypes.func,
  fields: PropTypes.array,
  onSubmit: PropTypes.func,
}

const ConfigureTheme = (props) => {
  const {
    config: { variables: { _theme } },
    theme,
  } = props
  const hasTheme = React.useRef(!!_theme)
  const navigate = useNavigate()
  const copyTheme = useCopyTheme({ _theme })

  return (
    <div>
      <Block space={[7, 7, 2]}>
        <Stack gap={6}>
          <Grid columns="auto" align="split" valign="center">
            <Text tag="h3">{hasTheme.current ? 'Edit' : 'New'} Theme</Text>
            {hasTheme.current && Object.values(_theme || {})?.length ? <Button onClick={copyTheme} icon="share-from-square" tip="copy theme" /> : null}
          </Grid>
          <Text tag="p">Template themes make it easier to reuse colors. You can even override colors from the sending API. <Link to="https://docs.enveloop.com/enveloop-api/core-api-endpoints/post-messages#theme-optional" target="_blank">learn more</Link></Text>
        </Stack>
      </Block>
      <Block space={7}>
        <ThemeForm
          {...props}
          hasTheme={hasTheme}
          fields={themeToFields(theme)}
          close={() => navigate('..')}
        />
      </Block>
    </div>
  )
}

ConfigureTheme.propTypes = {
  config: PropTypes.object,
  theme: PropTypes.object,
}

const ThemeConfig = (props) => {
  const {
    config, config: { variables: { _theme } },
    theme,
  } = props
  const hasTheme = React.useRef(!!_theme)
  const type = getConfigType(config)
  const navigate = useNavigate()
  return (
    <Block space={7}>
      <Stack gap={5} align="stretch">
        <Text className="level-label">
          {hasTheme.current ? `${type} Theme` : `${type} colors`}
        </Text>
        <div>
          <Button theme="input" space={0} onClick={() => navigate('./theme')} grow>
            <Grid space={3} valign="center" columns="auto" align="split">
              <ThemeSwatches config={config} theme={theme} />
              {hasTheme.current ? (
                <Tooltip tip="Edit Theme">
                  <Icon name="sliders-up" space={[3, 5]} />
                </Tooltip>
              ) : (
                <Tooltip tip="Add Theme">
                  <Icon name="plus" space={[3, 4]} />
                </Tooltip>
              )}
            </Grid>
          </Button>
        </div>
        {!hasTheme.current ? (
          <Text size={1}>Add a theme to make it easy to reuse colors.</Text>
        ) : null}
      </Stack>
    </Block>
  )
}

ThemeConfig.propTypes = {
  config: PropTypes.object,
  theme: PropTypes.object,
}

export {
  ThemeConfig,
  ConfigureTheme,
}
