import { useDialog } from '@level'
import { useFormQuery } from './useFormQuery'

const useFormDialog = (props = {}) => {
  const {
    data: formData,
    hooks: formHooks,
    ...formQueryProps
  } = props
  const sendDialog = useDialog()
  const { onSubmit: onConfirm } = useFormQuery(formQueryProps)

  return ({
    data, hooks, ...dialogProps
  }) => sendDialog({
    onConfirm,
    form: {
      data: { ...formData, ...data },
      hooks: { ...formHooks, ...hooks },
    },
    ...dialogProps,
  })
}

export {
  useFormDialog
}
