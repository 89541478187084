import { useFormQuery, useProfile } from '@app/hooks'

const confirmInvitationQuery = ({ id, accept }) => `mutation {
  invitation: confirmTeamMemberInvite(id: "${id}", accept: ${accept}) { id acceptedAt }
}`

// Returns invitations and an onSubmit handler which submits a form with InvitationsInput component
const useInvitations = (options = {}) => {
  const { refreshOnSubmit = true } = options
  const { invitations, refresh } = useProfile()

  const { onSubmit: sendQuery } = useFormQuery({
    query: confirmInvitationQuery,
    successMessage: ({ formData: { accept, id } }) => {
      const { name } = invitations.find((i) => i.id === String(id)).team
      return `You ${accept === 'true' ? 'accepted' : 'rejected'} the invitation from ${name}.`
    },
  }, [invitations])

  // Because there might be multiple invitations, the queries will be executed async promises
  const onSubmit = async ({ data }) => {
    await Promise.all(data.invitations.map(
      ({ accept }, id) => sendQuery({
        data: {
          id,
          accept,
        },
      }),
    ))
    if (refreshOnSubmit) refresh()
    return data
  }

  return {
    invitations,
    refresh,
    onSubmit,
  }
}

export {
  useInvitations,
}
