import React from 'react'
import { toParams, includeKeys, camelize } from '@level'

const eventNames = [
  'delivered',
  'opened',
  'clicked',
  'failed',
  'complaint',
]

const eventsConfig = eventNames.map((e) => camelize(`on_${e}`))

const configToEvent = (config) => (
  eventNames[eventsConfig.findIndex((c) => c === config)]
)

const createEventWebhookQuery = ({
  Project, url, enabled, includeBody, ...rest
}) => {
  const events = includeKeys(rest, eventsConfig)
  const attributes = toParams({
    url, enabled, includeBody, ...events,
  })
  return `mutation {
    webhook: createEventWebhook(team: "${Project.team.slug}", project: "${Project.slug}", attributes: { ${attributes}}) {
      id
    }
  }`
}

const updateEventWebhookQuery = ({
  id, url, enabled, includeBody, ...rest
}) => {
  const events = includeKeys(rest, eventsConfig)
  const attributes = toParams({
    url, enabled, includeBody, ...events,
  })
  return `mutation {
    webhook: updateEventWebhook(id: "${id}", attributes: { ${attributes} }) {
      id
    }
  }`
}

const deleteEventWebhookQuery = ({ id }) => `mutation {
  webhook: deleteEventWebhook(id: "${id}") {
    id
  }
}`

const eventWebhooksQuery = ({ Project }) => `Project: project(team: "${Project.team.slug}", project: "${Project.slug}") {
  slug
  team { slug }
  eventWebhooks {
    id enabled url includeBody createdAt ${eventsConfig.join(' ')}  
  }
}`

const EventWebhooksContext = React.createContext({})
const useEventWebhooks = () => React.useContext(EventWebhooksContext)

export {
  createEventWebhookQuery,
  updateEventWebhookQuery,
  deleteEventWebhookQuery,
  eventWebhooksQuery,
  eventNames,
  eventsConfig,
  configToEvent,
  EventWebhooksContext,
  useEventWebhooks,
}
