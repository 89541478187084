import React from 'react'
import { Navigate } from 'react-router-dom'
import {
  Main, Text, P, ScrollPanel, Drawer, Block, Divider, Stack, Template, Link, Card, Button,
} from '@level'
import { PreviewEmailTemplate } from '../templates/email/previewEmailTemplate'

import { DeployCode } from '../templates/deploy/drawer'

const TemplateDeployDrawer = ({ template }) => {
  const data = React.useMemo(() => ({
    to: 'to.whom@example.com',
    template: 'template-name',
    templateVariables: Template.getTemplateVars({ template }),
    type: template.type,
  }), [template])

  return (
    <Drawer>
      <ScrollPanel>
        <Block space={7}>
          <Stack gap={5}>
            <Text tag="h3">Deploying is Easy</Text>
            <P>
              With an Enveloop account,
              you can send email and text messaages with our simple API.
            </P>
          </Stack>
        </Block>
        <Divider />
        <DeployCode
          tokens={[{ token: 'YOUR_TOKEN_VALUE' }]}
          data={data}
        />
        <Block space={[0, 7]}>
          <Card>
            <Stack gap={6}>
              <P>
                With free plans including <strong>10,000 free messages</strong> each month,
                it&rsquo;s easy to try out Enveloop.
              </P>
              <Button to="/auth/new">Create a Free Account</Button>
            </Stack>
          </Card>
        </Block>
      </ScrollPanel>
    </Drawer>
  )
}

const TemplateDeploy = ({ template }) => {
  if (!template) return <Navigate to="../build" />

  const config = template.previewConfig

  return (
    <Main drawer={<TemplateDeployDrawer template={template} />}>
      <PreviewEmailTemplate
        type={template.type}
        config={config}
        mode="preview"
      />
    </Main>
  )
}

export {
  TemplateDeploy,
}
