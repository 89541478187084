import { toSearchString } from './object'

const postJson = async ({
  url,
  data,
  body,
  signal,
  headers = {},
  method = 'POST',
  handleErrors,
}) => {
  const fetchObj = {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    body: body || JSON.stringify(data),
    signal,
  }

  const response = await fetch(url, fetchObj)
  let json = {}
  let error

  try {
    json = await response.json()
    if (json.error) error = json.error
  } catch (e) {
    error = e.message
  }

  if (error && handleErrors) {
    handleErrors(error)
  }

  return { data: json }
}

const postFormData = ({
  url,
  signal,
  data = {},
  method = 'POST',
  headers = {},
}) => {
  const body = new FormData()
  Object.keys(data).forEach((key) => {
    body.append(key, data[key])
  })
  return fetch(url, {
    headers,
    method,
    body,
    signal,
  })
}

const getJson = ({
  url,
  query,
  signal,
  headers = {},
}) => fetch(`${url}?${toSearchString(query)}`, {
  headers: {
    'Content-Type': 'application/json',
    ...headers,
  },
  signal,
})

export {
  getJson,
  postJson,
  postFormData,
}
