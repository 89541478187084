import React from 'react'
import PropTypes from 'prop-types'

import { useParams } from 'react-router-dom'
import { QueryProvider, useProject } from '@app/hooks'
import { useSearchState, includeFilterParams } from '@level'
import { errorsQuery, operators } from './queries'

const templatesQuery = ({ teamId, projectId }) => (
  `templates(team: "${teamId}", project: "${projectId}") { id name slug type published }`
)

const templateLink = ({
  templates, template,
}) => {
  if (template && templates.find(({ slug }) => slug === template)) {
    return `templates/template/${template}`
  }
  return undefined
}

const TemplateContext = React.createContext()
const useErrorTemplates = () => React.useContext(TemplateContext)

const ErrorTemplateProvider = (props) => {
  const { teamId, projectId } = useParams()

  const onData = React.useCallback(({ data }) => ({
    templates: data.templates.filter(({ published }) => published) || [],
  }), [])
  return (
    <QueryProvider
      query={templatesQuery({ teamId, projectId })}
      context={TemplateContext}
      onData={onData}
      {...props}
    />
  )
}

const ErrorLogContext = React.createContext()
const ErrorLogProvider = ({ testMode = false, ...rest }) => {
  const {
    url,
    Project: {
      team: { slug: teamId },
      slug: projectId,
    },
  } = useProject()

  const [searchState] = useSearchState()
  // This will prevent changes which do not affect the query from triggering a refresh
  // Some Params store UI state and do not impact the query.
  const filters = includeFilterParams(searchState, operators)
  const { templates } = useErrorTemplates()

  const queryVars = React.useMemo(() => ({
    teamId, projectId, testMode, ...filters,
  }), [errorsQuery({
    teamId, projectId, testMode, ...filters,
  })])

  const onData = React.useCallback(({ data }) => ({
    errorLogs: data.errorLogs?.map((error) => {
      const link = templateLink({
        teamId, projectId, templates, template: error.body?.template,
      })
      return {
        ...error,
        templateLink: link ? url(link) : null,
      }
    }) || [],
  }), [])

  return (
    <QueryProvider
      query={errorsQuery}
      context={ErrorLogContext}
      queryVars={queryVars}
      rowVariable="errorLogs"
      onData={onData}
      limit={50}
      infinite
      {...rest}
    />
  )
}

const useErrorLog = () => React.useContext(ErrorLogContext)

ErrorLogProvider.propTypes = {
  testMode: PropTypes.bool,
}

export {
  ErrorLogProvider,
  useErrorLog,
  ErrorTemplateProvider,
  useErrorTemplates,
}
