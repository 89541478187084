import PropTypes from 'prop-types'
import { ElementWrapper, elementStyle } from '../helpers'
import { AlignmentHeader } from './alignment'
import { getMustacheTypes, renderDataRowHtml } from './helpers'
import { Rows } from './rows'

const Table = (props) => {
  const style = elementStyle(props, Table.config)
  const {
    element: { config: { tableConfig } },
    mode,
  } = props

  return (
    <ElementWrapper {...props} style={style}>
      <table style={{ borderCollapse: 'collapse' }}>
        {mode === 'builder' ? <AlignmentHeader {...props} tableConfig={tableConfig} /> : null}
        <Rows {...props} color={style.color} rowGroup="header" />
        <Rows {...props} color={style.color} rowGroup="body" />
        <Rows {...props} color={style.color} rowGroup="footer" />
      </table>
    </ElementWrapper>
  )
}

Table.onUpdateConfig = (props) => {
  const { color } = elementStyle(props, Table.config)
  return renderDataRowHtml({ ...props, color })
}

Table.propTypes = {
  ...ElementWrapper.propTypes,
  varPath: PropTypes.string.isRequired,
}

Table.type = 'Table'

// User configurable variables
Table.config = {
  name: 'table',
  tableConfig: {
    columns: [
      { id: 'column1', align: 'left' },
      { id: 'column2', align: 'left' },
      { id: 'column3', align: 'left' },
    ],
    body: {
      rows: [
        { id: 'row1', type: 'iterable', columns: [] },
      ],
      borders: {
        horizontal: false,
        vertical: false,
        outerHorizontal: true,
        outerVertical: true,
      },
      style: {
        color: null,
        borderColor: null,
        backgroundColor: null,
      },
    },
    header: {
      rows: [
        { id: 'header1', type: 'text', columns: [] },
      ],
      borders: {
        horizontal: false,
        vertical: false,
        outerHorizontal: true,
        outerVertical: true,
        match: true,
      },
      style: {
        color: null,
        borderColor: null,
        backgroundColor: null,
        fontWeight: 700,
      },
    },
    footer: {
      borders: {
        horizontal: false,
        vertical: false,
        outerHorizontal: true,
        outerVertical: true,
        match: true,
      },
      rows: [],
      style: {
        color: null,
        borderColor: null,
        backgroundColor: null,
        fontWeight: 400,
      },
    },
  },
  padding: '0 0 0 0',
  fontSize: '12px',
  lineHeight: '120%',
  fontFamily: null,
}

Table.configLayout = [
  'tableConfig',
  '---',
  'fontSize',
  'lineHeight',
  'fontFamily',
  'padding',
]

Table.getMustacheTypes = getMustacheTypes

export {
  Table
}
