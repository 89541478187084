import {
  newEmailTemplate,
  copyEmailTemplate,
  exportEmailTemplate,
  renderEmailTemplate,
} from './Email'

const newTemplate = (options = {}) => {
  if (options.type === 'email') return newEmailTemplate(options)
  if (options.type === 'sms') return { body: '' }
  return null
}

const copyTemplate = (template) => {
  if (template.type === 'email') return copyEmailTemplate(template)
  if (template.type === 'sms') return template
  return null
}

const exportTemplate = (template) => {
  if (template.type === 'email') return exportEmailTemplate(template)
  return null
}

const renderTemplate = (template) => {
  if (template.type === 'email') return renderEmailTemplate(template)
  return null
}

export {
  renderTemplate,
  newTemplate,
  copyTemplate,
  exportTemplate,
}
