import { useCallback, useEffect, useRef } from 'react'

const useTimeoutCallback = ({
  callback,
  dependencies = [],
  time = 250,
  limit = 0, // If set, and is less than time from last callback, execute callback.
  leading = true, // Leading means it should be triggered the first time it is called.
  trailing = true, // Trailing means it shuold be triggered once it is done being called for `time`.
  batch = false,
  onBounce = undefined, // Callback to execute when a trigger is skipped due to debouncing
}) => {
  const timeoutRef = useRef()
  const callbackRef = useRef(batch ? [] : null)
  const lastCalledRef = useRef(0)

  const cancel = useCallback(() => {
    if (timeoutRef.current) {
      window.cancelAnimationFrame(timeoutRef.current)
    }
  }, [])
  // When unloaded, this will cancel callbacks
  useEffect(() => cancel, [])

  const invoke = useCallback(() => {
    if (batch) {
      callbackRef.current.forEach((cb) => cb())
      callbackRef.current = []
    } else {
      callbackRef.current()
    }
  }, [])

  const hasElapsed = () => {
    const elapsed = Date.now() - lastCalledRef.current
    return time <= elapsed || (limit && limit <= elapsed)
  }

  const debouncedFn = useCallback((...args) => {
    cancel()
    // If batch is used, all callbacks will be executed when invoke is called
    if (batch) {
      callbackRef.current.push(() => callback(...args))
    // If not a batch, only the last trigger will be called
    } else {
      callbackRef.current = () => (callback ? callback(...args) : undefined)
    }

    // This function will invoke the callback if trailing: true and time has elapsed
    // Otherwise it will keep looping until it the time has elapsed.
    // It will clear the lastCalledRef to reset to default order
    // If trailing it will finally execute the callback
    const loop = (fn) => {
      // If already waited enough, call callback:
      if (hasElapsed()) {
        if (trailing && fn) fn()

        // Clear last execution so that leading can work
        lastCalledRef.current = 0
      // Otherwise queue callback for another frame
      } else {
        timeoutRef.current = window.requestAnimationFrame(() => loop(fn))
      }
    }

    // The first time this called, it will excute if leading is true
    if (leading && !lastCalledRef.current) {
      invoke()
      lastCalledRef.current = Date.now()
      // Do not pass a function since first call should not invoke if both leading and trailing
      loop(null)
    } else {
      // If this execution will not trigger the callback (it will be debounced) fire onBounce
      if (lastCalledRef.current && onBounce && !hasElapsed()) {
        onBounce(...args)
      }
      // If there is no limit on how long debouncing should occur,
      // each trigger should reset the trigger delay to this occurance
      if (!limit) lastCalledRef.current = Date.now()

      // Queue the invocation when time elapses
      loop(invoke)
    }
  }, dependencies)

  debouncedFn.cancel = cancel

  return debouncedFn
}

export {
  useTimeoutCallback,
}
