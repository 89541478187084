import React from 'react'
import PropTypes from 'prop-types'

import {
  Card, Stack, Shelf, Text, Button,
  pluralize,
} from '@level'
import { CopyCode } from './CopyCode'

const Errors = ({ value, issues }) => (
  <>
    { value ? (
      <Text tag="p">
        <Text>Your DNS record <strong>`<code>{value}</code>`</strong> has {pluralize('an issue', 'issues', issues?.length)}:</Text>
      </Text>
    ) : null}
    <ul>
      {issues.map((issue) => (<li key={issue}><Text color="red-500">{issue}.</Text></li>))}
    </ul>
  </>
)

const DnsConfig = ({ type, record }) => {
  const {
    name, value, example, valid, issues, domain,
  } = record
  const [expanded, setExpanded] = React.useState(!valid)
  const icon = valid ? { name: 'circle-check', color: 'green-700' } : { name: 'circle-exclamation', color: 'yellow-600' }
  return (
    <Stack gap={5} space={5}>
      <Shelf align="split" nowrap>
        <Stack gap={4}>
          <Text icon={{ ...icon, size: 3, gap: 4 }} tag="h4">
            {type} Configuration
          </Text>
          {valid
            ? <Text tag="p">Your {type} configuration is valid.</Text>
            : <Errors value={value} issues={issues} />}
        </Stack>
        {valid ? <Button label="View Config" theme="ghost" onClick={() => setExpanded((e) => !e)} icon={expanded ? 'angle-down' : 'angle-left'} /> : null}
      </Shelf>
      {expanded ? (
        <Stack gap={5}>
          {valid ? (
            <Stack gap={4}>
              <Text icon={icon}>Valid TXT record <strong>`<code>{name}</code>`</strong> on <strong>`<code>{domain}</code>`</strong></Text>
              <Card space={4}>
                <Text style={{ fontFamily: 'system-mono' }} forceWrap>{value}</Text>
              </Card>
            </Stack>
          ) : (
            <Stack gap={4}>
              <Text icon={icon}>
                To fix {pluralize('this issue', 'these issues', issues?.length)} {value ? 'edit' : 'add a'} TXT record <strong>`<code>{name}</code>`</strong> on <strong>`<code>{domain}</code>`</strong> with this value
              </Text>
              <CopyCode value={example} />
            </Stack>
          )}
        </Stack>
      ) : null}
    </Stack>
  )
}

DnsConfig.propTypes = {
  type: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
}

export { DnsConfig }
