import PropTypes from 'prop-types'
import './preview.scss'

const Preview = ({
  zoom = 0.50,
  style = {},
  children,
  className,
  ...rest
}) => (
  <div
    className={`level-preview ${className || ''}`}
    style={{
      overflow: 'hidden',
      height: `${Math.ceil(100 / zoom)}%`,
      width: `${Math.ceil(100 / zoom)}%`,
      transform: `scale(${zoom})`,
      transformOrigin: 'top left',
      ...style,
    }}
    {...rest}
  >{children}
  </div>
)

Preview.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  zoom: PropTypes.number,
  style: PropTypes.object,
}

export {
  Preview,
}
