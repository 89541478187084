import PropTypes from 'prop-types'
import { templateStatusText, templateUpdateText } from '../../helpers/template'
import { Card } from '../Card'
import { Divider } from '../Divider'
import { Link } from '../Link'
import { Preview } from '../Preview'
import { Shelf } from '../Shelf'
import { Stack } from '../Stack'
import { Text } from '../Text'
import { Template } from './Template'

import './preview.scss'

const Wrapper = ({ to, onClick, ...rest }) => {
  const cardStyle = {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '250px',
    borderRadius: '12px',
  }
  const card = <Card {...rest} space={0} style={cardStyle} />
  if (to || onClick) {
    return (
      <div style={{ position: 'relative', display: 'grid' }}>
        {card}
        <Link
          style={{ position: 'absolute', inset: 0 }}
          to={to}
          color="text-default"
          onClick={onClick}
          label="Edit template"
        />
      </div>
    )
  }
  return card
}

Wrapper.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
}

const StatusText = (props) => {
  const { color, text, icon } = templateStatusText(props)
  return (
    <Text
      color={color}
      icon={{ name: icon, size: 3, gap: 4 }}
      size={0}
    >
      {text}
    </Text>
  )
}

const LastUpdateText = (props) => {
  const text = templateUpdateText(props)
  return text ? <Text size={0} color="neutral-300"> {text} </Text> : null
}

const TemplatePreviewCard = ({
  title,
  published,
  publishedAt,
  updatedAt,
  zoom,
  type,
  actionButton,
  config,
  hooks,
  ...rest
}) => (
  <Wrapper {...rest}>
    {actionButton ? <div style={{ position: 'absolute', top: '8px', right: '8px' }}>{actionButton}</div> : null}
    <div style={{
      overflow: 'hidden', flexGrow: 1, display: 'flex', flexDirection: 'column',
    }}
    >
      <div
        style={{
          overflow: 'hidden',
          borderRadius: '11px 11px 0 0',
        }}
      >
        <Preview zoom={zoom}>
          <Template height="1000px" type={type} mode="preview" config={config} hooks={hooks} />
        </Preview>
      </div>
    </div>
    <Divider />
    <Stack gap={5} space={6}>
      <Stack gap={3}>
        <Text tag="h4">{title}</Text>
        <LastUpdateText updatedAt={updatedAt} published={published} publishedAt={publishedAt} />
      </Stack>
      <Shelf align="split" valign="center" nowrap>
        <StatusText published={published} publishedAt={publishedAt} />
        {type ? <div className="level-preview-card-badge" data-type={type}>{type}</div> : null}
      </Shelf>
    </Stack>
  </Wrapper>
)

TemplatePreviewCard.propTypes = {
  title: PropTypes.string.isRequired,
  published: PropTypes.bool,
  publishedAt: PropTypes.string,
  updatedAt: PropTypes.string,
  type: PropTypes.string,
  zoom: Preview.propTypes.zoom,
  actionButton: PropTypes.node,
  config: PropTypes.object,
  hooks: PropTypes.object,
}

export {
  TemplatePreviewCard
}
