import React from 'react'
import PropTypes from 'prop-types'

import {
  Grid, Stack, Text, Shelf, Spacer, Button,
  toColor, toPercentage,
} from '@level'

import { ComplianceCard } from '../complianceCard'

const ModalHeader = ({
  close,
  data,
  selectPrevious,
  selectNext,
}) => {
  const {
    metrics: {
      total,
      passed,
      rejected,
      quarantined,
    },
  } = data

  return (
    <div style={{ borderBottom: `1px solid ${toColor('neutral-50')}` }}>
      <Shelf gap={4} space={[7, 7, 0]} valign="center">
        <Shelf gap={4} valign="baseline">
          <Text tag="h3">{data.submitter}</Text>
          <Text color="neutral-400">{data.from}</Text>
        </Shelf>
        <Spacer grow />
        <Button theme="wrapper" label="close" icon={{ name: 'x-light', size: 4 }} onClick={close} />
      </Shelf>
      <Shelf space={7} className="message-log-item-meta" align="split" valign="bottom">
        <Shelf gap={12}>
          <Stack gap={5}>
            <Text><strong>Volume:</strong> {data.emails}</Text>
            <Text><strong>Start Date:</strong> {data.startDate}</Text>
            <Text><strong>End Date:</strong> {data.endDate}</Text>
          </Stack>
          <Grid columns={3} gap={5}>
            <ComplianceCard
              title="DMARC Compliant"
              number={passed}
              percentage={toPercentage(passed, total)}
              percentageRange={[50, 80]}
            />
            <ComplianceCard
              title="DMARC Quarantined"
              number={quarantined}
              percentage={toPercentage(quarantined, total)}
              percentageRange={[5, 20]}
              negative
            />
            <ComplianceCard
              title="DMARC Rejected"
              number="0"
              percentage={toPercentage(rejected, total)}
              percentageRange={[10, 20]}
              negative
            />
          </Grid>
        </Shelf>
        <Shelf>
          <Button onClick={selectPrevious} icon="angle-left" label="previous message" disabled={!selectPrevious} />
          <Button onClick={selectNext} icon="angle-right" label="next message" disabled={!selectNext} />
        </Shelf>
      </Shelf>
    </div>
  )
}

ModalHeader.propTypes = {
  data: PropTypes.shape({
    from: PropTypes.string,
    emails: PropTypes.number,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    submitter: PropTypes.string,
    metrics: PropTypes.shape({
      total: PropTypes.number,
      passed: PropTypes.number,
      rejected: PropTypes.number,
      quarantined: PropTypes.number,
    }),
  }).isRequired,
  close: PropTypes.func.isRequired,
  selectNext: PropTypes.func,
  selectPrevious: PropTypes.func,
}

export {
  ModalHeader,
}
