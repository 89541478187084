import React from 'react'
import PropTypes from 'prop-types'

import {
  Shelf, Stack, RadioPanel,
} from '@level'

import { TeamBadge } from '../teams/switcher'

const InvitationInputs = ({ invitations }) => (
  <div className="invitation-inputs">
    <Stack gap={8}>
      { invitations.map(({ id, team }) => (
        <Shelf align="split" valign="center" key={id}>
          <TeamBadge team={team} avatarSize={3} textSize={2} textProps={{ size: 2 }} />
          <RadioPanel
            name={`invitations.${id}.accept`}
            required
            items={[
              {
                value: false,
                label: 'Decline',
                checkedColor: 'red-700',
                icon: 'x-light',
              },
              {
                value: true,
                label: 'Accept',
                checkedColor: 'green-700',
                icon: 'check',
                defaultChecked: true,
              },
            ]}
          />
        </Shelf>
      ))}
    </Stack>
  </div>
)

InvitationInputs.propTypes = {
  invitations: PropTypes.array.isRequired,
}

export {
  InvitationInputs,
}
