import {
  useState, useCallback, useRef, useEffect, useMemo,
} from 'react'

import {
  closestCenter as closest,
  pointerWithin,
  rectIntersection,
  getFirstCollision,
} from '@dnd-kit/core'

/**
 * Encapsulates custom collision detection strategy for our grid-type needs
 * Stores its own state so that parent is only aware of drag-end; where currently card goes to.
 * Won't this become problematic in a sense that if we update original state, we'll get outdated data?
 * What if we run onChange all the time? I think we should.
 */

const useGridCollisionDetection = (props) => {
  const containerIds = useMemo(() => (
    props.containers.map(props.containerIdTransformer)
  ), [props.containers])

  const payload = useMemo(() => (
    props.containers.reduce((map, container, i) => {
      const containerId = containerIds[i]
      return { ...map, [containerId]: props.containerDataIdTransformer(container) }
    }, {})
  ), [props.containers])

  // An active item's starting position so we know how to put it back when user cancels
  const [startEvent, setStartEvent] = useState(null)
  const [currentEvent, setCurrentEvent] = useState(null)
  const [activeId, setActiveId] = useState(null)
  const lastOverId = useRef(null)
  const recentlyMovedToNewContainer = useRef(false)

  /**
   * Custom collision detection strategy optimized for multiple containers
   *
   * - First, find any droppable containers intersecting with the pointer.
   * - If there are none, find intersecting containers with the active draggable.
   * - If there are no intersecting containers, return the last matched intersection
   *
   * @source https://github.com/clauderic/dnd-kit/blob/6f307806c09d4a1147073bf04f7cd15b7de721fc/stories/2%20-%20Presets/Sortable/MultipleContainers.tsx#L180-L187
   */
  const collisionDetection = useCallback((args) => {
    if (activeId && activeId in payload) {
      return closest({
        ...args,
        droppableContainers: args.droppableContainers.filter(
          (container) => container.id in payload,
        ),
      })
    }

    // Start by finding any intersecting droppable
    const pointerIntersections = pointerWithin(args)

    const intersections = pointerIntersections.length > 0
      // If there are droppables intersecting with the pointer, return those
      ? pointerIntersections
      : rectIntersection(args)
    let overId = getFirstCollision(intersections, 'id')

    if (overId != null) {
      if (overId in payload) {
        const containerItems = payload[overId]

        // If a container is matched and it contains items (columns 'A', 'B', 'C')
        if (containerItems.length > 0) {
          // Return the closest droppable within that container
          overId = closest({
            ...args,
            droppableContainers: args.droppableContainers.filter(
              (container) => container.id !== overId && containerItems.includes(container.id),
            ),
          })[0]?.id
        }
      }

      lastOverId.current = overId

      return [{ id: overId }]
    }

    // When a draggable item moves to a new container, the layout may shift
    // and the `overId` may become `null`. We manually set the cached `lastOverId`
    // to the id of the draggable item that was moved to the new container, otherwise
    // the previous `overId` will be returned which can cause items to incorrectly shift positions
    if (recentlyMovedToNewContainer.current) {
      lastOverId.current = activeId
    }

    // If no droppable is matched, return the last match
    return lastOverId.current ? [{ id: lastOverId.current }] : []
  }, [activeId, payload])

  const findContainer = (id) => {
    // Check if the id is actually a container id
    // Very important to allow dragOver to work when dragging an item into an empty sortable
    if (id in payload) return id

    return containerIds.find((containerId) => (
      payload[containerId].includes(id)
    ))
  }

  const onDragStart = ({ active }) => {
    // Avoid conflict with useDragContainer
    // This means we're dragging a container, and we don't care about it
    if (active.id in payload || active.id.match(/section/)) return

    const activeContainer = findContainer(active.id)

    setActiveId(active.id)

    setStartEvent({
      container: activeContainer,
      index: activeContainer.indexOf(active.id),
    })
  }

  // Responsible for moving an item from array to array
  // when a user drags an item over to another container
  const onDragOver = ({ active, over }) => {
    // Avoid conflict with useDragContainer
    // This means we're dragging a container, and we don't care about it
    if (!activeId) return

    const overId = over?.id

    if (!overId) { return }

    const overContainer = findContainer(overId)
    const activeContainer = findContainer(active.id)

    // Bail if any of the containers are invalid
    if (!overContainer || !activeContainer) { return }

    // Bail if we're dragging to the same container as drag end already covers this.
    if (activeContainer === overContainer) { return }

    const activeItems = payload[activeContainer]
    const overItems = payload[overContainer]
    const activeIndex = activeItems.indexOf(active.id)
    const overIndex = overItems.indexOf(overId)

    let newIndex

    if (overId in payload) {
      newIndex = overItems.length + 1
    } else {
      const isBelowOverItem = over
        && active.rect.current.translated
        && active.rect.current.translated.top > over.rect.top + over.rect.height

      const modifier = isBelowOverItem ? 1 : 0

      newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1
    }

    recentlyMovedToNewContainer.current = true

    setCurrentEvent({
      container: overContainer,
      index: newIndex,
    })

    props.onChange(
      {
        container: activeContainer,
        index: activeIndex,
      },
      {
        container: overContainer,
        index: newIndex,
      },
    )
  }

  // Responsible for moving an item in the same container
  const onDragEnd = ({ active, over }) => {
    // Avoid conflict with useDragContainer
    // This means we're dragging a container, and we don't care about it
    if (!activeId) {
      return
    }

    const activeContainer = findContainer(active.id)

    // The container somehow does not exist
    if (!activeContainer) {
      setStartEvent(null)
      setCurrentEvent(null)
      setActiveId(null)
      return
    }

    const overId = over?.id

    // We're not dragging to a valid item
    if (!overId) {
      setStartEvent(null)
      setCurrentEvent(null)
      setActiveId(null)
      return
    }

    const overContainer = findContainer(overId)

    // We're not dragging to a valid container
    if (!overContainer) {
      setStartEvent(null)
      setCurrentEvent(null)
      setActiveId(null)
      return
    }

    const activeIndex = payload[activeContainer].indexOf(activeId)

    const orderIndex = payload[overContainer].indexOf(overId)

    // We're not dragging to the same index
    if (activeIndex !== orderIndex) {
      props.onDragEnd(
        {
          container: activeContainer,
          index: payload[activeContainer].indexOf(activeId),
        },
        {
          container: overContainer,
          index: payload[overContainer].indexOf(overId),
        },
      )
    }

    props.onComplete?.(
      startEvent,
      {
        container: overContainer,
        activeId,
        index: payload[overContainer].indexOf(overId),
      },
    )

    setStartEvent(null)
    setCurrentEvent(null)
    setActiveId(null)
  }

  const onDragCancel = () => {
    // Avoid conflict with useDragContainer
    // This means we're dragging a container, and we don't care about it
    if (!activeId) return

    if (currentEvent && startEvent) {
      // Reset items to their original state in case items have been dragged across containers
      props.onChange(currentEvent, startEvent)
    }

    setStartEvent(null)
    setCurrentEvent(null)
    setActiveId(null)
  }

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false
    })
  }, [props.containers])

  return {
    collisionDetection,
    onDragStart,
    onDragOver,
    onDragEnd,
    onDragCancel,
  }
}

export {
  useGridCollisionDetection,
}
