import React from 'react'
import PropTypes from 'prop-types'
import { useMaxSize } from '../../hooks/useMaxSize'

const heightInVh = (height) => (height * 100) / window.innerHeight
const PanelContext = React.createContext()
const usePanelDrawer = () => React.useContext(PanelContext)

const PanelDrawer = ({
  children, toolbar, show, ...props
}) => {
  const panelRef = React.useRef()
  const [dragging, setDragging] = React.useState(false)
  const [active, setActive] = React.useState(show)
  const [resized, setResized] = React.useState()
  const { contentRef, height: contentHeight } = useMaxSize({ refresh: resized })

  const dragStart = () => setDragging(true)

  React.useEffect(() => {
    const panel = panelRef.current

    const dragEnd = () => {
      setDragging(false)
      panel.style.transition = ''
    }
    window.addEventListener('mouseup', dragEnd)

    // Initialize height
    const height = panel.querySelector('.level-panel-drawer-toolbar').clientHeight
    panel.style.height = `${height}px`
    panel.dataset.dragged = false

    return () => {
      window.removeEventListener('mouseup', dragEnd)
    }
  }, [])

  React.useEffect(() => {
    panelRef.current.style.minHeight = (active && panelRef.current) ? '40%' : ''
  }, [panelRef, active])

  React.useEffect(() => {
    const handleDrag = (event) => {
      event.preventDefault()
      event.stopPropagation()

      const panel = panelRef.current
      const handleHeight = panel.querySelector('.level-panel-drawer-resizer').clientHeight / 2
      const { innerHeight } = window

      const height = heightInVh(Number.parseFloat(panel.style.height))
      const dragHeight = heightInVh(innerHeight - event.y + handleHeight)
      const maxHeight = heightInVh(panel.parentElement.clientHeight - 100)

      const minHeight = dragHeight < maxHeight ? dragHeight : maxHeight
      const newHeight = minHeight < height ? height : minHeight

      panel.style.minHeight = `${newHeight}vh`
      panel.style.transition = 'none'
      panel.dataset.dragged = true
    }

    if (dragging) window.addEventListener('mousemove', handleDrag)

    // when done dragging resize the body scroll
    if (!dragging) setResized({})

    return () => {
      window.removeEventListener('mousemove', handleDrag)
    }
  }, [dragging])

  const value = React.useMemo(() => ({
    active,
    showDrawer: (newState = !active) => setActive(newState),
    resized,
  }), [active, setActive, resized])

  return (
    <PanelContext.Provider value={value}>
      <div
        className="level-panel-drawer"
        ref={panelRef}
        data-active={active}
        {...props}
      >
        <div className="level-panel-drawer-toolbar">
          {active ? (
            <div
              onMouseDown={dragStart}
              role="button"
              tabIndex={0}
              className="level-panel-drawer-resizer"
              aria-label="resize panel"
            />
          ) : null}
          {toolbar}
        </div>
        <div
          className="level-panel-drawer-content"
          ref={contentRef}
          style={{
            height: contentHeight,
            overflowY: 'auto',
          }}
        >
          {children}
        </div>
      </div>
    </PanelContext.Provider>
  )
}

PanelDrawer.propTypes = {
  children: PropTypes.node,
  toolbar: PropTypes.node,
  show: PropTypes.bool,
}

export {
  PanelDrawer,
  usePanelDrawer,
}
