import React from 'react'

import {
  Form, MultiSelect,
  useSearchState, useDebounce,
} from '@level'

import { useMessageTemplates } from '../provider'

const TemplateFilter = () => {
  const [params, setParams] = useSearchState()
  const defaultValue = params?.template
  const { templates } = useMessageTemplates()

  const onSubmit = useDebounce(({ data: { TemplateId: value } }) => {
    // If input value matches a published template slug, set the filter
    const TemplateId = value.split(',').map((val) => templates.find(({ slug }) => slug === val)?.id).join(',')
    const template = value
    setParams.merge({ template, TemplateId })
  }, [templates], 500)

  return (
    <Form onSubmit={onSubmit}>
      <MultiSelect
        defaultValue={defaultValue}
        name="TemplateId"
        items={templates}
        match={{ keys: ['name', 'slug'] }}
        itemDisplay={({ slug }) => slug}
      />
    </Form>
  )
}

export {
  TemplateFilter,
}
