import React from 'react'

import {
  PopoverPill, DateRangeFilter,
  camelToTitle, useSearchState,
} from '@level'

const DateFilterPill = (props) => {
  const [params] = useSearchState()

  const display = () => {
    if (!params?.date) return null
    if (params.date === 'range') return 'Custom Range'
    return camelToTitle(params.date)
  }

  return (
    <PopoverPill
      param="Date"
      value={display()}
      content={(
        <DateRangeFilter
          name="dateRange"
          rangeLabel="Show reports within"
          span={[
            'pastWeek',
            'pastTwoWeeks',
            'pastMonth',
            'pastTwoMonths',
          ]}
          dateRange={[
            '1 week',
            '2 weeks',
            '1 month',
            '2 months',
          ]}
        />
      )}
      {...props}
    />
  )
}

export {
  DateFilterPill,
}
