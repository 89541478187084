import React from 'react'
import PropTypes from 'prop-types'

import { AsUserBanner } from '@app/hooks'
import {
  Form, Input, SubmitButton, Layout, Stack,
} from '@level'

import { WelcomeCard } from './WelcomeCard'

const WelcomeUser = ({ onSubmit, welcomeData }) => {
  const [userName, setUserName] = React.useState()
  return (
    <Layout
      root={<AsUserBanner />}
      className="level-layout-welcome"
    >
      <WelcomeCard
        title="Welcome! First things first..."
        userName={userName}
        welcomeData={welcomeData}
      >
        <Form
          onSubmit={onSubmit}
          onChange={(data) => setUserName(data.name)}
        >
          <Stack gap={10}>
            <Input name="name" placeholder="Full Name" label="What's your name?" autoComplete={false} />
            <SubmitButton text="Next" grow />
          </Stack>
        </Form>
      </WelcomeCard>
    </Layout>
  )
}

WelcomeUser.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  welcomeData: PropTypes.object.isRequired,
}

export {
  WelcomeUser,
}
