import React from 'react'
import { useQuery, useProfile } from '@app/hooks'
import { toParams, useToast, useRestQuery } from '@level'

const aiQuery = ({ variables }) => `query {
  TemplateVars: templateAiVars(${toParams({ variables: JSON.stringify(variables || {}) })})
}`

const aiVarsUrl = `${import.meta.env.VITE_API_BASE_URL}/try/aiVariables`

const useRestAiVars = () => {
  const { anonymousId } = useProfile()
  return useRestQuery({
    url: aiVarsUrl, headers: { anonymousId },
  }, [anonymousId])
}

const useAiVars = ({ previewVars, setEditorContent }) => {
  const { isPublic } = useProfile()
  const [getVars, { isLoading }] = isPublic
    // eslint-disable-next-line react-hooks/rules-of-hooks
    ? useRestAiVars()
    // eslint-disable-next-line react-hooks/rules-of-hooks
    : useQuery(aiQuery)

  const { sendError } = useToast()

  const fetch = React.useCallback(async () => {
    try {
      const { data } = await getVars({ variables: previewVars })
      if (data?.TemplateVars || data) {
        setEditorContent(data.TemplateVars || data)
      }
    } catch {
      sendError({ title: 'AI has failed us', message: 'So sorry. Your request couldn\'t be processed. On the plus side, no robot uprising though…' })
    }
  }, [getVars, previewVars])

  return { fetch, isLoading }
}

export {
  useAiVars,
}
