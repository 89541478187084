import React from 'react'
import PropTypes from 'prop-types'

import {
  Stack, Text, Editor, Block, Shelf,
  titleize,
} from '@level'

import { formatMessageDate } from './helpers'

const Event = ({ event: name, at, data }) => (
  <Block space={4} style={{ maxWidth: '100%', overflow: 'auto' }}>
    <Stack gap={3}>
      <Shelf gap={5} space={[4, 5, 0, 5]} align="split" valign="center">
        <Text tag="strong" size={3}>{titleize(name)}</Text>
        <Text className="message-log-date">{formatMessageDate(at)}</Text>
      </Shelf>
      <Block space={5}>
        <Editor content={JSON.stringify({ at, ...data }, null, 2)} lang="json" />
      </Block>
    </Stack>
  </Block>
)

Event.propTypes = {
  event: PropTypes.string.isRequired,
  at: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
}

export {
  Event,
}
