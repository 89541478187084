import { excludeKeys, filterEmptyValues } from '../../../../helpers/object'
import { uuid } from '../../../../helpers/text'
import Elements from './Elements'

const getElementDefault = ({ type }) => Elements[type]?.config
const getElementConfigLayout = ({ type }) => Elements[type]?.configLayout

// onUpdateElementConfig: a hook to alter an element's config when some property is changed
// Receives element, columnConfig, sectionConfig, varPath, and templateVariables
// Should return the updated config object
const onUpdateElementConfig = (props) => {
  if (Elements[props.element.type]?.onUpdateConfig) {
    return Elements[props.element.type].onUpdateConfig(props)
  }
  return props.element.config
}

const newElement = ({ element, config = {}, templateVariables = {} }) => {
  const id = `element${uuid().replace(/-/g, '')}`
  const { type } = element
  let elementConfig = getElementDefault(element)

  if (templateVariables.defaults && templateVariables.defaults[type]) {
    const defaults = excludeKeys(templateVariables.defaults[type], ['color'])
    elementConfig = { ...elementConfig, ...defaults }
  }

  return {
    id,
    type,
    config: {
      ...elementConfig,
      ...config,
    },
  }
}

const exportElement = ({ type, config }) => ({
  type,
  config: filterEmptyValues(config),
})

export {
  exportElement, getElementConfigLayout, getElementDefault, newElement, onUpdateElementConfig
}

export * from './Elements'
export default Elements
export { isActive } from './helpers'
