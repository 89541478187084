import React from 'react'
import { TextFilter } from '@level'

const FromFilter = () => (
  <TextFilter
    name="from"
    placeholder="Name, email, or number"
  />
)

export {
  FromFilter,
}
