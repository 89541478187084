import Bugsnag from '@bugsnag/browser'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React from 'react'

if (import.meta.env.VITE_BUGSNAG_API_KEY) {
  Bugsnag.start({
    apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
    appVersion: import.meta.env.VITE_GIT_VERSION,
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ['production', 'staging'],
    enabledBreadcrumbTypes: ['error'],
    releaseStage: import.meta.env.VITE_NODE_ENV,
  })
}

const BugsnagContext = React.createContext(Bugsnag)
const useBugsnag = () => React.useContext(BugsnagContext)
const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React)

export {
  Bugsnag, BugsnagContext,
  ErrorBoundary,
  useBugsnag
}
