import PropTypes from 'prop-types'

const spacerHeight = ({ virtualizer, top, bottom }) => {
  const { getVirtualItems, getTotalSize } = virtualizer
  let height = 0
  const rows = getVirtualItems()

  if (top && rows.length > 0) {
    height = rows?.[0]?.start || 0
  }

  if (bottom && rows.length > 0) {
    const totalSize = getTotalSize()
    height = totalSize - (rows?.[rows.length - 1]?.end || 0)
  }
  return height
}

const RowSpacer = ({ virtualizer, top, bottom }) => {
  if (!virtualizer) return null

  const height = spacerHeight({ virtualizer, top, bottom })
  return height ? <tr><td style={{ height: `${height}px` }} /></tr> : null
}

RowSpacer.propTypes = {
  virtualizer: PropTypes.object,
  top: PropTypes.bool,
  bottom: PropTypes.bool,
}

export {
  RowSpacer,
}
