import React from 'react'

import { useTeam } from '@app/hooks'
import {
  PopoverPill, RadioFilter,
  useSearchState,
} from '@level'

const DomainFilter = () => {
  const { Team: { senderIdentities } } = useTeam()
  const [params] = useSearchState()

  // No need to show domain filter if there is only one domain
  if (senderIdentities.length < 2) {
    return null
  }

  const options = [{ label: 'All Domains', value: '' }]
  senderIdentities.forEach(({ identity }) => {
    options.push({ value: identity.replace(/.+?@/, '') })
  })
  return (
    <PopoverPill
      param="Domain"
      value={params.domain || 'All'}
      content={<RadioFilter name="domain" options={options} />}
    />
  )
}

export {
  DomainFilter,
}
