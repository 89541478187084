import React from 'react'
import PropTypes from 'prop-types'

import { MenuButton } from '@level'

import { useDeleteSharedSection, useRenameSharedSection } from './hooks'

const ActionButton = ({ template: sharedSection, refresh }) => {
  const deleteSharedSection = useDeleteSharedSection({ sharedSection })
  const renameSharedSection = useRenameSharedSection({ sharedSection })

  const menuActions = React.useMemo(() => {
    if (!sharedSection) return []
    return [
      {
        text: 'Change Name',
        onSelect: async () => {
          const data = await renameSharedSection()
          if (data) refresh()
        },
        icon: 'pen-field',
      },
      {
        text: 'Delete',
        onSelect: async () => {
          await deleteSharedSection()
          refresh()
        },
        icon: 'trash',
        destructive: true,
      },
    ]
  }, [
    deleteSharedSection,
  ])
  return <MenuButton items={menuActions} size={2} icon="three-dots-horizontal" label="Section options" style={{ position: 'relative', zIndex: 1 }} />
}

ActionButton.propTypes = {
  template: PropTypes.object.isRequired,
  refresh: PropTypes.func.isRequired,
}

export {
  ActionButton,
}
