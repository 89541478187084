import React from 'react'
import {
  useTeam, useFormQuery,
  useMailSettings,
} from '@app/hooks'
import {
  Stack, Shelf, Text, Input, Button, HiddenInput, Card,
  Divider, SettingsGrid, P, Body, Link,
  useDialog, Main, BlankSlate, Notice, sentenceJoin,
} from '@level'

import {
  createSenderIdentity,
  deleteSenderIdentity,
} from './queries'

import { DefaultFrom } from './defaultFrom'
import { ProjectHeader } from '../../projects/header'
import { DkimConfig } from './dkim'
import { DnsConfig } from './dnsConfig'
import { DedicatedIp, DedicatedIpBadge } from './dedicatedIp'

const fullyVerified = ({
  type, isVerified, dkimRecords, dmarcRecord, spfRecord,
}) => {
  if (type === 'domain') {
    return isVerified
      && !dkimRecords.find(({ confirmed }) => !confirmed)
      && dmarcRecord.valid
  }
  return isVerified
}

const DomainIdentity = ({ expanded, ...props }) => {
  if (!expanded) return null
  return (
    <>
      <Divider flush={4} />
      { props.dedicatedIp ? (
        <>
          <DedicatedIp {...props} />
          <Divider flush={4} />
        </>
      ) : null}
      <DkimConfig {...props} />
      <Divider flush={4} />
      <DnsConfig record={props.dmarcRecord} type="Dmarc" />
    </>
  )
}

const EmailIdentity = ({ identity, isVerified, expanded }) => {
  if (!expanded) return null

  if (isVerified) {
    return (
      <>
        <Divider flush={4} />
        <Text tag="h4">Your email address has been verified</Text>
      </>
    )
  }

  return (
    <>
      <Divider flush={4} />
      <Stack gap={4} space={5}>
        <Text tag="h4">Verify Your Email Address</Text>
        <P>
          A verification email was sent to <strong>{identity}</strong>.
          Click the link in that message to verify that you control this email account.
        </P>
      </Stack>
    </>
  )
}

const verifiedIconProps = ({ identity, isLoading }) => {
  if (isLoading) {
    return {
      name: 'loader',
      color: 'neutral-500',
    }
  }
  return fullyVerified(identity) ? {
    name: 'shield-check',
    color: 'green-700',
  } : {
    name: 'shield-exclamation',
    color: 'yellow-600',
  }
}

const Identity = ({
  identity, deleteIdentity, isLoading, ...rest
}) => {
  const [expanded, setExpanded] = React.useState(false)
  const verifyChecked = typeof identity.isVerified !== 'undefined'

  React.useEffect(() => {
    if (verifyChecked) setExpanded(!fullyVerified(identity))
  }, [identity.isVerified])

  const { id, identity: name } = identity
  return (
    <Card space={4} key={id}>
      <Stack gap={4}>
        <Shelf gap={5} space={[3, 2, 3, 5]} align="split" valign="center">
          <Shelf gap={5}>
            <Text
              tag="strong"
              size={3}
              icon={{
                ...verifiedIconProps({ identity, isLoading }), size: 4, gap: 4,
              }}
            >
              {name}
            </Text>
            <DedicatedIpBadge {...identity} />
          </Shelf>
          <Shelf gap={3} valign="center">
            <Button label="View Config" theme="ghost" onClick={() => setExpanded((e) => !e)} icon={expanded ? 'angle-down' : 'angle-left'} />
            <Button label="Remove ID" theme="destructive-ghost" onClick={deleteIdentity(identity)} icon="trash" />
          </Shelf>
        </Shelf>
        { (verifyChecked)
          ? {
            domain: <DomainIdentity
              expanded={expanded}
              isLoading={isLoading}
              {...identity}
              {...rest}
            />,
            email: <EmailIdentity expanded={expanded} {...identity} />,
          }[identity?.type]
          : null }
      </Stack>
    </Card>
  )
}

const ownProviderDialog = () => {
  const sendDialog = useDialog()
  return () => sendDialog({
    title: 'Bring your own Provider',
    content: (
      <>
        <Text>
          Enveloop is a fully featured email provider, but we also support sending mail via
          MailGun, Postmark, SendGrid and SMTP.
        </Text>
        <Text>
          These configurations can be found on each project under settings/integrations.
        </Text>
      </>
    ),
  })
}

const SenderIdForm = () => (
  <Stack gap={8}>
    <Input name="identity" label="Sender ID" placeholder="domain.com or user@domain.com" />
    <Stack gap={4}>
      <Text tag="h4">Domain (recommended)</Text>
      <Text>
        Send from any email address on your domain and
        prevent your email from being marked as spam with DNS verification.
      </Text>
    </Stack>
    <Stack gap={4}>
      <Text tag="h4">Email Address (easier)</Text>
      <Text>
        This is faster to set up, but it doesn&rsquo;t support DKIM verification.
        We will email you a link to confirm ownership of this address.
      </Text>
    </Stack>
  </Stack>
)

export const SenderIdentities = () => {
  const { Team } = useTeam()
  const {
    fetch,
    data,
    isLoading,
  } = useMailSettings()
  const sendDialog = useDialog()
  const openProviderDialog = ownProviderDialog()

  const { onSubmit: createIdentity } = useFormQuery({
    query: createSenderIdentity,
    queryProps: { Team },
    onSuccess: () => fetch(),
  }, [Team])

  const { onSubmit: deleteIdentity } = useFormQuery({
    query: deleteSenderIdentity,
    queryProps: { Team },
    onSuccess: () => fetch(),
  }, [Team])

  const addIdentityDialog = () => sendDialog({
    onConfirm: createIdentity,
    title: 'Add a Sender ID',
    content: SenderIdForm,
    confirmText: 'Add Sender ID',
  })

  const deleteIdentityDialog = ({ identity, id }) => () => sendDialog({
    onConfirm: deleteIdentity,
    title: 'Delete Sender ID',
    content: (
      <Stack gap={8}>
        <Text>
          If deleted, sending messages with {identity} will fail and return an error message.
        </Text>
        <Input
          name="identity"
          label={`Type ${identity} below to confirm.`}
          placeholder={identity}
          pattern={`^${identity.replace(/([+.])/g, '\\$1')}$`}
          messages={{ pattern: `Please type "${identity}" to confirm.` }}
          required
        />
        <HiddenInput name="id" defaultValue={id} />
      </Stack>
    ),
    destructive: true,
    confirmText: 'Remove Sender ID',
  })

  const senderIdentities = data?.senderIdentities?.length
    ? data?.senderIdentities
    : false
  return (
    <>
      { data?.issues?.length ? (
        <Notice space={6} icon={{ space: 5, name: 'exclamation-triangle' }}><strong>Setup is incomplete:</strong> {`${sentenceJoin(data.issues.map(({ message }) => message))}.`}</Notice>
      ) : null }
      { (data.mailProviders && !data.hasMailProvider) ? (
        <Notice
          space={6}
          theme="info"
          icon={{
            space: 5, name: 'circle-info', size: 4, gap: 4,
          }}
        >
          <strong>Add a sender identity</strong> to send email from your domain or email address.
        </Notice>
      ) : null }
      <SettingsGrid>
        <Stack gap={5}>
          <Text tag="h3">Sender IDs</Text>
          <Text tag="p">
            Add a Sender ID to deliver mail from any domain or email address you control.
            Until you have configured email delivery, Enveloop sends email from robot@enveloop.net.
          </Text>
          <Text>Want to use <Link onClick={openProviderDialog} theme="text">your own email provider</Link>?</Text>
        </Stack>
        <Stack gap={6}>
          { senderIdentities ? (
            <Stack gap={8}>
              { senderIdentities.map((i) => (
                <Identity
                  key={i.id}
                  identity={i}
                  checkAgain={() => fetch()}
                  deleteIdentity={deleteIdentityDialog}
                  isLoading={isLoading}
                />
              )) }
            </Stack>
          ) : (
            <Stack gap={6}>
              <Card space={8}>
                <BlankSlate
                  icon="sender-identities"
                  heading="Send email from your domain or email address"
                  description="Add a Sender ID so your customers know your email is coming from you."
                  buttonText="Add Sender ID"
                  buttonAction={addIdentityDialog}
                  style={{ maxWidth: '500px' }}
                />
              </Card>
            </Stack>
          )}
          { senderIdentities ? (
            <Button style={{ alignSelf: 'end' }} text="Add Sender ID" onClick={addIdentityDialog} icon="plus" />
          ) : null }
        </Stack>
      </SettingsGrid>
    </>
  )
}

export const MailSettings = () => (
  <Main>
    <ProjectHeader title="Mail Settings" />
    <Body space={[9, 8]} scroll>
      <Stack gap={9}>
        <SenderIdentities />
        <Divider flush={8} />
        <DefaultFrom />
      </Stack>
    </Body>
  </Main>
)
