import React from 'react'

import {
  Navigate,
  Routes,
  Route,
} from 'react-router-dom'

import {
  Main, Text, Header,
} from '@level'

import { DmarcReports } from './dmarc'
import './dmarc.scss'

const Monitoring = () => (
  <Main>
    <div style={{ backgroundColor: '#fff' }}>
      <Header primary>
        <Text tag="h2">Monitoring</Text>
      </Header>
    </div>
    <Routes>
      <Route path="*" element={<Navigate to="dmarc" replace />} />
      <Route path="dmarc" element={<DmarcReports />} />
    </Routes>
  </Main>
)

export {
  Monitoring,
}
