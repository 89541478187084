import React from 'react'

import { useNavigate } from 'react-router-dom'
import { useSharedSection } from './provider'

const RevertSharedSection = () => {
  const { revert, sharedSection } = useSharedSection()
  const navigate = useNavigate()

  const revertPath = `../shared-section/${sharedSection.id}/section/${sharedSection.previewConfig.variables.id}`
  React.useEffect(() => {
    (async () => {
      await revert()
      navigate(revertPath, { replace: true })
    })()
  }, [])

  return null
}

export {
  RevertSharedSection,
}
