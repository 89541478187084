import React from 'react'
import {
  Editor, Template, Stack, MagicLoader, Button, Grid,
  useDebounce, sortKeys, includeKeys, removeNestedKeys,
} from '@level'

import { useTemplate, useTemplateTest } from '../context'
import { useAiVars } from './useAiVars'

const reduceTheme = (theme = {}) => (
  theme ? Object.entries(theme).reduce((acc, [name, values]) => (
    { ...acc, [name]: values.value }
  ), {}) : {}
)

const getSectionThemes = ({ sections }) => (
  sections.reduce((acc, section) => {
    const theme = section.variables._theme
    return theme ? { ...acc, ...reduceTheme(theme) } : acc
  }, {})
)

const getThemeVars = (template, previewVars = {}) => {
  const theme = {
    ...getSectionThemes(template.previewConfig),
    ...reduceTheme(template.previewConfig.variables._theme),
  }
  const previewThemeVars = includeKeys(previewVars.theme || {}, theme)
  return (Object.keys(theme).length)
    ? sortKeys({ ...theme, ...previewThemeVars })
    : undefined
}

const useTestEditor = () => {
  const { getData } = useTemplate()
  const { previewVars, setPreviewVars } = useTemplateTest()
  const [refresh, setRefresh] = React.useState(0)

  const editorContent = React.useMemo(() => {
    // Get template vars and theme values
    const templateVars = Template.getTemplateVars({ template: getData().template, previewVars })
    // Show only theme variables that are used in the template
    if (templateVars.theme || templateVars._theme) {
      // Get all theme values merging user vars over theme vars
      let _theme = getThemeVars(getData().template, previewVars) || {}
      // Remove any keys not present in template
      _theme = includeKeys(_theme, { ...templateVars.theme, ...templateVars._theme })
      delete templateVars._theme

      templateVars.theme = _theme
    }
    const json = JSON.stringify(templateVars, null, 2)
    return json === '{}' ? '{\n  \n}' : json
  }, [getData()?.template.previewTemplateVariables, refresh])

  const getEmptyVars = () => {
    const { theme, _theme, ...rest } = Template.getTemplateVars({ template: getData().template })
    return rest
  }

  const setEditorContent = (str) => {
    setPreviewVars(str)
    window.setTimeout(() => setRefresh((r) => r + 1), 0)
  }

  const resetEditor = () => {
    setPreviewVars({})
    window.setTimeout(() => setRefresh((r) => r + 1), 0)
  }

  React.useEffect(() => {
    if (JSON.stringify(previewVars) === '{}') {
      setPreviewVars(JSON.parse(editorContent))
    }
  }, [])

  const editorOnChange = useDebounce((text) => {
    if (text) {
      try {
        setPreviewVars(JSON.parse(text))
      } catch {
        // do nothing
      }
    }
  }, [], 500)

  return {
    previewVars,
    editorContent,
    getEmptyVars,
    resetEditor,
    setEditorContent,
    editorOnChange,
    refresh,
  }
}

const TemplateTestEditor = () => {
  const { editorContent, editorOnChange, resetEditor } = useTestEditor()
  return (
    <Stack gap={6}>
      <Editor content={editorContent} lang="js" onChange={editorOnChange} />
      <Button text="Reset" onClick={resetEditor} />
    </Stack>
  )
}

const AiTemplateTestEditor = () => {
  const {
    editorContent,
    setEditorContent,
    editorOnChange,
    getEmptyVars,
    resetEditor,
    refresh,
  } = useTestEditor()
  const { fetch, isLoading } = useAiVars({ previewVars: getEmptyVars(), setEditorContent })
  return (
    <Stack gap={6}>
      <MagicLoader loading={isLoading}>
        <Editor content={editorContent} lang="js" onChange={editorOnChange} updateContent={refresh} />
      </MagicLoader>
      <Grid gap={5} templateColumns="1fr auto">
        <Button icon="wand-magic-sparkles" text="Magic Fill" onClick={fetch} isLoading={isLoading} />
        <Button icon="trash" label="Clear Values" onClick={resetEditor} />
      </Grid>
    </Stack>
  )
}

export {
  useTestEditor,
  TemplateTestEditor,
  AiTemplateTestEditor,
}
