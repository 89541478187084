import {
  newTemplate, toParams,
} from '@level'

const templateQueryDefaults = `
  id
  slug
  type
  name
  updatedAt
  published
  publishedAt
  previewTemplateVariables
  teamDefaultFrom
  previewConfig {
    body
    variables
    sections {
      body
      variables
      sharedSectionId
    }
  }
  liveConfig {
    body
    variables
    sections {
      body
      variables
      sharedSectionId
    }
  }
`

const createTemplateQuery = ({
  Project, name, slug, config, type,
}) => `mutation {
  template: createTemplate(
    team: "${Project.team.slug}",
    project: "${Project.slug}",
    attributes: {
      type: ${type},
      ${toParams({ slug, name })},
      previewConfig: {
        ${toParams(config || newTemplate({ type }))}
      }
    }
  ) { slug }
}`

const templateQuery = ({ slug, Project }) => `
  template(slug: "${slug}", project: "${Project.slug}", team: "${Project.team.slug}") {
    ${templateQueryDefaults}
  }
`

const deleteTemplateQuery = ({ id }) => `mutation {
  deleteTemplate(id: "${id}") {
    id
  }
}`

const revertTemplateQuery = ({ id }) => `mutation {
  revertTemplate(id: ${id}) { id } 
}`

const updateTemplateQuery = ({
  id, previewConfig, name, slug,
}) => {
  let attributes = toParams({ name, slug })
  if (previewConfig) {
    attributes += `, previewConfig: {
      ${toParams(previewConfig)}
    }`
  }
  return `mutation {
  template: updateTemplate(id: ${id},
  attributes: { 
    ${attributes}
  }
) { id, name, slug }
}`
}

const publishTemplateQuery = ({ id }) => `mutation { publishTemplate(id: ${id}) { id } }`

export {
  templateQuery,
  createTemplateQuery,
  updateTemplateQuery,
  deleteTemplateQuery,
  publishTemplateQuery,
  revertTemplateQuery,
  templateQueryDefaults,
}
