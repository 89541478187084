import React from 'react'

import { Text } from '../Text'
import { PopoverButton } from '../Popover'
import { useSearchState } from '../../hooks'
import { titleize } from '../../helpers/text'

const HeaderFilter = ({ name, activeParams, ...rest }) => {
  const keys = activeParams || [name]
  const [params] = useSearchState()
  const active = Object.keys(params).find((k) => keys.includes(k))

  // Memoise Header to reduce overload
  return React.useMemo(() => (
    <PopoverButton
      className="message-filter-popover-button"
      theme="wrapper"
      text={(
        <Text
          iconAfter={{ name: 'bars-filter', color: active ? 'blue-600' : 'neutral-200', size: '0' }}
          iconAfterGap={4}
          color="neutral-500"
          style={{ textTransform: 'uppercase' }}
        >
          { titleize(name) }
        </Text>
      )}
      {...rest}
    />
  ), [name, active])
}

export {
  HeaderFilter,
}
