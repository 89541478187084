import React from 'react'
import { useSendAuthLink } from '@app/hooks'
import { useNavigate } from 'react-router-dom'
import {
  Header, NavPanel, Icon, Spacer, Button, Main,
  downloadText, useDialog, renderTemplate,
} from '@level'

import { ExportDialog } from './export'
import { SignUpDialog } from './signup'

const templateNav = [
  { text: 'Build', to: '../build' },
  { text: 'Test', to: '../test' },
  { text: 'Deploy', to: '../deploy' },
]

const downloadTemplate = (template) => {
  downloadText(renderTemplate({ ...template, config: template.previewConfig, mode: 'preview-html' }), 'text/html', 'enveloop-template.html')
}

const useExportTemplate = ({ signUp }) => {
  const sendDialog = useDialog()

  return (template) => sendDialog({
    exportHtml: () => downloadTemplate(template),
    signUp: () => signUp(),
    element: ExportDialog,
    width: '650px',
  })
}

const useSignUpDialog = () => {
  const sendDialog = useDialog()
  const { sent, sendLink } = useSendAuthLink()

  const onConfirm = ({ data: { email } }) => {
    sendLink(email)
  }

  return () => sendDialog({
    onConfirm,
    element: SignUpDialog,
    width: '650px',
    sent,
  })
}

const TryLayout = ({ template, children }) => {
  const signUp = useSignUpDialog()
  const exportTemplate = useExportTemplate({ signUp })
  const sendDialog = useDialog()
  const navigate = useNavigate()

  const newTemplateDialog = React.useCallback(() => {
    sendDialog({
      title: 'Overwrite Template?',
      content: 'Choosing a new template will overwrite your current template.',
      confirmText: 'Continue',
      onConfirm: () => navigate('../'),
    })
  }, [])

  return (
    <Main>
      <Header primary>
        <Icon name="enveloop-logomark" width="60px" fill="none" />
        <Spacer space={9} />
        <Button onClick={newTemplateDialog} text="Choose new template" icon={{ gap: 4, name: 'arrow-left', size: 3 }} />
        <Spacer grow />
        <NavPanel navItems={templateNav} />
        <Spacer grow />
        { template ? <Button onClick={() => exportTemplate(template)} icon={{ name: 'up-from-bracket', gap: 4 }} text="Export HTML" /> : null }
        <Spacer space={4} />
        <Button onClick={signUp} theme="primary" text="Save to Enveloop" icon={{ gap: 4, name: 'enveloop-logomark-flat', size: 3 }} />
      </Header>
      { children }
    </Main>
  )
}

export {
  TryLayout,
}
