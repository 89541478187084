import { useSearchState } from './useSearchState'
import { titleize, toOperatorParams } from '../helpers'

// Converts an array of options and a selected option to an array of values
// where the unselected options are nullified
// Input:
//   options: [
//     [{ name: 'square', value: true }],
//     [{ name: 'triangle', value: true }],
//     [{ name: 'circle', value: true }, { name: 'sphere', true, }],
//   }],
//   selectedIndex: 1,
//
// Returns: [
//  { name: 'square', value: null },
//  { name: 'circle', value: null }
//  { name: 'shpere', value: null }
//  { name: 'triangle', value: true },
// ]
const selectValues = ({ options, selectedIndex }) => {
  const unselected = options.filter((op, i) => i !== selectedIndex)
  const selected = options[selectedIndex]

  // Nullify all values for unselected options
  const value = unselected.flat().reduce((acc, option) => {
    if (option?.name) {
      return [...acc, { name: option.name, value: null }]
    }
    return acc
  }, [])

  // If selected option exists, pass on its name and value
  if (selected) {
    return [...value, ...selected]
  }

  return value
}

const useRadioFilter = ({ name, options = [], operators }) => {
  const [params, setParams] = useSearchState()

  return {
    params,
    onChange: (obj) => {
      const value = Object.entries(obj)[0][1]
      const selectedIndex = options.findIndex((i) => i.value === value)
      const filteredValues = selectValues({ options: options.map((o) => o.filter), selectedIndex })

      setParams.merge({
        [name]: options[selectedIndex].value,
        ...(operators ? toOperatorParams(operators, filteredValues) : null),
      })
    },
    inputs: options.map(({ value, label, icon }) => {
      const filterValue = params?.[name]

      // Checked if param value matches, or if param value is unset and there is no value
      const defaultChecked = (filterValue === value) || (!value && !filterValue)

      // Set the value specified and unset any values for other inputs
      return ({
        key: `${name}_${value || label}`,
        label: label || titleize(value),
        icon,
        name,
        value,
        defaultChecked,
      })
    }),
  }
}

const useCheckFilter = ({ name, options = [] }) => {
  const [params, setParams] = useSearchState()
  const current = (params?.[name]?.split(',')) || []
  return {
    onChange: (obj) => {
      // Get the name of the input which changed
      const key = Object.entries(obj)[0][0]
        // Convert to param from the name
        .replace(`${name}_`, '')

      const value = current.includes(key)
      // If the value is already present remove from list
        ? current.filter((v) => v !== key).join(',')
      // If the value is not present, add it.
        : [...current, key].join(',')
      setParams.merge({ [name]: value })
    },
    inputs: options.map(({ value, label, icon }) => ({
      key: `${name}_${value}`,
      name: `${name}_${value}`,
      icon,
      value,
      label: label || titleize(value),
      defaultChecked: current.includes(value),
    })),
  }
}

export {
  useRadioFilter,
  useCheckFilter,
}
