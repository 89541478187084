const sanitizeSecret = (key, value) => {
  const keysToSanitize = ['key', 'secret']
  const keysToHide = ['pass', 'authtoken', 'password']

  if (keysToSanitize.find((k) => key.toLowerCase().includes(k))) {
    return `**********${value.slice(-5)}`
  } if (keysToHide.find((k) => key.toLowerCase().includes(k))) {
    return '**********'
  }
  return value
}

const mg = 'mailgun'
const pm = 'postmark'
const sg = 'sendgrid'
const smtp = 'smtp'
const ses = 'ses'

const twilio = 'twilio'
const textgrid = 'textgrid'

const providerLabels = {
  [mg]: 'Mailgun',
  [pm]: 'Postmark',
  [sg]: 'SendGrid',
  [ses]: 'SES',
  [smtp]: 'SMTP',
  [twilio]: 'Twilio',
  [textgrid]: 'TextGrid',
}

const docsRoot = 'https://docs.enveloop.com/integrations'
const providerDocs = {
  email: `${docsRoot}/email-integrations`,
  [mg]: `${docsRoot}/email-integrations/mailgun`,
  [pm]: `${docsRoot}/email-integrations/postmark`,
  [sg]: `${docsRoot}/email-integrations/sendgrid`,
  [ses]: `${docsRoot}/email-integrations/amazon-ses`,
  [smtp]: `${docsRoot}/email-integrations/smtp`,
  sms: `${docsRoot}/sms-integrations`,
  [twilio]: `${docsRoot}/email-integrations/twilio`,
  [textgrid]: `${docsRoot}/email-integrations/textgrid`,
}

/* const defaultConfig = { */
/*   mailProvider: mg, */
/*   smsProvider: twilio, */
/* } */
/**/
const providerConfigs = {
  mailProvider: {
    [mg]: {
      apiDomain: { label: 'API Domain', placeholder: 'mg.domain.com', required: true },
      apiKey: { label: 'API Key', placeholder: 'Vrbdy2ThLmtCmnFhqwhgads', required: true },
      region: { options: [{ value: 'US' }, { value: 'EU' }], required: false },
    },
    [pm]: {
      apiKey: { label: 'API Key', placeholder: 'Vrbdy2ThLmtCmnFhqwhgads', required: true },
    },
    [sg]: {
      apiKey: { label: 'API Key', placeholder: 'Vrbdy2ThLmtCmnFhqwhgads', required: true },
    },
    [ses]: {
      accessKeyId: { label: 'AWS accessKeyId', placeholder: '1234abcdefg', required: true },
      secretAccessKey: { label: 'AWS secretAccessKey', placeholder: 'Vrbdy2ThLmtCmnFhqwhgads', required: true },
      region: { label: 'AWS Region', placeholder: 'us-east-1', required: true },
    },
    [smtp]: {
      host: { placeholder: 'smtp.pawnee.gov', required: true },
      user: { placeholder: 'lilsebastion', required: true },
      pass: { placeholder: '5000C@ndl3z', required: true },
      port: { placeholder: '587', required: false },
    },
  },
  smsProvider: {
    [twilio]: {
      accountSid: { label: 'Account SID', placeholder: '1234abcdefg', required: true },
      authToken: { placeholder: 'Vrbdy2ThLmtCmnFhqwhgads', required: true },
    },
    [textgrid]: {
      accountSid: { label: 'Account SID', placeholder: '1234abcdefg', required: true },
      authToken: { placeholder: 'Vrbdy2ThLmtCmnFhqwhgads', required: true },
    },
  },
}

export {
  sanitizeSecret,
  providerLabels,
  providerConfigs,
  providerDocs,
}
