import { Button } from './Button'
import { Divider } from './Divider'
import { Heading } from './Heading'
import { Image } from './Image'
import { Table } from './Table'
import { Text } from './Text'

const Elements = {
  Button,
  Divider,
  Heading,
  Image,
  Table,
  Text,
}

export {
  Button, Divider, Heading, Image, Table, Text
}

export default Elements
