import PropTypes from 'prop-types'
import React from 'react'
import { useTemplateState } from '../../../../hooks/useTemplatePath'
import { Button } from '../../../Button'
import { Card } from '../../../Card'
import { Divider } from '../../../Divider'
import { Grid } from '../../../Grid'
import { Icon } from '../../../Icon'
import { Link } from '../../../Link'
import { Notice } from '../../../Notice'
import { Preview } from '../../../Preview'
import { Shelf } from '../../../Shelf'
import { Stack } from '../../../Stack'
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '../../../Tabs'
import { SectionLabel, Text } from '../../../Text'
import { Template } from '../../Template'
import { PresetSections } from '../Section'
import { useTemplateConfig } from './useTemplateConfig'

import { dasherize } from '../../../../helpers'

const ElementMenu = [
  { text: 'Text', icon: 'section-text-section' },
  { text: 'Heading', icon: 'section-heading-section' },
  { text: 'Image', icon: 'section-image-section' },
  { text: 'Button', icon: 'section-button-section' },
  { text: 'Divider', icon: 'section-divider-section' },
  { text: 'Table', icon: 'section-table-section' },
]

const SharedSectionMenuItem = ({ section, ...props }) => {
  const { config: { variables } } = useTemplateConfig()
  return (
    <Link
      label={`add shared section ${section.name}`}
      color="text-default"
      {...props}
    >
      <Stack gap={3}>
        <Card space={[3, 3]} theme="gray">
          <Stack style={{ height: '100px' }}>
            <div style={{ flexGrow: 1, overflow: 'hidden' }}>
              <Preview zoom={0.4}>
                <Template type="email" config={{ sections: [section.liveConfig], variables }} mode="preview" hooks={{ watermark: false }} />
              </Preview>
            </div>
          </Stack>
        </Card>
        <Text size={0} color="neutral-500">{section.name}</Text>
      </Stack>
    </Link>
  )
}

SharedSectionMenuItem.propTypes = {
  section: PropTypes.object.isRequired,
}

const MenuItem = ({
  text, icon, children, ...props
}) => (
  <Link
    label={`add a ${text} section`}
    color="text-default"
    {...props}
  >
    <Stack gap={2}>
      {children ? (
        <Card space={[3, 3]} theme="gray">
          {children}
        </Card>
      ) : (
        <Card space={[3, 3]} theme="gray">
          {icon ? <Icon name={icon} width="100%" height={null} /> : null}
        </Card>
      )}
      <Text size={0} color="neutral-500">{text}</Text>
    </Stack>
  </Link>
)

MenuItem.propTypes = Link.propTypes

const Menu = ({
  label,
  ...props
}) => (
  <Stack gap={5}>
    {label ? <SectionLabel>{label}</SectionLabel> : null}
    <Stack className="level-drawer-menu" {...props} />
  </Stack>
)

Menu.propTypes = {
  label: PropTypes.string,
}

const PresetMenu = ({ add }) => (
  Object.entries(PresetSections).map(([name, sections], index) => (
    <React.Fragment key={name}>
      {index !== 0 ? <Divider /> : null}
      <Stack gap={5} space={7}>
        <Text tag="h5">{name}</Text>
        <Menu>
          <Grid columns={3} gap={6}>
            {Object.keys(sections).reduce((all, section) => (
              [...all, (
                <MenuItem
                  key={section}
                  text={sections[section].name}
                  icon={`section-${dasherize(section)}`}
                  onClick={() => { add(section) }}
                />
              )]
            ), [])}
          </Grid>
        </Menu>
      </Stack>
    </React.Fragment>
  ))
)
const ElementsMenu = () => {
  const { config, setShowDrawerMenu, addElement } = useTemplateConfig()
  const {
    section, sectionId, elementId,
  } = useTemplateState({ config })
  const close = () => setShowDrawerMenu(false)
  const add = async (type) => {
    await addElement({ element: { type }, sectionId, elementId })
    close()
  }
  if (!section) {
    return (
      <Stack gap={5} space={[7, 7, 0, 7]}>
        <Notice space={5}>
          Elements must be placed in sections
          Select a section (or an element) in the template builder to add a new element.
        </Notice>
      </Stack>
    )
  }
  if (section.sharedSectionId) {
    return (
      <Stack gap={5} space={[7, 7, 0, 7]}>
        <Notice space={5}>
          Elements cannot be added to a shared section from here.
        </Notice>
      </Stack>
    )
  }
  return (
    <Stack gap={5} space={[7, 7, 0, 7]}>
      <Menu>
        <Grid columns={3} gap={6}>
          {ElementMenu.map((el) => (
            <MenuItem
              key={el.text}
              text={el.text}
              icon={el.icon}
              onClick={() => { add(el.text) }}
            />
          ))}
        </Grid>
      </Menu>
    </Stack>
  )
}

const DrawerMenu = () => {
  const {
    mode, addSection, addSharedSection, sharedSections, config, setShowDrawerMenu,
  } = useTemplateConfig()
  const { sectionId, section } = useTemplateState({ config })
  const close = () => setShowDrawerMenu(false)
  const liveSharedSections = sharedSections
    ? sharedSections.filter((s) => !!s.liveConfig.body)
    : false

  const add = async (s) => {
    await addSection({ section: s, sectionId })
    close()
  }

  const addShared = async (s) => {
    await addSharedSection({ section: s, sectionId })
    close()
  }

  const header = (
    <Shelf space={[7, 7, 0, 7]} align="split" valign="center">
      <Text tag="h3">Add Template Items</Text>
      <Button icon="x-light" size={2} theme="ghost" label="close panel" onClick={close} />
    </Shelf>
  )

  const tabs = []
  if (config.sections.length) {
    tabs.push({
      id: 'add-elements-tab',
      title: 'Elements',
      content: (
        <ElementsMenu add={add} templateVariables={config.variables} sectionId={sectionId} />
      ),
    })
  }
  if (mode !== 'section-builder') {
    tabs.push({
      id: 'add-sections-tab',
      title: 'Sections',
      content: <PresetMenu add={add} templateVariables={config.variables} sectionId={sectionId} />,
    })
    tabs.push({
      id: 'add-shared-sections-tab',
      title: 'Shared Sections',
      content: liveSharedSections.length ? (
        <Grid columns={2} gap={6} space={7}>
          {liveSharedSections.map((sec) => (
            <SharedSectionMenuItem
              key={sec.id}
              onClick={() => addShared(sec)}
              section={sec}
            />
          ))}
        </Grid>
      ) : (
        <div className="message-log-empty">
          <Text tag="h4">No Shared Sections</Text>
        </div>
      ),
    })
  }
  const selected = !config.sections.length || (sectionId && !section?.sharedSectionId) ? 'add-elements-tab' : 'add-sections-tab'

  return (
    <Stack gap={5}>
      {header}
      <Tabs defaultSelectedId={selected}>
        <TabList>
          {tabs.map(({ title, id }) => (
            <Tab text={title} key={title} id={id} />
          ))}
        </TabList>
        <TabPanels>
          {tabs.map(({ title, content }) => (
            <TabPanel key={title}>{content}</TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Stack>
  )
}

export {
  DrawerMenu
}
