const asciiCredit = `
<!--
  ______                _                                       
 |  ____|  created on  | |                                      
 | |__   _ ____   _____| | ___   ___  _ __   ___ ___  _ __ ___  
 |  __| | '_ \\ \\ / / _ \\ |/ _ \\ / _ \\| '_ \\ / __/ _ \\| '_ \` _ \\ 
 | |____| | | \\ V /  __/ | (_) | (_) | |_) | (_| (_) | | | | | |
 |______|_| |_|\\_/ \\___|_|\\___/ \\___/| .__(_)___\\___/|_| |_| |_|
                                     | |                        
                                     |_|                        
-->`

export { asciiCredit }
