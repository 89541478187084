import PropTypes from 'prop-types'
import { themes, tagSizes } from '../constants/themes'
import { toColor } from './color'
import { spaceToSize, validSizes } from './space'
import sizes from '../constants/fontSize'

const validFontSizes = Object.keys(sizes).map((n) => {
  const num = parseInt(n, 10)
  return Number.isNaN(num) ? n : num
})

const toFontSize = (props) => {
  if (typeof props !== 'object') return sizes[props]

  const obj = {}
  Object.keys(props).forEach((key) => {
    obj[key] = sizes[props[key]]
  })
  return obj
}

const toTheme = (props = {}, allThemes = themes) => {
  const options = {
    ...themes.default, ...allThemes[props.theme], ...props,
  }
  return {
    ...options,
    color: toColor(options.color),
    space: spaceToSize(options.space),
    fontSize: toFontSize(options.fontSize),
  }
}

toTheme.propTypes = {
  ...toColor.propTypes,
}

const toTextStyle = (props = {}) => {
  const fontSize = validFontSizes.includes(props.fontSize) ? props.fontSize : tagSizes[props.tag]

  const options = toTheme({
    ...themes.default,
    ...props,
    fontSize,
  })

  options.style = { fontSize: options.fontSize, color: options.color }

  return options
}

toTextStyle.propTypes = {
  theme: PropTypes.string,
  tag: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  ...toTheme.propTypes,
}

toFontSize.propTypes = {
  size: PropTypes.oneOf(validSizes.concat(['inherit', 0])),
}

export {
  toTheme,
  toTextStyle,
  toFontSize,
}
