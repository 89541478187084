import mustache from 'mustache'

// False if all values are falsy
const varsAreSet = (obj) => (
  Object.values(obj).filter((v) => v).length
)
const renderMustache = (content, vars) => {
  try {
    return (vars && varsAreSet(vars))
      ? mustache.render(content, vars)
      : content
  } catch (e) {
    return content
  }
}

const parseMustacheVariables = (mustacheParts, variableName = null) => {
  let variables = []

  mustacheParts.forEach((part) => {
    const [partType, variable] = part

    if (partType === 'name' || partType === '^' || partType === '&') { // variable nodes
      if (variableName) { // if we're in a nested variable
        if (variable === variableName || variable === '.') {
          variables.push(variableName)
        } else {
          variables.push(`${variableName}.${variable}`)
        }
      } else { // if we're in the root
        variables.push(variable)
      }
    } else if (part[0] === '#') { // this could be a conditional section or an array
      const section = part[1] // the name of the section/array variable
      const sectionVariables = parseMustacheVariables(part[4], variableName ? `${variableName}.${section}` : section)
      if (!sectionVariables.length) { // if the section is empty, it's a conditional section
        variables.push(variableName ? `${variableName}.${section}` : section)
      }

      // add the section name and all the variables in the section
      variables = [...variables, ...sectionVariables]
    }
  })

  return variables
}

const getTemplateVariables = (content) => parseMustacheVariables(mustache.parse(content))

// Replace {{ var }} with {{{ var }}}
const autoEscapeMustache = (value) => (
  value ? value.replace(/(^|[^{])({{2}[^}{]+?}{2})($|[^}])/g, (_, p1, p2, p3) => (
    `${p1 || ''}{${p2}}${p3 || ''}`
  )) : value
)

export {
  renderMustache,
  getTemplateVariables,
  autoEscapeMustache,
}
