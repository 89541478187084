import React from 'react'
import PropTypes from 'prop-types'

import { useProjects, useTeam } from '@app/hooks'
import {
  Nav, Button, NavTree, Block,
} from '@level'
import { useNewProjectDialog } from './new'

const ProjectNav = ({ project, ...props }) => {
  const { projectsUrl } = useProjects()
  const root = projectsUrl(project.slug)

  const navItems = React.useMemo(() => [
    { text: 'Templates', to: `${root}/templates?type=all` },
    { text: 'Shared Sections', to: `${root}/shared-sections` },
    { text: 'Message Log', to: `${root}/messages` },
    { text: 'Error Log', to: `${root}/errors` },
    { text: 'Analytics', to: `${root}/analytics`, feature: import.meta.env.VITE_NODE_ENV === 'development' },
    { text: 'Settings', to: `${root}/settings` },
  ], [root])

  return (
    <NavTree
      rootLink={{
        to: root,
        text: project.name || '',
      }}
      navItems={navItems}
      {...props}
    />
  )
}

ProjectNav.propTypes = {
  project: PropTypes.object.isRequired,
}

const ProjectsNav = () => {
  const { projects } = useProjects()
  const { isProviderTeam } = useTeam()
  const newProjectDialog = useNewProjectDialog()

  return projects ? (
    <Nav space={0} className="projects-nav">
      {projects.map((project) => (
        <ProjectNav key={project.slug} project={project} open={projects.length === 1} />
      ))}
      {!isProviderTeam ? (
        <Block space={5}>
          <Button onClick={newProjectDialog} icon="plus" text="Add Project" theme="link" />
        </Block>
      ) : null}
    </Nav>
  ) : null
}

export {
  ProjectNav,
  ProjectsNav,
}
