import {
  keymap, highlightSpecialChars, drawSelection, highlightActiveLine, dropCursor,
  lineNumbers, highlightActiveLineGutter,
} from '@codemirror/view'
import {
  defaultHighlightStyle, syntaxHighlighting, indentOnInput, bracketMatching,
} from '@codemirror/language'
import { defaultKeymap, history, historyKeymap } from '@codemirror/commands'
import { closeBrackets, closeBracketsKeymap } from '@codemirror/autocomplete'
import { oneDark } from '@codemirror/theme-one-dark'

const config = [
  lineNumbers(),
  highlightActiveLineGutter(),
  highlightSpecialChars(),
  history(),
  drawSelection(),
  dropCursor(),
  indentOnInput(),
  syntaxHighlighting(defaultHighlightStyle, { fallback: true }),
  oneDark,
  bracketMatching(),
  closeBrackets(),
  highlightActiveLine(),
  keymap.of([
    ...closeBracketsKeymap,
    ...defaultKeymap,
    ...historyKeymap,
  ]),
]

export {
  config,
}
