import React from 'react'
import PropTypes from 'prop-types'
import { useParams, Outlet } from 'react-router-dom'
import {
  useProject, useQuery, QueryProvider,
  useUploadImage,
} from '@app/hooks'
import {
  TemplateConfigProvider, templateConfigDefaults, renderSection,
  useDebounce, useChannel, useUuid,
} from '@level'
import { updateSectionConfig } from '@level/components/Template/Email/helpers'

import {
  useRevertSharedSection,
  usePublishSharedSection,
  useDeleteSharedSection,
  useSaveToSharedSection,
  useRenameSharedSection,
} from './hooks'

import {
  sharedSectionsQuery,
  sharedSectionQuery,
  updateSectionQuery,
} from './queries'

// All SharedSections
const SharedSectionsContext = React.createContext({})
const useSharedSections = () => React.useContext(SharedSectionsContext)

const SharedSectionsProvider = (props) => {
  const { Project } = useProject()
  const saveToSharedSection = useSaveToSharedSection()

  const onData = React.useCallback(({ refresh }) => (
    {
      saveToSharedSection: async (data) => {
        const response = await saveToSharedSection(data)
        refresh()
        return response
      },
    }
  ), [Project?.slug])

  if (Project) {
    return (
      <QueryProvider
        context={SharedSectionsContext}
        query={sharedSectionsQuery({ Project })}
        onData={onData}
        {...props}
      />
    )
  }
  return null
}

// Current Shared Section
const SharedSectionContext = React.createContext({})
const useSharedSection = () => React.useContext(SharedSectionContext)

const SharedSectionProvider = ({ children }) => {
  const { Project } = useProject()
  const { sharedSectionId } = useParams()

  if (sharedSectionId) {
    return (
      <QueryProvider
        context={SharedSectionContext}
        query={sharedSectionQuery({ Project, sharedSectionId })}
      >
        <ConfigSharedSectionProvider>
          {children || <Outlet />}
        </ConfigSharedSectionProvider>
      </QueryProvider>
    )
  }
  return null
}

SharedSectionProvider.propTypes = {
  children: PropTypes.node,
}

const ConfigSharedSectionProvider = ({ children }) => {
  const { sharedSection, refresh, isLoading } = useSharedSection()
  const [state, setState] = React.useState({ section: sharedSection, updated: null })
  const channel = useChannel(`sharedSection/${sharedSection.id}-${useUuid()}`)
  const uploadImage = useUploadImage()

  const updateState = ({ data }) => {
    if (data?.sharedSection) setState({ section: data.sharedSection, updated: {} })
  }

  const refreshSection = () => refresh().then(updateState)

  const publish = usePublishSharedSection({ sharedSection })
  const publishSection = () => publish().then(updateState)

  const revert = useRevertSharedSection({ sharedSection })
  const revertSection = () => revert().then(updateState)

  const deleteSharedSection = useDeleteSharedSection({ sharedSection, redirect: '../' })

  const [update] = useQuery(updateSectionQuery)
  const pushUpdate = useDebounce(async (updates) => {
    const { data } = await update(updates)
    if (state.section.published === true) {
      updateState({ data })
    }
  }, [state.section.published], 500)

  const updateSharedSection = React.useCallback(async ({
    section, localOnly, ...props
  }) => {
    updateSectionConfig({ section, ...props })
    const updates = {
      id: sharedSection.id,
      previewConfig: renderSection({ section }),
    }

    setState(({ section: sec }) => ({
      section: { ...sec, ...updates.previewConfig },
      updated: {},
    }))
    if (!localOnly) pushUpdate(updates)
  }, [pushUpdate])

  const renameSection = useRenameSharedSection({ sharedSection: state.section })
  const renameSharedSection = async () => {
    const response = await renameSection()
    if (response?.data) {
      updateState({ data: response.data })
    }
  }

  const value = React.useMemo(() => ({
    sharedSection: state.section,
    publishSection,
    deleteSharedSection,
    updateSharedSection,
    renameSharedSection,
    uploadImage,
    refresh: refreshSection,
    revertSection,
    isLoading,
    channel,
    config: {
      sections: [state.section.previewConfig],
      variables: templateConfigDefaults.template,
    },
  }), [state.updated])
  const [imageHeights, setImageHeights] = React.useState({})

  return (
    <SharedSectionContext.Provider value={value}>
      <TemplateConfigProvider
        mode="section-builder"
        config={value.config}
        name="none"
        slug="none"
        type="email"
        uploadImage={uploadImage}
        updateSharedSection={updateSharedSection}
        renameSharedSection={renameSharedSection}
        deleteSharedSection={deleteSharedSection}
        imageHeights={imageHeights}
        setImageHeights={setImageHeights}
        watermark={false}
      >
        {children}
      </TemplateConfigProvider>
    </SharedSectionContext.Provider>
  )
}

ConfigSharedSectionProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export {
  useSharedSections,
  SharedSectionsProvider,
  useSharedSection,
  SharedSectionProvider,
}
