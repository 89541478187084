import PropTypes from 'prop-types'
import React from 'react'
import { useParams } from 'react-router-dom'
import {
  EmailTable,
  IfMso,
  PreviewText, Raw, deepMerge,
  injectHtml, jsxToString,
  preRenderSections,
  previewThemeVariables,
  renderPreview,
} from '../../../helpers'
import { useMaxSize } from '../../../hooks/useMaxSize'
import { Link } from '../../Link'
import { TemplateProvider, useTemplate } from '../useTemplate'
import { asciiCredit } from './ascii'
import { fontFamilies } from './Config/defaults'
import { ElementDragContext } from './DragContext'
import { DragOverlay } from './DragOverlay'
import Elements from './Element'
import { Section } from './Section'
import { WatermarkSection } from './Section/watermark'
import previewStyle from './style/preview.scss?inline'
import templateStyle from './style/template.scss?inline'

const Style = ({ style }) => (style?.toString ? (
  <style>{style.toString().replace(/\/\*[^\0]+$/g, '')}</style>
) : null)

Style.propTypes = { style: PropTypes.any }

const PreviewLink = () => {
  const params = useParams()
  let root = './'

  // Root for shared sections are the section
  if (params.sharedSectionId) {
    // Get the url before the element path
    [root] = `./${params['*']}`.split('element/')
  } else if (params['*'] === '') root = '.' // Link should be active at .../build/ and .../build

  return (
    <Link
      label="Back to root"
      title=""
      className="template-root-link"
      to={root}
      end
    >
      Back
    </Link>
  )
}

PreviewLink.propTypes = {
  mode: PropTypes.string.isRequired,
}

const TemplateTable = React.forwardRef(function TemplateTable({
  mode,
  style,
  children,
  ...props
}, ref) {
  return (
    <EmailTable
      align="center"
      className="enveloop-email-background"
      style={{
        backgroundColor: style.backgroundColor || null,
        maxWidth: '100%',
        position: 'relative',
      }}
      data-mode={!['render', 'preview-html'].includes(mode) ? mode : null}
      onClickCapture={!['render', 'preview-html'].includes(mode) ? (e) => {
        // Prevent clicks on links rendered from user content
        const link = e.target.closest('a')
        if (link && !link.href?.includes(window.location.host)) {
          e.preventDefault()
          e.stopPropagation()
        }
      } : null}
      ref={ref}
      {...props}
    >
      <tbody>
        <tr>
          <td
            valign="top"
            align="center"
            style={{
              padding: style.padding,
            }}
          >
            {mode.match('builder') ? <PreviewLink mode={mode} /> : null}
            <EmailTable
              className="enveloop-email-container"
              width={!mode.match(/builder/) && !mode.match(/preview/) ? style.width : null}
              style={{
                backgroundColor: style.contentBackgroundColor || null,
                fontFamily: fontFamilies[style.fontFamily] || null,
                maxWidth: `${style.width}px`,
                fontSize: '16px',
              }}
            >
              {/* if children is a string, embed string as jsx otherwise do that */}
              {typeof children === 'string'
                ? <tbody {...injectHtml(children)} />
                : <tbody>{children}</tbody>}
            </EmailTable>
          </td>
        </tr>
      </tbody>
    </EmailTable>
  )
})

TemplateTable.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object.isRequired,
  mode: PropTypes.string,
}

const TemplateTableBody = React.forwardRef(function TemplateTableBody({ content, ...props }, ref) {
  return (
    <TemplateTable {...props} ref={ref}>
      {content}
    </TemplateTable>
  )
})

TemplateTableBody.propTypes = {
  content: PropTypes.any,
}

const BuilderTemplateBody = ({
  sections, hooks, isActive, footerRef, children, ...props
}) => {
  const { contentRef } = useMaxSize({ footerRef, offsetPadding: 35 })
  const dragging = (() => {
    if (hooks.dragging) {
      return hooks.dragging.match(/element/) ? 'element' : 'section'
    }
    return undefined
  })()
  return (
    <>
      <Style style={templateStyle} />
      <Style style={previewStyle} />
      <ElementDragContext
        sections={sections}
        onChangeElements={hooks.changeElementOrder}
        onChangeSections={hooks.changeSectionOrder}
        onComplete={hooks.saveTemplate}
        isActive={isActive}
        onDragStart={hooks.onDragStart}
        onDragEnd={hooks.onDragEnd}
        onDragCancel={hooks.onDragEnd}
      >
        <div
          className="level-template-builder-bg"
          data-dragging={dragging}
        >
          <div className="level-template-wrapper">
            {props.mode.match('builder') ? <PreviewLink mode={props.mode} /> : null}
            <TemplateTableBody ref={contentRef} content={children} {...props} />
            <DragOverlay />
          </div>
        </div>
      </ElementDragContext>
    </>
  )
}

BuilderTemplateBody.propTypes = {
  sections: PropTypes.array,
  hooks: PropTypes.object,
  isActive: PropTypes.func,
  footerRef: PropTypes.object,
  children: PropTypes.node,
  mode: PropTypes.string,
}

const PreviewTemplateBody = ({ footerRef, ...props }) => {
  const { contentRef } = useMaxSize({ footerRef })
  return (
    <>
      <Style style={templateStyle} />
      <Style style={previewStyle} />
      <TemplateTableBody {...props} ref={contentRef} />
    </>
  )
}

PreviewTemplateBody.propTypes = {
  footerRef: PropTypes.object,
}

const EmailTemplateBody = ({ sections, footerRef }) => {
  const {
    mode, config: templateConfig,
    hooks, isActive, previewVars,
  } = useTemplate()

  const { style, _theme, config } = templateConfig.variables
  const watermark = <WatermarkSection mode={mode} style={style} watermark={hooks.watermark} />

  if (mode.match(/builder/)) {
    return (
      <BuilderTemplateBody
        footerRef={footerRef}
        hooks={hooks}
        isActive={isActive}
        sections={sections}
        mode={mode}
        style={previewThemeVariables({ style, _theme })}
        watermark={watermark}
      >
        {sections.map(({ variables, ...rest }) => (
          <Section
            key={variables.id}
            variables={variables}
            mode={mode}
            templateVariables={{
              style: previewThemeVariables({ style, _theme }),
              config,
              _theme,
            }}
            {...rest}
          />
        ))}
        {watermark}
      </BuilderTemplateBody>
    )
  }

  const sectionsContent = (() => {
    // If render, we're sending {{{ sections }}} to server for remote replacemnt
    if (mode === 'render') return `{{{ sections }}} ${jsxToString(watermark)}`
    // If preview mode, pre-render sections with markdown variables baked in.
    return preRenderSections({
      sections, previewVars, _theme, preview: mode === 'preview',
    })
  })()

  if (['render', 'preview-html'].includes(mode)) {
    return (
      <html lang="en">
        {/* Some clients like to strip the first head element */}
        <Raw text={asciiCredit} />
        <Raw text="<head></head>" />
        <head>
          <meta charSet="UTF-8" />
          <meta name="viewport" content="width=device-width" initial-scale="1" />
          <meta name="x-apple-disable-message-reformatting" />
          <meta name="color-scheme" content="light" />
          <meta name="supported-color-schemes" content="light" />
          <IfMso mode={mode}>
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
          </IfMso>
          <Style style={templateStyle} />
        </head>
        <body
          className="enveloop-email-background"
          style={{
            padding: '0',
            margin: '0',
            backgroundColor: style.backgroundColor || null,
          }}
        >
          <Style style={templateStyle} />
          <PreviewText text={config?.previewText} />
          <TemplateTable mode={mode} style={style}>
            {sectionsContent}
          </TemplateTable>
        </body>
      </html>
    )
  }
  return (
    <PreviewTemplateBody
      footerRef={footerRef}
      mode={mode}
      style={previewThemeVariables({ style, _theme, previewVars })}
      content={renderPreview(sectionsContent, { previewVars, _theme })}
    />
  )
}

EmailTemplateBody.propTypes = {
  sections: PropTypes.array.isRequired,
  footerRef: PropTypes.object,
}

const EmailTemplate = (props) => {
  const context = useTemplate()
  const {
    mode, config, footerRef,
  } = { ...context, ...props }

  if (context?.config && mode !== 'preview') {
    return <EmailTemplateBody {...config} footerRef={footerRef} mode={mode} />
  }

  return (
    <TemplateProvider {...props}>
      <EmailTemplateBody {...config} footerRef={footerRef} mode={mode} />
    </TemplateProvider>
  )
}

EmailTemplate.propTypes = {
  mode: PropTypes.string,
}

EmailTemplate.getMustacheTypes = ({ template, config = 'previewConfig' }) => {
  let types = {
    iterable: [],
    conditional: [],
  }
  const { sections } = template[config] // previewConfig or liveConfig
  sections.forEach(({ variables }) => {
    types = deepMerge(types, Section.getMustacheTypes(variables))
    variables.columns.forEach(({ elements }) => {
      elements.filter((el) => !!el).forEach((element) => {
        const El = Elements[element.type]
        if (El?.getMustacheTypes) {
          const elTypes = El.getMustacheTypes({ element })
          types = deepMerge(types, elTypes)
        }
      })
    })
  })

  return types
}

export {
  EmailTemplate
}
