import React from 'react'
import PropTypes from 'prop-types'

import {
  CheckSwitch, Form, Shelf, Text, Icon, CopyButton, Grid,
  includeKeys, capitalize,
} from '@level'

import { useFormQuery } from '@app/hooks'

import {
  updateEventWebhookQuery,
  eventsConfig, configToEvent, useEventWebhooks,
} from './queries'

import { EditWebhook } from './edit'

const ToggleUpdate = ({ name, cell, row }) => {
  const value = cell.renderValue()
  const { refresh } = useEventWebhooks()
  const [state, setState] = React.useState(value)
  const { onSubmit } = useFormQuery({
    query: updateEventWebhookQuery,
    onComplete: () => refresh(),
    queryProps: { id: row.original.id },
    successMessage: state ? 'Webhook disabled' : 'Webhook enabled',
  })

  return (
    <Form onSubmit={onSubmit}>
      <CheckSwitch
        defaultChecked={value}
        name={name}
        label={false}
        onChange={() => setState((s) => !s)}
        submitOnChange
        title={value ? 'Disable Webhook' : 'Enable Webhook'}
      />
    </Form>
  )
}

ToggleUpdate.propTypes = {
  name: PropTypes.string.isRequired,
  cell: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
}

const ToggleEnabled = (props) => (
  <ToggleUpdate name="enabled" {...props} />
)

const EventsCell = ({ row }) => {
  const events = includeKeys(row.original, eventsConfig)
  const truthyEvents = Object.entries(events).filter((i) => i[1] === true).map(([i]) => i)
  return (
    <Shelf gap={8} align="split" valign="center" nowrap>
      { truthyEvents.map(configToEvent).map(capitalize).join(', ') }
      <EditWebhook webhook={row.original} />
    </Shelf>
  )
}

const URLCell = ({ cell }) => (
  <Grid templateColumns="1fr min-content" gap={4} valign="center">
    <Text tag="code" forceWrap>{ cell.renderValue()}</Text>
    <CopyButton text={cell.renderValue()} />
  </Grid>
)

const IncludeBodyCell = ({ cell }) => (
  cell.renderValue() ? <Icon name="check-circle" color="neutral-400" /> : null
)

export {
  ToggleEnabled,
  EventsCell,
  URLCell,
  IncludeBodyCell,
}
