import { changeArrayPosition, copyObject, jsxToString, uuid } from '../../../../../helpers'
import { Row } from './rows'

const getMustacheRows = ({ tableConfig }) => {
  const result = []
  const rowGroups = ['header', 'body', 'footer']
  rowGroups.forEach((rowGroup) => {
    const { rows } = tableConfig[rowGroup]
    rows.forEach((row, rowIndex) => {
      result.push({
        row,
        rowGroup,
        rowIndex,
      })
    })
  })
  return result
}

const renderDataRowHtml = (props) => {
  const { element: { config } } = props
  const { tableConfig } = config
  const newTableConfig = copyObject(tableConfig)

  const rows = getMustacheRows(config)

  rows.forEach(({ row, rowIndex, rowGroup }) => {
    newTableConfig[rowGroup].rows[rowIndex].html = jsxToString(<Row
      row={row}
      rowGroup={rowGroup}
      rowIndex={rowIndex}
      {...props}
      mode="preview"
    />)
  })

  // find iterable configs
  return { ...config, tableConfig: newTableConfig }
}

const getMustacheTypes = ({ element: { config: { tableConfig } } }) => {
  const types = {
    iterable: [],
    conditional: [],
  }
  const rows = getMustacheRows({ tableConfig })
  rows.forEach(({ row }) => {
    types[row.type]?.push(row.variable)
  })

  return types
}

const addRow = ({ tableConfig, rowGroup, type = 'text' }) => {
  const obj = copyObject(tableConfig[rowGroup])
  obj.rows.push({ id: `row-${uuid()}`, columns: [], type })
  return { ...tableConfig, [rowGroup]: obj }
}

const removeRow = ({ tableConfig, rowGroup, index }) => {
  const obj = copyObject(tableConfig[rowGroup])
  obj.rows.splice(index, 1)
  return { ...tableConfig, [rowGroup]: obj }
}

const changeRowOrder = ({
  tableConfig, rowGroup, from, to,
}) => {
  const obj = copyObject(tableConfig[rowGroup])
  obj.rows = changeArrayPosition({ from, to, array: obj.rows })
  return { ...tableConfig, [rowGroup]: obj }
}

const changeRowType = ({
  tableConfig, rowGroup, index, type,
}) => {
  const obj = copyObject(tableConfig)
  obj[rowGroup].rows[index].type = type
  return obj
}

const changeColumnCount = ({ tableConfig, count }) => {
  const config = copyObject(tableConfig)
  // Remove columns
  if (count < config.columns.length) {
    const remove = config.columns.length - count
    config.columns.splice(count, remove)
  }
  // Add columns
  if (config.columns.length < count) {
    while (config.columns.length < count) {
      config.columns.push({ id: `column-${uuid()}`, align: 'left' })
    }
  }
  return config
}

export { isActive } from '../helpers'
export {
  addRow, changeColumnCount,
  changeRowOrder,
  changeRowType, getMustacheTypes, removeRow, renderDataRowHtml
}

