import { sortBy, useToast } from '@level'
import PropTypes from 'prop-types'
import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { QueryProvider } from './QueryProvider'
import { useAnonymousId } from './useAnonymousId'
import { useAsUser } from './useAsUser'
import { rootUrl } from '../helpers/rootUrl'

const ProfileContext = React.createContext({})
const useProfile = () => React.useContext(ProfileContext)

// Check if a user is the current user.
// If a user is passed to the hook, it checks that user,
// otherwise it returns a function to check users against the current user
const useIsCurrentUser = (propsUser) => {
  const { Profile: current } = useProfile()
  const isCurrent = React.useCallback((user) => (
    current.id === user.id
    || current.email === user.email
  ), [current])

  return propsUser ? isCurrent(propsUser) : isCurrent
}

const profileQuery = `{
  Profile: whoami {
    id
    name
    email
    phone
    avatar { url }
  } 
  teams {
    name,
    slug,
    avatar { url }
  }
  invitations: teamMemberInvites {
    id
    email
    role
    team {
      slug
      name
      avatar { url }
    }
  }
}`

const devUser = import.meta.env.VITE_DEV_USER

const Account = ({ admin = false, children }) => {
  const { Profile } = useProfile()
  const { pathname } = useLocation()
  const { sendError } = useToast()
  const { asUser, setAsUser } = useAsUser()


  React.useEffect(() => {
    if (asUser && Profile.email !== asUser) {
      sendError({ title: 'ActingAs Failed', message: `No user found with email ${asUser}.` })
      setAsUser()
    }
  }, [Profile, asUser])

  if (!Profile?.name && pathname !== rootUrl('account/new')) {
    return <Navigate to={rootUrl('account/new')} />
  }

  if (admin && !(Profile.email.endsWith('@enveloop.com') || devUser)) {
    return <Navigate to={rootUrl()} />
  }

  return children
}

Account.propTypes = {
  admin: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

const teamUrl = ({ teamId, path }) => {
  const url = rootUrl(`teams/${teamId}`)
  return path ? `${url}/${path}` : url
}

const ProfileProvider = ({ admin = false, children }) => {
  const onData = React.useCallback(({ data: { Profile, teams, ...rest } }) => ({
    ...rest,
    Profile,
    Teams: teams.sort(sortBy('name')),
    rootPath: rootUrl(),
    rootUrl: (path) => rootUrl(path),
    teamUrl,
    isEnvelooper: Profile.email.endsWith('@enveloop.com') || !!devUser,
  }), [])

  return (
    <QueryProvider
      query={profileQuery}
      context={ProfileContext}
      onData={onData}
    >
      <Account admin={admin}>{children}</Account>
    </QueryProvider>
  )
}

ProfileProvider.propTypes = {
  admin: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

const PublicProfileProvider = (props) => {
  const anonymousId = useAnonymousId()

  const value = React.useMemo(() => ({
    isPublic: true,
    anonymousId,
  }), [anonymousId])

  return (<ProfileContext.Provider value={value} {...props} />)
}

export {
  ProfileContext, ProfileProvider, PublicProfileProvider, useIsCurrentUser, useProfile
}
