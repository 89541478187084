import React from 'react'

import {
  useDialog, Block, NavTabs, Editor, Divider,
} from '@level'

import { ModalHeader } from './header'
import { ModalTable } from './table'
import '../dmarc.scss'

const RowModal = ({
  data,
  dialogContainerRef,
  ...rest
}) => {
  const current = React.useMemo(() => data.records.map((record) => {
    const {
      sourceIp, ipAddress, count: emails, dkim, spf, disposition,
    } = record

    return {
      sourceIp, sourceDomain: ipAddress?.domain, emails, dkim, spf, disposition,
    }
  }), [data?.id])
  const [view, setView] = React.useState('table')

  return (
    <>
      <ModalHeader data={data} {...rest} />
      <NavTabs
        setState={setView}
        state={view}
        space={8}
        navItems={[
          { text: 'Table', state: 'table' },
          { text: 'Raw XML', state: 'xml' },
        ]}
      />
      <Divider />
      <Block space={7} style={{ backgroundColor: '#F5F6F7' }} maxHeight rootRef={dialogContainerRef}>
        { view === 'table' ? <ModalTable data={current} rootRef={dialogContainerRef} /> : <Editor content={data.xmlReport} lang="xml" /> }
      </Block>
    </>
  )
}

const useRowModal = ({ table }) => {
  const sendDialog = useDialog()

  const {
    selected: data,
    selectNext,
    selectPrevious,
    deselectAll: onClose,
  } = table.getSelection(table)

  // View row when selected
  React.useEffect(() => {
    if (data) {
      sendDialog({
        element: RowModal,
        data,
        selectNext,
        selectPrevious,
        onClose,
        cancelText: 'close',
        width: '80vw',
        height: '80vh',
      })
    }
  }, [data])
}

export {
  useRowModal,
}
