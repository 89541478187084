import PropTypes from 'prop-types'

import { ScrollPanel } from '../ScrollPanel'
import { PhoneContact } from './contact'
import { AddImage } from './AddImage'
import { FileDrop } from '../FileDrop'

import './phone.scss'

const DropWrapper = ({ onDrop, children }) => {
  if (!onDrop) return children
  return (
    <FileDrop type="image" onDrop={onDrop}>{children}</FileDrop>
  )
}

DropWrapper.propTypes = {
  onDrop: PropTypes.func,
  children: PropTypes.node.isRequired,
}

const Phone = ({ children, ...props }) => (
  <DropWrapper {...props}>
    <ScrollPanel limitHeight>
      <div className="level-phone-wrapper">
        <div className="level-phone">
          <PhoneContact {...props} />
          <div className="level-phone-messages">
            {children}
            <AddImage {...props} />
          </div>
        </div>
      </div>
    </ScrollPanel>
  </DropWrapper>
)

Phone.propTypes = {
  children: PropTypes.node.isRequired,
}

export { PhoneText } from './text'
export { PhoneContact } from './contact'
export { PhoneImages } from './images'
export { Phone }
