import React from 'react'
import PropTypes from 'prop-types'

import {
  Layout, Main, Card, Text, Body, Form,
  Shelf, Button, SubmitButton, Stack, Divider,
  useSearchState,
} from '@level'

import { useNavigate, Navigate } from 'react-router-dom'

import { useAuth, useProfile, ProfileProvider } from '@app/hooks'
import { useInvitations } from './useInvitations'
import { InvitationInputs } from './inputs'

const DefaultInvitationAction = ({ to, url }) => (
  <Stack gap={7}>
    <Text>
      Enveloop helps you design and send beautiful messages with a simple API.
    </Text>
    <Text>
      Create an account or sign in as <strong>{to}</strong> to join the team and get started.
    </Text>
    <Divider />
    <Shelf gap={5} align="center" valign="center">
      <Button text="Sign In" to={url} />
      or
      <Button text="Create an Account" theme="primary" to={url} />
    </Shelf>
  </Stack>
)

DefaultInvitationAction.propTypes = {
  to: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

// The bulk of the interface showing the invitation details
const InvitationCard = ({ currentUser, children }) => {
  const [params] = useSearchState()
  const {
    teamName, fromName, fromEmail, to,
  } = params

  // If they are a current user they'll be linked to sign out before creating an account
  const buttonUrl = `/auth/${currentUser ? 'signOut' : 'new'}?email=${to}`

  return (
    <Layout>
      <Main>
        <Body scroll>
          <div style={{ maxWidth: '520px', margin: '45px auto' }}>
            <div style={{ margin: '0 auto 45px', width: '140px' }}>
              <img src="https://assets.enveloop.com/logo-dark-gradient.png" width="100%" alt="enveloop logo" />
            </div>
            <Card space={9}>
              <Stack gap={7}>
                <Text tag="h1">Join {teamName}</Text>
                <Divider />
                <Text tag="h4" color="neutral-700">{fromName} ({fromEmail}) has invited you to join {teamName} on Enveloop.</Text>
                {children || <DefaultInvitationAction to={to} url={buttonUrl} />}
              </Stack>
            </Card>
            <Stack style={{ marginTop: '14px' }} align="center">
              <Text>
                This invitation was sent to <strong>{to}</strong>.
              </Text>
            </Stack>
          </div>
        </Body>
      </Main>
    </Layout>
  )
}

InvitationCard.propTypes = {
  currentUser: PropTypes.string,
  children: PropTypes.node,
}

// If a user is signed in viewing this invitation for their current account
// This allows them to accept/reject the invitation
const HandleInvitation = ({ invitation }) => {
  const { onSubmit } = useInvitations()
  const navigate = useNavigate()

  const handleSubmit = async (args) => {
    const data = await onSubmit(args)
    const url = data.invitations[invitation.id].accept ? `/teams/${invitation.team.slug}` : '/'
    navigate(url)
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Stack gap={7}>
        <InvitationInputs invitations={[invitation]} />
        <Divider />
        <SubmitButton grow text="Continue" />
      </Stack>
    </Form>
  )
}

HandleInvitation.propTypes = {
  invitation: PropTypes.object.isRequired,
}

// If Authed, this will check if the current user has access to this invitation
// It then renders the invitation card with the form for accepting/rejecting the invitaiton
const CheckInvitationUser = () => {
  const { Profile: { email }, invitations } = useProfile()
  const [{ invitationId }] = useSearchState()
  const invitation = invitations.find((i) => i.id === invitationId)
  window.invitations = invitations

  if (invitation) {
    return (
      <InvitationCard>
        <HandleInvitation invitation={invitation} />
      </InvitationCard>
    )
  }

  return <InvitationCard currentUser={email} />
}

const Invitation = () => {
  const { sessionActive } = useAuth()
  const [{ invitationId }] = useSearchState()

  // If the user doesn't have an invitationId param, they don't belong here.
  if (!invitationId) {
    return <Navigate to="/" />
  }

  // If they have an active session, they may be able to accept the invitation
  // Or they may need to sign out and create an account with a different email address
  // To accep the invitation there
  if (sessionActive) {
    return <ProfileProvider><CheckInvitationUser /></ProfileProvider>
  }

  // Most likely, users will render the invitation card with no active session.
  return <InvitationCard />
}

export {
  Invitation,
}
