import React from 'react'

import {
  Card, Stack, Spacer, Link,
  Text, P, Grid, Icon,
} from '@level'

const Help = () => (
  <Card space={8}>
    <Grid gap={10} columns={2} gridMin={500}>
      <Grid templateColumns="min-content 1fr" gap={8}>
        <Icon name="onboarding-book" size={8} />
        <Stack gap={3}>
          <Text tag="h5">Documentation</Text>
          <P>Check out our documentation to help answer any questions you may have.</P>
          <Spacer space={7} />
          <Link to="https://docs.enveloop.com/" text="View Documentation" target="_blank" />
        </Stack>
      </Grid>
      <Grid templateColumns="min-content 1fr" gap={8}>
        <Icon name="onboarding-laptop" size={8} />
        <Stack gap={3}>
          <Text tag="h5">Blog</Text>
          <P>View our blog for latest updates and tips on features.</P>
          <Spacer space={7} />
          <Link to="https://blog.enveloop.com/" text="Read the Blog" target="_blank" />
        </Stack>
      </Grid>
      <Grid templateColumns="min-content 1fr" gap={8}>
        <Icon name="onboarding-tools" size={8} />
        <Stack gap={3}>
          <Text tag="h5">Support</Text>
          <P>Need further assistance? Reach out to us directly.</P>
          <Spacer space={7} />
          <Link to="mailto:support@enveloop.com" text="Send us an email" />
        </Stack>
      </Grid>
      <Grid templateColumns="min-content 1fr" gap={8}>
        <Icon name="onboarding-speech-bubbles" size={8} />
        <Stack gap={3}>
          <Text tag="h5">Send Feedback</Text>
          <P>Have an idea or feature request? We'd love to hear more.</P>
          <Spacer space={7} />
          <Link to="mailto:support@enveloop.com" text="Share your idea" />
        </Stack>
      </Grid>
    </Grid>
  </Card>
)

export {
  Help,
}
