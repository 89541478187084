import { Button, Heading, Image, Text } from '../Element'
import { newSectionConfig } from './helpers'

const LargeHero = newSectionConfig(
  'Large Hero',
  ({ config, addColumn }) => {
    config({ align: 'center' })
    const column = addColumn()
    column.addElement(Heading, { fontSize: '36px' })
    column.addElement(Text)
    column.addElement(Button)
  },
)

const HeroColumnLeft = newSectionConfig(
  'Hero Column Left',
  ({ addColumn }) => {
    const column = addColumn()
    column.addElement(Heading, { fontSize: '24px' })
    column.addElement(Text)
    column.addElement(Button)
    const column2 = addColumn()
    column2.addElement(Image)
  },
)

const HeroColumnRight = newSectionConfig(
  'Hero Column Right',
  ({ addColumn }) => {
    const column = addColumn()
    column.addElement(Image)
    const column2 = addColumn()
    column2.addElement(Heading, { fontSize: '24px' })
    column2.addElement(Text)
    column2.addElement(Button)
  },
)

export default {
  LargeHero,
  HeroColumnLeft,
  HeroColumnRight,
}
