const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

const tokenSessionRemaining = (token) => {
  const { exp } = parseJwt(token)
  const dateExpires = new Date(exp * 1000)
  const now = new Date()
  const remaining = (dateExpires - now)
  // If there's more than a second remaining, return the time
  return (remaining < 1000) ? 0 : remaining
}

const tokenAge = (token) => {
  const { iat } = parseJwt(token)
  const dateIssued = new Date(iat * 1000)
  const now = new Date()
  return now - dateIssued
}

export {
  parseJwt,
  tokenSessionRemaining,
  tokenAge,
}
