import { templateConfigDefaults, addThemeColors } from '@level'

const themeColors = {
  navy: {
    accentColor: '#1E2749',
  },
  blue: {
    accentColor: '#325EEA',
  },
  green: {
    accentColor: '#02AF76',
  },
  red: {
    accentColor: '#D83D13',
  },
}

const theme = ({ color, ...config }) => {
  const { _theme, ...colors } = addThemeColors({
    templateBg: '#F2F2F2',
    contentBg: '#FFFFFF',
    tableBg: '#F9F9F9',
    heading: '#1E2749',
    text: '#414D6D',
    textSubtle: '#7E889E',
    border: '#DEDFE2',
    ...themeColors[color],
  })

  return {
    colors,
    hasThemes: true,
    variables: {
      style: {
        ...templateConfigDefaults.template.style,
        color: colors.text,
        backgroundColor: colors.templateBg,
        contentBackgroundColor: colors.contentBg,
        linkColor: colors.accentColor,
        fontFamily: 'sans-serif',
        padding: '30px 30px 30px 30px',
        width: '640',
      },
      config: {
        ...templateConfigDefaults.template.config,
        ...config,
      },
      _theme,
      defaults: {
        section: {
          padding: '15px 40px 15px 40px',
        },
        Button: {
          cornerStyle: 'round',
          fontWeight: 600,
        },
      },
    },
  }
}

const colors = Object.keys(themeColors)

export {
  theme,
  colors,
}
