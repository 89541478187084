import {
  BlankSlate,
  Body, Button,
  Divider,
  Main, NavTabs,
  Shelf,
  sortBy,
  Stack,
  Text,
  useLocalStorage,
  useSearchState,
} from '@level'
import PropTypes from 'prop-types'
import React from 'react'
import { Route, Routes, } from 'react-router-dom'

import { QueryProvider, useProject, useTeam } from '@app/hooks'
import { SharedSectionsProvider } from '../sharedSections/provider'
import { TemplateProvider } from './context'
import { ImportTemplateBanner } from './importTry'
import { TemplateCards } from './indexCards'
import { TemplateList } from './indexList'
import { useNewProjectTemplateDialog } from './new'
import { TemplateShow } from './template'
import { ActionButton } from './templateActionButton'

const templatesQuery = ({ teamId, projectId }) => `
templates(team: "${teamId}", project: "${projectId}") {
  id
  name
  slug
  type
  previewConfig {
    body
    variables
    sections {
      variables
      body
    }
  }
  published
  publishedAt
  createdAt
  updatedAt
}`

const Templates = ({
  newTemplate, ...rest
}) => {
  const [{ view }] = useSearchState()
  const View = !view || view === 'list' ? TemplateList : TemplateCards
  return rest.templates.length ? (
    <View {...rest} path="template" ActionButton={ActionButton} />
  ) : (
    <BlankSlate
      icon="template"
      heading="No Templates"
      description="Let's fix that! Add a new template to get started."
      buttonText="Add Template"
      buttonAction={newTemplate}
    />
  )
}

Templates.propTypes = {
  templates: PropTypes.array.isRequired,
  newTemplate: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
  watermark: PropTypes.bool.isRequired,
}

const viewNavItems = [
  { icon: 'list-ul', label: 'List view', state: 'list' },
  { icon: 'grid2', label: 'Card view', state: 'cards' },
]

const Index = () => {
  const { Project } = useProject()
  const { Team: { plan: { watermark } } } = useTeam()
  const [view, setView] = useLocalStorage('template-view', 'list')
  const [searchState, setSearchState] = useSearchState({ type: 'all' })
  const { type } = searchState
  const newTemplate = useNewProjectTemplateDialog()
  const providerVars = React.useMemo(() => ({ type, newTemplate, watermark }), [type, newTemplate])

  const onData = React.useCallback(({ data, type: filter }) => {
    const sorted = data.templates.sort(sortBy('name'))
    return {
      templates: sorted.filter((t) => (!filter || filter === 'all' ? t : t.type === filter)),
      newTemplate,
      type,
    }
  }, [])

  React.useLayoutEffect(() => {
    if (view !== searchState.view) {
      setSearchState.merge({ view })
    }
  }, [searchState.view])

  return (
    <Main>
      <Stack gap={5} space={[8, 8, 0]} style={{ backgroundColor: '#fff' }}>
        <Shelf valign="center" align="split">
          <Text tag="h2">Templates</Text>
          <Button size={4} theme="primary" text="Add Template" onClick={newTemplate} />
        </Shelf>
        <Shelf valign="center" align="split">
          <NavTabs
            setState={(s) => setSearchState.merge({ type: s })}
            state={type}
            space={0}
            navItems={[
              { text: 'All Templates', state: 'all' },
              { text: 'Email', state: 'email' },
              { text: 'SMS', state: 'sms' },
            ]}
          />
          <NavTabs
            setState={(v) => { setSearchState.merge({ view: v }); setView(v) }}
            state={view}
            space={0}
            itemSpace={0}
            theme="radio"
            navItems={viewNavItems}
          />
        </Shelf>
      </Stack>
      <Divider />
      <ImportTemplateBanner />
      <Body scroll>
        <QueryProvider
          query={templatesQuery({ projectId: Project.slug, teamId: Project.team.slug })}
          contextVars={providerVars}
          onData={onData}
          element={Templates}
        />
      </Body>
    </Main>
  )
}

const TemplatesIndex = () => (
  <Routes>
    <Route index element={<Index />} />
    <Route
      path="template/:templateId/*"
      element={(
        <SharedSectionsProvider>
          <TemplateProvider><TemplateShow /></TemplateProvider>
        </SharedSectionsProvider>
      )}
    />
  </Routes>
)

export {
  TemplatesIndex
}
