import React from 'react'
import { timeAgoOrDate } from '../helpers/date'
import { useInterval } from './useInterval'

const useLiveDate = ({ date, format = timeAgoOrDate, interval = 60000 }) => {
  const [dateText, setDateText] = React.useState(format(date))

  useInterval(() => {
    setDateText(format(date))
  }, interval)

  React.useEffect(() => {
    setDateText(format(date))
  }, [date, format])

  return dateText
}

export {
  useLiveDate,
}
