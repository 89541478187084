import React from 'react'
import PropTypes from 'prop-types'

import {
  Card, CardHeader, CardSection, Shelf, Stack, Text,
  DataShelf, DataStack, Button, ButtonShelf, Link, Notice,
  Column, Icon, Spacer, Grid, SettingsGrid,
} from '@level'

import { useProject, useTeam } from '@app/hooks'
import { sanitizeSecret, providerLabels, providerDocs } from './helpers'

const BlankSlate = () => {
  const { Project } = useProject()
  const { smsRemaining } = Project

  return (
    <Card space={8}>
      <Stack gap={9}>
        <Text tag="h4">No SMS provider configured</Text>
        <Text>
          You currently have <strong>{smsRemaining} free messages </strong>
          remaining this month.
        </Text>
        <Grid gap={5} columnMax={150} fill>
          <Card>
            <Stack gap={5} align="center">
              <Icon name="integration-twilio" size={7} />
              <Text tag="h5">Twilio</Text>
              <Button to="./configure/smsProvider?provider=twilio" text="Setup" theme="primary" />
            </Stack>
          </Card>
          <Card>
            <Stack gap={5} align="center">
              <Icon name="integration-textgrid" size={7} />
              <Text tag="h5">TextGrid</Text>
              <Button to="./configure/smsProvider?provider=textgrid" text="Setup" theme="primary" />
            </Stack>
          </Card>
        </Grid>
        <Column style={{ fontSize: '12px', paddingBottom: '10px' }}>
          <Text color="neutral-400">
            Don&rsquo;t see your provider? <Link to="mailto:support@enveloop.com">Send us a request</Link> and we&rsquo;ll see about getting it added to our lineup.
          </Text>
        </Column>
      </Stack>
    </Card>
  )
}

const UpgradePrompt = () => {
  const { isOwner } = useTeam()
  return (
    <Card space={8}>
      <Stack gap={9}>
        <Text tag="h4">SMS not allowed on current plan</Text>
        <Text>
          You are currently on the <strong>Developer Plan</strong>.
          {isOwner ? ' You' : ' Your team owner'} will need to upgrade to enable SMS integration.
        </Text>
        {isOwner ? (
          <ButtonShelf>
            <Button text="Manage Plan" to="../../../../../settings/plan" />
          </ButtonShelf>
        ) : null}
      </Stack>
    </Card>
  )
}

const ProviderConfig = ({ provider: smsProvider }) => {
  if (smsProvider) {
    return (
      <Card space={0}>
        <CardHeader space={[6, 8]}>
          <Shelf style={{ alignItems: 'center' }}>
            <Text tag="h4" icon={{ name: `integration-${smsProvider.provider}`, size: 3, space: 6 }}>{providerLabels[smsProvider.provider]}</Text>
            <Spacer grow />
            <Button text="Manage" to={`./configure/smsProvider?provider=${smsProvider.provider}`} />
          </Shelf>
        </CardHeader>
        <CardSection space={8}>
          <DataStack gap={8}>
            {Object.entries(smsProvider.config).filter(([k]) => k !== 'defaultFrom').map(([key, value]) => (
              <DataShelf key={key} title={key}>{sanitizeSecret(key, value)}</DataShelf>
            ))}
            <DataShelf title="defaultFrom">
              {smsProvider.config.defaultFrom || <Notice icon="exclamation-triangle" gap={4}>Configure a number with your Provider</Notice>}
            </DataShelf>
          </DataStack>
        </CardSection>
      </Card>
    )
  }
  return <BlankSlate />
}

ProviderConfig.propTypes = {
  provider: PropTypes.object,
}

const SmsProvider = () => {
  const { Project } = useProject()
  const { smsProvider, team: { plan: { allowSms } } } = Project

  return (
    <SettingsGrid>
      <Stack gap={5}>
        <Text tag="h3">SMS Integration</Text>
        <Text>
          Configure and manage your SMS provider for this project.
          Have questions? Check out our <Link target="_blank" to={providerDocs.sms}>SMS integrations docs</Link>.
        </Text>
      </Stack>
      {allowSms ? <ProviderConfig provider={smsProvider} /> : <UpgradePrompt />}
    </SettingsGrid>
  )
}

export {
  SmsProvider,
}
