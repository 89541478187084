import { copyObject, filterEmptyValues, jsxToString, uuid } from '../../../../helpers'
import { configDefaults, newColumnConfig } from '../Config/defaults'
import { exportElement, newElement, onUpdateElementConfig } from '../Element'
import { Section } from './Section'

const exportSection = ({ variables, sharedSectionId }) => ({
  sharedSectionId,
  variables: {
    config: filterEmptyValues(variables.config),
    columns: variables.columns.map((column) => ({
      config: column.config,
      elements: column.elements.map(exportElement),
    })),
  },
})

// Some elements need to have their configuration updated when section or template
// configuration changes. This ensures that all elements configuration is up to date
const updateSectionVariables = ({ section, templateVariables }) => ({
  ...section.variables,
  columns: section.variables.columns.map((column, columnIndex) => ({
    ...column,
    elements: column.elements.map((element, elementIndex) => ({
      ...element,
      config: onUpdateElementConfig({
        element,
        columnConfig: column.config,
        sectionConfig: section.variables.config,
        varPath: `columns.${columnIndex}.elements.${elementIndex}.config`,
        templateVariables,
      }),
    })),
  })),
})

const renderSection = ({
  section, templateVariables, mode = 'render',
}) => {
  const variables = updateSectionVariables({ section, templateVariables })

  return {
    ...section,
    variables,
    body: jsxToString(<Section
      variables={variables}
      mode={mode}
      templateVariables={templateVariables}
    />),
  }
}

// Sections are a config object like this:
// {
//   name: (how it will be referenced for viewer)
//   new() => { A function which creates a new section object with:
//     id: (uuid)
//     config (settings)
//     columns: [{
//       config: object to configure column properties
//       elements: [{
//         id: (uuid)
//         type: Key which finds the <Element> component to render it
//         config: Props passed to <Element>
//       }, ...]
//     }, ...]
//   }
// }
//  Accepts a name, and a callback for passing config setters to
const newSectionConfig = (name, fn) => {
  const config = { ...configDefaults.section, name }
  let configProps = {}
  const columns = []

  const configure = (props) => {
    configProps = props
  }

  const addColumn = (options = {}) => {
    const index = columns.length

    columns.push(newColumnConfig({ config: options }))

    return {
      addElement: (element, opts = {}) => {
        // Add an element factory for later execution
        columns[index].elements.push(
          ({ templateVariables }) => newElement({ element, config: opts, templateVariables }),
        )
      },
    }
  }

  // Builds column element objects, generating configs with uuids
  // This is necessary so that each section has elements with unique ids
  // If it were executed on initial config, all section elements would
  // build uuids on build rather than on each run of new().
  const renderColumns = ({ templateVariables }) => (
    columns.map((col) => newColumnConfig({
      config: col.config,
      elements: col.elements.map((e) => e({ templateVariables })),
    }))
  )

  if (fn) { fn({ config: configure, addColumn }) }

  return {
    name,
    new: (template = {}) => {
      const { templateVariables } = template
      const templateDefaults = templateVariables?.defaults?.section || {}
      const sectionConfig = { ...config, ...templateDefaults, ...configProps }

      return {
        id: `section-${uuid()}`,
        config: sectionConfig,
        columns: renderColumns({ templateVariables }),
      }
    },
  }
}

const newSectionObject = ({ section, templateVariables }) => {
  if (section?.sharedSectionId) {
    const sectionConfig = copyObject({
      ...section,
      variables: {
        ...section.variables,
        id: `section-${uuid()}`,
      },
    })

    return renderSection({ section: sectionConfig, templateVariables })
  }

  const { variables } = section
  let { config } = variables

  const templateDefaults = templateVariables?.defaults?.section || {}
  config = { ...configDefaults.section, ...templateDefaults, ...config }

  const sectionConfig = {
    variables: {
      id: `section-${uuid()}`,
      config,
      columns: variables.columns.map((column) => ({
        id: `column-${uuid()}`,
        config: column.config,
        elements: column.elements.map((element) => newElement({
          element,
          config: element.config,
          templateVariables,
        })),
      })),
    },
  }

  return renderSection({ section: sectionConfig, templateVariables })
}

export {
  exportSection, newSectionConfig, newSectionObject, renderSection
}
