import PropTypes from 'prop-types'
import { alignSelfStyle, alignPropTypes } from '../../helpers'

const Column = ({
  grow,
  shrink,
  align,
  valign,
  className,
  width,
  style = {},
  tag: Tag = 'div',
  ...props
}) => (
  <Tag
    className={`level-column ${className || ''}`}
    style={{
      flexGrow: grow ? 1 : 0,
      flexShrink: grow ? 0 : shrink,
      flexBasis: width,
      ...alignSelfStyle({ align, valign }),
      ...style,
    }}
    {...props}
  />
)

Column.propTypes = {
  children: PropTypes.node.isRequired,
  tag: PropTypes.string,
  style: PropTypes.object,
  grow: PropTypes.bool,
  shrink: PropTypes.bool,
  align: alignPropTypes.align,
  valign: alignPropTypes.valign,
  className: PropTypes.string,
  width: PropTypes.any,
}

export { Column }
