import React from 'react'
import PropTypes from 'prop-types'

import {
  ScrollPanel, Text, Drawer, Block, Divider,
} from '@level'

import { TemplateTestForm } from './testForm'

const TestDrawer = ({ title, ...props }) => (
  <Drawer>
    <ScrollPanel>
      <Block space={7}>
        <Text tag="h3">{title}</Text>
      </Block>
      <Divider />
      <TemplateTestForm {...props} />
    </ScrollPanel>
  </Drawer>
)

TestDrawer.propTypes = {
  title: PropTypes.string.isRequired,
}

export {
  TestDrawer,
}
