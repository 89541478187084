import React from 'react'
import {
  mapToKey, fetchGraphQl,
} from '../helpers'

const useQuery = ({
  data = (d) => d,
  query = data,
  url,
  handleQuery,
  handleErrors = [],
  log = false,
  test = false,
  headers,
}, dependencies = []) => {
  // We'll use a simple state hook to trigger renders
  const setTrigger = React.useState()[1]
  const renderState = () => setTrigger({})

  const stateRef = React.useRef({
    data: null,
    errors: null,
    controller: null,
    isLoading: false,
    queryCount: 0,
    refresh: null,
  })

  const getState = {
    getData: () => stateRef.current.data,
    getErrors: () => stateRef.current.errors,
    getIsLoading: () => stateRef.current.isLoading,
    getQueryCount: () => stateRef.current.queryCount,
    getController: () => stateRef.current.controller,
  }

  const sendQuery = React.useCallback(async ({
    query: queryString,
    headers: queryHeaders = {},
  }) => {
    // If a controller is passed in from a previous fetch state, abort that fetch
    if (stateRef.current.controller?.abort) {
      stateRef.current.controller.abort()
    }
    // Create a new abort controller
    stateRef.current.controller = new AbortController()

    let newData
    let newErrors

    try {
      const queryResponse = await handleQuery({
        url,
        headers: { ...(headers || {}), ...queryHeaders },
        query: queryString,
        signal: stateRef.current.controller.signal,
      })
      if (queryResponse.ok) {
        try {
          const json = await queryResponse.json()
          newData = json?.data || json
          newErrors = json?.errors
        } catch (e) {

          console.error(e)

        }
      } else {
        newData = null
        newErrors = await queryResponse.json()
      }

      if (import.meta.env.VITE_LOG_QUERIES === 'true' || log) {

        if (newData) {
          Object.keys(newData).forEach((res) => {
            console.log(res)
            console.table(newData[res])
          })
        }

      }
    } catch (er) {
      // Abort errors are a side-effect of the UI fetching before another has fetch has finished
      // These usually occur when a child view triggers a refresh while being rendered
      // during an async fetch
      if (er?.name !== 'AbortError') { newErrors = er }
    }

    stateRef.current.controller = null

    return {
      data: newData,
      errors: newErrors,
    }
  }, [...dependencies, url])

  const fetchData = React.useCallback(async ({
    headers: fetchHeaders,
    clearData,
    ...queryArgs
  }) => {
    // This concat/map evaluates one ore more query functions with the data sent
    // mapping to an array of query strings
    const queries = [].concat(query).map((q) => (typeof q === 'function' ? q(queryArgs) : q)).flat(1)

    if (test) {

      console.log({ queries })

      return {}
    }

    // Do we have multiple queries chained together as an array
    // This will help us decied whether to return the response as an array
    const bulkQuery = Array.isArray(query)

    if (window.navigator.onLine || import.meta.env.VITE_ALLOW_OFFLINE) {
      stateRef.current.isLoading = true
      // clear state data wtih null (if clearData shape not specified)
      // or whatever clearData is set to
      if (clearData) stateRef.current.data = clearData === true ? null : clearData
    } else {
      stateRef.current.errors = [{ code: 'offline' }]
      stateRef.current.isLoading = false
      stateRef.current.data = null

      renderState()
      return stateRef.current
    }

    renderState()

    // Execute query as an array of promises
    const response = await Promise.all(queries.map(
      (queryString) => sendQuery({
        query: queryString,
        headers: fetchHeaders,
      }),
    ))

    stateRef.current.isLoading = false
    stateRef.current.queryCount += 1

    // Collect response errors filtering out empty data
    const Err = mapToKey(response, 'errors')
    // Collect response data, filtering out empty data
    const Data = mapToKey(response, 'data')

    if (Data.length) {
      stateRef.current.data = !bulkQuery ? Data[0] : Data
    } else {
      stateRef.current.data = null
    }
    stateRef.current.errors = Err.length ? Err : null

    renderState()

    // Return the response or if a bulk query, return a response object
    // with data and errors as arrays
    return stateRef.current
  }, [...dependencies, sendQuery, query])

  // Add abort request function to fetchData to enable aborting down stream of the hook
  const abort = () => { stateRef.current.controller?.abort() }
  fetchData.abort = abort

  // If component unmounts cancel active requests
  React.useEffect(() => () => { abort() }, [])

  React.useEffect(() => {
    if (stateRef.current.errors?.length && handleErrors?.length) {
      stateRef.current.errors.map(handleErrors)
    }
  }, [stateRef.current.errors])

  return [fetchData, { ...stateRef.current }, getState]
}

const handleGraphQlQuery = ({
  url, query, headers, signal,
}) => fetchGraphQl({
  url,
  query,
  headers,
  // do not allow mutations to be aborted
  signal: !query.startsWith('mutation') ? signal : null,
})

const handleJsonQuery = ({
  url, query, headers, ...props
}) => fetch(url, {
  method: 'POST',
  body: JSON.stringify(query),
  headers: {
    'Content-Type': 'application/json',
    ...headers,
  },
  ...props,
})

const useGraphQlQuery = (props, dependencies = []) => useQuery({
  handleQuery: handleGraphQlQuery,
  ...props,
}, dependencies)

const useRestQuery = (props, dependencies = []) => useQuery({
  handleQuery: handleJsonQuery,
  ...props,
}, dependencies)

export {
  useQuery,
  useGraphQlQuery,
  useRestQuery,
}
