import { toParams } from '@level/helpers'

export const operators = {
  submitter: 'substring',
  domain: 'substring',
  date: 'between',
}

// domain: $domain, submitter: $submitter, startDate: $startDate, endDate: $endDate
export const dmarcQuery = ({
  teamId: team,
  dateRange,
  domain,
}) => {
  const params = { team, domain }
  if (dateRange?.split) {
    const [startDate, endDate] = dateRange.split(',').map((d) => new Date(Number.parseInt(d, 10)))
    params.startDate = startDate
    params.endDate = endDate
  }

  return `{
    dmarcReports(${toParams(params)}) {
      to
      from
      subject
      startDate
      endDate
      identity
      submitter
      reportId
      metrics
      dmarcReportRecords {
        sourceIp
        ipAddress {
          ip
          fqdn
          domain
        }
        count
        disposition
        dkim
        spf
        headerFrom
        dkimResults
        spfResults
      }
      xmlReport
    }
  }`
}
