import React from 'react'
import PropTypes from 'prop-types'

import {
  CheckSwitch, Form, Text, Input,
  Stack, Shelf, CopyButton, Spacer, Button,
  useDialog,
} from '@level'

import { useFormQuery, useQuery, useProject } from '@app/hooks'

import {
  updateApiTokenQuery,
  deleteApiTokenQuery,
} from './queries'

const DeleteToken = ({ id, testMode, name }) => {
  const { refresh } = useProject()
  const [deleteToken] = useQuery(deleteApiTokenQuery({ id }))
  const sendDialog = useDialog()
  const onClick = async () => sendDialog({
    onConfirm: async () => {
      await deleteToken()
      refresh()
    },
    title: `Delete ${name} token?`,
    content: (
      <Stack gap={8}>
        <Text>
          Removing this <strong>{ testMode ? 'Sandbox' : 'Production'} token</strong> will revoke connections from any app using this token. This cannot be undone. Are you sure?
        </Text>
        { testMode !== true ? (
          <Input
            name="token"
            label={`Type "${name}" below to confirm.`}
            placeholder={name}
            pattern={`^${name.replace(/([+.])/g, '\\$1')}$`}
            messages={{ pattern: `Please type "${name}" to confirm.` }}
            required
          />
        ) : null}
      </Stack>
    ),
    confirmText: 'Delete Token',
    destructive: true,
  })

  return (
    <Button
      icon="trash"
      theme="wrapper"
      label="Delete Api Token"
      onClick={onClick}
      title="Delete Api Token"
    />
  )
}

DeleteToken.propTypes = {
  id: PropTypes.string.isRequired,
  testMode: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
}

const Token = ({ cell, row }) => (
  <Shelf gap={4} valign="center">
    <Text style={{ fontFamily: 'system-mono' }}>{ cell.renderValue() }</Text>
    <CopyButton text={cell.renderValue()} />
    <Spacer grow />
    { cell.column.columnDef.canDelete ? (
      <DeleteToken
        name={row.original.name}
        testMode={row.original.testMode}
        id={row.original.id}
      />
    ) : null }
  </Shelf>
)

Token.propTypes = {
  cell: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
}

const ToggleActive = ({ cell, row }) => {
  const value = cell.renderValue()
  const { refresh } = useProject()
  const [state, setState] = React.useState(value)
  const { onSubmit } = useFormQuery({
    query: updateApiTokenQuery,
    onComplete: () => refresh(),
    queryProps: { id: row.original.id },
    successMessage: state ? 'API Token enabled' : 'API Token disabled',
  })

  return (
    <Form onSubmit={onSubmit}>
      <CheckSwitch
        defaultChecked={value}
        name="active"
        label={false}
        onChange={() => setState((s) => !s)}
        submitOnChange
        title={value ? 'Disable API token' : 'Enable API token'}
      />
    </Form>
  )
}

ToggleActive.propTypes = {
  cell: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
}

export {
  DeleteToken,
  ToggleActive,
  Token,
}
