import PropTypes from 'prop-types'
import * as Ariakit from '@ariakit/react'

import { toColor } from '../../helpers/color'
import { toFontSize } from '../../helpers/font'

import './tooltip.scss'

const Tooltip = ({
  children,
  tip,
  arrow = true,
  color = 'white',
  backgroundColor = 'neutral-700',
  borderColor = 'neutral-800',
  size = 0,
  className,
  tabIndex = -1,
  render,
  ...props
}) => {
  const style = {
    color: toColor(color),
    backgroundColor: toColor(backgroundColor),
    borderColor: toColor(borderColor),
    fontSize: toFontSize(size),
  }
  return (
    <Ariakit.TooltipProvider showTimeout={500} {...props}>
      <Ariakit.TooltipAnchor className={`${className || ''} level-tooltip-anchor`} tabIndex={!render ? tabIndex : null} render={render}>
        {children}
      </Ariakit.TooltipAnchor>
      <Ariakit.Tooltip
        className="level-tooltip"
        style={style}
      >
        {arrow ? <Ariakit.TooltipArrow /> : null}
        {tip}
      </Ariakit.Tooltip>
    </Ariakit.TooltipProvider>
  )
}

Tooltip.propTypes = {
  className: PropTypes.string,
  children: ({
    render, children,
  }) => ((!children && !render) ? (new Error('Tooltip requires either children, or a render prop.')) : null),
  tip: PropTypes.any.isRequired,
  arrow: PropTypes.bool,
  color: toColor.propTypes.color,
  backgroundColor: toColor.propTypes.color,
  borderColor: toColor.propTypes.color,
  size: toFontSize.propTypes.size,
  showTimeout: PropTypes.number,
  tabIndex: PropTypes.any,
  render: PropTypes.node,
}

export {
  Tooltip,
}
