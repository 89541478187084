import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from '../Icon'
import { Swatch } from '../Swatch'
import { Grid } from '../Grid'
import { Stack } from '../Stack'
import { Divider } from '../Divider'
import { Button } from '../Button'
import { PopoverButton } from '../Popover'
import { Text } from '../Text'
import { HiddenInput } from './HiddenInput'
import { ColorPicker } from '../ColorPicker'
import { useFormRef } from '../../hooks/useForm'
import { useThrottle } from '../../hooks/useThrottle'
import { useLeadingDebounce } from '../../hooks/useDebounce'
import { useUuid } from '../../hooks/useUuid'
import {
  camelToTitle, Color, validateColor, getContrast, sortColors,
} from '../../helpers'

// 'fullName' => 'Full Name'
const deriveLabel = ({ label, name }) => (label === true ? camelToTitle(name) : label) || ''

const useColorPicker = ({
  name, onSelectColor, theme, defaultValue, required, ...rest
}) => {
  const { setValue, setError, clearErrors } = useFormRef()
  const themeValues = theme ? Object.values(theme || {}).map((v) => v?.value).join('-') : null

  const colorToHex = (color) => Color(color)?.hex

  const swatchRef = React.useRef() // Ref for setting swatch colors
  const textInputRef = React.useRef() // Ref for user text input
  const colorInputRef = React.useRef() // Ref for color picker input
  const themeKeyRef = React.useRef() // Ref for theme key name text input
  const [updated, setUpdated] = React.useState()

  const colorInputId = `color-${useUuid()}`

  const inputValueToHex = React.useCallback((value) => {
    const validColor = validateColor({ color: value })
    return validColor ? colorToHex(validColor) : null
  }, [])

  const colorValue = React.useRef()
  const themeValue = React.useRef({})
  const getColor = React.useCallback(() => colorValue.current, [])
  const getThemeValue = React.useCallback(() => themeValue.current.value, [])
  const getThemeKey = React.useCallback(() => themeValue.current.key, [])
  const getValue = React.useCallback(() => getThemeValue() || getColor() || defaultValue, [])
  const getDisplayValue = React.useCallback(() => (getThemeKey() ? `theme.${getThemeKey()}` : getColor()), [])

  const getColorValue = React.useCallback((value) => {
    const match = value?.match?.(/theme\.([^.]+)/)
    return {
      color: theme?.[match?.[1]]?.value || inputValueToHex(value),
      themeValue: match?.[1] ? theme?.[match[1]]?.config : null,
      themeKey: theme ? match?.[1] : null,
    }
  }, [themeValues])

  const setColorValue = React.useCallback((value) => {
    const cv = getColorValue(value)
    colorValue.current = cv.color
    themeValue.current = { key: cv.themeKey, value: cv.themeValue }
    colorInputRef.current.value = cv.color
    return cv
  }, [getColorValue])

  const getInputValue = React.useCallback((value) => {
    const cv = getColorValue(value)
    return cv.themeValue || cv.color
  }, [getColorValue])

  const updateColor = React.useCallback((value) => {
    // Translate input value to final value, example: theme.color01 -> {{{_theme.color01.config}}}
    // Then only trigger a form change with shouldDirty if it is a new value
    const inputValue = getInputValue(value)

    if (!value && required) {
      textInputRef.current.value = value
      setError(name, { type: 'custom', message: 'Color is required' })
      textInputRef.current?.setCustomValidity('Color is required')
      textInputRef.current?.reportValidity()
    } else if (value !== '' && !inputValue) {
      setError(name, { type: 'custom', message: `${value} is not a valid color` })
      textInputRef.current?.setCustomValidity(`${value} is not a valid color`)
      textInputRef.current?.reportValidity()
    } else {
      setValue(name, inputValue, { shouldDirty: inputValue !== getValue() })
      textInputRef.current?.setCustomValidity('')
      clearErrors(name)
    }
    setColorValue(value)
    onSelectColor?.({ color: value })
    // If input change is not from user text input, update text input with value
    window.setTimeout(() => {
      if (textInputRef.current && textInputRef.current !== document.activeElement) {
        textInputRef.current.value = getDisplayValue()
      }
    }, 12)
    if (swatchRef.current) {
      swatchRef.current.style.backgroundColor = getColor()
      swatchRef.current.dataset.none = !getColor()
    }
    setUpdated({})
    return value
  }, [setColorValue, getInputValue])

  // Ensure color value is updated if theme is updated
  React.useEffect(() => {
    if (getValue()) {
      if (defaultValue !== getValue()) {
        setColorValue(defaultValue)
      } else {
        setColorValue(getValue())
      }
      textInputRef.current.value = getDisplayValue()
      swatchRef.current.style.backgroundColor = getColor()
    }
  }, [themeValues])

  // Update color swatches and input values
  const onChangeText = useThrottle(({ target }) => {
    updateColor(target.value)
  }, [], 12)

  React.useEffect(() => {
    if (defaultValue) {
      updateColor(defaultValue)
      if (textInputRef.current) textInputRef.current.value = getDisplayValue() || ''
      if (themeKeyRef.current) themeKeyRef.current.value = getThemeKey() || ''
    }
  }, [])

  const picker = (
    <div>
      {/* This is the input the form sees, which is programatically updated by user controls */}
      <HiddenInput name={name} defaultValue={getValue()} required={required} />
      {/* Browser native color input */}
      <input
        id={colorInputId}
        defaultValue={getColor()}
        name="colorswatch"
        type="color"
        ref={colorInputRef}
      />
    </div>
  )

  return {
    ...rest,
    name,
    theme,
    picker,
    updated,
    getColor,
    getThemeKey,
    getThemeValue,
    getDisplayValue,
    updateColor,
    getValue,
    onChangeText,
    textInputRef,
    themeKeyRef,
    colorInputRef,
    swatchRef,
    required,
  }
}

const MenuColor = ({
  name, value, onClick, selected,
}) => (
  <Button theme="wrapper" tip={name} onClick={() => onClick(value)}>
    <Swatch color={value} size={24} className="level-swatch-button" data-selected={selected} />
  </Button>
)

const ThemeMenu = ({
  colors, theme = {}, onSelect, getColor,
}) => {
  const hasColors = colors?.length
  const themeColors = Object.values(theme || {})

  // Delay tracking selected color to avoid flickering while using a color picker.
  const [selected, setSelected] = React.useState(getColor())
  const updateSelected = useLeadingDebounce(() => {
    setSelected(getColor())
  }, [getColor], 250, { trailing: true })
  React.useEffect(() => updateSelected(), [getColor()])

  return (
    <Stack gap={5}>
      <ColorPicker color={getColor()} onChange={onSelect} />
      {themeColors.length ? <Divider flush={6} /> : null}
      {themeColors.length ? (
        <>
          <Text size={1} color="neutral-500">Theme Colors</Text>
          <Grid gap={4} columns={7}>
            {themeColors.map(({ value, config, name }) => (
              <MenuColor
                name={name}
                selected={selected === value}
                value={value}
                onClick={() => onSelect(config)}
                key={name}
              />
            ))}
          </Grid>
        </>
      ) : null}
      {hasColors ? <Divider flush={6} /> : null}
      {hasColors ? (
        <>
          <Text size={1} color="neutral-500">Template Colors</Text>
          <Grid gap={4} columns={7}>
            {sortColors(colors).map((color) => (
              <MenuColor
                selected={selected === color}
                name={color}
                value={color}
                onClick={onSelect}
                key={color}
              />
            ))}
          </Grid>
        </>
      ) : null}
    </Stack>
  )
}

const ColorInput = ({
  placeholder = 'none', colors, showNone, swatchProps, ...props
}) => {
  const {
    label, name, theme, required,
    picker, getColor, onChangeText, getDisplayValue, updateColor,
    textInputRef, swatchRef,
  } = useColorPicker({ defaultValue: '', showNone: true, label: true, ...props })
  const labelText = deriveLabel({ label, name })
  const textInputId = `input-${useUuid()}`

  // When bluring, set text input to last color value
  // If a user types in a value like `#000`, this will correct it to `#000000` on blur
  const onBlur = (e) => {
    e.target.value = getDisplayValue() || ''
  }
  const input = (
    <div className="level-color-input">
      {picker}
      <PopoverButton
        space={6}
        theme="wrapper"
        className="level-theme-color-button"
        popoverConfig={{ autoFocusOnShow: false }}
        content={(
          <ThemeMenu {...{
            colors,
            theme,
            getColor,
            onSelect: updateColor,
            showNone,
          }}
          />
        )}
      >
        <Swatch color={getColor()} ref={swatchRef} {...swatchProps}>
          <Icon name="eye-dropper" fill={getColor() ? getContrast(getColor()) : null} />
        </Swatch>
      </PopoverButton>
      {/* Input for user text entry */}
      <input
        className="level-input"
        id={textInputId}
        type="text"
        onInput={onChangeText}
        onBlur={onBlur}
        placeholder={placeholder}
        ref={textInputRef}
        spellCheck="false"
        required={required}
      />
    </div>
  )

  return (
    <Stack gap={4}>
      {label ? <label className="level-label" htmlFor={textInputId}>{labelText}</label> : null}
      {input}
    </Stack>
  )
}

ColorInput.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  placeholder: PropTypes.string,
  onSelectColor: PropTypes.func,
  theme: PropTypes.object,
  colors: PropTypes.array,
  showNone: PropTypes.bool,
  swatchProps: PropTypes.object,
}

export {
  ColorInput,
}
