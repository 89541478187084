import React from 'react'
import PropTypes from 'prop-types'
import { Avatar } from './Avatar'
import { useDrop } from '../../hooks/useDropZone'
import { FileDrop } from '../FileDrop'
import { FileInput } from '../FileInput'
import { Stack } from '../Stack'
import { imageFileSrc } from '../../helpers/object'
import { Button } from '../Button'

import './avatar-input.scss'

// This allows me to do some styling of the Avatar reflecting drag-drop interactions
const AvatarInputDrop = (props) => {
  const { dragOver } = useDrop()
  return (
    <div className="avatar-input-drop" data-drag-item-over={dragOver} {...props} />
  )
}

// Renders existing avatar and offers drag and drop, as well as "click to set" options
// for replacing that avatar. Meant to be used in a form which attaches the avatar to a resource
const AvatarInput = ({
  shape = 'rect',
  object, label,
  ...props
}) => {
  const [state, setState] = React.useState({ value: null, fileSrc: null })
  // const [fileSrc, setFileSrc] = React.useState()
  // const [value, setValue] = React.useState()

  // When input changes, update preveiw
  const onChange = React.useCallback(async (event) => {
    const { files } = event.target
    const fileSrc = await imageFileSrc(files)
    setState((s) => ({ ...s, fileSrc }))
  }, [])

  // Remove input value and image preview
  const clear = React.useCallback(async () => {
    setState({
      value: null,
      fileSrc: null,
    })
  }, [])

  const onDrop = async ({ data: value }) => {
    const fileSrc = await imageFileSrc(value)
    setState({ value, fileSrc })
  }

  // Reset input on saved
  React.useEffect(() => { clear() }, [clear, object.avatar?.url])

  return (
    <Stack gap={4}>
      {label !== false ? (
        <div className="level-label">{label || 'Avatar'}</div>
      ) : null}
      <FileInput
        {...props}
        className="avatar-input"
        onChange={onChange}
        defaultValue={state.value}
        label={(
          <Stack gap={5}>
            <FileDrop type="image" onDrop={onDrop}>
              <AvatarInputDrop data-shape={shape}>
                <Avatar
                  width={110}
                  name={object?.name}
                  email={object?.email || object?.name}
                  src={state.fileSrc || object?.avatar?.url}
                  shape={shape}
                  gradient
                />
              </AvatarInputDrop>
            </FileDrop>
            {state.fileSrc ? null : (
              <div style={{ textAlign: 'center' }}>
                <div
                  className="level-button"
                  data-size="3"
                  data-theme="default"
                >
                  Change Image
                </div>
              </div>
            )}
          </Stack>
        )}
      />
      {state.fileSrc ? (
        <div style={{ textAlign: 'center' }}>
          <Button text="Cancel" size={3} onClick={clear} />
        </div>
      ) : null}
    </Stack>
  )
}

AvatarInput.propTypes = {
  object: PropTypes.shape({
    avatar: PropTypes.shape({ url: PropTypes.string }),
    name: PropTypes.string.isRequired,
    email: PropTypes.string,
  }).isRequired,
  size: PropTypes.number,
  label: PropTypes.string,
  shape: PropTypes.string,
}

export {
  AvatarInput,
}
