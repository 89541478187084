import { useParams } from 'react-router-dom'
import { useTemplateConfig } from '../useTemplateConfig'
import { ElementConfigForm } from './form'

const ElementConfig = () => {
  const { elementId, sharedSectionId } = useParams()
  const { getSectionElement } = useTemplateConfig()
  const { element, section } = getSectionElement({ elementId }) || {}

  if (!element) return null

  return (
    <ElementConfigForm
      element={element}
      section={section}
      sharedSection={sharedSectionId ? section : null}
    />
  )
}

export {
  ElementConfig,
  ElementConfigForm
}
