import PropTypes from 'prop-types'
import { tagSizes } from '../../constants/themes'
import { typeRequiredUnless } from '../../helpers/react'
import { Link } from '../Link'
import { Shelf, Spacer } from '../Shelf'
import { Text } from '../Text'

import './header.scss'

const Header = ({
  text,
  tag = 'h3',
  primary,
  children,
  ...rest
}) => (
  <Shelf
    className="level-header"
    valign="center"
    data-primary={primary}
    {...rest}
  >
    {text ? <Text tag={tag}>{text}</Text> : null}
    {text ? <Spacer grow /> : null}
    {children || null}
  </Shelf>
)

Header.propTypes = {
  ...Shelf.propTypes,
  primary: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  tag: PropTypes.oneOf(Object.keys(tagSizes)),
  children: typeRequiredUnless({ type: PropTypes.node, present: 'text' }),
}

const HeaderNavBack = (props) => (
  <Link
    className="level-header-nav-back"
    to=".."
    icon={{
      name: 'arrow-left',
      color: 'neutral-200',
    }}
    {...props}
  />
)

export {
  Header,
  HeaderNavBack
}
