import {
  BrowserRouter,
} from 'react-router-dom'

import './index.scss'
import { BugsnagProvider } from '@app/components/Bugsnag'
import { WebSocketProvider } from '@app/hooks/useWebSocket'
import { AppIndex } from '@app/views/index'
import {
  MessageProvider,
  ToastIndex,
  LoadingProvider,
  LoadingBar,
  Dialog,
} from '@level'

export default function App() {
  return (
    <BrowserRouter>
      <BugsnagProvider>
        <WebSocketProvider>
          <LoadingProvider>
            <LoadingBar />
            <MessageProvider>
              <Dialog />
              <ToastIndex />
              <AppIndex />
            </MessageProvider>
          </LoadingProvider>
        </WebSocketProvider>
      </BugsnagProvider>
    </BrowserRouter>
  )
}
