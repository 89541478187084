import PropTypes from 'prop-types'
import { camelToTitle } from '../../helpers'
import { useFormRef } from '../../hooks'
import { HiddenInput } from '../Input'
import { StateMenuButton } from '../MenuButton'
import { Stack } from '../Stack'

const deriveLabel = ({ label, name }) => (label === true && name ? camelToTitle(name) : label)

// Works like a Select input, but accepts React nodes for labels so it can render any content
const SelectMenu = ({
  name, label = true, options, onChange: onChangeProp, defaultValue, ...props
}) => {
  const labelText = deriveLabel({ label, name })

  const formRef = useFormRef()
  const onChange = (value) => {
    if (name && formRef?.register) {
      formRef?.setValue(name, value, { shouldDirty: true })
    }
    if (onChangeProp) onChangeProp(value)
  }

  return (
    <>
      {name && formRef?.register ? (
        <HiddenInput id={name} name={name} defaultValue={defaultValue} />
      ) : null}
      <Stack gap={4}>
        {labelText ? <label className="level-label" htmlFor={name}>{labelText}</label> : null}
        <StateMenuButton
          onChange={onChange}
          options={options}
          defaultValue={defaultValue}
          {...props}
        />
      </Stack>
    </>
  )
}

SelectMenu.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.any.isRequired,
    value: PropTypes.string,
  })).isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
}

export {
  SelectMenu
}
