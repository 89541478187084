import { Avatar } from '@level'
import { useTeam } from '@app/hooks'

const TeamAvatar = (props) => {
  const { Team } = useTeam()

  return Team ? (
    <Avatar
      size={4}
      name={Team.name}
      src={Team.avatar?.url}
      email={Team.name}
      gradient
      {...props}
    />
  ) : null
}

export {
  TeamAvatar,
}
