import React from 'react'
import PropTypes from 'prop-types'

import { Stack } from '../Stack'
import { Shelf } from '../Shelf'
import { Text } from '../Text'
import { useFormRef, useUuid } from '../../hooks'
import { camelToTitle, toColor } from '../../helpers'

import './radio-panel.scss'

const RadioPanel = ({
  name, defaultValue, label, items, required, checkedColor = 'blue-primary', onChange: onChangeProp, submitOnChange,
}) => {
  const {
    submit, register, onChangeInput, setValue,
  } = useFormRef()

  const onChange = (event) => {
    if (submitOnChange && submit) { submit() }
    onChangeInput?.({ [name]: event.target.value })
    onChangeProp?.(event)
  }
  const id = useUuid()
  const reg = register ? register(name, { required, onChange }) : {}
  React.useEffect(() => {
    setValue(name, defaultValue)
  }, [defaultValue])

  return (
    <Stack gap={4}>
      {typeof label === 'string' ? (
        <Text className="level-label">{label || camelToTitle(name)}</Text>
      ) : label}
      <Shelf className="level-radio-panel" valign="center">
        {items.map(({
          label: content,
          title, icon, value: itemValue,
          checkedColor: itemCheckedColor = checkedColor,
          defaultChecked,
        }) => {
          const radioId = `radio-${itemValue}-${id}`
          return (
            <div style={{ color: toColor(itemCheckedColor) }} key={radioId}>
              <input
                type="radio"
                name={name}
                id={radioId}
                value={itemValue}
                defaultChecked={defaultChecked || defaultValue === itemValue}
                {...reg}
              />
              <Text
                as="label"
                align="center"
                className="level-radio-panel-item"
                title={!content ? (title || camelToTitle(itemValue)) : null}
                htmlFor={radioId}
                icon={icon}
              >
                {content}
                {!content && !icon ? camelToTitle(itemValue) : null}
              </Text>
            </div>
          )
        })}
      </Shelf>
    </Stack>
  )
}

RadioPanel.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    title: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    checkedColor: PropTypes.string,
  })).isRequired,
  required: PropTypes.bool,
  checkedColor: PropTypes.string,
  onChange: PropTypes.func,
  submitOnChange: PropTypes.bool,
}

export {
  RadioPanel,
}
