import React from 'react'
import PropTypes from 'prop-types'

import { gapSize, spaceToSize } from '../../helpers/space'
import { alignItemsStyle, alignments, valignments } from '../../helpers/alignment'
import './stack.css'

const Stack = React.forwardRef(function Stack({
  gap: rowGap = 0,
  space,
  style = {},
  tag: Tag = 'div',
  align,
  valign,
  ...rest
}, ref) {
  return (
    <Tag
      className="level-stack"
      style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: gapSize(rowGap),
        padding: space ? spaceToSize(space) : space,
        // We think of align as horizontal alignment setting,
        // but with flexDirection: column the axis of alignment is flipped to vertical.
        // We swap these properties to keep our metla model simple.
        ...alignItemsStyle({ valign: align, align: valign }),
        ...style,
      }}
      {...rest}
      ref={ref}
    />
  )
})

Stack.propTypes = {
  tag: PropTypes.string,
  style: PropTypes.object,
  gap: gapSize.propTypes.gap,
  space: spaceToSize.propTypes.space,
  align: PropTypes.oneOf(valignments),
  valign: PropTypes.oneOf(alignments),
}

export { Stack }

