import React from 'react'
import PropTypes from 'prop-types'
import {
  Main, P, SubmitButton, ButtonShelf, Stack,
} from '@level'

import { useProfile, useSendPreview } from '@app/hooks'
import { TestDrawer } from '../test/drawer'
import { PreviewEmailTemplate } from './previewEmailTemplate'

const TestEmailTemplate = ({ template }) => {
  const { Profile } = useProfile()
  const { onSubmit } = useSendPreview({ template })

  return (
    <Main drawer={(
      <TestDrawer
        title="Test Email Template"
        onSubmit={onSubmit}
        templateVariables={template.previewTemplateVariables}
        theme={template.previewConfig.variables._theme}
      >
        <Stack gap={6} space={6}>
          <P>
            Send a test email with the test variables above to this account&rsquo;s
            email address ({Profile.email}).
          </P>
          <ButtonShelf>
            <SubmitButton text="Send Email" />
          </ButtonShelf>
        </Stack>
      </TestDrawer>
    )}
    >
      <PreviewEmailTemplate {...template} mode="preview" />
    </Main>
  )
}

TestEmailTemplate.propTypes = {
  template: PropTypes.object.isRequired,
}

export {
  TestEmailTemplate,
}
