// Works like classList.toggle, adding or removing an item based on its presence
const toggleArrayIncludes = (array, item) => (
  array.includes(item) ? array.filter((i) => i !== item) : [...array, item]
)

// Remove all items in `exclusions` from `base` array
const excludeItemsFromArray = (base, exclusions) => (
  base.filter((item) => !exclusions.includes(item))
)

// Using new `Set` primative
const uniqueCombineArrays = (a, b) => [...new Set([...a, ...b])]

const uniqueArray = (array) => [...new Set([...array])]

// Returns an array of items which are in both arrays a and b
const sharedArrayItems = (a, b) => (
  a.filter((item) => b.includes(item))
)

const sort = (a, b) => {
  if (a?.localeCompare) return a.localeCompare(b)
  if (a < b) return -1
  if (b < a) return 1
  return 0
}

const sortDesc = (a, b) => sort(b, a)

const sortBy = (sortKey, options = {}) => (a, b) => {
  const sortFn = options.desc ? sortDesc : sort
  return sortFn(a?.[sortKey], b?.[sortKey])
}

const mapToKey = (array, key, includeFalsy = false) => (
  array.reduce((all, item) => (
    item[key] || includeFalsy ? all.concat(item[key]) : all
  ), [])
)

const changeArrayPosition = ({ array, from, to }) => {
  if (typeof from !== 'number' || typeof to !== 'number') return array
  array.splice(to, 0, array.splice(from, 1)[0])
  return array
}

const allTruthy = (arr) => arr.filter((a) => a).length === arr.length

// make it easier to asynchronosly map across an object
const asyncMap = (items, mapFn) => {
  // Convert any iterable object into an array to be sure it can map
  const array = Array.from(items)
  const map = (fn) => Promise.all(array.map(fn))
  // If they pass a map function, go ahead an execute it.
  // Otherwise, return map as a chainable function
  return mapFn ? map(mapFn) : { map }
}

// Create an array of numbers from start to end incremented by the step value
const toRange = ({ start, end, step = 1 }) => {
  const range = [start]
  while (range[range.length - 1] + step <= end) {
    range.push(range[range.length - 1] + step)
  }
  return range
}

export {
  excludeItemsFromArray,
  toggleArrayIncludes,
  uniqueCombineArrays,
  uniqueArray,
  sharedArrayItems,
  changeArrayPosition,
  allTruthy,
  sort,
  sortDesc,
  sortBy,
  mapToKey,
  asyncMap,
  toRange,
}
