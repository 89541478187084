import PropTypes from 'prop-types'
import { spaceToSize } from '../../helpers'
import { Link } from '../Link'

import './nav-tabs.scss'

// Lets us set a default link props (like icon size) for nav items
const defaultLinkProps = ({ icon: iconProps, ...props }) => {
  if (!iconProps) return props
  const icon = (typeof iconProps === 'string')
    ? { name: iconProps, size: 2 }
    : { size: 2, ...iconProps }

  return ({ ...props, icon })
}

const NavTabs = ({
  space = 6, navItems = [], state, setState, itemSpace = 4, theme = 'default', border,
}) => (
  <nav className="level-nav-tabs" data-theme={theme} data-border={border}>
    <ul
      className="level-nav-tabs-list"
      style={{ paddingLeft: spaceToSize(space), paddingRight: spaceToSize(space) }}
    >
      {navItems.map(({
        text, children, state: linkState, ...rest
      }, index) => (
        <li
          key={rest.key || linkState || rest.to || text}
          className="level-nav-list-item"
          style={{
            paddingLeft: index ? spaceToSize(itemSpace) : null,
            paddingRight: index !== navItems.length ? spaceToSize(itemSpace) : null,
          }}
        >
          <Link
            className={`level-nav-tab ${typeof state !== 'undefined' && state === linkState ? 'active' : ''}`}
            onClick={setState ? () => setState(linkState) : null}
            {...defaultLinkProps(rest)}
            text={children || text}
          />
        </li>
      ))}
    </ul>
  </nav>
)

NavTabs.propTypes = {
  space: spaceToSize.propTypes.space,
  itemSpace: spaceToSize.propTypes.space,
  navItems: PropTypes.array,
  state: PropTypes.any,
  setState: PropTypes.func,
  theme: PropTypes.string,
  border: PropTypes.bool,
}

const NavTab = (props) => (
  <Link
    className="level-nav-tab"
    {...props}
  />
)

export {
  NavTab, NavTabs
}
