import { objToRubyString, toRubyParams } from '@level'

const rubySnippet = ({
  data: { templateVariables, ...data },
  token,
}) => `require "enveloop"

enveloop = Enveloop::Client.new(api_key: "${token}")

enveloop.send_message(
  ${toRubyParams(data)},
  template_variables: ${objToRubyString(templateVariables, 2)}
)`

export {
  rubySnippet,
}
