import { NotFound } from '@level'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Authed } from '../auth'
import { AccountLayout } from './layout'
import { AccountNew } from './new'
import { AccountSettings } from './settings'

const AccountIndex = () => (
  <Authed>
    <Routes>
      <Route index element={<Navigate to="./settings" replace />} />
      <Route path="new/*" element={<AccountNew />} />
      <Route path="*" element={<AccountLayout />}>
        <Route path="settings" element={<AccountSettings />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  </Authed>
)

export {
  AccountIndex
}
