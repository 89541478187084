import React from 'react'

import {
  DateRangeFilter,
} from '@level'

const DateFilter = () => (
  <DateRangeFilter
    defaults={{ date: '' }}
    span={[
      'all',
      'lastHour',
      'today',
      'yesterday',
    ]}
    dateRange={[
      '10 minutes',
      '30 minutes',
      '1 hour',
      '2 hours',
      '3 hours',
      '6 hours',
      '12 hours',
      '1 day',
      '2 days',
      '5 days',
    ]}
  />
)

export {
  DateFilter,
}
