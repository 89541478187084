import React from 'react'
import PropTypes from 'prop-types'

import {
  Card, Stack, Shelf, Text, P, Grid, Swatch, Tooltip,
  numberAbreviation, displayNumber, getChartHigh, getChartSteps,
  toCleanPercentage, formatDate, abbreviateNumber, sortBy,
} from '@level'

const Insights = ({ insights }) => (
  <Stack gap={7}>
    <Text tag="h4">Insights</Text>
    <Card space={0}>
      <Grid columns={2} className="insights" templateColumns="1fr 1fr">
        <Stack gap={5} space={8}>
          <Text tag="h1" style={{ margin: 0 }}>{insights.projectCount}</Text>
          <P>Projects</P>
        </Stack>
        <Stack gap={5} space={8}>
          <Text tag="h1" style={{ margin: 0 }}>{insights.activeTemplateCount}</Text>
          <P>Active Templates</P>
        </Stack>
        <Stack gap={5} space={8}>
          <Text tag="h1" style={{ margin: 0 }}>{numberAbreviation(insights.emailCount)}</Text>
          <P>Messages Sent</P>
        </Stack>
        <Stack gap={5} space={8}>
          <Text tag="h1" style={{ margin: 0 }}>{`${insights.errorCount} (${(insights.errorRatio * 100).toFixed(1)}%)`}</Text>
          <P>Errors Reported (last 30 days)</P>
        </Stack>
      </Grid>
    </Card>
  </Stack>
)

Insights.propTypes = {
  insights: PropTypes.object.isRequired,
}

const GraphKey = ({ label, color, number }) => (
  <Shelf gap={4}>
    <Swatch width={16} height={16} color={color} />
    <Stack gap={2}>
      <Text color="neutral-800">{label}</Text>
      { number ? <Text size={0} color="neutral-300">{displayNumber(number)}</Text> : null }
    </Stack>
  </Shelf>
)

const FreePlanUsage = ({ planUsage }) => {
  const ratio = toCleanPercentage(planUsage.messagesUsed, planUsage.messagesIncluded)
  const usagePercentage = ratio

  return (
    <Stack gap={6}>
      {planUsage.messagesUsed ? (
        <Grid gap={3} style={{ width: '100%' }} templateColumns={`${usagePercentage}% 1fr`}>
          <Swatch height={40} color="blue-700" />
          <Swatch height={40} color="neutral-50" />
        </Grid>
      ) : (
        <Swatch height={40} style={{ width: '100%' }} color="neutral-50" />
      )}
      <Shelf gap={8}>
        <GraphKey label="Sent" color="blue-700" number={planUsage.messagesUsed} />
        <GraphKey label="Remaining" color="neutral-50" number={planUsage.messagesIncluded - planUsage.messagesUsed} />
      </Shelf>
    </Stack>
  )
}

const splitAbbreviation = (num) => {
  const match = num.toString().match(/^(-?\d*\.?\d+)(\D?)$/)
  return (!match?.[2]) ? [num] : [match[1], match[2]]
}

const MonthlyUsage = ({ planUsage }) => {
  const { historicalUsage } = planUsage
  const high = historicalUsage.reduce((acc, {
    emailsUsed,
    smsUsed,
  }) => Math.max(emailsUsed, smsUsed, acc), 0)

  if (!high) return null
  const size = 150
  const chartHigh = getChartHigh(high)
  const barHeight = (count) => {
    const h = size * (count / chartHigh)
    return h < 1 ? 1 : h
  }
  const chartSteps = getChartSteps(chartHigh)
  const rowHeight = size / (chartSteps.length - 1)

  return (
    <Card space={8}>
      <Stack gap={5}>
        <Shelf gap={5}>
          <GraphKey label="Email" color="blue-600" />
          <GraphKey label="SMS" color="aqua-600" />
        </Shelf>
        <Grid templateColumns="auto 1fr" gap={4}>
          <Grid templateRows="auto" valign="end" style={{ height: `${size + rowHeight}px` }}>
            { chartSteps.map((step) => {
              const [num, abbr] = splitAbbreviation(abbreviateNumber(step))
              return (
                <Text key={step} align="right">
                  {num}
                  { abbr ? <Text color="neutral-200">{ abbr }</Text> : null}
                </Text>
              )
            })}
          </Grid>
          <div style={{ position: 'relative' }}>
            <Grid
              templateRows="auto"
              valign="end"
              align="stretch"
              style={{
                height: `${size + rowHeight}px`,
                position: 'absolute',
                top: '0',
                right: 0,
                width: '100%',
              }}
            >
              { chartSteps.map((step) => (
                <div key={step} style={{ borderBottom: '1px dashed #ddd' }} />
              ))}
            </Grid>
            <Grid columns={4} gap={5} valign="bottom" style={{ marginTop: `${rowHeight}px` }}>
              { historicalUsage.sort(sortBy('startOfMonth')).map(({
                emailsUsed,
                smsUsed,
                startOfMonth,
              }) => (
                <Stack gap={5} key={startOfMonth} align="center" style={{ textAlign: 'center' }}>
                  <Grid templateColumns="repeat(2, minmax(10px, 30px))" align="center" valign="bottom" gap={3} style={{ height: `${size}px`, width: '90%' }}>
                    <Tooltip color="white" backgroundColor="blue-600" tip={displayNumber(emailsUsed)}>
                      <Swatch color="blue-600" width="100%" height={barHeight(emailsUsed)} />
                    </Tooltip>
                    <Tooltip color="white" backgroundColor="aqua-600" tip={displayNumber(smsUsed)}>
                      <Swatch color="aqua-600" width="100%" height={barHeight(smsUsed)} />
                    </Tooltip>
                  </Grid>
                  <Text size={2} color="neutral-300">{formatDate(startOfMonth, { format: 'MMM', utc: true })}</Text>
                </Stack>
              ))}
            </Grid>
          </div>
        </Grid>
      </Stack>
    </Card>
  )
}

const Usage = ({ Team, insights }) => {
  const { planUsage } = insights
  const used = displayNumber(planUsage.messagesUsed)
  const remaining = displayNumber(planUsage.messagesIncluded)

  return (
    <Stack gap={7}>
      <Text tag="h4">Plan Usage</Text>
      <Card space={8}>
        <Stack gap={6}>
          <Shelf align="split" valign="bottom">
            <Stack>
              <Text tag="h4" size={6}>{used}</Text>
              <Text tag="strong">Total Messages</Text>
            </Stack>
            <Stack style={{ textAlign: 'right' }}>
              <Text tag="h5">{Team.plan.name} Plan</Text>
              <Text color="neutral-400" align="right">This Month</Text>
            </Stack>
          </Shelf>
          { Team.plan.id === 'developer' ? <FreePlanUsage planUsage={planUsage} /> : null }
        </Stack>
      </Card>
      <MonthlyUsage planUsage={planUsage} />
    </Stack>
  )
}

Usage.propTypes = {
  Team: PropTypes.object.isRequired,
  insights: PropTypes.object.isRequired,
}

export {
  Usage,
  Insights,
}
