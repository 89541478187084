import { includeKeys } from './object'

// Ensure query string is properly formatted with braces
// If a mutation or if it already has braces it returns the query string unmodified
// Otherwise it wraps with braces
const formatQueryString = (query) => {
  const alreadyFormatted = query.match(/mutation/) || query.match(/^{/) || query.match(/^query/)
  return JSON.stringify({
    query: alreadyFormatted ? query : `{ ${query} }`,
  })
}

const formatQueryObject = ({
  query, // GraphQL query string
  signal, // AbortController signal for aborting fetch calls
  headers = {},
}) => ({
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    ...headers,
  },
  body: formatQueryString(query),
  signal,
})

// Wrap fetch in a GraphQL friendly API
const fetchGraphQl = ({
  url, // API endpoint
  query, // GraphQL query string
  signal, // AbortController signal for aborting fetch calls
  headers,
}) => fetch(
  url,
  formatQueryObject({ query, signal, headers }),
)

// Converts an object to a parameter style object.
// 1. Cast to a JSON object and then stringify (which quotes string values).
// 2. Removes quotes around keys and surrounding brackets
// { 'foo': 'bar', baz: 1 } => foo: 'bar', baz: 1
const toParams = (object, options = {}) => {
  const { pretty = false, literals = [] } = options
  const json = JSON.stringify(object, null, pretty ? 2 : null).replace(/"(\w+?)":/g, '$1:').replace(/^{/, '').replace(/}$/, '')
  return literals.length ? json.replace(new RegExp(`([^\\w])(${literals.join('|')}):"(\\w+?)"`, 'g'), '$1$2: $3') : json.trim()
}

const toRubyParams = (object, options = {}) => (
  toParams(object, { pretty: true, ...options }).replace(/null/g, 'nil')
)

const toPythonParams = (object, options = {}) => {
  const { pretty = false, literals = [] } = options
  const json = JSON.stringify(object, null, pretty ? 4 : null).replace(/"(\w+?)":\s*/g, '$1=').replace(/^{/, '').replace(/}$/, '')
  const val = literals.length ? json.replace(new RegExp(`([^\\w])(${literals.join('|')}): "(\\w+?)"`, 'g'), '$1$2: $3') : json
  return val.trim().replace(/null/g, 'None')
}

// Return a GraphQL ready object for all conditions with values
// Accepts a params object
const paramsObjToConditions = ({ params, keys = [], operators = {} }) => (
  // Oonly use params matching keys in condition defaults
  keys.reduce((acc, field) => {
    if (params[field]) {
      const settings = {
        field,
        // If the param doesn't include an operator, use the default operator
        operator: params[`${field}Op`] || operators[field],
        value: params[field],
      }
      return [...acc, settings]
    }
    return acc
  }, [])
)

// Accepts a params object, an array of keys (to permit)
const getQueryFilter = ({ params, operators, keys = Object.keys(operators) }) => {
  const {
    offset = 0, limit = 100, ...rest
  } = params
  const conditions = paramsObjToConditions({ params: rest, keys, operators })

  // Conditions must be processed manuallyy to ensure that operators are presented as enums
  const conditionString = conditions.map((condition) => {
    const cond = toParams(
      { ...condition, value: (condition.value?.includes && condition.value.includes(',')) ? condition.value.split(',') : condition.value },
      { literals: ['operator'] },
    )
    return `{${cond}}`
  }).join(', ')

  return `filter: { limit: ${limit}, offset: ${offset}, conditions: [${conditionString}] }`
}

const includeFilterParams = (params, operators) => (
  includeKeys(params, Object.keys(operators).reduce((acc, op) => (
    [...acc, op, `${op}Op`]
  ), []))
)

export {
  fetchGraphQl,
  formatQueryObject,
  formatQueryString,
  toParams,
  toRubyParams,
  toPythonParams,
  paramsObjToConditions,
  getQueryFilter,
  includeFilterParams,
}
