import PropTypes from 'prop-types'
import { useDroppable } from '@dnd-kit/core'
import { Icon } from '../Icon'

const Droppable = ({
  id,
  as: Tag = 'div',
  overChildren = [],
  disabled,
  children,
  ...props
}) => {
  const { setNodeRef, isOver, over } = useDroppable({ id, disabled })
  const overChild = overChildren?.length && over?.id && overChildren.includes(over?.id)
  const draggingOver = isOver || overChild || undefined

  return (
    <Tag
      ref={!disabled ? setNodeRef : undefined}
      data-drag-over={disabled ? undefined : draggingOver}
      data-drag-disabled={(draggingOver && disabled) || undefined}
      {...props}
    >
      {children}
      {draggingOver && disabled ? <div className="level-drag-disabled"><Icon size={6} name="ban" className="level-drag-disabled-icon" /></div> : null}
    </Tag>
  )
}

Droppable.propTypes = {
  id: PropTypes.string.isRequired,
  overChildren: PropTypes.array,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  disabled: PropTypes.bool,
  children: PropTypes.node,
}

export { Droppable }
