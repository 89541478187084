import * as Ariakit from '@ariakit/react'
import PropTypes from 'prop-types'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '../Button'
import { Divider } from '../Divider'
import { Text } from '../Text'
import { spaceToSize } from '../../helpers'
import './menu-button.scss'

const MenuButton = ({
  items, menuItemSpace = [4, 6], menuIconGap = 4, sameWidth, ...rest
} = {}) => {
  const navigate = useNavigate()
  return (
    <Ariakit.MenuProvider>
      <Button as={Ariakit.MenuButton} {...rest} />
      <Ariakit.Portal>
        <Ariakit.Menu gutter={4} className='level-menu' sameWidth={sameWidth}>
          {items.map(({
            to, text, onSelect, divider, heading, key, children, destructive, ...props
          }) => {
            return (
              <React.Fragment key={key || text}>
                {heading ? (<Text as={Ariakit.MenuHeading} size={0} space={menuItemSpace}>{heading}</Text>) : null}
                {divider ? <Divider space={[3, 0]} /> : null}
                <Ariakit.MenuItem
                  onClick={to ? () => navigate(to) : onSelect}
                  render={<Text data-destructive={destructive} space={menuItemSpace} iconGap={menuIconGap} {...props} />}
                  className="level-menu-item"
                >
                  {children || text}
                </Ariakit.MenuItem>
              </React.Fragment>
            )
          })}
        </Ariakit.Menu>
      </Ariakit.Portal>
    </Ariakit.MenuProvider>
  )
}
MenuButton.propTypes = {
  items: PropTypes.array.isRequired,
  menuItemSpace: spaceToSize.propTypes.space,
  menuIconGap: PropTypes.number,
  sameWidth: PropTypes.bool,
}

const StateMenuButton = ({
  options, defaultValue, onChange, ...props
}) => {
  const defaultOption = options.find(({ value }) => value === defaultValue)
  const [buttonText, setButtonText] = React.useState(defaultOption?.children || defaultOption?.label || 'Select One')

  const items = options.map(({ value, label: text = value, ...rest }) => ({
    key: value,
    text,
    onSelect: () => {
      if (onChange) onChange(value)
      setButtonText(text)
    },
    ...rest,
  }))
  return (
    <MenuButton
      items={items}
      text={buttonText}
      iconAfter="angle-up-and-down"
      grow
      {...props}
    />
  )
}

StateMenuButton.propTypes = {
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
}

export { MenuButton, StateMenuButton }
