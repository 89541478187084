import PropTypes from 'prop-types'
import { useDrop } from '../../hooks/useDropZone'
import { Icon } from '../Icon'
import { Shelf } from '../Shelf'
import './add-image.scss'

const AddImageInput = ({ addImage }) => {
  const dragData = useDrop()
  const onChange = async ({ target: input }) => {
    const { files } = input

    const match = Array.from(files).filter((f) => f.type.match('image'))
    if (match) await addImage(files)
    input.value = null
  }

  return (
    <div className="level-add-image" data-dragover={dragData.dragOver || null}>
      <label htmlFor="add-file-input">
        <Shelf gap={3} valign="center">
          <Icon size={6} className="level-add-image-icon" name="plus" />
          {dragData.dragOver ? 'Drop to add image' : 'Add Image'}
        </Shelf>
        <input type="file" id="add-file-input" name="images" label="hi" onChange={onChange} />
      </label>
    </div>
  )
}

AddImageInput.propTypes = {
  addImage: PropTypes.func.isRequired,
}

const AddImage = (props) => {
  const { addImage } = props
  if (addImage) return <AddImageInput {...props} />
  return null
}

AddImage.propTypes = {
  addImage: PropTypes.func,
}

export {
  AddImage
}
