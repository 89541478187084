import PropTypes from 'prop-types'
import { Outlet } from 'react-router-dom'
import { OfflineBanner } from '../OfflineBanner'

import './layout.scss'

const Layout = ({
  className, children, sidebar, root,
}) => (
  <div className="level-layout-wrapper">
    {root}
    <OfflineBanner />
    <div
      className={`level-layout${className ? ` ${className}` : ''}`}
      data-sidebar={!!sidebar}
    >
      {sidebar}
      {children || <Outlet />}
    </div>
  </div>
)

Layout.propTypes = {
  root: PropTypes.node,
  sidebar: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
}

export {
  Layout,
}
