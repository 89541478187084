import PropTypes from 'prop-types'
import { useMaxSize } from '../../hooks/useMaxSize'

const PanelContainer = ({ children }) => {
  const { height, ref } = useMaxSize()

  return (
    <div
      ref={ref}
      style={{ height }}
      className="level-panel-wrapper"
    >
      {children}
    </div>
  )
}

PanelContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export {
  PanelContainer
}
