import React from 'react'
import { Loading } from '@level'

import {
  useTemplate,
} from '../context'

import { DeployEmailTemplate } from '../email'
import { DeploySMSTemplate } from '../sms'

const TemplateDeploy = () => {
  const { template } = useTemplate()

  if (template?.type === 'email') return <DeployEmailTemplate />
  if (template?.type === 'sms') return <DeploySMSTemplate />
  return <Loading />
}

export { TemplateDeploy }
