import { dasherize } from './text'

// Return an object where only the included keys are present
const includeKeys = (obj, includedKeys) => {
  const include = Array.isArray(includedKeys) ? includedKeys : Object.keys(includedKeys)
  return include.reduce((filtered, key) => (
    (key in obj)
      ? { ...filtered, [key]: obj[key] }
      : filtered
  ), {})
}

const excludeKeys = (obj, excludedKeys) => {
  const exclude = Array.isArray(excludedKeys) ? excludedKeys : Object.keys(excludedKeys)
  return Object.keys(obj).reduce((all, key) => (
    (exclude.includes(key)) ? all : { ...all, [key]: obj[key] }
  ), {})
}

const isEmpty = (val, ...ignore) => (
  typeof val === 'undefined' || val === null || val === '' || ignore.includes(val)
)

// Remove all empty values from an object.
// If `forKeys` is set, only filter empty values on those keys
const filterEmptyValues = (obj, forKeys) => (
  Object.keys(obj).reduce((all, key) => (
    // Value is truthy or there are key selections and this isn't one of them
    (!isEmpty(obj[key]) || (forKeys && !forKeys.includes(key))) ? { ...all, [key]: obj[key] } : all
  ), {})
)

const copyObject = (obj) => JSON.parse(JSON.stringify(obj))

const toSearchString = (obj) => {
  const params = new URLSearchParams()
  Object.keys(obj).forEach((key) => {
    params.append(key, obj[key])
  })
  return params.toString()
}

const searchFilterObj = ({ name, operator, value }, operators = {}) => {
  const filter = { [name]: value }
  if (operator && operator !== operators[name]) filter[`${name}Op`] = operator
  return filter
}

const searchToObject = (str) => {
  const params = new URLSearchParams(str)
  const obj = {}

  params.forEach((val, key) => {
    if (obj[key]) {
      obj[key] = Array([obj[key], val]).flat(2)
    } else obj[key] = val
  })

  return obj
}

const toOperatorParams = (defaultOperators, values) => (
  values.filter((arg) => arg).reduce((acc, { name, operator, value }) => {
    const param = { [name]: value }
    if (operator && operator !== defaultOperators[name]) param[`${name}Op`] = operator
    if (!operator) param[`${name}Op`] = null
    return { ...acc, ...param }
  }, {})
)

function isEqual(a, b) {
  const ta = typeof a
  const tb = typeof b
  return a && b && ta === 'object' && ta === tb ? (
    Object.keys(a).length === Object.keys(b).length
      && Object.keys(a).every((key) => isEqual(a[key], b[key]))
  ) : (a === b)
}

const expandAria = (aria) => {
  const obj = {}

  if (aria) {
    Object.keys(aria).forEach((key) => {
      obj[`aria-${dasherize(key)}`] = aria[key]
    })
  }
  return obj
}

const isImageFile = (file) => !file || !!file.type.match(/image/)

const imageData = (file) => (
  file ? new Promise((resolve) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      resolve(reader.result)
    }
    reader.readAsDataURL(file)
  }) : null
)

// Extract the image data to render a src preview
const imageFileSrc = async (fileList) => {
  if (!fileList) return null
  if (isImageFile(fileList.item(0))) {
    return imageData(fileList.item(0))
  }
  return null
}

const toDataAttributes = (obj) => (
  Object.keys(obj).reduce((all, key) => ({
    ...all, [`data-${key}`]: obj[key],
  }), {})
)

const inherit = ({ key, configs, exclude }) => {
  const found = configs.find((c) => (!isEmpty(c?.[key], ...Array(exclude).flat()) ? c[key] : null))
  return found ? found[key] : null
}

const sortKeys = (obj) => {
  const sorted = Object.keys(obj).sort().reduce((acc, key) => ({ ...acc, [key]: null }), {})
  return { ...sorted, ...obj }
}

/**
 * Sorts an object's keys based on the property within nested objects.
 *
 * @param {Object} obj - The object to be sorted, with the structure
 *                       { key: { nestedKey: 'value' }, ... }
 * @param {Function} sortFn - A comparison function that defines the sort order.
 *                            It takes two arguments and should return a negative
 *                            value if the first argument should come before the
 *                            second, a positive value if it should come after,
 *                            or zero if they are considered equal.
 * @param {String} nestedKey - The name of the key (optional) whose value to sort by
 *                            in the nested object, in this example it would be 'nestedKey'
 *                            if not provided, the sort function will redeive the nested object
 *                            to handle the object inspection for sorting
 * @returns {Object} A new object with the same structure as the input, but
 *                   with keys sorted based on their corresponding 'val' values.
 *
 * @example
 * const obj = {
 *   key1: { val: 'banana' },
 *   key2: { val: 'apple' },
 *   key3: { val: 'cherry' }
 * };
 * const sorted = sortObjectByNestedValue(obj, (a, b) => a.localeCompare(b));
 * // sorted would be an object where keys are sorted alphabetically by 'val'
 */

const sortByNestedValue = (obj, sortFn, key) => {
  // Extract the entries from the object into an array for sorting
  const entries = Object.entries(obj).map(([k, value]) => [k, value])

  // Sort the entries using the provided sort function
  entries.sort((a, b) => {
    // Extract the values for passing to the sort function
    const valA = key ? a[1][key] : a[1]
    const valB = key ? b[1][key] : b[1]
    return sortFn(valA, valB)
  })

  // Convert back to an object with the sorted order
  return Object.fromEntries(entries.map(([k, val]) => [k, val]))
}

// Calls a callback on all variables in an object.
// obj should be an object like: { one: [1, 2, 3], two: 'fun' }
// callback should be a function which accepts { key, value } and returns a transformed value
const mapCallback = ({ obj, callback, overwrite = true }) => {
  if (obj === null) return obj
  if (typeof obj === 'string') return callback({ value: obj })

  const renderedVars = overwrite ? obj : {}

  Object.entries(obj).forEach(([key, value]) => {
    const valType = typeof value

    if (Array.isArray(value)) {
      renderedVars[key] = value.map((v) => mapCallback({ obj: v, callback, overwrite }))
    } else if (valType === 'object' && value !== null) {
      renderedVars[key] = mapCallback({ obj: value, callback, overwrite })
    } else {
      renderedVars[key] = callback({ key, value })
    }
  })

  return renderedVars
}

/**
 * Removes specified keys from an object and its nested objects while preserving arrays
 * @param {Object|Array} obj - The source object or array
 * @param {string|string[]} keys - Key or array of keys to remove
 * @returns {Object|Array} A new object or array with specified keys removed
 */
const removeNestedKeys = (obj, keys) => {
  const keysToRemove = Array.isArray(keys) ? keys : [keys]

  // Handle base cases
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  // Handle arrays
  if (Array.isArray(obj)) {
    return obj.map((item) => removeNestedKeys(item, keysToRemove))
  }

  // Handle objects
  return Object.fromEntries(
    Object.entries(obj)
      .map(([key, value]) => {
        if (keysToRemove.includes(key)) return null
        return [key, removeNestedKeys(value, keysToRemove)]
      })
      .filter(Boolean),
  )
}

export {
  isEqual,
  includeKeys,
  excludeKeys,
  filterEmptyValues,
  toSearchString,
  searchToObject,
  expandAria,
  imageData,
  isImageFile,
  imageFileSrc,
  toDataAttributes,
  copyObject,
  inherit,
  sortKeys,
  searchFilterObj,
  toOperatorParams,
  mapCallback,
  sortByNestedValue,
  removeNestedKeys,
}
export { default as deepMerge } from 'deepmerge'
