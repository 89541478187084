import { sort } from '@level'
import PropTypes from 'prop-types'
import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { QueryProvider } from './QueryProvider'
import { useProfile } from './useProfile'

const TeamContext = React.createContext({})
const useTeam = () => React.useContext(TeamContext)

const teamQuery = ({ teamId, getAllMailSettings }) => `
  team(team: "${teamId}") {
    name
    slug
    channel
    avatar { url }
    defaultFromEmail
    defaultFromSms
    disabled
    providerId
    provider
    teamMembers {
      id
      email
      role
      isAdmin
      isOwner
      acceptedAt
      createdAt
      user {
        name email avatar { url } 
      }
    }
    plan {
      id
      name
      basePrice
      includedMessages
      additionalMessages
      pricingTiers
      watermark
      public
      allowSms
    }
    senderIdentities {
      id
      identity
      type
      ${getAllMailSettings ? `isVerified
      dedicatedIp
      dkimRecords 
      dmarcRecord
      spfRecord` : ''}}
  } 
  projects(team: "${teamId}") { slug mailProvider { config provider } }
`

// Provides Team, Team.members (sorted), and utilities:
// - isAdmin - boolean, (is the currentUser a team admin?)
// - isOwner - boolean, (is the currentUser the team owner?)
// - isCurrentUser - functon, (tells you if a team member is the current user)
// - refresh - function, (fetches profile, teams, invitations)
const TeamProvider = ({ children }) => {
  const { teamId } = useParams()
  const { Profile, Teams, teamUrl } = useProfile()
  const [getAllMailSettings, setGetAllMailSettings] = React.useState(false)

  const onData = React.useCallback(({ data: { team, projects } }) => {
    const members = team.teamMembers
      ? team.teamMembers.sort(
        (a, b) => (sort(a.user.name || a.user.email, b.user.name || b.user.email)),
      ).map(
        (m) => ({ ...m, isCurrentUser: m.email === Profile.email }),
      ) : null

    return ({
      Team: team,
      members,
      admins: members.filter(({ isAdmin: admin, acceptedAt }) => admin && acceptedAt),
      isAdmin: team.teamMembers.find(({ email, isAdmin }) => email === Profile.email && isAdmin),
      isOwner: team.teamMembers.find(({ email, isOwner }) => email === Profile.email && isOwner),
      mailProviders: projects ? projects.map((p) => p.mailProvider).filter((f) => !!f) : undefined,
      url: (path) => teamUrl({ teamId, path }),
      fetchAllMailSettings: () => setGetAllMailSettings(true),
      isCurrentUser: ({ email }) => email === Profile.email,
      isProviderTeam: !!team.providerId,
    })
  }, [Profile.email, teamId])

  if (!Teams.find(({ slug }) => slug === teamId)) {
    return <Navigate to="/" />
  }

  return (
    <QueryProvider
      query={teamQuery({ teamId, getAllMailSettings })}
      context={TeamContext}
      onData={onData}
    >
      {children}
    </QueryProvider>
  )
}

TeamProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export {
  TeamProvider, useTeam
}
