export const createSenderIdentity = ({ Team, identity }) => `mutation {
  identity: createSenderIdentity(team: "${Team.slug}", identity: "${identity}") {
    id
  }
}`

export const deleteSenderIdentity = ({ Team, id }) => `mutation {
  identity: deleteSenderIdentity(team: "${Team.slug}", id: "${id}") {
    id
  }
}`
