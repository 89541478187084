import { flexRender } from '@tanstack/react-table'
import PropTypes from 'prop-types'
import { Icon } from '../Icon'
import { Shelf, Spacer } from '../Shelf'

const SortableHeader = ({ header, children }) => (
  <Shelf gap={6} valign="center" nowrap>
    {children}
    <Spacer grow />
    <Icon
      className="level-table-sort-icon"
      name={header.column.getIsSorted() === 'desc' ? 'angle-down' : 'angle-up'}
    />
  </Shelf>
)

SortableHeader.propTypes = {
  header: PropTypes.shape({
    column: PropTypes.shape({
      getIsSorted: PropTypes.func,
    }),
  }).isRequired,
  children: PropTypes.node.isRequired,
}

const TH = ({ header }) => {
  const { column } = header
  const { columnDef } = column
  if (header.isPlaceholder) return null
  const content = flexRender(columnDef.header, header.getContext())
  return (
    <th
      key={header.id}
      width={columnDef.width}
      style={{ textAlign: columnDef.align, ...(column.columnDef.style || {}) }}
      data-cell-id={column.id}
      data-sortable={column.getCanSort() ? true : null}
      data-is-sorted={column.getIsSorted() ? true : null}
      onClick={header.column.getToggleSortingHandler()}
    >
      {header.column.getCanSort()
        ? <SortableHeader header={header}>{content}</SortableHeader>
        : content}
    </th>
  )
}

TH.propTypes = {
  header: PropTypes.shape({
    id: PropTypes.string,
    isPlaceholder: PropTypes.bool,
    column: PropTypes.object,
    getContext: PropTypes.func,
    getIsSorted: PropTypes.func,
    getCanSort: PropTypes.func,
  }).isRequired,
}

const TableHead = ({ headers, empty }) => (
  <thead className="level-table-head" data-empty={empty || null}>
    {headers.map((headerGroup) => (
      <tr key={headerGroup.id}>
        {headerGroup.headers.map((header) => (
          <TH key={header.id} header={header} />
        ))}
      </tr>
    ))}
  </thead>
)

TableHead.propTypes = {
  headers: PropTypes.array.isRequired,
  empty: PropTypes.bool.isRequired,
}

export {
  TableHead
}
