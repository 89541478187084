import React from 'react'
import PropTypes from 'prop-types'

import { MenuButton } from '@level'

import {
  useCopyTemplate,
  useDeleteTemplate,
  useRenameTemplate,
  useCopyTheme,
  usePasteTheme,
} from './hooks'

const ActionButton = ({ template, refresh }) => {
  const copyTemplate = useCopyTemplate()
  const deleteTemplate = useDeleteTemplate()
  const renameTemplate = useRenameTemplate()
  const copyTheme = useCopyTheme({ template })
  const pasteTheme = usePasteTheme({ template, clearOnPaste: false })

  const menuActions = React.useMemo(() => {
    if (!template) return []
    const actions = [
      {
        text: 'Rename',
        icon: 'pen-field',
        onSelect: () => {
          renameTemplate({
            data: template,
            hooks: { onSuccess: refresh },
          })
        },
      },
      {
        text: 'Duplicate',
        onSelect: async () => {
          await copyTemplate({ data: template })
          refresh()
        },
        icon: 'copy',
      },
    ]

    if (template.type === 'email') {
      if (copyTheme) {
        actions.push({
          text: 'Copy Theme',
          onSelect: () => copyTheme(),
          icon: 'share-from-square',
        })
      }
      if (pasteTheme) {
        actions.push({
          text: 'Apply Theme',
          onSelect: async () => {
            await pasteTheme()
            refresh()
          },
          icon: 'fill-drip',
        })
      }
    }

    actions.push({
      text: 'Delete',
      onSelect: async () => {
        await deleteTemplate({ data: template })
        refresh()
      },
      icon: 'trash',
      destructive: true,
      divider: true,
    })

    return actions
  }, [
    renameTemplate,
    deleteTemplate,
    copyTemplate,
  ])
  return <MenuButton items={menuActions} size={2} icon="three-dots-horizontal" label="Template options" style={{ position: 'relative', zIndex: 1 }} />
}

ActionButton.propTypes = {
  template: PropTypes.object.isRequired,
  refresh: PropTypes.func.isRequired,
}

export {
  ActionButton,
}
