import React from 'react'
import { useProfile, useQuery, projectsQuery } from '@app/hooks'

const useWelcomeProgress = () => {
  const {
    Profile: { name: userName },
    Teams,
    invitations,
    refresh: refreshProfile,
  } = useProfile()

  const [getProjects, { data }] = useQuery(projectsQuery)
  const Project = data?.projects ? data?.projects[0] : null
  const Team = Teams[0]

  React.useEffect(() => {
    if (Team) { getProjects({ Team }) }
  }, [Team?.slug])

  let current = 0
  if (userName) current = 1
  if (userName && Team) current = 2

  const refresh = React.useCallback(async () => {
    if (!userName) return refreshProfile()
    if (Team && !invitations.length) return getProjects({ Team })
    return refreshProfile()
  }, [userName, Team])

  const newSignup = [
    'Enter Your Name',
    'Name your Team',
    'Name your Project',
  ]

  const invitedUser = [
    'Enter Your Name',
    'Manage Invitation(s)',
  ]

  const progress = {
    userName,
    teamName: Team?.name,
    Team,
    Project,
    invitations,
    current,
    refresh,
  }

  // User was invited
  if (invitations?.length) return { ...progress, steps: invitedUser }

  // User initiated creating an account
  return { ...progress, steps: newSignup }
}

export {
  useWelcomeProgress,
}
