import React from 'react'
import { Main } from '@level'

import { PreviewSMSTemplate } from './preview'
import { DeployDrawer } from '../deploy/drawer'

const DeploySMSTemplate = () => (
  <Main drawer={<DeployDrawer />}>
    <PreviewSMSTemplate mode="render" />
  </Main>
)

export { DeploySMSTemplate }
