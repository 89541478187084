import React from 'react'
import PropTypes from 'prop-types'
import { useFormState } from 'react-hook-form'
import { Text, TextIcon } from '../Text'
import { Loading } from '../Loading'
import { Link } from '../Link'
import { useFormRef, useLeadingDebounce } from '../../hooks'
import { spaceToSize } from '../../helpers/space'
import { Tooltip } from '../Tooltip'

import './button.scss'

import {
  sizes, themes,
} from '../../constants/button'

const useDebounceClick = ({ onClick, debounceClick }) => {
  const delay = typeof debounceSubmit === 'number' ? debounceClick : 500
  return useLeadingDebounce(
    onClick, // Immediately execute the click
    [],
    delay,
    {
      // Additional clicks are prevented
      onBounce: (event) => {
        event.preventDefault()
        event.stopPropagation()
      },
    },
  )
}

const Button = React.forwardRef(function Buttoin({
  space,
  type = 'button',
  disabled,
  icon,
  iconAfter,
  isLoading = false,
  label,
  onClick: onClickProp,
  text,
  children = text,
  size = 4,
  theme = 'default',
  className: propsClassName,
  grow,
  as,
  inline,
  style = {},
  debounceClick,
  tip,
  ...rest
}, ref) {
  const className = `level-button ${propsClassName || ''}`
  const isLink = rest.to || rest.href
  const Component = !as && isLink ? Link : as || 'button'
  const buttonStyle = {
    padding: typeof space !== 'undefined' ? spaceToSize(space) : null,
    cursor: 'pointer',
    ...style,
  }

  // Prevent additional clicks
  const debounceOnClick = useDebounceClick({ onClick: onClickProp, debounceClick })

  const onClick = (event) => {
    if (debounceClick) {
      debounceOnClick(event)
    } else if (onClickProp && !event.defaultPrevented) {
      onClickProp(event)
    }
  }

  const button = (
    <Component
      className={className}
      aria-label={label || tip}
      disabled={disabled || isLoading}
      onClick={onClick}
      type={isLink ? null : type}
      data-theme={theme === 'none' ? null : theme}
      theme={isLink ? theme : null}
      data-size={size}
      data-grow={grow}
      ref={ref}
      style={buttonStyle}
      {...rest}
    >
      {icon && !children && !iconAfter ? (
        <TextIcon icon={icon} />
      ) : (
        <Text
          className="level-button-text"
          inline={inline}
          icon={icon}
          iconAfter={iconAfter}
          align="center"
          style={buttonStyle}
        >
          {isLoading ? <Loading text={children} /> : children}
        </Text>
      )}
    </Component>
  )
  if (tip) {
    return <Tooltip className="level-button-tooltip" tip={tip} render={button} />
  }
  return button
})

Button.propTypes = {
  label: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.array]),
  text: ({
    text, children, label, tip,
  }) => ((!text && !children && !label && !tip) ? (new Error('Button requires either text, children, tip, or a label prop.')) : null),
  type: PropTypes.string,
  icon: Text.propTypes.icon,
  iconAfter: Text.propTypes.iconAfter,
  isLoading: PropTypes.bool,
  theme: PropTypes.oneOf(themes),
  size: PropTypes.oneOf(sizes),
  grow: PropTypes.bool,
  as: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
  style: PropTypes.object,
  space: spaceToSize.propTypes.space,
  onClick: PropTypes.func,
  tip: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.array]),
}

const SubmitButton = React.forwardRef(function SubmitButton({
  text = 'Submit',
  children = text,
  ...rest
}, ref) {
  const formRef = useFormRef()
  const { isSubmitting, errors } = useFormState(formRef)

  return (
    <Button
      theme="primary"
      type="submit"
      {...rest}
      text={children}
      isLoading={isSubmitting || rest.isLoading}
      disabled={rest.disabled || !!Object.keys(errors).length}
      debounceClick
      ref={ref}
    />
  )
})

SubmitButton.propTypes = {
  ...Button.propTypes,
}

export { Button, SubmitButton }
