import React from 'react'
import PropTypes from 'prop-types'

import { EditorState } from '@codemirror/state'
import { EditorView } from '@codemirror/view'
import { CopyButton } from '../CopyButton'
import { config } from './config'
import { langConfig } from './lang'

import './editor.scss'

const Editor = ({
  content = '', updateContent = content, onChange, lang, copyable = true,
}) => {
  const [view, setView] = React.useState()
  const [update, setUpdate] = React.useState()

  const ref = React.useRef()
  const copyRef = React.useRef()

  React.useEffect(() => {
    const initialState = EditorState.create({
      doc: content,
      extensions: [
        ...config,
        ...langConfig(lang),
        EditorView.updateListener.of(setUpdate),
      ],
    })
    const v = new EditorView({ state: initialState })

    window.editor = v
    setView(v)
  }, [lang])

  React.useEffect(() => {
    if (view) {
      view.dispatch({
        changes: { from: 0, to: view.state.doc.length, insert: content },
      })
    }
  }, [updateContent])

  React.useEffect(() => {
    const main = ref?.current
    if (main && view) {
      // remove all children
      while (main.firstChild) main.removeChild(main.lastChild)
      //
      main.append(view.dom)
    }
  }, [view, ref?.current])

  React.useEffect(() => {
    const text = update?.state?.doc?.text ? update.state.doc.text.join('\n') : content
    if (copyable) copyRef.current = text
    if (update?.docChanged && update?.state?.doc?.text) {
      onChange?.(text)
    }
  }, [update])

  return (
    <div className="level-editor">
      <div className="level-editor-main" ref={ref} />
      {copyable ? <CopyButton className="level-editor-copy-button" theme="ghost" copyRef={copyRef} /> : null}
    </div>
  )
}

Editor.propTypes = {
  content: PropTypes.string,
  updateContent: PropTypes.any,
  onChange: PropTypes.func,
  lang: PropTypes.string,
  copyable: PropTypes.bool,
}

export {
  Editor,
}
