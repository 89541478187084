import React from 'react'
import {
  TemplateConfigProvider,
} from '@level'
import { Navigate } from 'react-router-dom'

import { TemplateBuilderMain } from '../templates/email/build'

const EmailBuilder = ({ template, onChange }) => {
  const [imageHeights, setImageHeights] = React.useState({})
  return template ? (
    <TemplateConfigProvider
      config={template.previewConfig}
      type={template.type}
      name={template.name}
      slug="try"
      onChange={onChange}
      imageHeights={imageHeights}
      setImageHeights={setImageHeights}
      sharedSections={false}
    >
      <TemplateBuilderMain />
    </TemplateConfigProvider>
  ) : (
    <Navigate to="../new" />
  )
}

export {
  EmailBuilder,
}
