import { uuid, useLocalStorage } from '@level'
import React from 'react'

const useAnonymousId = () => {
  const [id, setId] = useLocalStorage('anonymous-id', null)

  React.useEffect(() => {
    if (!id) setId(uuid)
  }, [id])

  return id
}

export {
  useAnonymousId
}
