import React from 'react'
import PropTypes from 'prop-types'

import { useParams } from 'react-router-dom'

import {
  Text, Loading, Stack, Button, Grid,
  useSearchState, useMessages,
  useThrottle,
} from '@level'

import { useTeamChannel } from '@app/hooks'

import {
  MessageLogProvider, MessageTemplateProvider, useMessageLog,
} from './provider'
import { MessagesTable } from './table'
import { FilterPills } from './filters/pills'

const LiveLogs = ({ enabled, onChange }) => {
  const { env } = useParams()
  const [params, setSearch] = useSearchState()
  const { items: messages, refresh } = useMessageLog()
  const [live, setLive] = React.useState(enabled)
  const isLive = React.useRef(enabled)
  const [hover, setHover] = React.useState()
  const dirty = React.useRef(false)

  const throttledRefresh = useThrottle(() => {
    refresh()
  }, [refresh], 3000)

  React.useEffect(() => {
    isLive.current = enabled && (!params?.date || params.date === 'all')
    setLive(enabled && (!params?.date || params.date === 'all'))
  }, [enabled, params?.date])

  const ToggleLive = () => {
    setLive((current) => {
      isLive.current = !current
      return !current
    })
  }

  React.useEffect(() => {
    if (live && params.date) {
      setSearch.clear(['date', 'createdAt'])
    }
    if (live && dirty.current) {
      throttledRefresh()
      dirty.current = false
    }
    onChange(live)
  }, [live])

  // A new message comes in
  const reloadMessages = React.useCallback((msg) => {
    if (isLive.current) {
      const { testMode } = msg.data
      const messageEnv = {
        true: 'sandbox',
        false: 'live',
      }[testMode]

      if (messageEnv === env) throttledRefresh()
      dirty.current = false
    } else {
      dirty.current = true
    }
  }, [throttledRefresh])

  // A message in the current list has an update
  const addEvent = React.useCallback((e) => {
    if (isLive.current) {
      const { data: { event: { MessageId } } } = e

      const message = messages.find(({ id }) => id === MessageId.toString())
      if (message) { throttledRefresh() }
    }
  }, [throttledRefresh])

  useTeamChannel({ event: 'messageSent', handler: reloadMessages })
  useTeamChannel({ event: 'eventReceived', handler: addEvent })

  const icon = (() => {
    if (hover && live) return { name: 'pause', color: 'neutral-500' }
    if (live) return { name: 'circle-small', color: 'green-500' }
    if (hover) return { name: 'play', color: 'green-500' }
    return { name: 'pause', color: 'neutral-500' }
  })()

  const text = (() => {
    if (live) return 'Live Logs'
    if (hover) return 'Resume Live Logs'
    return 'Live Logs Paused'
  })()

  return (
    <Button
      className="live-log-toggle"
      icon={icon}
      onClick={() => ToggleLive()}
      theme="light"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      data-live={(live && !hover) || null}
      data-hover={hover || null}
    >
      { text }
    </Button>
  )
}

LiveLogs.propTypes = {
  enabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

const Messages = () => {
  const [selectedMessage, setSelectedMessage] = React.useState(null)
  const [params, setParams] = useSearchState()
  const selectMessage = (message) => setSelectedMessage(message)
  const [scrolled, setScrolled] = React.useState(false)
  const scrollEl = React.useRef()
  const { items: messages } = useMessageLog()
  const { publish } = useMessages({ channel: 'layout' })

  React.useEffect(() => {
    publish({ refresh: {} })
    return () => { publish({ refresh: {} }) }
  }, [params])

  const onScroll = ({ target }) => {
    if (target.scrollTop < 1) {
      scrollEl.current = null
      setScrolled(false)
    } else {
      if (!scrollEl.current) { setScrolled(true) }
      scrollEl.current = target
    }
  }

  const onChangeLive = React.useCallback((isLive) => {
    if (isLive && scrollEl.current) {
      scrollEl.current.scrollTop = 0
    }
  }, [scrolled])

  return (
    <Stack gap={6} space={6} className="message-log">
      <Grid gap={5} templateColumns="auto 1fr auto" valign="top">
        <LiveLogs enabled={!selectedMessage && !scrolled} onChange={onChangeLive} />
        <FilterPills />
        { messages.length || Object.keys(params).length ? (
          <Button
            theme="light"
            className="clear-message-filters"
            icon={{ name: 'x-circle', color: 'neutral-300' }}
            onClick={() => setParams({})}
            text="Clear Filters"
            disabled={!Object.keys(params)?.length}
          />
        ) : null }
      </Grid>
      <MessagesTable
        selectMessage={selectMessage}
        onScroll={onScroll}
      />
    </Stack>
  )
}

const MessageInbox = () => {
  const { env } = useParams()
  const testMode = env !== 'live'

  return (
    <MessageTemplateProvider>
      <MessageLogProvider
        testMode={testMode}
        loading={(
          <div className="message-log-empty">
            <Text tag="h4"><Loading text="Loading Messages" /></Text>
          </div>
        )}
      >
        <Messages />
      </MessageLogProvider>
    </MessageTemplateProvider>
  )
}

export {
  MessageInbox,
}
