import Ably from 'ably'
import React from 'react'

const WebSocketContext = React.createContext()

const mockApi = {
  channels: {
    get: (channel) => ({
      publish: (name, data, callback) => {
        console.log('Publishing to channel', { channel, name, data })
        callback()
      },
      subscribe: (name) => {
        console.log('Subscribing to channel', { channel, name })
      },
      unsubscribe: (name) => {
        console.log('Unsubscribing to channel', { channel, name })
      },
    }),
  },
}

const useWebSocket = ({
  channel, event, handler,
}) => {
  const { getWebSocket } = React.useContext(WebSocketContext)
  const handlerRef = React.useRef()
  handlerRef.current = handler

  const eventHandler = React.useCallback((...args) => handlerRef.current(...args), [])

  React.useEffect(() => {
    const webSocket = getWebSocket()
    const Channel = webSocket.channels.get(channel)
    Channel.subscribe(event, eventHandler)

    return () => {
      Channel.unsubscribe(event, eventHandler)
    }
  }, [channel, event, eventHandler])
}

const WebSocketProvider = (props) => {
  const connection = React.useRef()
  const value = React.useMemo(() => ({
    getWebSocket: () => {
      if (!connection.current) {
        connection.current = import.meta.env.VITE_ABLY_API_KEY
          ? new Ably.Realtime(import.meta.env.VITE_ABLY_API_KEY)
          : mockApi
      }
      return connection.current
    },
  }), [])

  return <WebSocketContext.Provider value={value} {...props} />
}

export {
  WebSocketProvider,
  useWebSocket
}
