import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from '@level'

const WelcomeSkeleton = ({
  userName,
  teamName,
  projectName,
}) => (
  <svg
    style={{
      WebkitUserSelect: 'none',
      userSelect: 'none',
      display: 'block',
    }}
    width="15px"
    height="15px"
    version="1.1"
    viewBox="0 0 1069 962"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden
    className="welcome-skeleton-wrapper"
  >
    <Icon name="welcome-skeleton" fill="none" height="100%" width="100%" />
    {teamName ? (
      <g transform="matrix(1.60816,0,0,1.60816,-733.962,-203.414)">
        <text fill="#6F7074" x="515.943px" y="241.187px" style={{ fontWeight: 500, fontSize: '12px' }}>{teamName}</text>
      </g>
    ) : (
      <g transform="matrix(0.858868,0,0,1.19188,9.56345,-911.019)">
        <rect x="95.14" y="903.63" width="236.17" height="20.09" fill="rgb(245,246,247)" fillRule="nonzero" />
      </g>
    )}

    {projectName ? (
      <g transform="matrix(1.60816,0,0,1.60816,-735.962,52.1089)">
        <text fill="#6F7074" x="515.943px" y="241.187px" style={{ fontWeight: 500, fontSize: '12px' }}>{projectName}</text>
      </g>
    ) : (
      <g id="welcome-project-cover" transform="matrix(1.044,0,0,1.19188,-14.5784,-655.587)">
        <rect x="95.14" y="903.63" width="236.17" height="20.09" fill="rgb(245,246,247)" fillRule="nonzero" />
      </g>
    )}
    {userName ? (
      <g transform="matrix(1.60816,0,0,1.60816,-733.962,533.812)">
        <text fill="#6F7074" x="515.943px" y="241.187px" style={{ fontWeight: 500, fontSize: '12px' }}>{userName}</text>
      </g>
    ) : (
      <g id="welcome-user-cover" transform="matrix(1,0,0,1.19188,0,-175.241)">
        <rect x="95.14" y="903.63" width="236.17" height="20.09" fill="rgb(245,246,247)" fillRule="nonzero" />
      </g>
    )}
  </svg>
)

WelcomeSkeleton.propTypes = {
  userName: PropTypes.string,
  teamName: PropTypes.string,
  projectName: PropTypes.string,
}

export {
  WelcomeSkeleton,
}
