const jsSnippet = ({
  data, apiUrl, token,
}) => `data = ${JSON.stringify(data, null, 2)}

fetch("${apiUrl}", {
  method: "POST",
  body: JSON.stringify(data),
  headers: {
    "Content-Type": "application/json",
    "Authorization": "token ${token}",
  },
})
.then(response => response.json()) 
.then(json => console.log(json))
.catch(err => console.log(err))`

export { jsSnippet }
