import React from 'react'

import { useQuery } from '@app/hooks'

import {
  Card, Stack, Button,
  Text, P, Grid, Icon,
} from '@level'

import { useNewProjectDialog } from '../../projects/new'

const templatesQuery = ({ Team, Project }) => `query {
  Templates: templates(team: "${Team.slug}", project: "${Project.slug}") {
    slug
    name
    publishedAt
  }
}`

const funnelActions = [
  'actionProjectCreatedAt',
  'actionTemplateCreatedAt',
  'actionTemplatePublishedAt',
  'actionMessageSentAt',
  'actionProviderAddedAt',
]

const stepStatus = ({ Team, step }) => {
  if (Team[step]) return 'complete'
  // Find next uncomplete action
  const nextAction = funnelActions.find((a) => !Team[a])
  // Is this the next uncomplete action
  if (nextAction === step) return 'current'

  return 'inactive'
}

const FunnelStep = ({
  step, action, to, onClick, children, Team,
}) => {
  const status = stepStatus({ Team, step })
  const linkProps = status === 'current' ? { theme: 'primary' } : { space: [4, 0], theme: 'link' }

  return (
    <Grid templateColumns="min-content 1fr" className="welcome-step" data-status={status} gap={8}>
      {status === 'complete' ? <Icon name="check" className="step-icon" /> : <span className="step-icon" />}
      <Grid gap={4}>
        { children }
        { action && (onClick || to) && status !== 'complete'
          ? <div><Button to={to} onClick={onClick} text={action} {...linkProps} /></div>
          : null }
      </Grid>
    </Grid>
  )
}

const Funnel = ({ Team, Projects }) => {
  const newProjectDialog = useNewProjectDialog()
  const [firstProject] = Projects || []

  const [getTemplateData, { data: templateData }] = useQuery(templatesQuery)
  const [firstTemplate] = templateData?.Templates || []
  const publishedTemplate = React.useMemo(() => (
    templateData?.Templates.find((t) => t.publishedAt)
  ), [templateData])

  React.useEffect(() => {
    if (firstProject) {
      getTemplateData({ Team, Project: firstProject })
    }
  }, [firstProject])

  return (
    <Card space={8}>
      <Stack gap={10}>
        <Stack gap={5}>
          <Text tag="h2">Your quick setup guide</Text>
        </Stack>
        <Stack className="welcome-steps" gap={9}>
          <FunnelStep Team={Team} step="actionProjectCreatedAt" action="Add a Project" onClick={newProjectDialog}>
            <Text tag="h5">Add a project</Text>
            <P>Templates and integrations all live within a project.</P>
          </FunnelStep>
          <FunnelStep Team={Team} step="actionTemplateCreatedAt" action="Create a Template" to={firstProject ? `../projects/${firstProject.slug}/templates` : null}>
            <Text tag="h5">Create a template</Text>
            <P>Use one of our preconfigured templates or create your own using our builder.</P>
          </FunnelStep>
          <FunnelStep Team={Team} step="actionTemplatePublishedAt" action="Publish Template" to={firstTemplate ? `../projects/${firstProject.slug}/templates/template/${firstTemplate.slug}/build` : null}>
            <Text tag="h5">Publish a template</Text>
            <P>Now that you have built your email publish it.</P>
          </FunnelStep>
          <FunnelStep Team={Team} step="actionMessageSentAt" action="Send a Test Message" to={publishedTemplate ? `../projects/${firstProject.slug}/templates/template/${publishedTemplate.slug}/deploy/curl` : null}>
            <Text tag="h5">Send your first message</Text>
            <P>You can start sending immediately with 100 free messages per month.</P>
          </FunnelStep>
          <FunnelStep Team={Team} step="actionProviderAddedAt" action="Setup Identity" to="../mail-settings">
            <Text tag="h5">Setup your sender identity</Text>
            <P>Configure your sender identity to start sending up to 10,000 messages per month for free.</P>
          </FunnelStep>
        </Stack>
      </Stack>
    </Card>
  )
}

export {
  Funnel,
}
