import React from 'react'
import {
  newTemplate, toParams, useDialog,
} from '@level'
import { useFormQuery, useProject } from '@app/hooks'

import {
  EmailTemplateGallery,
  SMSTemplateGallery,
  TemplateGallery,
} from './gallery'

const createTemplateQuery = ({
  Project, name, slug, config, type,
}) => `mutation {
  Template: createTemplate(
    team: "${Project.team.slug}",
    project: "${Project.slug}",
    attributes: {
      type: ${type},
      ${toParams({ slug, name })},
      previewConfig: {
        ${toParams(config || newTemplate({ type }).config)}
      }
    }
  ) { slug }
}`

const useNewEmailTemplateDialog = ({ onSubmit }) => {
  const sendDialog = useDialog()
  return (opts = { templateConfig: {} }) => sendDialog({
    onConfirm: onSubmit,
    title: 'New Email Template',
    confirmText: 'Add Template',
    content: <EmailTemplateGallery {...opts.templateConfig} />,
    form: {
      defaultValues: { theme: 'stellar' },
      focus: '[name=theme]:checked',
    },
    width: '70vw',
    space: 0,
  })
}

const useNewSmsTemplateDialog = ({ onSubmit }) => {
  const sendDialog = useDialog()
  return (opts = { templateConfig: {} }) => sendDialog({
    onConfirm: ({ data: { config, name, type } }) => {
      onSubmit({ data: { config: config || null, name, type } })
    },
    title: 'New SMS Template',
    confirmText: 'Add Template',
    content: <SMSTemplateGallery {...opts.templateConfig} />,
    form: { focus: 'template' },
    width: '70vw',
    space: 0,
  })
}

const useNewTemplateDialog = ({ onSubmit, templateConfig = {} }) => {
  const sendDialog = useDialog()
  const newEmailTemplate = useNewEmailTemplateDialog({ onSubmit })
  const newSmsTemplate = useNewSmsTemplateDialog({ onSubmit })

  return () => sendDialog({
    onNext: ({ data: { type } }) => {
      if (type === 'email') newEmailTemplate({ templateConfig })
      if (type === 'sms') newSmsTemplate({ templateConfig })
    },
    title: 'Add New Template',
    confirmText: 'Next',
    content: <TemplateGallery />,
    form: { focus: 'type' },
    width: '50vw',
    space: 0,
  })
}

const useCreateTemplate = () => {
  const { Project } = useProject()

  return useFormQuery({
    query: ({
      name, config, type,
    }) => createTemplateQuery({
      name, Project, config, type,
    }),
    redirect: (result) => `template/${result.data.Template.slug}`,
  })
}

const useNewProjectTemplateDialog = () => {
  const { Project } = useProject()
  const { onSubmit } = useCreateTemplate()

  return useNewTemplateDialog({ onSubmit, templateConfig: { Project, Team: Project.team } })
}

export {
  createTemplateQuery,
  useCreateTemplate,
  useNewTemplateDialog,
  useNewProjectTemplateDialog,
  useNewEmailTemplateDialog,
}
