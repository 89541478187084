import React from 'react'
import { useTeam } from './useTeam'

const verificationIssues = ({
  identity, type, isVerified, dkimRecords,
}) => {
  // This data hasn't loaded yet.
  if (typeof isVerified === 'undefined') return false

  const unverified = !isVerified
  const unconfirmedDkim = type === 'domain' && dkimRecords && !!dkimRecords.find(({ confirmed }) => !confirmed)

  if (unverified || unconfirmedDkim) {
    let message = `${type} '${identity}'`
    if (unverified) message = `${message} has not been verified`
    if (unverified && unconfirmedDkim) message = `${message} and`
    if (unconfirmedDkim) message = `${message} has DKIM records which are not configured properly`

    return {
      type,
      unverified,
      unconfirmedDkim,
      message,
    }
  }

  return false
}

export const useMailSettings = () => {
  const {
    mailProviders,
    Team: { senderIdentities },
    fetchAllMailSettings,
    refresh: fetch,
    isLoading,
  } = useTeam()

  React.useEffect(() => {
    setTimeout(() => fetchAllMailSettings(), 100)
  }, [senderIdentities])

  const data = {
    mailProviders,
    senderIdentities,
    issues: senderIdentities.length && senderIdentities.map(verificationIssues).filter((f) => !!f),
    hasMailProvider: typeof mailProviders === 'undefined'
      ? undefined
      : !!(mailProviders?.length || senderIdentities?.length),
  }

  return { fetch, data, isLoading }
}
