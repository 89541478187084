import React from 'react'
import PropTypes from 'prop-types'

import {
  Layout, Form, Stack, SubmitButton,
} from '@level'

import { AsUserBanner } from '@app/hooks'
import { WelcomeCard } from './WelcomeCard'
import { InvitationInputs, useInvitations } from '../invitations'

const invitationsTitle = (invitations) => (
  invitations.length < 2
    ? `You've been invited to join ${invitations[0].team.name} on Enveloop`
    : `You've been invited to join ${invitations.length} Teams`
)

const WelcomeInvitation = ({ welcomeData }) => {
  const { onSubmit } = useInvitations()
  return (
    <Layout
      root={<AsUserBanner />}
      className="level-layout-welcome"
    >
      <WelcomeCard
        welcomeData={welcomeData}
        title={invitationsTitle(welcomeData.invitations)}
      >
        <Form onSubmit={onSubmit}>
          <Stack gap={10}>
            <InvitationInputs invitations={welcomeData.invitations} />
            <SubmitButton text="Finish" grow />
          </Stack>
        </Form>
      </WelcomeCard>
    </Layout>
  )
}

WelcomeInvitation.propTypes = {
  welcomeData: PropTypes.object.isRequired,
}

export {
  WelcomeInvitation,
}
