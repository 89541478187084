import PropTypes from 'prop-types'
import { Block } from '../Block'
import { Text } from '../Text'
import './notice.scss'

const Notice = ({
  icon, theme = 'notice', text, className, children,
  ...rest
}) => (
  <Block className={`level-notice ${className || ''}`} data-theme={theme} {...rest}>
    {icon ? (
      <Text tag="div" icon={icon}>{children || text}</Text>
    ) : (children || text)}
  </Block>
)

Notice.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  icon: Text.propTypes.icon,
}

export {
  Notice
}
