import React from 'react'
import PropTypes from 'prop-types'
import { Color, toColor, stringId } from '../../helpers'
import { useUuid } from '../../hooks'

import { Icon } from '../Icon'

const badgeSizes = {
  0: 20,
  1: 22,
  2: 24,
  3: 32,
  4: 44,
  5: 64,
  6: 88,
  7: 128,
}

const stringToColor = (str) => {
  const num = stringId(str)
  return Color(`hsl(${num % 361} 80% ${(num % 5) + 35}%)`, 'hsl')
}

const useGradient = ({ fill, pickGradient }) => {
  const uuid = useUuid()
  const gradientFill = `url(#gradient-fill-${uuid})`

  const gradientDefs = React.useMemo(() => {
    const bg = fill ? Color(toColor(fill) || fill) : stringToColor(pickGradient)
    return (
      <defs>
        <linearGradient id={`gradient-fill-${uuid}`} x1="0" x2="1" y1="0" y2="1">
          <stop offset="0" stopColor={bg.lighten(8)} />
          <stop offset=".5" stopColor={bg} />
          <stop offset="1" stopColor={bg.darken(8)} />
        </linearGradient>
      </defs>
    )
  }, [fill, pickGradient, uuid])

  return { gradientDefs, gradientFill }
}

const getSize = ({
  size,
  width = size,
  height = width,
}) => {
  const shapeWidth = badgeSizes[width] || width
  const shapeHeight = badgeSizes[height] || height
  return ({
    width: shapeWidth,
    height: shapeHeight,
  })
}

const Badge = ({
  fill,
  pickGradient,
  pickFill,
  shape = 'circle',
  children,
  className,
  style = {},
  ...rest
}) => {
  const { width, height } = getSize({ size: 2, ...rest })
  const { gradientDefs, gradientFill } = useGradient({ fill, pickGradient })
  const shapeFill = pickFill ? stringToColor(pickFill) : null
  const a = 0.5 * width // 100
  const b = 0.05 * width // 10
  const c = 0.95 * width // 190
  return (
    <svg
      className={`level-badge ${className}`}
      style={{
        WebkitUserSelect: 'none',
        userSelect: 'none',
        display: 'block',
        ...style,
      }}
      width={width}
      height={height}
      version="1.1"
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden
    >
      {gradientDefs}
      {shape === 'rect' ? (
        <rect
          fill={shapeFill || gradientFill || toColor(fill) || fill}
          width={width}
          height={height}
          ry={width * 0.15}
        />
      ) : null}
      {shape === 'circle' ? (
        <circle
          cx={width / 2}
          cy={height / 2}
          fill={shapeFill || gradientFill || toColor(fill) || fill}
          r={width / 2}
        />
      ) : null}
      {shape === 'squircle' ? (
        <path
          d={`
             M 0, ${a}
             C 0, ${b} ${b}, 0 ${a}, 0
             S ${width}, ${b} ${width}, ${a} ${c}, ${width} ${a}, ${width} 0, ${c} 0, ${a}
           `}
          fill={shapeFill || gradientFill || toColor(fill) || fill}
        />
      ) : null}
      {children}
    </svg>
  )
}

Badge.propTypes = {
  shape: PropTypes.oneOf(['squircle', 'circle', 'rect']),
  size: Icon.propTypes.size,
  className: PropTypes.string,
  pickGradient: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  pickFill: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  fill: toColor.propTypes.color,
  children: PropTypes.node,
  style: PropTypes.object,
}

const IconBadge = ({
  name,
  color = 'white',
  ...props
}) => {
  const { height, width } = getSize({ size: 2, ...props })
  return (
    <Badge {...props}>
      <Icon
        name={name}
        color={color}
        width={width * 0.57}
        height={height * 0.57}
        x={(width * 0.43) / 2}
        y={(height * 0.43) / 2}
      />
    </Badge>
  )
}
IconBadge.propTypes = {
  size: Icon.propTypes.size,
  name: Icon.propTypes.name,
  color: Icon.propTypes.color,
}

const TextBadge = ({
  text,
  color = '#ffffff',
  ...props
}) => {
  const { height, width } = getSize(props)
  return (
    <Badge {...props}>
      <text
        alignmentBaseline="central"
        dominantBaseline="central"
        fill={color}
        fontSize={`${(height / 2) * 0.9}px`}
        fontWeight="400"
        textAnchor="middle"
        x={width / 2}
        y={height / 2}
      >{text}
      </text>
    </Badge>
  )
}

TextBadge.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  color: PropTypes.string,
  ...Badge.propTypes,
}

export {
  TextBadge,
  IconBadge,
  badgeSizes,
}
