import Content from './content'
import Header from './header'
import { newSectionObject } from './helpers'
import Simple from './simple'

const AllSections = {
  ...Simple,
  ...Header,
  ...Content,
}

const PresetSections = {
  Simple,
  Header,
  Content,
}

const newSection = ({ section, templateVariables }) => {
  // If a string name for an existing section template
  if (typeof section === 'string' && AllSections[section]) {
    const variables = AllSections[section].new({ templateVariables })
    return newSectionObject({ section: { variables } })
  }
  // If a sectionConfig object, invoke it's new function and return it.
  if (typeof section?.new === 'function') {
    const variables = section.new({ templateVariables })
    return newSectionObject({ section: { variables } })
  }

  return newSectionObject({ section, templateVariables })
}

export { Section } from './Section'

export {
  exportSection,
  renderSection
} from './helpers'

export {
  Content, Header, newSection, PresetSections, Simple
}
