import {
  Combobox, ComboboxItem, ComboboxList, ComboboxProvider,
  SelectItem, SelectItemCheck, SelectLabel, SelectProvider,
  useComboboxStore, useSelectStore
} from '@ariakit/react'
import { matchSorter } from 'match-sorter'
import PropTypes from 'prop-types'
import React from 'react'
import { useFormRef } from '../../hooks/useForm'
import { Block } from '../Block'
import { Divider } from '../Divider'
import { HiddenInput } from '../Input'
import { Stack } from '../Stack'
import { SectionLabel } from '../Text'

const MultiSelect = ({
  name, label, items, match,
  itemDisplay = (i) => i,
  noResults = 'No results found',
  defaultValue,
  multi = true,
}) => {
  const [searchValue, setSearchValue] = React.useState('')
  const selectDefault = defaultValue && multi ? (defaultValue || '').split(',') : defaultValue || []

  const matches = React.useMemo(() => (
    (searchValue && matchSorter(items, searchValue, match)) || items
  ), [searchValue])

  const setSearch = (value) => {
    React.startTransition(() => {
      setSearchValue(value)
    })
  }

  const { setValue, submit } = useFormRef()

  const combobox = useComboboxStore({
    defaultOpen: !defaultValue,
    setValue: (value) => {
      setSearch(value)
    },
  })

  const select = useSelectStore({
    defaultValue: selectDefault,
    defaultActiveId: null,
    activeId: null,
  })

  const setInput = () => {
    setTimeout(() => {
      setValue(name, select.getState().value.join(','))
      setSearch('')
      combobox.setValue('')
      submit()
    }, 0)
  }

  return (
    <div className="wrapper">
      <HiddenInput name={name} defaultValue={defaultValue} />
      <ComboboxProvider store={combobox}>
        <SelectProvider store={select}>
          {label ? <SelectLabel>{label}</SelectLabel> : null}
          <Stack gap={7}>
            <Combobox
              autoSelect
              placeholder="Search..."
              className="level-input level-combobox-input"
              showOnMouseDown={false}
              autoFocus
            />
            <Divider flush={7} />
            <SectionLabel>Templates</SectionLabel>
          </Stack>
          <Block flush={7} space={[5, 5, 0]} className="level-combobox-items" style={{ maxHeight: '40vh', overflowY: 'auto' }}>
            <ComboboxList alwaysVisible>
              {matches.length ? matches.map((value) => {
                const selected = select.getState().value
                const checked = selected.includes(itemDisplay(value))
                return (
                  <div className="level-select-item" data-item-checked={checked || null} key={itemDisplay(value)} data-active-item={checked || null}>
                    <SelectItem
                      value={itemDisplay(value)}
                      className="level-combobox-item"
                      setValueOnClick={() => {
                        setInput(itemDisplay(value))
                        return true
                      }}
                      render={<ComboboxItem />}
                    />
                    <SelectItemCheck checked={checked} />
                  </div>
                )
              }) : (
                <div className="level-combobox-no-results">{noResults}</div>
              )}
            </ComboboxList>
          </Block>
        </SelectProvider>
      </ComboboxProvider>
    </div>
  )
}

MultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  match: PropTypes.object,
  placeholder: PropTypes.string,
  itemDisplay: PropTypes.func,
  defaultValue: PropTypes.string,
  noResults: PropTypes.any,
  multi: PropTypes.bool,
  label: PropTypes.string,
}

export { MultiSelect }
