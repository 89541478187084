const sizes = [0, 1, 2, 3, 4, 5, 6]
const themes = [
  'menu',
  'menu-item',
  'menu-add',
  'input',
  'default',
  'primary',
  'primary-green',
  'destructive',
  'primary-destructive',
  'ghost',
  'subtle',
  'link',
  'wrapper',
  'destructive-ghost',
  'subtle-destructive-ghost',
  'sidebar-heading',
  'wrapper-link',
  'select',
  'black',
  'outline-black',
  'text',
  'light',
  'none',
]
export { sizes, themes }
