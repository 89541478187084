import { useTimeoutCallback } from './useTimeoutCallback'

const useDebounce = (callback, dependencies, time, options = {}) => useTimeoutCallback({
  callback,
  dependencies,
  time,
  leading: false,
  trailing: true,
  ...options,
})

const useLeadingDebounce = (callback, dependencies, time, options = {}) => useTimeoutCallback({
  callback,
  dependencies,
  time,
  leading: true,
  trailing: false,
  ...options,
})

export {
  useTimeoutCallback,
  useDebounce,
  useLeadingDebounce,
}
