import PropTypes from 'prop-types'
import { camelToTitle } from '../../helpers/text'
import { useFormRef } from '../../hooks/useForm'
import { HiddenInput } from '../Input'
import { SliderInput } from './SliderInput'

const SliderSelectInput = ({
  name,
  label,
  options,
  defaultValue,
  onChange: onChangeProp,
  ...rest
}) => {
  const { setValue } = useFormRef()

  const onChange = ({ target }) => {
    const val = options[target.value]?.value || options[target.value]
    setValue(name, val, { shouldDirty: true, shouldTouch: true })
    if (onChangeProp) onChangeProp({ [name]: val })
  }

  return (
    <>
      <HiddenInput name={name} defaultValue={defaultValue} />
      <SliderInput
        name={name}
        label={label || camelToTitle(name)}
        defaultValue={options.findIndex((o) => o === defaultValue || o?.value === defaultValue)}
        max={options.length - 1}
        displayValue={name}
        onChange={onChange}
        {...rest}
      />
    </>
  )
}

SliderSelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
}

export {
  SliderSelectInput
}
