// Docs for DayJS: https://day.js.org
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import dateIsBetween from 'dayjs/plugin/isBetween'
import dateUtc from 'dayjs/plugin/utc'

dayjs.extend(dateIsBetween)
dayjs.extend(relativeTime)
dayjs.extend(dateUtc)

const formatTime = (value, { format = 'HH:mm', utc = false } = {}) => {
  if (!value) return null

  return utc ? dayjs(value).utc().format(format) : dayjs(value).format(format)
}

const formatDate = (value, { format = 'YYYY-MM-DD', utc = false } = {}) => (
  formatTime(value, { format, utc })
)

const formatDateTime = (value, { format = 'YYYY-MM-DD HH:mm', utc = false } = {}) => (
  formatTime(value, { format, utc })
)

const formatDateAsWords = (value) => (
  value ? dayjs(value).fromNow() : null
)

const formatDaysAsWords = (value) => {
  if (!value) return null
  const unit = value % 7 ? 'day' : 'week'
  const displayValue = unit === 'day' ? value : value / 7

  return `${displayValue} ${unit}${Number(displayValue) === 1 ? '' : 's'}`
}

const addDays = (value, days) => (
  value && days ? dayjs(value).add(days, 'day') : null
)

const subtractDays = (value, days) => (
  addDays(value, -days)
)

const isValidDate = (value) => (
  value ? dayjs(value).isValid() : null
)

const isBetween = (date, begin, end) => (
  date && begin ? dayjs(date).isBetween(begin, dayjs(end)) : null
)

const isAfter = (date1, date2) => dayjs(date1).isAfter(date2)

const diffDate = (date1, date2, distance = 'day') => (
  date1 && date2 ? dayjs(date2).diff(date1, distance) : null
)

const getDateRange = ({
  span, // String matching lastHour, today, yesterday, thisWeek, range
  range, // String for a time period, e.g. 10 minute, 2 hour, 5 day, 2 week
  datetime, // String, matching format, 'YYYY-MM-DD HH:mm:ss'
  direction, // String, values: 'after', 'before', or 'of'
  utc,
}) => {
  let value = []
  const dateParse = utc ? dayjs.utc : dayjs
  const now = dateParse(new Date())
  if (span === 'lastHour') {
    value = [now.subtract(1, 'hour'), now]
  } else if (span === 'today') {
    value = [now.startOf('day'), now]
  } else if (span === 'yesterday') {
    value = [now.subtract(1, 'day').startOf('day'), now.startOf('day')]
  } else if (span === 'thisWeek') {
    value = [now.startOf('week'), now]
  } else if (span === 'pastWeek') {
    value = [now.subtract(7, 'day').startOf('day'), now]
  } else if (span === 'lastWeek') {
    value = [now.subtract(2, 'week').startOf('day'), now.startOf('week')]
  } else if (span === 'pastTwoWeeks') {
    value = [now.subtract(14, 'day').startOf('day'), now]
  } else if (span === 'thisMonth') {
    value = [now.startOf('month'), now]
  } else if (span === 'pastMonth') {
    value = [now.subtract(30, 'day').startOf('day'), now]
  } else if (span === 'lastMonth') {
    value = [now.subtract(2, 'month').startOf('day'), now.startOf('month')]
  } else if (span === 'pastTwoMonths') {
    value = [now.subtract(60, 'day').startOf('day'), now]
  } else if (span === 'range') {
    const from = dateParse(datetime, 'YYYY-MM-DD HH:mm:ss')
    if (direction === 'after') {
      value = [from, from.add(...range.split(' '))]
    } else if (direction === 'before') {
      value = [from.subtract(...range.split(' ')), from]
    } else if (direction === 'of') {
      value = [from.subtract(...range.split(' ')), from.add(...range.split(' '))]
    }
  }
  return value
}

// Add or subtract business days from a date, requires a UTC adjusted date
const adjustByWeekDays = ({ date: startDate, days, mode }) => {
  // move to start of given day
  const date = dayjs(startDate).startOf('day')

  // Day of the week: 0 - 6
  const day = date.day()

  // Use mode to determine whether to add or subtract
  const adjust = (d, size) => d[mode](size, 'day')

  // When adding, weekends begin on Saturday (6)
  // When subtracting, Sunday (0)
  const startOfWeekend = mode === 'add' ? 6 : 0

  // If add mode, calculate remaining days before weekend by subtracting from 5
  // for example Monday == 1, so 5 - 1 == 4 days until weekend
  // When subtracting, days until weekend are calculated in reverse, so day of week can be used
  // since Monday is 1 day from the weekend (sunday)
  const weekSize = mode === 'add' ? 5 - day : day

  // If first day of a weekend, adjust by one day, and recalculate
  if (day === startOfWeekend) return adjustByWeekDays({ date: adjust(date, 1), days, mode })

  // If days fit into current week, business days are a simple adjustment
  if (days < weekSize) return adjust(date, days)

  // If current week is not a full 5 day week, add remaining days + weekend
  // This moves us into the next week to do simpler calculations
  if (weekSize !== 5) {
    const nextFullWeek = adjust(date, weekSize + 2)
    const newLength = days - weekSize
    return adjustByWeekDays({ date: nextFullWeek, days: newLength, mode })
  }

  // From here we're on the first day of a week
  // If adding, it's a Monday. if subtracting, we count backwards from Friday
  // leftOver tracks any partial week at the end
  const leftOver = days % 5

  // Get the number of full weeks
  const weeks = (days - leftOver) / 5

  // Adjusting weekx * 7 lets us add weekends, adding leftoer gives us the last partial week
  return adjust(date, (weeks * 7) + leftOver)
}

const addWeekDays = (date, days) => adjustByWeekDays({ date, days, mode: 'add' })
const subtractWeekDays = (date, days) => adjustByWeekDays({ date, days, mode: 'subtract' })

const weekdaysBetween = (startDate, endDate) => {
  if (!startDate || !endDate) return null
  // move to start of given day
  const start = dayjs(startDate).startOf('day')
  const end = dayjs(endDate).startOf('day')
  if (end < start) return 0 - weekdaysBetween(end, start)
  const daysBetween = diffDate(start, end)

  // Day of the week: 0 - 6
  const day = start.day()

  // Start counting on a monday.
  if (day === 0) return weekdaysBetween(start.add(1, 'day'), end)
  if (day === 6) return weekdaysBetween(start.add(2, 'day'), end)

  // days left in week
  const weekSize = 6 - day

  // If not a full week remaining
  if (daysBetween < weekSize) return daysBetween

  return weekSize + weekdaysBetween(start.add(weekSize + 2, 'day'), end)
}

const calculateDaysOfFloat = (startDate, dueDate, leadTime) => {
  if (startDate === null || dueDate === null || leadTime === null) return null
  const weekdaysAllowed = weekdaysBetween(startDate, dueDate)
  const weekdaysElapsed = weekdaysBetween(startDate, new Date())
  return weekdaysAllowed - Math.max(weekdaysElapsed, leadTime)
}

const timeAgo = (date, options = {}) => {
  const {
    suffix = false,
    long = false,
  } = options
  let str = dayjs(date).fromNow(suffix)
  if (!long) {
    str = str
      .replace(/an hour/, '1h')
      .replace(/ hours/, 'h')
      .replace(/a minute/, '1m')
      .replace(/ minutes/, 'm')
      .replace(/a day/, '1d')
      .replace(/ days?/, 'd')
      .replace(/a few seconds ago/, 'just now')
  }
  return str
}
const timeUntil = (date, suffix = false) => dayjs().to(date, suffix)

const timeAgoOrDate = (value, options = {}) => {
  const {
    unit = 'hours',
    limit = 24,
    format = 'YYYY/MM/DD • hh:mm A',
    long = false,
  } = options
  return dayjs(value).diff(dayjs(), unit) > limit
    ? timeAgo(value, { long })
    : formatDate(value, { format })
}

const dateTimePattern = ''
// YYYY/MM/DD
+ '\\d{4}\\/\\d{2}\\/\\d{1,2}'
// optional HH:mm (:ss)
+ '(\\s+\\d{1,2}:\\d{2}(:\\d{2})?)?'
// optional AM/PM (am/pm)
+ '(\\s([aA]|[pP])[mM])?'
//  optional timezone (+-HH:mm)
+ '(\\s[+\\-]\\d{1,2}(:\\d{2})?)?'

const serverTimePattern = ''
+ '\\d{4}-\\d{2}-\\d{1,2}' // YYYY-MM-DD
+ 'T\\d{2}:\\d{2}:\\d{2}' // THH:mm:ss
+ '\\.\\d+Z' // miliseconds, timezone

export {
  addDays,
  diffDate,
  subtractDays,
  formatDate,
  formatTime,
  formatDateTime,
  formatDateAsWords,
  formatDaysAsWords,
  getDateRange,
  isValidDate,
  isBetween,
  isAfter,
  addWeekDays,
  subtractWeekDays,
  weekdaysBetween,
  calculateDaysOfFloat,
  timeAgo,
  timeAgoOrDate,
  timeUntil,
  dateTimePattern,
  serverTimePattern,
}
