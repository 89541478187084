import {
  Card, Form, Stack, SubmitButton, Text,
  ButtonShelf,
  pluralize,
} from '@level'

import { useInvitations, InvitationInputs } from '../invitations'

const AccountInvitations = () => {
  const { invitations, onSubmit } = useInvitations()
  return (
    <Stack gap={6}>
      <Text tag="h3">Team {pluralize('Invitation', invitations)}</Text>
      <Card>
        <Form onSubmit={onSubmit}>
          <Stack gap={8}>
            <Text tag="h4">You have been invited to join {pluralize('a team', `${invitations.length} Teams`, invitations)} on Enveloop.</Text>
            <InvitationInputs invitations={invitations} />
            <ButtonShelf>
              <SubmitButton text="Confirm" />
            </ButtonShelf>
          </Stack>
        </Form>
      </Card>
    </Stack>
  )
}

export {
  AccountInvitations,
}
