import { Route, Routes } from 'react-router-dom'
import { TemplateConfigDrawer } from './Drawer'
import { DrawerMenu } from './DrawerMenu'
import { ElementConfig } from './Element'
import { ConfigureSection } from './Section'
import { ConfigureTheme } from './theme'
import { TemplateConfigProvider, useTemplateConfig } from './useTemplateConfig'

const TemplateConfigIndex = () => {
  const {
    showDrawerMenu, config, theme, updateTemplate,
  } = useTemplateConfig()
  if (showDrawerMenu) {
    return (<DrawerMenu />)
  }

  return (
    <Routes>
      <Route index element={<TemplateConfigDrawer />} />
      <Route path="theme" element={<ConfigureTheme theme={theme} config={config} onSubmit={updateTemplate} />} />
      <Route path="section/:sectionId/*" element={<ConfigureSection />} />
      <Route path="element/:elementId" element={<ElementConfig />} />
    </Routes>
  )
}

export * from './Config'
export { ElementConfig } from './Element'
export { SectionConfigForm } from './Section'
export {
  DrawerMenu, TemplateConfigIndex,
  TemplateConfigProvider,
  useTemplateConfig
}

