import React from 'react'
import PropTypes from 'prop-types'

import {
  useFormQuery, useProject,
  useUpdateProjectQuery,
} from '@app/hooks'

import { useParams } from 'react-router-dom'
import {
  Breadcrumbs, Card, CardHeader, CardSection, Stack, Divider,
  HiddenInput, Input, Select, Form, ButtonShelf, Button, SubmitButton,
  camelToTitle, capitalize, Text, Icon, Link,
  useSearchState,
} from '@level'

import {
  providerLabels,
  providerDocs,
  providerConfigs,
} from './providers/helpers'

import { ProviderDefaultFromInput } from './fromInput'
import { useDeleteIntegration } from './delete'

const ProviderInputs = ({ type, provider, deleteIntegration }) => {
  const { Project } = useProject()

  const activeFields = providerConfigs[type][provider]
  const providerName = providerLabels[provider]

  return (
    <>
      <CardHeader space={8}>
        <Stack gap={7}>
          <Stack gap={7} align="center">
            <Icon name={`integration-${provider}`} size={7} />
            <Text tag="h4">Configure {providerName} for {Project.name}</Text>
          </Stack>
          <Text>
            If you need help configuring {providerName}, <Link target="_blank" to={providerDocs[provider]}>check out our documentation</Link>.
          </Text>
        </Stack>
      </CardHeader>
      <CardSection space={7}>
        <Stack gap={5}>
          {Object.entries(activeFields).map(([name]) => {
            const config = activeFields[name]
            const props = {
              name: `${capitalize(type)}.config.${name}`,
              label: camelToTitle(name),
              ...config,
            }

            return config.options
              ? <Select key={name} {...props} />
              : <Input key={name} {...props} />
          })}
          <ProviderDefaultFromInput type={type} />
        </Stack>
      </CardSection>
      <Divider />
      <CardSection space={7}>
        <ButtonShelf cancelTo="../">
          <SubmitButton text="Save Integration" />
          {deleteIntegration ? <Button text="Delete" theme="destructive" onClick={deleteIntegration} /> : null}
        </ButtonShelf>
      </CardSection>
    </>
  )
}

ProviderInputs.propTypes = {
  type: PropTypes.string.isRequired,
  provider: PropTypes.string,
  deleteIntegration: PropTypes.func,
}

const ConfigureIntegration = () => {
  const { Project, refresh } = useProject()
  const updateProjectQuery = useUpdateProjectQuery()
  const { type } = useParams()
  const [{ provider }] = useSearchState()

  const { onSubmit } = useFormQuery({
    query: updateProjectQuery,
    // Refresh project list to update integration data
    onSuccess: () => refresh(),
    successMessage: 'Configured Integration',
    redirect: () => '../',
  })

  const confirmDelete = useDeleteIntegration()
  const deleteIntegration = () => {
    confirmDelete({ name: providerLabels[provider], type: capitalize(type) })
  }

  const values = Project[type] || {}
  delete values.id

  const defaultValues = { [capitalize(type)]: { provider, ...values } }

  return (
    <Stack gap={6}>
      <Breadcrumbs crumbs={[{ to: '../../', text: Project.name }, { to: '../', text: 'Integrations' }]}>
        Configure {camelToTitle(type)}
      </Breadcrumbs>
      <Card space={0} style={{ maxWidth: '600px', margin: '0 auto' }}>
        <Form onSubmit={onSubmit} defaultValues={defaultValues} focus="first">
          <HiddenInput name={`${capitalize(type)}.provider`} />
          <ProviderInputs
            deleteIntegration={Project[type] ? deleteIntegration : null}
            provider={values?.provider || provider}
            type={type}
          />
        </Form>
      </Card>
    </Stack>
  )
}

export {
  ConfigureIntegration,
}
