import PropTypes from 'prop-types'

export const filterPropTypes = {
  name: PropTypes.string.isRequired,
  operators: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string.isRequired,
    icon: PropTypes.node,
    filter: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      operator: PropTypes.string,
    })),
  })).isRequired,
}
