import React from 'react'
import PropTypes from 'prop-types'

import * as Ariakit from '@ariakit/react'

import { useMessages } from '../../hooks/useMessages'
import { Block } from '../Block'
import { SubmitButton, Button } from '../Button'
import { ButtonShelf } from '../ButtonShelf'
import { Stack } from '../Stack'
import { Divider } from '../Divider'
import { Form } from '../Form'
import { useMaxSize } from '../../hooks'

import './dialog.scss'

const DialogActions = ({
  destructive = false,
  onCancel,
  onConfirm,
  confirmText = 'Confirm',
  focusRef,
  cancelText,
}) => {
  const hasConfirm = !!onConfirm
  const confirmButton = (
    <SubmitButton
      theme={destructive ? 'primary-destructive' : 'primary'}
      text={confirmText}
      ref={focusRef}
    />
  )
  return (
    <ButtonShelf>
      {hasConfirm ? confirmButton : null}
      <Button
        // as={Ariakit.DialogDismiss}
        theme={hasConfirm ? 'default' : 'primary'}
        text={cancelText}
        onClick={onCancel}
        ref={!hasConfirm ? focusRef : null}
      />
    </ButtonShelf>
  )
}

DialogActions.propTypes = {
  onCancel: PropTypes.func.isRequired,
  focusRef: PropTypes.object,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onConfirm: PropTypes.func,
  destructive: PropTypes.bool,
}

const DialogContent = ({
  title,
  space = 8,
  actions,
  contentRef,
  footerRef,
  ...props
}) => {
  const content = (typeof props.content === 'function') ? <props.content /> : props.content
  const DialogActionsEl = actions || DialogActions

  return (
    <React.Fragment>
      {title ? <Ariakit.DialogHeading className="level-dialog-header">{title}</Ariakit.DialogHeading> : null}
      {title ? <Divider /> : null}
      <div ref={contentRef} style={{ overflowY: 'auto' }}>
        {content ? (
          <Stack space={space} gap={5}>
            {content}
          </Stack>
        ) : null}
      </div>
      <div className="level-dialog-actions" ref={footerRef}>
        <DialogActionsEl width='500px' {...props} />
      </div>
    </React.Fragment>
  )
}

DialogContent.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  width: PropTypes.string,
  space: Block.propTypes.space,
  dialogContainerRef: PropTypes.object.isRequired,
  actions: PropTypes.func,
  contentRef: PropTypes.object,
  footerRef: PropTypes.object,
}

const DialogBox = ({
  onConfirm,
  onCancel,
  width = '500px',
  height,
  form = {},
  element,
  style = {},
  debounceSubmit = false,
  dialog,
  dialogContainerRef,
  ...props
}) => {
  const focusRef = React.useRef()
  const onSubmit = ({ data, ...rest }) => (
    onConfirm({ data: { ...form.data, ...data }, hooks: form.hooks, ...rest })
  )
  const { footerRef, contentRef } = useMaxSize({ rootRef: dialogContainerRef, withinParent: true, limitHeight: true })
  const DialogContentEl = element || DialogContent
  return (
    <Ariakit.Dialog
      unmountOnHide
      store={dialog}
      backdrop={<div className="level-dialog-overlay" />}
      ref={dialogContainerRef}
      className="level-dialog-wrapper"
    >
      <div
        style={{
          width,
          height,
          ...style,
        }}
        className="level-dialog"
      >
        <Form focus="first" {...form}
          onSubmit={onSubmit} debounceSubmit={debounceSubmit}>
          <DialogContentEl
            {...{
              ...props,
              cancelText: onConfirm ? 'Cancel' : 'Got it',
              dialogContainerRef,
              focusRef,
              onConfirm,
              onCancel,
              contentRef,
              footerRef,
            }}
          />
        </Form>
      </div>
    </Ariakit.Dialog>
  )
}

DialogBox.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  dialog: PropTypes.object.isRequired,
  form: PropTypes.shape({
    data: PropTypes.object,
    hooks: PropTypes.object,
    ...Form.propTypes,
  }),
  debounceSubmit: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  style: PropTypes.object,
  width: PropTypes.string,
  element: PropTypes.func,
  height: PropTypes.string,
}

const Dialog = () => {
  const { subscribe, clear } = useMessages({ channel: 'dialog' })
  const data = subscribe() || null
  const dialog = Ariakit.useDialogStore()

  const {
    onConfirm,
    onCancel,
    onClose,
    onNext,
  } = data || {}
  const dialogContainerRef = React.useRef()

  React.useEffect(() => {
    if (data) dialog.show()
  }, [data, dialog])

  const close = (options = {}) => {
    const { timeout, ...props } = options
    // TODO: Get closing animation to work again so I don't have to manually remove these
    delete dialogContainerRef.current.dataset.enter
    delete dialogContainerRef.current.previousElementSibling.dataset.enter
    return new Promise((resolve) => {
      window.setTimeout(async () => {
        dialog.hide()
        clear()
        if (typeof onClose === 'function') await onClose(props)
        resolve()
      }, timeout || 300)
    })
  }

  const cancel = async ({ timeout, ...props }) => {
    if (typeof onCancel === 'function') await onCancel(props)
    return close({ cancel: props, timeout })
  }

  const confirm = onConfirm || onNext ? async ({ timeout, ...props }) => {
    try {
      if (typeof onConfirm === 'function') await onConfirm(props)
      if (typeof onNext === 'function') {
        await onNext(props)
      } else {
        close({ confirm: props, timeout })
      }
    } catch { (() => null)() }
  } : null

  return data ? (
    <DialogBox
      {...data}
      onCancel={cancel}
      onConfirm={confirm}
      confirm={confirm}
      close={close}
      dialog={dialog}
      dialogContainerRef={dialogContainerRef}
    />
  ) : null
}

export {
  Dialog,
}
