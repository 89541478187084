const webhookExample = (opts = {}) => {
  const { event = 'delivered', type = 'email' } = opts
  return JSON.stringify({
    event,
    at: new Date().toISOString(),
    messageType: type,
    messageId: '18v01-19c910-19t1cu-1kjv810',
    to: 'recipient@email.com',
    from: 'sender@email.com',
    data: '{ /* specific data for this event */ }',
    body: '{ /* message content */ }',
  }, null, 2)
}

export {
  webhookExample,
}
