import { theme } from './theme'

const forgotPassword = {
  name: 'Forgot Password',
  config: ({ color, Team = { name: 'CompanyName' } }) => {
    const {
      colors: {
        heading,
        textSubtle,
        templateBg,
        border,
        textFooter,
      },
      variables,
    } = theme({ color, defaultSubject: 'Password Reset Requested' })

    return {
      variables,
      sections: [
        {
          sharedSectionId: null,
          variables: {
            config: {
              align: 'left',
              valign: 'top',
              padding: '30px 40px 30px 40px',
              elementSpacing: '15px',
              name: 'Header',
            },
            columns: [
              {
                config: {},
                elements: [
                  {
                    type: 'Text',
                    config: {
                      content: Team.name,
                      color: heading,
                      padding: '0 0 0 0',
                      fontSize: '16px',
                      fontWeight: '600',
                    },
                  },
                ],
              },
            ],
          },
        },
        {
          sharedSectionId: null,
          variables: {
            config: {
              name: 'Content',
              align: 'left',
              valign: 'top',
              padding: '25px 40px 25px 40px',
              elementSpacing: '20px',
            },
            columns: [
              {
                config: {},
                elements: [
                  {
                    type: 'Heading',
                    config: {
                      padding: '0 0 0 0',
                      fontSize: '30px',
                      fontWeight: 600,
                      text: 'Forgot your password?',
                      color: heading,
                      align: 'left',
                    },
                  },
                  {
                    type: 'Text',
                    config: {
                      padding: '0 0 0 0',
                      fontSize: '14px',
                      fontWeight: 400,
                      content:
                        'That\'s okay! We know it happens. Click the button below to reset your password and we\'ll have you up and running in no time.',
                    },
                  },
                  {
                    type: 'Button',
                    config: {
                      minWidth: '0%',
                      buttonPadding: '10px 25px 10px 25px',
                      padding: '15px 0px 15px 0px',
                      fontSize: '12px',
                      fontWeight: 600,
                      cornerStyle: 'rounded',
                      text: 'Reset Password',
                      url: '{{{reset_url}}}',
                    },
                  },
                  {
                    type: 'Text',
                    config: {
                      content:
                        'If this was a mistake, just ignore this email and nothing will happen.',
                      padding: '15px 0px 15px 0px',
                      fontSize: '14px',
                      fontWeight: 400,
                      color: textSubtle,
                    },
                  },
                  {
                    type: 'Divider',
                    config: {
                      color: border,
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      padding: '15px 0px 15px 0px',
                    },
                  },
                  {
                    type: 'Text',
                    config: {
                      content:
                        'If you have trouble with the button above, copy and paste the URL below into your web browser.\n\n{{reset_url}}',
                      color: textFooter,
                      align: 'center',
                      padding: '15px 0px 15px 0px',
                      fontSize: '10px',
                      fontWeight: 400,
                    },
                  },
                ],
              },
            ],
          },
        },
        {
          sharedSectionId: null,
          variables: {
            config: {
              align: 'left',
              valign: 'top',
              padding: '15px 0px 15px 0px',
              elementSpacing: '15px',
              backgroundColor: templateBg,
              name: 'Footer',
            },
            columns: [
              {
                config: {},
                elements: [
                  {
                    type: 'Text',
                    config: {
                      content:
                        `© Copyright 2022 ${Team.name}. All Rights Reserved.`,
                      color: textFooter,
                      padding: '25px 0px 25px 0px',
                      fontSize: '10px',
                      fontWeight: '400',
                    },
                  },
                ],
              },
            ],
          },
        },
      ],
    }
  },
}

export { forgotPassword }
