import { getQueryFilter } from '@level/helpers'

export const operators = {
  error: 'substring',
  sdkVersion: 'substring',
  createdAt: 'between',
}

export const errorsQuery = ({
  projectId,
  teamId,
  testMode = true,
  createdAt,
  ...rest
}) => {
  const params = rest
  // For ease, createdAt is stored in params in unix time. Here we canovert to the required Date
  if (createdAt) params.createdAt = createdAt.split(',').map((d) => new Date(Number.parseInt(d, 10))).join(',')
  const filter = getQueryFilter({ params, operators, keys: [...Object.keys(operators), 'date'] })
  return `{
    errorLogs(team: "${teamId}", project: "${projectId}", testMode: ${testMode}, ${filter}) {
      body rawBody createdAt errorMessage errorCode errorDescription path sdkVersion testMode
    }
  }`
}
