import PropTypes from 'prop-types'
import { useParams, } from 'react-router-dom'
import { MenuButton } from '../MenuButton'

const SelectNav = ({
  items,
  noneSelected = 'Select one',
  currentItem,
  param,
  ...rest
}) => {
  const slug = useParams()[param]
  const currentUrl = slug && items.find(({ matchParam }) => matchParam === slug)

  return (
    <MenuButton
      // Filter out matchParam from items
      items={items.map(({ matchParam, ...rest }) => rest)}
      iconAfter="angle-up-and-down"
      className="level-select-nav-button"
      {...rest}
    >
      {currentItem || currentUrl?.children || currentUrl?.text || noneSelected}
    </MenuButton>
  )
}

SelectNav.propTypes = {
  items: PropTypes.array.isRequired,
  param: PropTypes.string.isRequired,
  noneSelected: PropTypes.string,
  currentItem: PropTypes.any,
}

export {
  SelectNav
}
