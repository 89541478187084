import { rootUrl } from '@app/helpers'
import {
  Banner,
  Button,
  Loading,
  Shelf,
  Spacer,
  Text,
} from '@level'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useAuth } from './Auth'

// ActAsUser at /as/user@domain.com/optional/redirect/path
// - /as/user@domain.com/ -> enables and redirects to: '/'
// - /as/user@domain.com/projects/1 -> enables and redirects to: '/projects/1'
const actAsUserRoute = 'as/*'

const useAsUser = () => {
  const {
    asUser,
    setAsUser,
  } = useAuth()

  return {
    asUser,
    setAsUser,
  }
}

// Process route params and set context state accordingly
const ActAsUser = () => {
  const { setAsUser } = useAsUser()
  const params = useParams()
  const { search } = window.location

  // from /as/:email/* the '*' gets stored as params[0], this is our redirect url
  const {
    // the whole match
    0: full,
    // the match before the / or full if there is no /
    1: email = full,
    // The part after the / or undefined if there is no /
    2: url,
  } = params['*'].match(/(.+?)\/(.*)|(.+)/)

  // Set ActingAs state and store in local storage
  React.useEffect(() => {
    setAsUser(email === 'me' ? null : email)
    window.location = rootUrl(`${url || ''}${search || ''}`)
  }, [email])

  return <Loading text="Redirecting" />
}

// Make it easy to disable acting as user with a link
const AsUserBanner = () => {
  const { asUser } = useAsUser()

  return asUser ? (
    <Banner color="aqua-50" border="aqua-900" background="aqua-800">
      <Shelf valign="center">
        <Button theme="text" to={rootUrl('/as/me')} label="End ActingAs" icon="power-off" />
        <Spacer space={4} />
        <Text>Acting as <strong>{asUser}</strong></Text>
      </Shelf>
    </Banner>
  ) : null
}

export {
  ActAsUser, actAsUserRoute, AsUserBanner, useAsUser
}
