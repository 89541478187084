import React from 'react'

import {
  ProjectsProvider,
  TeamProvider,
  AsUserBanner,
  useTeam,
} from '@app/hooks'
import { Sidebar, Layout, Icon } from '@level'
import { ProjectsNav } from '../projects/nav'
import { TeamNav } from './nav'
import { AccountNav } from '../account/nav'

const TeamSidebar = () => {
  const { Team: { disabled } } = useTeam()
  return (
    <Sidebar
      header={(
        <>
          <Icon
            name="enveloop-logomark"
            className="level-sidebar-logo"
            fill="none"
          />
          <TeamNav />
        </>
      )}
      footer={<AccountNav />}
    >
      {/* Disabled teams cannot view their projects navigation */}
      { disabled ? null : <ProjectsNav /> }
    </Sidebar>
  )
}

const TeamLayout = (props) => (
  <TeamProvider>
    <ProjectsProvider>
      <Layout
        root={<AsUserBanner />}
        sidebar={<TeamSidebar />}
        {...props}
      />
    </ProjectsProvider>
  </TeamProvider>
)

export {
  TeamLayout,
}
