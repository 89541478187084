import PropTypes from 'prop-types'
import { renderMarkdownToReact } from '../../../../helpers'
import { EditInPlace } from './EditInPlace'
import { ElementWrapper, elementStyle, } from './helpers'

const Text = (props) => {
  const {
    sharedSectionId,
    varPath,
    element: { config },
    mode,
  } = props

  // extract content or use placeholder text
  let textContent = `{{{ ${varPath}.content }}}`
  if (mode.includes('builder')) textContent = renderMarkdownToReact(config.content, { type: 'content' })

  const style = elementStyle(props, Text.config)

  return (
    <ElementWrapper {...props} style={style}>
      {!sharedSectionId ? (
        <EditInPlace
          color={style.color}
          configVar="content"
          placeholder="Marzipan caramels sugar plum marzipan croissant cotton candy. Bonbon powder powder ice cream dessert dragée fruitcake halvah. Fruitcake chocolate bar gingerbread lollipop jelly-o. Cupcake candy gingerbread muffin pie. Candy brownie soufflé cotton candy brownie sugar plum dragée halvah."
          {...props}
        >{textContent}
        </EditInPlace>
      )
        : textContent}
    </ElementWrapper>
  )
}

Text.propTypes = {
  ...ElementWrapper.propTypes,
  varPath: PropTypes.string.isRequired,
}

// Default inline styling goes here
Text.type = 'Text'

// User configurable variables
Text.config = {
  content: null,
  color: null,
  linkColor: null,
  align: null,
  padding: '0 0 0 0',
  fontSize: '12px',
  lineHeight: '120%',
  fontFamily: null,
  fontWeight: 400,
  backgroundColor: null,
}

Text.configLayout = [
  'color',
  'backgroundColor',
  'linkColor',
  'fontSize',
  'fontWeight',
  'lineHeight',
  'fontFamily',
  '---',
  'align',
  'padding',
]

export { Text }
