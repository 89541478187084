import React from 'react'
import PropTypes from 'prop-types'

import { useTeam, useProfile, useMailSettings } from '@app/hooks'
import {
  Stack, Nav, NavList, sentenceJoin,
} from '@level'

import { TeamSwitcher } from './switcher'
import { InvitationsNotice } from '../invitations'

const TeamNav = () => {
  const { Team, isAdmin, url } = useTeam()
  const { invitations, isEnvelooper } = useProfile()
  const { data } = useMailSettings()
  const root = url()

  const navIssue = React.useMemo(() => {
    if (data) {
      if (data?.issues?.length) {
        return {
          label: `Setup incomplete: ${sentenceJoin(data.issues.map(({ message }) => message))}.`,
          icon: { name: 'exclamation-triangle', color: 'yellow-700' },
        }
      }
      if (!data?.hasMailProvider) {
        return {
          label: 'Configure mail settings to send email from your domain or email address.',
          icon: { name: 'circle-small', color: 'blue-500' },
        }
      }
    }
    return null
  }, [data?.issues])

  const items = React.useMemo(() => {
    const settingsNav = { text: 'Team Settings', to: `${root}/settings`, icon: 'gear' }
    // Disabled teams can only access the team settings page
    if (Team.disabled) return [settingsNav]
    const nav = [
      { text: 'Dashboard', to: `${root}/dashboard`, icon: 'tachometer-alt-average' },
      {
        text: 'Mail Settings',
        to: `${root}/mail-settings`,
        icon: 'envelope-gear',
        iconAfter: navIssue ? navIssue.icon : null,
        label: navIssue?.label || null,
      },
      settingsNav,
      {
        text: 'Monitoring',
        to: `${root}/monitoring`,
        icon: 'wave-pulse',
      }
    ]
    return nav
  }, [navIssue, root, isAdmin])

  return (
    <Nav>
      <Stack gap={6}>
        {invitations?.length ? <InvitationsNotice /> : null}
        <TeamSwitcher />
        <NavList theme="sidebar-nav" navItems={items} />
      </Stack>
    </Nav>
  )
}

export {
  TeamNav,
}
