import React from 'react'
import PropTypes from 'prop-types'

import {
  Block, Icon, Grid, Card, Stack, Shelf, Text, Button, CopyButton,
  toColor,
} from '@level'

const DedicatedIp = ({ dedicatedIp }) => {
  if (!dedicatedIp) return null
  const [expanded, setExpanded] = React.useState(false)
  return (
    <Stack gap={5} space={5}>
      <Shelf align="split" nowrap>
        <Stack gap={4}>
          <Text
            icon={{
              name: 'circle-check', color: 'green-700', size: 3, gap: 4,
            }}
            tag="h4"
          >
            Dedicated IP
          </Text>
          <Text tag="p">This Sender ID has been configured to use a Dedicated IP</Text>
        </Stack>
        <Button label="View Config" theme="ghost" onClick={() => setExpanded((e) => !e)} icon={expanded ? 'angle-down' : 'angle-left'} />
      </Shelf>
      {expanded ? (
        <Card space={5}>
          <Grid templateColumns="auto 1fr auto" gap={5}>
            <Icon name="us-flag" width="31px" />
            <Stack>
              <Text tag="strong" color="neutral-700">United States</Text>
              <Text size={1} color="text-light">{dedicatedIp}</Text>
            </Stack>
            <CopyButton text={dedicatedIp} />
          </Grid>
        </Card>
      ) : null}
    </Stack>
  )
}

const DedicatedIpBadge = ({ dedicatedIp }) => {
  if (!dedicatedIp) return null
  return (
    <Block style={{ backgroundColor: toColor('green-25'), borderRadius: '4px' }} space={[1, 4]}>
      <Text
        size={1}
        color="green-500"
        icon={{
          name: 'globe',
          color: 'green-600',
        }}
      >
        Dedicated IP
      </Text>
    </Block>
  )
}

DedicatedIp.propTypes = {
  type: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
}

export {
  DedicatedIp,
  DedicatedIpBadge,
}
