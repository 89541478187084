import { toSearchString, useToast } from '@level'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useUploadFile } from './useUploadFile'

const useUploadImage = (opts = {}) => {
  const { query } = opts
  const { teamId, projectId } = useParams()
  const { sendNotice } = useToast()

  let url = `${import.meta.env.VITE_API_BASE_URL}/teams/${teamId}/projects/${projectId}/uploadImage`
  if (query) url = `${url}?${toSearchString(query)}`

  const uploadImage = useUploadFile({ url })

  const upload = React.useCallback(async (...args) => {
    const response = await uploadImage(...args)
    if (response.convertedFrom) {
      sendNotice({ title: 'Image Converted', message: `For broader compatability your image was converted from ${response.convertedFrom} to png` })
    }
    return response
  }, [url])

  return upload
}

export {
  useUploadImage
}
