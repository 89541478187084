import React from 'react'
import Proptypes from 'prop-types'

import {
  Main, Text, Button, Stack, BlankSlate,
} from '@level'

const verifyTitle = (success) => `Identity ${success ? '' : 'could not be'} verified`

const verifyBody = (success) => {
  if (success) {
    return 'You may now send mail through Enveloop using this address.'
  }
  return 'Unfortunately your address could not be verified.'
}

const VerifyIdentity = ({ success }) => (
  <Main>
    <BlankSlate icon="enveloop-logomark">
      <Stack gap={10} align="center">
        <Stack gap={5} align="center">
          <Text tag="h2">{verifyTitle(success)}</Text>
          <Text tag="p">{verifyBody(success)}</Text>
        </Stack>
        <Button onClick={window.close} text="Close Window" />
      </Stack>
    </BlankSlate>
  </Main>
)

VerifyIdentity.propTypes = {
  success: Proptypes.bool,
}

export { VerifyIdentity }
