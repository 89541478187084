import { useResolvedPath, matchPath } from 'react-router-dom'
import { getTemplateSection, getTemplateElement } from '../helpers'

const getTemplatePath = ({ root }) => {
  const elementPath = `${root}element/:elementId`
  const elementMatch = matchPath(elementPath, window.location.pathname)
  const sectionPath = `${root}section/:sectionId/*`
  const sectionMatch = matchPath(sectionPath, window.location.pathname)

  const sectionUrl = sectionMatch?.pathnameBase
  const elementUrl = elementMatch?.pathname

  if (elementMatch) {
    return {
      root,
      ...elementMatch.params,
      elementUrl,
    }
  }

  if (sectionMatch) {
    return {
      root,
      ...sectionMatch.params,
      sectionUrl,
    }
  }

  return { root }
}

const useTemplateRoot = (options = {}) => {
  const { root: templateRoot = './' } = options
  const { pathname: root } = useResolvedPath(templateRoot)
  return root
}

// Derive the state of the section & element navigation from the path
const useTemplatePath = (options = {}) => {
  const root = useTemplateRoot(options)
  return getTemplatePath({ root })
}

const getTemplateState = (options = {}) => {
  const { root, config } = options
  const path = getTemplatePath({ root })
  const { elementId, sectionId } = path
  if (elementId && config) {
    const el = getTemplateElement({ config, elementId })
    return {
      ...path,
      ...el,
      sectionUrl: `${root}/section/${el.sectionId}`,
    }
  }
  if (sectionId && config) {
    return {
      ...path,
      ...getTemplateSection({ config, sectionId }),
    }
  }
  return path
}

const useTemplateState = (options = {}) => {
  const { config } = options
  const root = useTemplateRoot(options)
  return getTemplateState({ root, config })
}

export {
  useTemplateRoot,
  useTemplatePath,
  useTemplateState,
  getTemplatePath,
  getTemplateState,
}
