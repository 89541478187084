import { marked } from 'marked'
import { renderString } from './react'

// Marked options: https://marked.js.org/using_advanced#options
marked.setOptions({
  // Replace single newlines with `<br>`s.
  breaks: true,
})

// Clears margins on first and last paragraphs
const removeMarkdownMargins = (input) => (
  // replace last paragraph with margin-bottom: 0
  input.replace(/<p>(?![^\0]*<p>)/g, '<p style="margin-bottom: 0">')
    // If the first paragraph already has a style, keep it when replacing
    .replace(/^<p style="(.+?)"/, '<p style="$1; margin-top: 0"')
    // If the first paragraph doesn't have a style
    .replace(/^<p>/, '<p style="margin-top: 0">')
)

const renderMarkdown = (input, options = { }) => {
  const { type, ...opts } = options
  if (!input || !['text', 'content'].includes(type)) return input
  let val = input.trim()
  if (type === 'text') {
    // replace all newlines with <br/>
    val = val.replace(/\n/g, '<br/>')
    val = marked.parseInline(val, { ...opts, gfm: false })
  } else if (type === 'content') {
    val = val.trim()
    val = marked.parse(val, opts).trim()
    val = removeMarkdownMargins(val)
  }
  // Replace escaped braces, this occurs when markdown includes mustache vars.
  // Preventing this: [some link]({{{ url }}}) -> [some link](%7b%7b%7b url %7d%7d%7d)
  return val.replaceAll('%7B', '{').replaceAll('%7D', '}')
}

const renderMarkdownToReact = (input, options) => (
  renderString(renderMarkdown(input, options)) || ''
)

export {
  renderMarkdown,
  renderMarkdownToReact,
}
