import React from 'react'
import { uuid } from '../helpers/text'

const Dependencies = []

// This will generate a unique ID the first time it is called
// and then generate a new one, only if the dependencies change
const useUuid = (dependencies = Dependencies) => {
  // initialize to null
  const set = React.useRef(null)
  const id = React.useRef(uuid())

  React.useEffect(() => {
    // If a dependency changes, this will be rendering a second time
    // and id will not be null, so it will generate a new UUID
    if (set.current) id.current = uuid()
    set.current = true
  }, dependencies)

  return id.current
}

export { useUuid }
