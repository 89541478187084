import PropTypes from 'prop-types'

import { Link } from '../Link'
import { Shelf } from '../Shelf'
import { Stack } from '../Stack'
import { Text } from '../Text'

const PhoneContact = ({ photo, number, changeFrom }) => {
  const icon = !number ? { name: 'exclamation-triangle', color: 'yellow-600' } : null
  const numberContent = (
    <Text icon={icon}>
      <Shelf gap={3}>
        From:
        {number ? (
          <Text className="level-phone-contact-number-value">{number}</Text>
        ) : (
          <Text className="level-phone-contact-number-value" data-placeholder>+X XXX XXX-XXXX</Text>

        )}
      </Shelf>
    </Text>
  )
  return (
    <div className="level-phone-contact-wrapper">
      <Stack className="level-phone-contact" gap={3}>
        <div className="level-phone-contact-photo">
          {photo}
        </div>
        {changeFrom ? (
          <Link
            onClick={changeFrom}
            label="Add from number"
            theme="plain"
            className="level-phone-contact-number"
            data-empty={!number}
          >
            {numberContent}
          </Link>
        ) : <div className="level-phone-contact-number">{numberContent}</div>}
      </Stack>
    </div>
  )
}

PhoneContact.propTypes = {
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  photo: PropTypes.any,
  changeFrom: PropTypes.func,
}

export {
  PhoneContact
}
