import { Divider, Heading, Image, Text } from '../Element'
import { newSectionConfig } from './helpers'

const DividedSection = newSectionConfig(
  'Divided Section',
  ({ addColumn }) => {
    const column = addColumn()
    column.addElement(Divider)
    column.addElement(Heading)
    column.addElement(Text)
  },
)

const ImageFeatureColumns = newSectionConfig(
  'Image Feature Columns',
  ({ addColumn }) => {
    const column = addColumn()
    column.addElement(Image)
    column.addElement(Heading)
    column.addElement(Text)
    const column2 = addColumn()
    column2.addElement(Image)
    column2.addElement(Heading)
    column2.addElement(Text)
  },
)

const ImageFeature = newSectionConfig(
  'Image Feature',
  ({ addColumn }) => {
    const column = addColumn()
    column.addElement(Image)
    column.addElement(Heading)
    column.addElement(Text)
  },
)

export default {
  DividedSection,
  ImageFeature,
  ImageFeatureColumns,
}
