// Defaults for the five different toast message types
//
// timeout: in ms, when a message should auto-dismiss
// replace: bool, should a message be dismissed when replaced?
const toastDefaults = {
  success: {
    title: 'Success',
    icon: 'check-circle',
    timeout: 2000,
  },
  warning: {
    title: 'Warning',
    icon: 'exclamation-triangle',
    timeout: 2000,
    replace: true,
  },
  error: {
    title: 'Error',
    icon: 'x-circle',
    timeout: 6000,
    replace: true,
  },
  systemUpdate: {
    title: 'System update',
    icon: 'lightbulb',
  },
  processing: {
    title: 'Processing',
    icon: 'ellipsis',
    replace: true,
    delay: 800,
  },
  notice: {
    title: 'Notice',
    icon: 'lightbulb',
    timeout: 3000,
  },
}

export {
  toastDefaults,
}
