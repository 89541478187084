import PropTypes from 'prop-types'
import React from 'react'
import { ScrollPanel } from '../ScrollPanel'
import { TableHead } from './Head'
import { LoadingRow, TableRow } from './Row'
import { RowSpacer } from './RowSpacer'

import './table.scss'

// Get table rows (or virtual table rows)
const tableRows = (table) => {
  const { rows } = table.getRowModel()
  if (table.virtualizer) {
    return table.virtualizer.getVirtualItems().map((virtual) => (
      rows[virtual.index]
    ))
  }
  return rows
}

const TableWrapper = ({
  table, limitHeight, rootRef, offsetSpace, ...props
}) => {
  if (table.virtualizer || limitHeight) {
    return (
      <ScrollPanel
        data-virtual={!!table.virtualizer}
        ref={table.containerRef}
        limitHeight={limitHeight}
        offsetSpace={offsetSpace}
        rootRef={rootRef}
        {...props}
      />
    )
  }
  return (
    <div {...props} />
  )
}

TableWrapper.propTypes = {
  limitHeight: PropTypes.bool,
  table: PropTypes.shape({
    containerRef: PropTypes.object,
    virtualizer: PropTypes.object,
  }).isRequired,
  offsetSpace: ScrollPanel.propTypes.offsetSpace,
  rootRef: ScrollPanel.propTypes.rootRef,
}

const Table = React.forwardRef(({
  table,
  className,
  style = {},
  theme,
  limitHeight,
  offsetSpace,
  rootRef,
  onScroll: onScrollProp,
  blankSlate,
  ...rest
}, ref) => {
  const rows = tableRows(table)
  const onScroll = onScrollProp ? (event) => {
    onScrollProp(event, table.virtualizer)
  } : null
  return (
    <TableWrapper
      table={table}
      className={`level-table-wrapper ${className || ''}`}
      data-theme={theme}
      limitHeight={limitHeight}
      offsetSpace={offsetSpace}
      rootRef={rootRef}
      onScroll={onScroll}
    >
      <table
        className="level-table"
        style={style}
        ref={ref}
        {...rest}
      >
        <TableHead headers={table.getHeaderGroups()} empty={!rows?.length} />
        <tbody className="level-table-body">
          <RowSpacer top virtualizer={table.virtualizer} />
          {rows.map((row, index) => (
            <TableRow
              key={row.id}
              row={row}
              index={index}
              selectRow={table.selectRow}
              nextSelected={rows[index + 1]?.getIsSelected() ? true : null}
            />
          ))}
          {rows?.length === 0 && blankSlate ? (
            <tr><td style={{ textAlign: 'center' }} colSpan={table.getVisibleFlatColumns().length}>{blankSlate}</td></tr>
          ) : null}
          <RowSpacer bottom virtualizer={table.virtualizer} />
          <LoadingRow table={table} />
        </tbody>
      </table>
    </TableWrapper>
  )
})

Table.propTypes = {
  table: PropTypes.shape({
    getHeaderGroups: PropTypes.func,
    getRowModel: PropTypes.func,
    containerRef: PropTypes.object,
    virtualizer: PropTypes.object,
  }).isRequired,
  className: PropTypes.string,
  theme: PropTypes.string,
  style: PropTypes.object,
  limitHeight: PropTypes.bool,
  offsetSpace: ScrollPanel.propTypes.offsetSpace,
  rootRef: ScrollPanel.propTypes.rootRef,
  blankSlate: PropTypes.node,
}

export {
  Table
}
