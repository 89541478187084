import React from 'react'

const useNetworkStatus = () => {
  const [status, setStatus] = React.useState({
    online: window.navigator.onLine || import.meta.env.VITE_ALLOW_OFFLINE,
  })

  const connect = React.useCallback(() => {
    setStatus({ online: true, connected: true, changed: {} })
  }, [])

  const disconnect = React.useCallback(() => {
    setStatus({ online: false, disconnected: true, changed: {} })
  }, [])

  React.useEffect(() => {
    if (import.meta.env.VITE_ALLOW_OFFLINE) return undefined
    window.addEventListener('online', connect)
    window.addEventListener('offline', disconnect)

    return () => {
      window.removeEventListener('online', connect)
      window.removeEventListener('offline', disconnect)
    }
  }, [])

  return status
}

export {
  useNetworkStatus,
}
