import { configDefaults } from './Config/defaults'
import { newSection } from './Section'
import { renderEmailTemplate } from './helpers'

const newEmailTemplate = (options = {}) => {
  const { sections = [], variables = {} } = options
  const defaults = configDefaults.template

  const templateVariables = {
    style: {
      ...defaults.style,
      ...variables.style,
    },
    config: {
      ...defaults.config,
      ...variables.config,
    },
    defaults: variables.defaults || {},
    _theme: variables._theme || defaults._theme,
  }

  const template = {
    variables: templateVariables,
    sections: sections.map((section) => (
      newSection({ section, templateVariables })
    )),
  }
  return { config: { ...template, body: renderEmailTemplate({ config: template }) } }
}

export {
  newEmailTemplate
}
