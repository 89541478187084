import React from 'react'
import { useParams } from 'react-router-dom'

import { useQuery } from '@app/hooks'

import {
  Main, Header, Body, Stack,
  Text, Grid, Card,
} from '@level'

import './dashboard.scss'
import { Usage, Insights } from './metrics'
import { Help } from './help'
import { Funnel } from './funnel'
import { Welcome } from './welcome'

const dashboardQuery = ({ teamId }) => `query {
  Team: team(team: "${teamId}") {
    name
    slug
    plan {
      id
      name
      basePrice
      includedMessages
      additionalMessages
      pricingTiers
      watermark
      public
      allowSms
    }
    actionProjectCreatedAt
    actionTemplateCreatedAt
    actionTemplatePublishedAt
    actionTestMessageSentAt
    actionMessageSentAt
    actionProviderAddedAt
    funnelCompleted
  }
  Projects: projects(team: "${teamId}") {
    slug
    name
    createdAt
  }
  insights: dashboard(team: "${teamId}") {
    projectCount
    activeTemplateCount
    emailCount
    errorCount
    errorRatio
    planUsage {
      messagesIncluded
      messagesUsed
      historicalUsage {
        emailsUsed
        messagesUsed
        smsUsed
        startOfMonth
      }
    }
  }
}`

const TeamDashboard = () => {
  const { teamId } = useParams()

  const [getDashboardData, { data }] = useQuery(dashboardQuery)
  React.useEffect(() => { getDashboardData({ teamId }) }, [teamId])

  if (!data) return null
  const { insights, ...funnelData } = data
  const { funnelCompleted } = data?.Team || {}

  return (
    <Main>
      <div style={{ backgroundColor: '#fff' }}>
        <Header primary>
          <Text tag="h2">Dashboard</Text>
        </Header>
      </div>
      <Body space={8} scroll>
        <Grid gap={10} templateColumns="7fr 5fr" gridMin={800}>
          <Stack gap={10}>
            <Card space={8}><Welcome /></Card>
            { !funnelCompleted ? <div><Funnel {...funnelData} /></div> : null }
            <Help />
          </Stack>
          <Stack gap={10} style={{ maxWidth: '800px' }}>
            <Usage insights={insights} Team={data?.Team} />
            <Insights insights={insights} />
          </Stack>
        </Grid>
      </Body>
    </Main>
  )
}

export {
  TeamDashboard,
}
