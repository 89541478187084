import React from 'react'
import { passthroughCallback } from '../helpers'

const DropZoneContext = React.createContext()
const useDrop = () => React.useContext(DropZoneContext)

const useDropZone = ({ onDrop: onDropFn, type = 'text', effectAllowed = 'move' }) => {
  const [state, setState] = React.useState({ data: null, dragOver: false })
  const ref = React.useRef()
  const dragDepth = React.useRef(0)

  const clear = React.useCallback(() => {
    setState({ dragOver: false, data: null })
    dragDepth.current = 0
  }, [])

  const setDrop = React.useCallback(async (dropData, event) => {
    // Allow calback to manipulate the dropData (useful for files)
    const value = await passthroughCallback(onDropFn, { data: dropData, event })
    setState({
      dragOver: false,
      drop: value || dropData,
    })
  }, [onDropFn])

  const onDrop = React.useCallback(async (event) => {
    event.preventDefault()
    const dropData = type === 'file' ? event.dataTransfer.files : event.dataTransfer.getData(type)
    dragDepth.current = 0

    setDrop(dropData, event)
  }, [type, setDrop])

  const onDragOver = React.useCallback((event) => {
    // Required to allow onDrop to execute
    event.preventDefault()
    /* if (onDropFn) event.stopPropagation() */
    event.dataTransfer.effectAllowed = effectAllowed // eslint-disable-line no-param-reassign
    setState((s) => ({
      ...s,
      dragOver: s.dragOver === false ? true : s.dragOver,
    }))
  }, [])

  const onDragEnter = React.useCallback((event) => {
    event.preventDefault()
    dragDepth.current += 1
  }, [])

  const onDragLeave = React.useCallback((event) => {
    event.preventDefault()
    /* if (onDropFn) event.stopPropagation() */
    dragDepth.current -= 1
    // Reset dragOver on exit
    if (dragDepth.current === 0) {
      setState((s) => ({ ...s, dragOver: false }))
    }
  }, [])

  React.useEffect(() => {
    const el = ref.current
    if (el) {
      el.addEventListener('dragover', onDragOver, { capture: true })
      el.addEventListener('dragenter', onDragEnter, { capture: true })
      el.addEventListener('dragleave', onDragLeave, { capture: true })
      if (onDrop) el.addEventListener('drop', onDrop, { capture: true })
    }
    return () => {
      if (el) {
        el.removeEventListener('dragover', onDragOver, { capture: true })
        el.removeEventListener('dragenter', onDragEnter, { capture: true })
        el.removeEventListener('dragleave', onDragLeave, { capture: true })
        if (onDrop) el.removeEventListener('drop', onDrop, { capture: true })
      }
    }
  }, [onDrop])

  return {
    dragOver: state.dragOver,
    data: state.data,
    setDrop,
    clear,
    ref,
  }
}

export {
  useDropZone,
  DropZoneContext,
  useDrop,
}
