import PropTypes from 'prop-types'
import { Shelf } from '../Shelf'
import './tags.scss'

const Tags = (props) => (
  <Shelf className="level-tag-wrapper" {...props} />
)

Tags.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
}

const Tag = ({
  text,
  fill = 'blue-400',
  color = 'blue-600',
  className,
  ...rest
}) => (
  <div
    className={`level-tag ${className || ''}`}
    style={{
      backgroundColor: fill,
      color,
    }}
    {...rest}
  >
    {text}
  </div>
)

Tag.propTypes = {
  text: PropTypes.string,
  fill: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
}

export {
  Tag, Tags
}
