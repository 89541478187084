import PropTypes from 'prop-types'

import {
  Divider, Block, Shelf,
  RadioInputWrapper, Text, Template,
} from '@level'

const TemplatePreview = ({
  name, config, type, defaultChecked, onSelect,
}) => {
  const zoom = type === 'sms' ? 0.8 : 0.28
  return (
    <RadioInputWrapper
      name="template"
      label={`Template: ${name}`}
      value={name}
      theme="card"
      defaultChecked={defaultChecked}
      onSelect={onSelect}
      required
    >
      <div className="theme-gallery-preview">
        <Block space={[4, 6]}>
          <Shelf align="split" valign="top" nowrap>
            <Text tag="h5">{name || 'Blank'}</Text>
            {type ? <div className="level-preview-card-badge" data-type={type}>{type}</div> : null}
          </Shelf>
        </Block>
        <Divider />
        <div
          className="theme-gallery-preview-wrapper"
          style={{
            position: 'relative',
            height: `${100 / zoom}%`,
            width: `${100 / zoom}%`,
            transform: `scale(${zoom})`,
            transformOrigin: 'top left',
          }}
        >
          <Template config={config} mode="preview" type={type} />
        </div>
      </div>
    </RadioInputWrapper>
  )
}

TemplatePreview.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
  defaultChecked: PropTypes.bool,
  onSelect: PropTypes.func,
}

export {
  TemplatePreview,
}
