import {
  copyObject,
  excludeKeys,
  includeKeys,
  pluralize,
  sentenceJoin,
} from '../../../helpers'
import { useClipboard } from '../../../hooks/useClipboard'
import { useToast } from '../../../hooks/useToast'
import { updateTemplateConfig } from './helpers'

const getOverridenValues = (a, b) => {
  const subset = includeKeys(a, b)
  return Object.entries(subset).reduce((acc, [key, val]) => (
    b[key].value !== val.value ? { ...acc, [key]: b[key].value } : acc
  ), {})
}

const usePasteThemeNotice = () => {
  const { sendNotice } = useToast()

  return function pasteTheme(pastedTheme, config) {
    if (pastedTheme) {
      const { _theme = {} } = config.variables
      const names = Object.keys(pastedTheme)
      // Template doesn't have a theme
      if (Object.keys(_theme).length === 0) {
        sendNotice({
          title: 'Theme Added',
          message: <p>A new theme with ${pluralize('value', names)} <strong>${sentenceJoin(names)}</strong> ${pluralize('was', 'were', names)} added to this template.</p>,
          timeout: 4000,
        })
      } else {
        // If template will override some theme values
        const overridenValues = getOverridenValues(_theme, pastedTheme)
        const overriden = Object.keys(overridenValues)
        const overridenMessage = overriden.length ? <strong>{overriden.length} {pluralize('value was', 'values were', overriden)} merged</strong> : ''
        // If pasted theme will add some theme values
        const addedValues = excludeKeys(pastedTheme, _theme)
        const added = Object.keys(addedValues)
        const addedMessage = added.length ? <strong>{added.length} {pluralize('new value was', 'new values were', added)} added</strong> : ''
        let message = ''

        if (added.length && overriden.length) {
          message = <p>{addedMessage} and {overridenMessage} merged into this template‘s theme.</p>
        } else if (added.length) {
          message = <p>{addedMessage} to this template‘s theme.</p>
        } else if (overriden.length) {
          message = <p>{overridenMessage} into this template‘s theme.</p>
        } else {
          message = 'No changes were made to this templates theme.'
        }
        if (message) { sendNotice({ title: 'Theme Applied', message }) }
      }
    }
  }
}

const usePasteTheme = ({ template, clearOnPaste = true }) => {
  const pasteNotice = usePasteThemeNotice()
  const { inspect, paste } = useClipboard()
  const { name, data } = inspect()
  if (name === 'theme') {
    return function pasteTheme() {
      const pastedTheme = copyObject(clearOnPaste ? paste().data._theme : data._theme)
      const config = template.previewConfig
      pasteNotice(pastedTheme, config)
      const _theme = { ...(config.variables._theme || {}), ...pastedTheme }
      return updateTemplateConfig({ config, update: { _theme } })
    }
  }
  return null
}

export {
  usePasteTheme, usePasteThemeNotice
}
