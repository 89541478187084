import React from 'react'
import PropTypes from 'prop-types'

import { useProject } from '@app/hooks'
import {
  Header, Spacer, Text,
} from '@level'

const ProjectHeader = ({ title = '', actions, children }) => {
  const { Project } = useProject()

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Header primary>
        <Text tag="h2">{title || Project.name || ''}</Text>
        <Spacer grow />
        {actions}
      </Header>
      {children}
    </div>
  )
}

ProjectHeader.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  actions: PropTypes.node,
}

export {
  ProjectHeader,
}
