import PropTypes from 'prop-types'
import { Shelf } from '../../../../Shelf'
import { Text } from '../../../../Text'

const ElementConfigHeader = ({ element }) => (
  <Shelf space={[6, 7]} align="split" valign="center" nowrap>
    <Text tag="h3">{element.type}</Text>
  </Shelf>
)

ElementConfigHeader.propTypes = {
  element: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
}

export {
  ElementConfigHeader,
}
