import React from 'react'
import PropTypes from 'prop-types'

import {
  Grid, Input, Block, Text, HiddenInput,
  useFormRef,
} from '@level'

import { TemplatePreview } from '../preview'
import { templates } from './templates'

const SMSTemplateGallery = ({ Team, Project }) => {
  const { setValue } = useFormRef()
  const onSelect = ({ name, config }) => {
    setValue('name', name)
    setValue('config', config({ Team, Project }))
  }
  return (
    <>
      <HiddenInput name="type" value="sms" />
      <div className="theme-gallery-main">
        <Block className="theme-gallery-header" space={[6, 5, 6, 8]}>
          <Text tag="h4">Select a Template</Text>
        </Block>
        <Block space={8} className="theme-gallery-content">
          <div className="theme-gallery-themes">
            <Grid gap={8} fill columnMin={180}>
              { templates.map((template, index) => (
                <TemplatePreview
                  key={template.name}
                  type="sms"
                  name={template.name}
                  config={template.config({ Team, Project })}
                  onSelect={() => onSelect(template)}
                  defaultChecked={!index}
                />
              )) }
            </Grid>
          </div>
        </Block>
        <Block className="theme-gallery-footer" space={[6, 5, 6, 8]}>
          <Input name="name" label="Name your template" required />
        </Block>
      </div>
    </>
  )
}

SMSTemplateGallery.propTypes = {
  Team: PropTypes.object.isRequired,
  Project: PropTypes.object.isRequired,
}

export {
  SMSTemplateGallery,
}
