import React from 'react'
import {
  Routes,
  Route,
} from 'react-router-dom'

import {
  useProjects,
} from '@app/hooks'

import {
  Stack, Divider,
} from '@level'

import { MailProvider } from './providers/mail'
import { SmsProvider } from './providers/sms'
import { ConfigureIntegration } from './configure'

const Integrations = () => (
  <Stack gap={9}>
    <MailProvider />
    <Divider flush={8} />
    <SmsProvider />
  </Stack>
)

const IntegrationsIndex = () => {
  const { refresh } = useProjects()

  React.useEffect(() => {
    // refresh()
  }, [refresh])

  return (
    <Routes>
      <Route index element={<Integrations />} />
      <Route path="configure/:type" element={<ConfigureIntegration />} />
    </Routes>
  )
}

export {
  IntegrationsIndex,
}
