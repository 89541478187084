import PropTypes from 'prop-types'
import React from 'react'
import { NavLink as RouterNavLink, useLocation, useNavigate } from 'react-router-dom'
import { toColor } from '../../helpers'
import { Loading } from '../Loading'
import { Text } from '../Text'
import './link.scss'

const LinkTag = (link) => {
  if (link?.href) return 'a'
  if (link?.to) return RouterNavLink
  return 'div'
}

const targetUrl = (to, href) => {
  if (href) return ({ href })
  if (!to) return null

  const url = (to.pathname || to)
  let pathname

  // If an outbound url, return an href prop instead of a to
  if (typeof url === 'string') {
    if (url.startsWith('http')) { return { href: url } }
    if (url.startsWith('mailto')) { return { href: url } }

    // Replace accidental double forward slashes caused by foolish router interactions
    pathname = url.replace(/\/+/g, '/')
    // Merge with other to props, or return as a prop
  } else if (typeof url === 'object') {
    pathname = to.pathname || '.'
  }
  return { to: typeof to === 'object' ? { ...to, pathname } : pathname }
}

const Link = React.forwardRef(function Link({
  href,
  to,
  role = 'link',
  className,
  isLoading,
  text,
  color,
  style,
  children = text,
  label,
  ...rest
}, ref) {
  const url = targetUrl(to, href)
  const Tag = href ? 'a' : LinkTag(url)
  const linkItemText = children
  const htmlRole = (Tag === 'div' ? role || 'button' : role)

  return (
    <Text
      as={Tag}
      className={`level-link ${className || ''}`}
      {...url}
      role={htmlRole}
      tabIndex={role === 'button' ? 0 : null}
      style={{ color: toColor(color), ...style }}
      aria-label={label}
      title={label}
      ref={ref}
      {...rest}
    >
      {isLoading ? <Loading text={linkItemText} /> : linkItemText}
    </Text>
  )
})

Link.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.array]),
  text: ({ text, children, label }) => (
    (!text && !children && !label) ? (new Error('Link requires either text, label, or a children prop.')) : null
  ),
  href: PropTypes.string,
  role: PropTypes.oneOf(['link', 'button', 'menuitem']),
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
    pathname: PropTypes.string,
    path: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  })]),
  className: PropTypes.string,
  label: PropTypes.string,
  isLoading: PropTypes.bool,
  style: PropTypes.object,
  color: toColor.propTypes.color,
}

const CancelLink = ({ to, text = 'Cancel', ...rest }) => {
  const { state } = useLocation()
  const history = useNavigate()

  // Get the cancel url from state or props
  const url = state?.cancelTo || to
  const linkText = state?.cancelText || text
  // If no url provided default to back button behavior
  const click = !url ? () => history.goBack() : null

  return (
    <Link
      to={url}
      className="level-link cancel"
      text={linkText}
      onClick={click}
      color="neutral-600"
      {...rest}
    />
  )
}

CancelLink.propTypes = {
  children: Link.propTypes.children,
  to: Link.propTypes.to,
  text: Link.propTypes.text,
}

export { CancelLink, Link }

