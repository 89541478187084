import { useComboboxStore, ComboboxProvider, Combobox, ComboboxItem } from '@ariakit/react'
import { matchSorter } from 'match-sorter'
import PropTypes from 'prop-types'
import React from 'react'
import { useFormRef } from '../../hooks/useForm'
import { Block } from '../Block'
import { Button } from '../Button'
import { Divider } from '../Divider'
import { HiddenInput } from '../Input'
import { Stack } from '../Stack'
import { SectionLabel, Text } from '../Text'

import './search-select.scss'

const SearchSelect = ({
  name, placeholder = 'Search…', items, match, itemDisplay = (i) => i, noResults = 'No results found', defaultValue,
}) => {
  const [searchValue, setSearchValue] = React.useState(defaultValue)
  const matches = React.useMemo(() => (
    (searchValue && matchSorter(items, searchValue, match)) || items
  ), [searchValue])

  const { setValue, submit } = useFormRef()

  const setSearch = (value) => {
    React.startTransition(() => {
      setSearchValue(value)
    })
  }

  const combobox = useComboboxStore({
    defaultValue,
    defaultOpen: !defaultValue,
    setValue: (value) => {
      setValue(name, value)
      setSearch(value)
      combobox.getState().baseElement.setCustomValidity('')
    },
  })

  const setInput = (val) => {
    setValue(name, val)
    setSearch(val)
    setTimeout(() => {
      combobox.hide()
      submit()
    }, 0)
  }

  React.useEffect(() => {
  }, [combobox.getState().baseElement])

  return (
    <div className="wrapper">
      <ComboboxProvider store={combobox}>
        <HiddenInput name={name} defaultValue={defaultValue} />
        <Stack gap={4}>
          <Stack gap={7}>
            <div className="level-input-clear-wrapper">
              <Combobox
                placeholder={placeholder}
                className="level-input level-combobox-input"
                name="combobox"
                showOnMouseDown={false}
                autoFocus
              />
              <Button
                className="level-input-clear"
                icon="x-light"
                label="clear input"
                theme="ghost"
                onClick={({ target }) => {
                  const searchInput = target.closest('button').previousElementSibling
                  combobox.setValue('')
                  setInput('')
                  searchInput.focus()
                }}
              />
            </div>
            {combobox.getState().open ? <Divider flush={7} /> : null}
            {combobox.getState().open ? <SectionLabel>Templates</SectionLabel> : null}
          </Stack>
          {combobox.getState().open ? (
            <Block flush={7} space={[0, 5]} className="level-combobox-items" style={{ maxHeight: '40vh', overflowY: 'auto' }}>
              {matches.length ? (
                matches.map((value) => (
                  <ComboboxItem
                    key={itemDisplay(value)}
                    value={itemDisplay(value)}
                    className="level-combobox-item"
                    setValueOnClick={({ target }) => {
                      setInput(target.value)
                      return true
                    }}
                  >
                    <Text truncate>{itemDisplay(value)}</Text>
                  </ComboboxItem>
                ))
              ) : (
                <div className="level-combobox-no-results">{noResults}</div>
              )}
            </Block>
          ) : null}
        </Stack>
      </ComboboxProvider>
    </div>
  )
}

SearchSelect.propTypes = {
  name: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  match: PropTypes.object,
  placeholder: PropTypes.string,
  itemDisplay: PropTypes.func,
  defaultValue: PropTypes.string,
  noResults: PropTypes.any,
}

export {
  SearchSelect
}
