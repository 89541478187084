import { toParams } from '@level'

const createApiTokenQuery = ({
  Project, name, testMode = false,
}) => `mutation {
  token: createApiToken(team: "${Project.team.slug}", project: "${Project.slug}", attributes: { ${toParams({ name, testMode })}}) {
    name token testMode active
  }
}`

const updateApiTokenQuery = ({ id, ...rest }) => `mutation {
  token: updateApiToken(id: "${id}", attributes: { ${toParams({ ...rest })} }) {
    name
  }
}`

const deleteApiTokenQuery = ({ id }) => `mutation {
  token: deleteApiToken(id: "${id}") {
    name
  }
}`

export {
  createApiTokenQuery,
  updateApiTokenQuery,
  deleteApiTokenQuery,
}
