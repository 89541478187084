import React from 'react'
import PropTypes from 'prop-types'
import { useResolvedPath } from 'react-router-dom'

import {
  Block, Button, Stack, Card, P,
} from '@level'

import { useProject } from '@app/hooks'

const SharedSectionPanel = ({ section, unlink }) => {
  const { url: projectUrl } = useProject()
  const editSection = projectUrl(`shared-sections/shared-section/${section.sharedSectionId}/`)
  const { pathname: templateUrl } = useResolvedPath('../')
  return (
    <Block space={7}>
      <Card theme="info">
        <Stack gap={6}>
          <P>
            This is a Shared Section.
            You can make changes to it under the shared sections for this project.
          </P>
          <div>
            <Button
              to={editSection}
              state={{ templateUrl }}
              icon={{
                name: 'up-right-from-square',
                space: 4,
              }}
              text="Edit Shared Section"
              theme="primary"
              grow
            />
            <Button
              onClick={unlink}
              text="Unlink Section"
              theme="link"
              grow
            />
          </div>
        </Stack>
      </Card>
    </Block>
  )
}

SharedSectionPanel.propTypes = {
  section: PropTypes.shape({
    sharedSectionId: PropTypes.string.isRequired,
    variables: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

SharedSectionPanel.propTypes = {
  unlink: PropTypes.func.isRequired,
}

export {
  SharedSectionPanel,
}
