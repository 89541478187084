import { theme } from './theme'

const forgotPassword = {
  name: 'Forgot Password',
  config: ({ color }) => {
    const {
      colors: {
        text,
        border,
      },
      variables,
    } = theme({ color, defaultSubject: 'Password Reset Requested' })

    return {
      variables,
      sections: [
        {
          sharedSectionId: null,
          variables: {
            config: {
              align: 'left',
              valign: 'top',
              padding: '0px 0px 0px 0px',
              elementSpacing: '10px',
              name: 'Content',
            },
            columns: [
              {
                config: {},
                elements: [
                  {
                    type: 'Heading',
                    config: {
                      text: 'Forgot your password?',
                      color: text,
                      padding: '0 0 0 0',
                      fontSize: '30px',
                      fontWeight: '600',
                    },
                  },
                  {
                    type: 'Text',
                    config: {
                      content: 'That\'s okay! We know it happens. Click the link below to reset your password and we\'ll have you up and running in no time.\n\n\n[Reset Password](https://enveloop.com)',
                      padding: '0 0 10px 0',
                      fontSize: '14px',
                      fontWeight: '400',
                    },
                  },
                  {
                    type: 'Text',
                    config: {
                      content: 'If this was a mistake, just ignore this email and nothing will happen.',
                      padding: '0 0 0 0',
                      fontSize: '12px',
                      fontWeight: '400',
                    },
                  },
                  {
                    type: 'Divider',
                    config: {
                      color: border,
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      padding: '10px 0 10px 0',
                    },
                  },
                  {
                    type: 'Text',
                    config: {
                      content: 'If you have trouble with the link above, copy and paste the URL below into your web browser.\n\n{{account_url}}',
                      padding: '0 0 0 0',
                      fontSize: '10px',
                      fontWeight: '400',
                    },
                  },
                ],
              },
            ],
          },
        },
      ],
    }
  },
}

export { forgotPassword }
