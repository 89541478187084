import React from 'react'
import {
  Grid, Stack, Shelf, Text, Button,
  pluralize, Card,
} from '@level'

import { CopyCode } from './CopyCode'

const DkimConfig = ({
  dkimRecords, isVerified, checkAgain, isLoading,
}) => {
  const invalidDkim = dkimRecords.filter(({ confirmed }) => !confirmed)
  const [expanded, setExpanded] = React.useState(!isVerified || invalidDkim.length)
  const dkimVerified = !invalidDkim?.length && isVerified
  const validIcon = { name: 'circle-check', color: 'green-700' }
  const invalidIcon = { name: 'circle-exclamation', color: 'yellow-600' }
  return (
    <Stack gap={7} space={5}>
      <Shelf align="split" nowrap>
        <Stack gap={4}>
          <Text icon={dkimVerified ? { ...validIcon, size: 3, gap: 4 } : null} tag="h4">
            {invalidDkim?.length ? 'Verify your domain with DKIM' : 'DKIM Verification'}
          </Text>
          {dkimVerified ? (
            <Text tag="p">Your domain has been verified and your DKIM records are looking good.</Text>
          ) : (
            <Grid gap={8} templateColumns="1fr auto">
              {isVerified && invalidDkim.length ? (
                <Text tag="p">Your domain was verified for sending email but {pluralize('one', 'several', invalidDkim?.length)} of your DKIM records are no longer correct. Update your DNS settings to fix the flagged {pluralize('record', invalidDkim.length)} below to ensure that mail servers do not mark your email as spam.</Text>
              ) : null}
              {!isVerified && invalidDkim.length ? (
                <Text tag="p">Add {pluralize('this', 'these', dkimRecords?.length)} {dkimRecords[0].type} records to your DNS configuration to verify your domain. Then you will be able to use your domain to send email.</Text>
              ) : null}
              {!isVerified && !invalidDkim.length ? (
                <Text tag="p">It looks like your DKIM records are in good shape. Updating your domain verification status shouldn&rsquo;t take much longer.</Text>
              ) : null}
              <Button text="Check Again" style={{ alignSelf: 'start' }} onClick={checkAgain} isLoading={isLoading} />
            </Grid>
          )}
        </Stack>
        {dkimVerified ? <Button label="View Config" theme="ghost" onClick={() => setExpanded((e) => !e)} icon={expanded ? 'angle-down' : 'angle-left'} /> : null}
      </Shelf>
      {expanded ? (
        <Stack gap={5}>
          {dkimRecords.map(({
            name, value, domain, confirmed,
          }) => (
            <Stack gap={4} key={name}>
              {confirmed ? (
                <>
                  <Text icon={validIcon}>
                    Confirmed TXT record <strong>`<code>{name}</code>`</strong> on <strong>`<code>{domain}</code>`</strong>
                  </Text>
                  <Card space={4}>
                    <Text style={{ fontFamily: 'system-mono' }} forceWrap>{value}</Text>
                  </Card>
                </>
              ) : (
                <>
                  <Text icon={invalidIcon}>
                    Add this value to the TXT record <strong>`<code>{name}</code>`</strong> on <strong>`<code>{domain}</code>`</strong>
                  </Text>
                  <CopyCode value={value} />
                </>
              )}
            </Stack>
          ))}
        </Stack>
      ) : null}
    </Stack>
  )
}

export {
  DkimConfig,
}
