import { sortBy } from '@level'
import React from 'react'
import { Navigate } from 'react-router-dom'
import { QueryProvider } from './QueryProvider'
import { useTeam } from './useTeam'

const ProjectsContext = React.createContext({})

const useProjects = () => React.useContext(ProjectsContext)

const projectsQuery = ({ Team: { slug } }) => `{
  projects(team: "${slug}") {
    id name slug createdAt
    team { slug }
  } 
}`

const ProjectsProvider = (props) => {
  const { Team, url: teamUrl } = useTeam()
  const onData = React.useCallback(({ data: { projects = [] } }) => (
    {
      projects: projects.sort(sortBy('name')),
      projectsUrl: (path = '') => {
        const url = teamUrl(`projects`)
        return path ? `${url}/${path}` : url
      },
    }
  ), [Team.slug])

  return Team ? (
    <QueryProvider
      query={projectsQuery({ Team })}
      context={ProjectsContext}
      onData={onData}
      {...props}
    />
  ) : <Navigate to="/not-found" />
}

export {
  ProjectsContext, ProjectsProvider, projectsQuery, useProjects
}
