import { renderSection, renderTemplate } from '@level'

// Migrate an elements config
const migrateElement = ({ config, ...element }) => {
  const newElement = { ...element, config }

  // Do whatever needs to be done or return element
  return newElement
}

// Migrate a section's config, elements, and render a new body
const migrateSection = ({ section, templateVariables }) => {
  if (section.sharedSectionId) return section
  const { variables } = section

  // Migrate element variables
  variables.columns = section.variables.columns.map(({ elements, ...rest }) => ({
    ...rest,
    elements: elements.map(migrateElement),
  }))

  return renderSection({ section: { variables }, templateVariables })
}

// Migrate a template's config, sections, and render a new body
const migrateTemplateConfig = ({ sections, variables }) => {
  // Here we'd migrate template configs if necessary
  const newConfig = { variables }
  // Migrate template config sections
  newConfig.sections = sections.map((section) => (
    migrateSection({ section, templateVariables: newConfig.variables })))
  // Update rendered body for this template config
  newConfig.body = renderTemplate({ config: newConfig, type: 'email' })

  return newConfig
}

// Updates preview and live config for a template
const migrateEmailTemplate = ({ id, previewConfig, liveConfig }) => ({
  id,
  previewConfig: migrateTemplateConfig(previewConfig),
  liveConfig: liveConfig ? migrateTemplateConfig(liveConfig) : liveConfig,
})

const migrateSharedSection = ({ id, previewConfig, liveConfig }) => ({
  id,
  previewConfig: migrateSection({ section: previewConfig }),
  liveConfig: liveConfig ? migrateSection({ section: liveConfig }) : liveConfig,
})

export {
  migrateSharedSection,
  migrateEmailTemplate,
}
