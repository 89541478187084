import { QueryProvider as LevelQueryProvider, Loading } from '@level'
import { useQuery } from './useQuery'

// This simplifies the common pattern of saving query data to a provider.
// The provider will pass through the data as well as a refresh function to trigger a new fetch.
const QueryProvider = (props) => (
  <LevelQueryProvider useQueryFn={useQuery} loading={<Loading />} {...props} />
)

export {
  QueryProvider
}
