import React from 'react'
import PropTypes from 'prop-types'

const ErrorNameCode = ({ error }) => {
  const errorParts = error.split('`')
  return (
    <>
      {errorParts[0]}
      { errorParts[1] ? <code className="level-inline-code">{errorParts[1]}</code> : null }
      {errorParts[2]}
    </>
  )
}

ErrorNameCode.propTypes = {
  error: PropTypes.string.isRequired,
}

export {
  ErrorNameCode,
}
