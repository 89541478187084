import { useTimeoutCallback } from './useTimeoutCallback'

const useThrottle = (callback, dependencies, time) => useTimeoutCallback({
  callback,
  dependencies,
  time,
  limit: time,
  leading: true,
  trailing: true,
})

export {
  useThrottle,
}
