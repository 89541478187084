import React from 'react'

import { useProfile } from '@app/hooks'

import {
  Stack,
  Text, P, Grid, Icon,
} from '@level'

const Welcome = () => {
  const { Profile: { name } } = useProfile()
  return (
    <Grid gap={9} gridMin={500} templateColumns="1fr 120px" valign="center">
      <Stack gap={6}>
        <Text tag="h1">Welcome, {name}!</Text>
        <P>We’ve created a personal team to get you started. You can create a new team or add a new project.</P>
      </Stack>
      <Icon name="illustration-celebrate" width={120} align="right" />
    </Grid>
  )
}

export {
  Welcome,
}
