import PropTypes from 'prop-types'

import { CSS } from '@dnd-kit/utilities'

import {
  SortableContext as Context,
  rectSortingStrategy,
  verticalListSortingStrategy,
  horizontalListSortingStrategy,
  rectSwappingStrategy,
  useSortable,
} from '@dnd-kit/sortable'

const strategies = {
  rect: rectSortingStrategy,
  vertical: verticalListSortingStrategy,
  horizontal: horizontalListSortingStrategy,
  swap: rectSwappingStrategy,
}

const SortableContext = ({
  strategy = 'vertical', ...props
}) => {
  return <Context strategy={strategies[strategy]} {...props} />
}

SortableContext.propTypes = {
  strategy: PropTypes.oneOf(Object.keys(strategies)),
}

const SortableItem = ({
  id,
  as: Tag = 'div',
  style: styleProp = {},
  handle = false,
  ...props
}) => {
  const sortable = useSortable({ id })
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = sortable
  if (transform) {
    transform.scaleX = 1
    transform.scaleY = 1
  }
  const style = {
    transform: CSS.Transform.toString(transform),
    position: 'relative',
    transition,
    ...(styleProp || undefined),
  }
  return (
    <Tag
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...(!handle ? listeners : undefined)}
      {...props}
    />
  )
}

SortableItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  style: PropTypes.object,
  handle: PropTypes.bool,
}

export {
  SortableContext,
  SortableItem,
  useSortable,
}
