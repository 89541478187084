import { getQueryFilter } from '@level/helpers'

export const operators = {
  type: 'in',
  status: 'in',
  from: 'substring',
  to: 'substring',
  TemplateId: 'in',
  openedAt: 'gt',
  clickedAt: 'gt',
  createdAt: 'between',
}

export const messagesQuery = ({
  projectId,
  teamId,
  testMode = true,
  createdAt,
  ...rest
}) => {
  const params = rest
  // For ease, createdAt is stored in params in unix time. Here we canovert to the required Date
  if (createdAt) params.createdAt = createdAt.split(',').map((d) => new Date(Number.parseInt(d, 10))).join(',')
  const filter = getQueryFilter({ params, operators, keys: [...Object.keys(operators), 'date'] })
  return `{
    messages(team: "${teamId}", project: "${projectId}", testMode: ${testMode}, ${filter}) {
      id to from subject name type 
      template { slug name }
      status createdAt openedAt clickedAt
    }
  }`
}

export const messageQuery = ({ id, testMode }) => `{
  message(id: "${id}", testMode: ${testMode}) {
    id to from subject name type 
    template { slug name }
    status createdAt openedAt clickedAt
    computedMessageEvents { event at data id }
    body text mediaUrls
    templateVariables
  }
}`
