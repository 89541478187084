const nodeSnippet = ({
  data, token,
}) => `const Enveloop = require("enveloop")
const enveloop = new Enveloop({ apiKey: "${token}" })

enveloop.sendMessage(${JSON.stringify(data, null, 2)}
)
.then(res => console.log(res))
.catch(err => console.log(err))
`

export { nodeSnippet }
