import { ColorInput } from '../../../../Input/Color'
import { useConfigFormContext } from '../Provider'

const ThemeColorInput = (props) => {
  const { theme, colors } = useConfigFormContext()
  return <ColorInput {...props} colors={colors} theme={theme} />
}

export {
  ThemeColorInput
}
