import PropTypes from 'prop-types'
import React from 'react'
import { CSSTransition } from 'react-transition-group'

import './transition.scss'

const Transition = ({
  type, timeout = 300, children, ...props
}) => (
  <CSSTransition timeout={timeout} {...props}>
    {(className) => (
      React.Children.map(children, (child) => {
        const newClass = `${child.props.className || ''} level-transition ${className}`
        return React.cloneElement(
          child,
          {
            ...child.props,
            className: newClass,
            'data-transition': type,
            style: {
              '--duration': `${timeout / 1000}s`,
            },
          },
        )
      })
    )}
  </CSSTransition>
)

Transition.propTypes = {
  type: PropTypes.string,
  timeout: PropTypes.number,
  children: PropTypes.node,
}

export {
  Transition
}
