import React from 'react'
import PropTypes from 'prop-types'

import { useParams } from 'react-router-dom'

import {
  Table, TruncateCell, ScrollPanel, Stack, Icon, Text, Button,
  useVirtualTable, useInfiniteFetch,
  formatDateTime, useSearchState,
} from '@level'

import { useErrorModal } from './modal'
import { useErrorLog } from './provider'
import {
  DateFilterHeader,
  ErrorFilterHeader,
  SdkVersionFilterHeader,
} from './filters'

import { ErrorNameCode } from './helpers'

const DateCell = ({ getValue }) => (
  <TruncateCell getValue={() => formatDateTime(getValue(), { utc: true })} />
)

const NameCell = ({ getValue }) => (
  <ErrorNameCode error={getValue()} />
)

const NoErrors = ({ isLoading }) => {
  const { env } = useParams()
  const [filters, setParams] = useSearchState()
  const hasFilters = filters && !!Object.keys(filters).length
  return !isLoading ? (
    <ScrollPanel limitHeight>
      <Stack space={10} gap={10} align="center">
        <Stack gap={5} align="center">
          <Text tag="h3">No Errors Found</Text>
          <Text tag="p" color="neutral-500">
            {hasFilters
              ? `No ${env === 'live' ? 'production' : 'sandbox'} error logs were found with these filters.`
              : `There are no ${env === 'live' ? 'production' : 'sandbox'} error logs for this project.`}
          </Text>
        </Stack>
        {hasFilters ? (
          <div>
            <Button
              theme="light"
              className="clear-message-filters"
              icon={{ name: 'x-circle', color: 'neutral-300' }}
              onClick={() => setParams({})}
              text="Clear Filters"
            />
          </div>
        ) : null}
      </Stack>
    </ScrollPanel>
  ) : null
}

const ErrorsTable = ({ selectItem, onScroll }) => {
  const { env } = useParams()
  const {
    items: errors, hasMore, getMore, isLoading,
  } = useErrorLog()
  const hadErrors = React.useRef(errors?.length)

  const columnNames = React.useMemo(() => [
    { errorMessage: { width: '30%', cell: NameCell, header: ErrorFilterHeader } },
    'path',
    { sdkVersion: { header: SdkVersionFilterHeader } },
    { createdAt: { header: DateFilterHeader, cell: DateCell } },
  ], [errors])

  const table = useVirtualTable({
    columnNames,
    enableRowSelection: true,
    singleRowSelection: true,
    hasMore,
    data: errors,
  })

  useErrorModal({ table })

  const selectedItem = table.getSelectedRowModel().rows[0]?.original
  const { selected, selectedRow, deselectAll } = table.getSelection(table)
  React.useEffect(() => {
    if (selectedRow) { deselectAll() }
  }, [env])

  React.useEffect(() => {
    selectItem(selectedItem)
  }, [selected])

  useInfiniteFetch({
    hasMore, getMore, virtualizer: table.virtualizer, isLoading, items: errors,
  })

  return (
    <div>
      {hadErrors ? (
        <Table
          table={table}
          onScroll={onScroll}
          theme="white"
          limitHeight
          offsetSpace={6}
          className="message-log-items"
        />
      ) : null}
      {errors.length === 0 ? <NoErrors isLoading={isLoading} /> : null}
    </div>
  )
}

ErrorsTable.propTypes = {
  selectItem: PropTypes.func.isRequired,
  onScroll: PropTypes.func,
}

export {
  ErrorsTable,
}
