import PropTypes from 'prop-types'
import { Block } from '../Block'
import { spaceToSize } from '../../helpers/space'

import './card.scss'

const Card = ({
  space = 6,
  className = '',
  style = {},
  theme,
  align = 'left',
  children,
  shadow,
  animate,
}) => {
  const s = { padding: spaceToSize(space), ...style }
  return (
    <div
      className={`${className} level-card`}
      style={s}
      data-theme={theme}
      data-align={align}
      data-shadow={shadow}
      data-animate={animate}
    >{children}
    </div>
  )
}

Card.propTypes = {
  space: spaceToSize.propTypes.space,
  theme: PropTypes.string,
  align: PropTypes.string,
  style: PropTypes.object,
  shadow: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  className: PropTypes.string,
  animate: PropTypes.string,
  children: PropTypes.node.isRequired,
}

const CardHeader = (props) => <Block className="level-card-header" {...props} />
const CardSection = (props) => <Block className="level-card-section" {...props} />
const CardFooter = (props) => <Block className="level-card-footer" {...props} />

export {
  Card,
  CardHeader,
  CardSection,
  CardFooter,
}
