import React from 'react'
import { useParams } from 'react-router-dom'

import { HeaderFilter } from '@level'

import { TemplateFilter } from '../filters/template'
import { DateFilter } from '../filters/date'
import { ActionFilter } from '../filters/action'
import { TypeFilter } from '../filters/type'
import { StatusFilter } from '../filters/status'
import { FromFilter } from '../filters/from'
import { ToFilter } from '../filters/to'

import {
  TruncateCell,
  TypeCell,
  DateCell,
  StatusCell,
  ActionCell,
  TemplateCell,
} from './cells'

const TypeHeader = () => <HeaderFilter name="type" content={<TypeFilter />} />
const StatusHeader = () => <HeaderFilter name="status" content={<StatusFilter />} />
const FromHeader = () => <HeaderFilter name="from" content={<FromFilter />} />
const ToHeader = () => <HeaderFilter name="to" content={<ToFilter />} />
const ActionHeader = () => <HeaderFilter name="action" content={<ActionFilter />} activeParams={['clickedAt', 'openedAt']} />
const TemplateHeader = () => <HeaderFilter name="template" content={<TemplateFilter />} activeParams={['TemplateId']} />
const DateHeader = () => <HeaderFilter name="date" content={<DateFilter />} activeParams={['createdAt']} />

const typeColumn = { type: { header: TypeHeader, cell: TypeCell, width: 70 } }
const statusColumn = { status: { header: StatusHeader, cell: StatusCell, width: 86 } }
const toColumn = { to: { header: ToHeader, cell: TruncateCell } }
const actionColumn = {
  action: {
    header: ActionHeader,
    cell: ActionCell,
    width: 86,
    type: 'display',
  },
}
const templateColumn = { template: { header: TemplateHeader, cell: TemplateCell } }
const fromColumn = { from: { header: FromHeader, cell: TruncateCell } }
const dateColumn = { createdAt: { header: DateHeader, cell: DateCell } }

const useMessageColumns = () => {
  const { env } = useParams()
  return React.useMemo(() => {
    if (env === 'sandbox') {
      return [
        typeColumn,
        toColumn,
        templateColumn,
        fromColumn,
        dateColumn,
      ]
    }
    return [
      typeColumn,
      statusColumn, // Only in production
      toColumn,
      actionColumn, // Only in production
      templateColumn,
      fromColumn,
      dateColumn,
    ]
  }, [env])
}

export {
  useMessageColumns,
}
