import React from 'react'
import {
  useFormQuery, useProfile,
} from '@app/hooks'

import {
  Input, P,
  useDialog, toParams,
} from '@level'

const createTeamQuery = ({ slug, name }) => `mutation {
  Team: createTeam(attributes: { ${toParams({ slug, name })}}) {
    slug
  }
}`

const useCreateTeam = () => {
  const { refresh, teamUrl } = useProfile()

  return useFormQuery({
    query: createTeamQuery,
    // Refresh project list before redirecting or you'll hit a not-found
    onSuccess: () => refresh(),
    redirect: ({ data }) => teamUrl({ teamId: data.Team.slug }),
  })
}

const useNewTeamDialog = () => {
  const sendDialog = useDialog()
  const { onSubmit } = useCreateTeam()

  return () => {
    sendDialog({
      onConfirm: onSubmit,
      title: 'New Team',
      confirmText: 'Create Team',
      content: (
        <>
          <P>
            Pick a name for your team or workspace.
            Don&rsquo;t worry, you can always change this or add more teams later.
          </P>
          <Input name="name" label="Team Name" placeholder="My Team" required />
        </>
      ),
    })
  }
}

export {
  useNewTeamDialog,
  createTeamQuery,
}
