import { includeKeys, toRange, uuid } from '../../../../helpers'
import { addThemeColors } from '../../../../helpers/template'

const oldFontSizes = {
  0: '10px',
  1: '12px',
  2: '14px',
  3: '16px',
  4: '20px',
  5: '24px',
  6: '30px',
  7: '36px',
  8: '44px',
  9: '48px',
  10: '64px',
}

const fontSizes = [
  ...toRange({ start: 7, end: 13, step: 1 }),
  ...toRange({ start: 14, end: 30, step: 2 }),
  ...toRange({ start: 32, end: 64, step: 4 }),
  ...toRange({ start: 68, end: 100, step: 8 }),
].map((s) => `${s}px`)

const fontFamilies = {
  'sans-serif': '-apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif',
  serif: 'Iowan Old Style, Apple Garamond, Baskerville, Times New Roman, Droid Serif, Times, Source Serif Pro, serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
  monospace: 'Menlo, Consolas, Monaco, Liberation Mono, Lucida Console, monospace',
  inherit: null,
}

const defaultTheme = addThemeColors({
  templateBg: '#ffffff',
  text: '#303132',
  linkColor: '#0000FF',
  border: '#dedfe2',
})._theme

const templateConfig = {
  style: {
    color: defaultTheme.text.config,
    backgroundColor: defaultTheme.templateBg.config,
    contentBackgroundColor: defaultTheme.templateBg.config,
    linkColor: defaultTheme.linkColor.config,
    fontFamily: 'sans-serif',
    padding: '30px 30px 30px 30px',
    width: '640',
  },
  config: {
    previewText: '',
    defaultSubject: '',
    defaultFrom: '',
  },
  _theme: defaultTheme,
}

const templateConfigLayout = [
  'fontFamily',
  'color',
  'linkColor',
  '---',
  'backgroundColor',
  'contentBackgroundColor',
  '---',
  'width',
  'padding',
]

const columnConfig = {
  width: '100%',
}

const sectionTypes = ['normal', 'iterable', 'conditional']

const sectionConfig = {
  align: 'left',
  valign: 'top',
  padding: '15px 40px 15px 40px',
  color: null,
  linkColor: null,
  elementSpacing: '15px',
  backgroundColor: null,
  fontFamily: null,
  columnSpace: 0,
  name: null,
  type: 'normal',
  variableStart: '',
  variableEnd: '',
}

const sectionConfigLayout = [
  'color',
  'linkColor',
  'backgroundColor',
  'fontFamily',
  '---',
  'align',
  'padding',
  'elementSpacing',
]

const configDefaults = {
  template: templateConfig,
  section: sectionConfig,
  column: columnConfig,
}

const inputLayouts = {
  section: sectionConfigLayout,
  template: templateConfigLayout,
}

const mergeConfig = (type, config = {}) => {
  const base = configDefaults[type]
  return { ...base, ...includeKeys(config, base) }
}

const configInputLayout = (type, object) => {
  const layout = inputLayouts[type]

  if (type === 'section') {
    return (object.variables.columns.length > 1) ? layout.concat(['---', 'valign', 'columnSpace']) : layout
  }

  return layout
}

const newColumnConfig = (options = {}) => {
  const { elements = [], config = columnConfig } = options
  return {
    id: `column-${uuid()}`,
    config,
    elements,
  }
}

export {
  configDefaults,
  configInputLayout, fontFamilies, fontSizes, inputLayouts, mergeConfig,
  newColumnConfig, oldFontSizes, sectionTypes
}
