import React from 'react'

import { Navigate, useSearchParams } from 'react-router-dom'
import {
  Text, Shelf, Grid, Stack, Icon, Table,
  PercentageBar, Loading, Button,
  useVirtualTable, toPercentage, formatDate,
  parseDateParams, toSearchString,
} from '@level'

import { useTeam } from '@app/hooks'

import { useRowModal } from './modal'
import { ComplianceCard } from './complianceCard'
import { DmarcProvider, useDmarcReports } from './provider'
import { DateFilterPill } from './filters/date'
import { DomainFilter } from './filters/domain'

const pctBar = ({ cell: { renderValue } }) => {
  const percentage = Number.parseInt(renderValue(), 10)
  return (
    <Shelf gap="6">
      <Text style={{ width: '40px' }}>{percentage}%</Text>
      <PercentageBar percentage={percentage} lessThan={50} greaterThan={80} />
    </Shelf>
  )
}

const dateCell = ({ cell: { renderValue } }) => (
  <Text>{formatDate(renderValue(), { utc: true })}</Text>
)

const DmarcReportsTable = () => {
  const {
    dmarcReports: data,
  } = useDmarcReports()
  const { Team, url } = useTeam()
  const [dmarcConfigured, setDmarcConfigured] = React.useState(false)

  React.useEffect(() => {
    if (!Team) return

    if (Team.senderIdentities.filter(({ dmarcRecord }) => dmarcRecord?.valid).length) {
      setDmarcConfigured(true)
    }
  }, [Team])

  const columnNames = React.useMemo(() => [
    'submitter',
    { emails: { header: '# of Emails', width: 120 } },
    { dmarc: { header: 'DMARC Compliant', cell: pctBar } },
    { dkim: { header: 'DKIM Verification', cell: pctBar } },
    { spf: { header: 'SPF Verification', cell: pctBar } },
    { date: { header: 'Date', cell: dateCell, width: 120 } },
  ], [])

  const table = useVirtualTable({
    data,
    columnNames,
    enableRowSelection: true,
    singleRowSelection: true,
  })

  const total = data.reduce((acc, { metrics: { total: t } }) => acc + t, 0)
  const totalPass = data.reduce((acc, { metrics: { passed } }) => acc + passed, 0)
  const totalRejected = data.reduce((acc, { metrics: { rejected } }) => acc + rejected, 0)
  const totalQuarantined = data.reduce((acc, { metrics: { quarantined } }) => acc + quarantined, 0)

  useRowModal({ table })

  return (
    <Stack gap={6} space={6} className="message-log">
      { data.length || dmarcConfigured ? (
        <>
          <Shelf gap={4}>
            <DomainFilter />
            <DateFilterPill />
          </Shelf>
          <Grid columns={4} gap={5}>
            <ComplianceCard
              title="Total Emails Reported"
              number={total}
              icon={<Icon name="check-circle" size={7} color="green-600" />}
            />
            <ComplianceCard
              title="DMARC Compliant"
              number={totalPass}
              percentage={toPercentage(totalPass, total)}
              percentageRange={[50, 80]}
            />
            <ComplianceCard
              title="DMARC Quarantined"
              number={totalQuarantined}
              percentage={toPercentage(totalQuarantined, total)}
              percentageRange={[5, 20]}
              negative
            />
            <ComplianceCard
              title="DMARC Rejected"
              number={totalRejected}
              percentage={toPercentage(totalRejected, total)}
              percentageRange={[10, 20]}
              negative
            />
          </Grid>
        </>
      ) : null }
      <div>
        <Table
          table={table}
          theme="white"
          className="dmarc-records"
          offsetSpace={8}
          limitHeight
          blankSlate={(
            dmarcConfigured
              ? (
                <Stack space={[10, 5]} gap={5} align="center">
                  <Icon name="dmarc-reports" size={9} color="neutral-200" />
                  <Text tag="h4">No Reports Available</Text>
                  <Text color="neutral-400">You’re all set! Everything is configured properly on your domain. Now we just wait for the DMARC reports to arrive.</Text>
                </Stack>
              )
              : (
                <Stack space={[10, 5]} gap={5} align="center">
                  <Icon name="dmarc-reports" size={9} color="neutral-200" />
                  <Text tag="h4">DMARC Not Configured</Text>
                  <Text color="neutral-400">Configure DMARC in Mail Settings to start monitoring reports.</Text>
                  <span />
                  <Button to={url('mail-settings')} text="Configure DMARC" theme="primary" />
                </Stack>
              )
          )}
        />
      </div>
    </Stack>
  )
}

const DmarcReports = () => {
  // Ensure that a date range is selected in url search string parameters
  const [params] = useSearchParams()
  if (!params.get('dateRange')) {
    const query = toSearchString(parseDateParams('dateRange', { date: 'pastWeek' }))
    return <Navigate to={`./?${query}`} replace />
  }

  return (
    <DmarcProvider
      loading={(
        <div className="message-log-empty">
          <Text tag="h4"><Loading text="Loading DMARC Log" /></Text>
        </div>
      )}
    >
      <DmarcReportsTable />
    </DmarcProvider>
  )
}

export {
  DmarcReports,
}
