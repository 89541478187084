import React from 'react'

const DropContext = React.createContext()

const useDrag = (options = {}) => {
  const { data, type = 'text' } = options
  const [dragging, setDragging] = React.useState(false)
  const ref = React.useRef()

  const onDragStart = React.useCallback((event) => {
    setDragging(true)
    if (typeof data !== 'undefined') event.dataTransfer.setData(type, JSON.stringify(data))
  }, [data?.from, data?.fromListId])

  const onDragEnd = React.useCallback(() => {
    setDragging(false)
  }, [])

  React.useEffect(() => {
    const el = ref.current
    if (el) {
      el.addEventListener('dragstart', onDragStart)
      el.addEventListener('dragend', onDragEnd)
      // We intentionally set the drop even to also fire the dragEnd event
      // This is because the drop event may trigger a re-render and unload the
      // dragEnd before it fires.
      el.addEventListener('drop', onDragEnd)
    }
    return () => {
      if (el) {
        el.removeEventListener('dragstart', onDragStart)
        el.removeEventListener('dragend', onDragEnd)
        el.removeEventListener('drop', onDragEnd)
      }
    }
  }, [onDragStart])

  return {
    dragging,
    ref,
  }
}

const DragAndDropProvider = (props) => {
  const [dragData, setDragData] = React.useState(null)
  const { ref: dragRef, dragging } = useDrag()

  const value = React.useMemo(() => ({
    dragData,
    setDragData,
    dragging,
  }), [
    dragData,
    dragging,
  ])

  return (
    <DropContext.Provider value={value}>
      <div
        className="level-drag-wrapper"
        data-dragging-item={dragging}
        ref={dragRef}
        {...props}
      />
    </DropContext.Provider>
  )
}

const useDragAndDrop = () => React.useContext(DropContext)

export {
  useDrag,
  useDragAndDrop,
  DragAndDropProvider,
}
