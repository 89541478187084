import React from 'react'

import {
  Notice, Text, Stack, Link, Card, P, Divider,
  Input, Button, ButtonShelf, SettingsGrid,
  useDialog,
} from '@level'
import {
  useTeam, useFormQuery, useProfile,
} from '@app/hooks'

const TeamDisabled = () => {
  const { Team: { name } } = useTeam()
  return (
    <Notice space={[10, 5]} theme="warning">
      <Stack gap={5} align="center">
        <Text tag="h2">Team Disabled</Text>
        <Text tag="p">
          The <strong>{name}</strong> team has been disabled. To re-enable this team, please <Link to="mailto:support@enveloop.com" text="contact support" />.
        </Text>
      </Stack>
    </Notice>
  )
}

const disableTeamMutation = ({ Team }) => `mutation {
  updateTeam(team: "${Team.slug}", attributes: { disabled: ${!Team.disabled} } ) {
    slug
  }
}`

const DisableTeam = () => {
  const { Team } = useTeam()
  const { hardRefresh: refreshProfile } = useProfile()
  const sendDialog = useDialog()

  const { onSubmit: toggleDisableTeam } = useFormQuery({
    query: disableTeamMutation,
    queryOptions: { headers: { 'Impersonated-User': null } },
    queryProps: { Team },
    onSuccess: () => refreshProfile(),
  })

  const confirmDisable = () => sendDialog({
    onConfirm: toggleDisableTeam,
    title: `Disable "${Team.name}" team?`,
    content: (
      <Stack gap={8}>
        <Text>
          Disabling this team will remove access for all users
          to all features except for team settings.
        </Text>
        <Input
          name="identity"
          label={`Type "${Team.name}" below to confirm.`}
          placeholder={Team.name}
          pattern={`^${Team.name.replace(/([+.])/g, '\\$1')}$`}
          messages={{ pattern: `Please type "${Team.name}" to confirm.` }}
          required
        />
      </Stack>
    ),
    confirmText: 'Disable Team',
    destructive: true,
  })

  return (
    <>
      <Divider flush={8} />
      <SettingsGrid>
        <Stack gap={6}>
          <Text tag="h3">Disable Team</Text>
          <P>Only Enveloopers can disable a team.</P>
        </Stack>
        <Card>
          <Stack gap={5}>
            <P>
              Disabling this team will remove access for all users
              to all features except for team settings.
            </P>
            <ButtonShelf valign="center">
              { Team.disabled ? (
                <Button text="Enable Team" theme="primary-green" onClick={toggleDisableTeam} />
              ) : (
                <Button text="Disable Team" theme="primary-destructive" onClick={confirmDisable} />
              )}
            </ButtonShelf>
          </Stack>
        </Card>
      </SettingsGrid>
    </>
  )
}

export {
  TeamDisabled,
  DisableTeam,
}
