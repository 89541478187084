import React from 'react'
import PropTypes from 'prop-types'

import './content-editable-input.scss'

const ContentEditableInput = ({
  onChange,
  placeholder,
  defaultValue,
  className,
  ...props
}) => {
  const value = React.useRef(defaultValue)
  const [empty, setEmpty] = React.useState(!defaultValue)
  const [style, setStyle] = React.useState({ left: 0, top: 0 })
  const ref = React.useRef()

  const onInput = onChange
    ? ({ target }) => {
      const text = target.innerText.trim()
      onChange(text)

      const isEmpty = !text
      setEmpty(isEmpty)

      // If empty, remove all whitesppace characters

      /* if (isEmpty) target.innerText = text */
    } : null

  // Set position of placeholder lining up with the text position
  React.useEffect(() => {
    if (onInput) {
      ref.current.insertAdjacentHTML('afterbegin', '<div>hi</div>')
      const div = ref.current.firstElementChild
      const divRect = div.getClientRects()[0]
      const el = div.offsetParent
      const elRect = el.getClientRects()[0]
      const topBorder = window.getComputedStyle(el).borderTopWidth

      setStyle({
        left: div.offsetLeft,
        top: divRect.top - elRect.top - Number.parseInt(topBorder, 10),
        textAlign: window.getComputedStyle(ref.current),
      })

      ref.current.removeChild(ref.current.firstElementChild)
    }
  }, [])

  React.useEffect(() => {
    value.current = defaultValue
    ref.current.innerText = defaultValue
  }, [defaultValue])

  return (
    <div className="level-content-editable">
      <div
        {...props}
        className={`${className} level-content-editable-input`}
        aria-placeholder={onInput ? placeholder : null}
        onInput={onInput}
        contentEditable={!!onInput || null}
        suppressContentEditableWarning
        tabIndex={onInput ? 0 : null}
        data-empty={empty || null}
        ref={ref}
      >
        {value.current}
      </div>
      {onInput ? (
        <div
          style={{
            position: 'absolute',
            opacity: !empty ? 0 : 0.4,
            pointerEvents: 'none',
            ...style,
          }}
          aria-hidden="true"
          className="level-content-editable-placeholder"
        >{placeholder}
        </div>
      ) : null}
    </div>
  )
}

ContentEditableInput.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  className: PropTypes.string,
}

export {
  ContentEditableInput,
}

