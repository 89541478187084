import PropTypes from 'prop-types'

import {
  camelize,
  toColor,
  dasherize,
} from '../../helpers'

// Transofrmed through SVGR
import * as icons from './icons'
const iconNames = Object.keys(icons).map(dasherize)
import sizes from '../../constants/fontSize'
import { Block } from '../Block'
import './icon.css'


const cascadeSize = (...args) => (
  args.find((a) => typeof a !== 'undefined')
)

const Icon = ({
  name,
  color,
  fill,
  size = 'default',
  className,
  width: widthProp,
  height: heightProp,
  space,
  ...rest
}) => {
  const Svg = icons[camelize(name)]

  if (!Svg) return null

  const width = cascadeSize(widthProp, heightProp, size)
  const height = cascadeSize(heightProp, width, size)

  const svg = (
    <Svg
      className={`level-icon${className ? ` ${className}` : ''}`}
      data-name={name}
      fill={fill || toColor(color) || 'currentColor'}
      width={sizes[width] || width}
      height={sizes[height] || height}
      {...rest}
    />
  )
  return typeof space !== 'undefined'
    ? <Block space={space}>{svg}</Block>
    : svg
}

Icon.propTypes = {
  name: PropTypes.oneOf(iconNames).isRequired,
  size: PropTypes.oneOf(Object.keys(sizes).map((n) => parseInt(n, 10) || n)),
  fill: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ...toColor.propTypes,
}

export {
  Icon,
  iconNames,
}
