import React from 'react'
import { TextFilter } from '@level'

const ToFilter = () => (
  <TextFilter
    name="to"
    placeholder="Name, email, or number"
  />
)

export {
  ToFilter,
}
