import PropTypes from 'prop-types'
import { TextBadge } from '../Badge'
import { getInitials } from '../../helpers'

import { badgeSizes } from '../Badge'

const getSize = ({
  size,
  width = size,
  height = width,
}) => {
  const shapeWidth = badgeSizes[width] || width
  const shapeHeight = badgeSizes[height] || height
  return ({
    width: shapeWidth,
    height: shapeHeight,
  })
}

const Avatar = ({
  name,
  email,
  src,
  size = 1,
  gradient = false,
  shape = 'rect',
  ...props
}) => {
  const { width, height } = getSize({ ...props, size })
  return src ? (
    <div
      className="level-avatar"
      style={{
        backgroundImage: `url(${src})`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        borderRadius: props.shape === 'rect' ? '15%' : '50%',
        width,
        height,
      }}
    />
  ) : (
    <TextBadge
      text={getInitials(name)}
      pickFill={gradient ? null : email}
      pickGradient={gradient ? email : null}
      className="level-avatar"
      size={size}
      shape={shape}
      {...props}
    />
  )
}

Avatar.propTypes = {
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  size: TextBadge.propTypes.size,
  shape: TextBadge.propTypes.shape,
  gradient: PropTypes.bool,
  src: PropTypes.string,
}

export {
  Avatar,
}
