import { linter } from '@codemirror/lint'
import { json, jsonParseLinter } from '@codemirror/lang-json'
import { xml } from '@codemirror/lang-xml'
import { python } from '@codemirror/lang-python'
import { javascript } from '@codemirror/lang-javascript'

const langs = {
  json: { config: json, parser: jsonParseLinter },
  js: { config: javascript, parser: null },
  xml: { config: xml, parser: null },
  python: { config: python, parser: null },
  node: { config: javascript, parser: null },
  ruby: { config: json, parser: null },
  curl: { config: json, parser: null },
}

const langConfig = (lang) => {
  const lintLang = langs[lang]
  if (!lintLang) return []
  const { config, parser } = lintLang

  const configs = [config()]
  if (parser) configs.push(linter(parser()))

  return configs
}

export { langConfig }
