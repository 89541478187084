import React from 'react'

import { Button } from '@level'
import { useFormQuery } from '@app/hooks'

import {
  createEventWebhookQuery,
  useEventWebhooks,
} from './queries'

import { useWebhookDialog } from './webhookDialog'

const AddWebhook = (props) => {
  const { refresh, Project } = useEventWebhooks()

  const { onSubmit: onConfirm } = useFormQuery({
    query: createEventWebhookQuery,
    queryProps: { Project, enabled: true },
    onSuccess: refresh,
    successMessage: false,
  })

  const addWebhook = useWebhookDialog({
    title: 'Add Webhook',
    confirmText: 'Add Webhook',
    onConfirm,
  })

  return (
    <Button
      label="Add Webhook"
      onClick={addWebhook}
      text="Add Webhook"
      {...props}
    />
  )
}

export {
  AddWebhook,
}
