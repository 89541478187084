import React from 'react'
import PropTypes from 'prop-types'

import {
  Select,
  useCleanPaste, useDebounce, asyncMap,
} from '@level'
import {
  useUploadImage,
  useFormDialog,
  useProject,
  allowedFromSmsOptions,
  useVerifyProvider,
} from '@app/hooks'
import { useTemplate } from '../context'
import { PreviewSMSTemplate } from './preview'

const SelectFromNumber = ({ getOptions, defaultValue, ...rest }) => {
  const [options, setOptions] = React.useState([{ label: 'Loading…', value: defaultValue || '' }])

  React.useEffect(() => {
    (async () => {
      const { data: { provider: { allowedFrom } } } = await getOptions()
      const opts = allowedFromSmsOptions({ allowedFrom })
      if (opts) {
        setOptions(opts)
      }
    })()
  }, [])
  return (
    <Select
      defaultValue={defaultValue}
      options={options}
      {...rest}
    />
  )
}

SelectFromNumber.propTypes = {
  getOptions: PropTypes.func.isRequired,
  defaultValue: PropTypes.any.isRequired,
}

const SMSTemplateBuilder = () => {
  const { template, channel } = useTemplate()
  const uploadImage = useUploadImage({ query: { width: 500, height: 500 } })
  const [updated, setUpdated] = React.useState()
  const { Project: { smsProvider } } = useProject()
  const { verify } = useVerifyProvider()
  const sendDialog = useFormDialog()

  useCleanPaste()

  // Message is received and acted on templates/context
  const onChange = useDebounce(async ({ config, name, slug }) => {
    channel.postMessage({ update: { config, name, slug } })
  }, [channel, template?.id], 500)

  const onChangeBody = React.useCallback((body) => {
    template.previewConfig.body = body
    onChange({ config: template.previewConfig })
  }, [onChange, template.config])

  const changeFrom = React.useCallback(async () => {
    const defaultFrom = template.teamDefaultFrom
      || template.previewConfig.variables.config.defaultFrom

    const onConfirm = async ({ data }) => {
      if (template.previewConfig.variables.config.defaultFrom !== data.defaultFrom) {
        template.previewConfig.variables.config.defaultFrom = data.defaultFrom
        onChange({ config: template.previewConfig })
      }
    }

    const getOptions = () => verify({ type: 'smsProvider', ...smsProvider })

    sendDialog({
      onConfirm,
      title: 'Set SMS From Number',
      content: (
        <SelectFromNumber
          name="defaultFrom"
          label="From Number"
          defaultValue={defaultFrom}
          getOptions={getOptions}
        />
      ),
    })
  }, [onChange, template.teamDefaultFrom])

  const addImage = React.useCallback(async (fileList) => {
    template.previewConfig.variables ||= {}
    template.previewConfig.variables.images ||= []

    const urls = await asyncMap(fileList, (image) => uploadImage(image))
    template.previewConfig.variables.images.push(...urls.map(({ url }) => url))

    onChange({ config: template.previewConfig })
    setUpdated({})
  }, [onChange])

  const onDropImage = React.useCallback(({ data }) => {
    addImage(data)
  }, [addImage])

  const removeImage = React.useCallback((src) => {
    const { images } = template.previewConfig.variables
    template.previewConfig.variables.images = images.filter((image) => image !== src)
    onChange({ config: template.previewConfig })
    setUpdated({})
  }, [onChange])

  const hooks = React.useMemo(() => ({
    onChange,
    onChangeBody,
    changeFrom: template.teamDefaultFrom ? changeFrom : null,
    addImage,
    removeImage,
    onDropImage,
  }), [onChange, updated])

  return (
    <PreviewSMSTemplate
      hooks={hooks}
      mode="builder"
    />
  )
}

export {
  SMSTemplateBuilder,
}
