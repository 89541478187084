import PropTypes from 'prop-types'

import { Link } from '../Link'
import { Grid } from '../Grid'

import './nav-panel.scss'

const NavPanel = ({ navItems = [], ...props }) => (
  <Grid
    tag="nav"
    className="level-nav-panel"
    auto
    {...props}
  >
    {navItems.map((itemProps) => {
      const { to, text } = itemProps
      return <Link key={to || text} className="level-nav-panel-item" to={to} {...itemProps} />
    })}
  </Grid>
)

NavPanel.propTypes = {
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
    }),
  ),
}

export {
  NavPanel,
}
