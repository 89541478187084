import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { renderToString } from 'react-dom/server'
import { sentenceJoin } from './text'

// Checks the type of a react Element
// - first by its type.name
// - second by its props.typeOf
// props.typeOf allows a component to delcare API compatability with another component type
const isOfType = (component, type) => {
  const types = Array.isArray(type) ? type : [type]
  return component && (
    types.includes(component?.type?.name)
    || types.includes(component?.props?.ofType)
  )
}

// Accepts a components children prop, returning the number of children of which match isOfType
const hasChildOfType = (children, type) => (
  React.Children.toArray(children).filter((child) => isOfType(child, type)).length
)

// Merges component props with passed object, proioritizing component's props
const mergeProps = (component, props) => (
  component ? React.cloneElement(component, { ...props, ...component.props }) : null
)

// Merges component props with passed object, proioritizing argument props
const overrideProps = (component, props) => (
  component ? React.cloneElement(component, { ...component.props, ...props }) : null
)

// Wrap react-dom render because it appends attributes to the root element
// This creates a placeholder root and removes it
const jsxToString = (element) => {
  const base = renderToString(<div>{element}</div>)
  /* console.warn({ base }) */
  return base.replace(/^<div>/, '').replace(/<\/div>$/, '')
    .replace(/<raw>/g, '')
    .replace(/<\/raw>/g, '')
}
const renderString = (str, options = {}) => (str ? parse(str, options) : str)

const injectHtml = (content) => ({
  dangerouslySetInnerHTML: { __html: typeof content === 'string' ? content : jsxToString(content) },
})

const Raw = ({ tag: Tag = 'raw', text }) => (
  <Tag {...injectHtml(text)} />
)

Raw.propTypes = {
  tag: PropTypes.string,
  text: PropTypes.string.isRequired,
}

const toPadding = (value) => {
  const padding = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  }
  if (value === null || typeof value === 'undefined') return undefined
  if (value === '') return padding

  const vals = value.split(' ')

  if (vals.length === 1) {
    return toPadding([value, value, value, value].join(' '))
  }
  if (vals.length === 2) {
    return toPadding([value[0], value[1], value[0], value[1]].join(' '))
  }
  if (vals.length === 3) {
    return toPadding([value[0], value[1], value[2], value[1]].join(' '))
  }

  const [top, right, bottom, left] = value.split(' ').map((n) => Number.parseInt(n, 10) || 0)
  return {
    top, right, bottom, left,
  }
}

const toPaddingValue = ({
  top, right, bottom, left,
}) => [top, right, bottom, left].map((n) => `${Number.parseInt(n, 10) || 0}px`).join(' ')

// Allow a propType validation where it is only required if other props are omited
// Example usage:
//   // Requires `text` of type 'string' unless `children` prop is present.
//   Button.propTypes = {
//     text: typeRequiredUnless({ type: PropTypes.string, present: 'children' }),
//  }
//
// Another Example:
//   // Requiers `text` of types 'string' or 'node', unless `children` or `label` prop is present.
//   Button.propTypes = {
//     text: typeRequiredUnless({
//       type: [PropTypes.string, PropTypes.node],
//       present: ['children', 'label']
//     }),
//  }
const typeRequiredUnless = ({ present, type }) => (
  PropTypes.oneOfType([...([].concat(type)), (props, propName) => {
    // concat/map present value, to see if any exist in the props
    const allPresent = [].concat(present)
    const isPresent = allPresent.map((val) => props[val]).filter((v) => v).length

    const { [props]: prop } = props
    if (!prop && !isPresent) {
      return new Error(
        `Prop ${propName} is required when ${sentenceJoin(allPresent)} ${allPresent.length < 2 ? 'is' : 'are'} absent.`,
      )
    }
    return null
  }])
)

export {
  isOfType,
  hasChildOfType,
  mergeProps,
  overrideProps,
  jsxToString,
  injectHtml,
  typeRequiredUnless,
  toPadding,
  toPaddingValue,
  renderString,
  Raw,
}
