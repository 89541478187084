import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'

import { NotFound } from '@level'
import { ProjectProvider } from '@app/hooks'
import { TemplatesIndex } from '../templates'
import { SharedSectionsIndex } from '../sharedSections'
import { MessagesIndex } from './messages'
import { ErrorLogIndex } from './errorLog'
import { ProjectSettingsIndex } from './settings'

const ProjectsIndex = () => (
  <Routes>
    <Route index element={null} />
    <Route path=":projectId/*" element={<ProjectProvider />}>
      <Route index element={<Navigate to="templates" replace />} />
      <Route path="templates/*" element={<TemplatesIndex />} />
      <Route path="shared-sections/*" element={<SharedSectionsIndex />} />
      <Route path="messages/*" element={<MessagesIndex />} />
      <Route path="errors/*" element={<ErrorLogIndex />} />
      <Route path="settings/*" element={<ProjectSettingsIndex />} />
      <Route path="not-found" element={<NotFound title="Project Not Found" />} />
      <Route path="*" element={<Navigate to="./" />} />
    </Route>
    <Route path="*" element={<Navigate to=".." />} />
  </Routes>
)

export {
  ProjectsIndex,
}
