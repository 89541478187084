import React from 'react'
import PropTypes from 'prop-types'

import { AsUserBanner } from '@app/hooks'
import {
  Form, SubmitButton, Layout, Stack,
} from '@level'

import { RandomInput } from '@app/components/RandomInput'

import { WelcomeCard } from './WelcomeCard'

const WelcomeProject = ({ onSubmit, welcomeData }) => {
  const [projectName, setProjectName] = React.useState()
  return (
    <Layout
      root={<AsUserBanner />}
      className="level-layout-welcome"
    >
      <WelcomeCard
        title="Add a Project to Your Team"
        description="Projects organize data and messages for a single app or purpose. You can create more projects later."
        projectName={projectName}
        welcomeData={welcomeData}
      >
        <Form
          onSubmit={onSubmit}
          onChange={(data) => setProjectName(data.name)}
          debounceSubmit={5000}
        >
          <Stack gap={10}>
            <RandomInput
              name="name"
              placeholder="Pawnee Commons (Lot 48)"
              label="Project Name"
              required
            />
            <SubmitButton text="Next" grow />
          </Stack>
        </Form>
      </WelcomeCard>
    </Layout>
  )
}

WelcomeProject.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  welcomeData: PropTypes.object.isRequired,
}

export {
  WelcomeProject,
}
