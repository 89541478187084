import React from 'react'

import {
  Navigate, Routes, Route, useParams, useSearchParams,
} from 'react-router-dom'

import {
  Shelf, Main, NavPanel, Text, Spacer, Header, Loading, Button, Stack, Grid,
  useSearchState, useThrottle, getDateRange, toSearchString,
} from '@level'

import { useTeamChannel } from '@app/hooks'

import { ErrorLogProvider, ErrorTemplateProvider, useErrorLog } from './provider'
import { ErrorsTable } from './table'
import {
  SdkVersionFilterPill,
  DateFilterPill,
  ErrorFilterPill,
} from './filters'

const LiveLogs = ({ enabled, onChange }) => {
  const { env } = useParams()
  const [params, setParams] = useSearchState()
  const { refresh, items } = useErrorLog()
  const [live, setLive] = React.useState(enabled)
  const isLive = React.useRef(enabled)
  const [hover, setHover] = React.useState()
  const dirty = React.useRef(false)

  const throttledRefresh = useThrottle(() => {
    refresh()
  }, [refresh], 3000)

  React.useEffect(() => {
    isLive.current = enabled && (!params?.date || params.date === 'all')
    setLive(enabled && (!params?.date || params.date === 'all'))
  }, [enabled, params?.date])

  const ToggleLive = () => {
    setLive((current) => {
      isLive.current = !current
      return !current
    })
  }

  React.useEffect(() => {
    if (live && params.date) {
      setParams.clear(['date', 'createdAt'])
    }
    if (live && dirty.current) {
      throttledRefresh()
      dirty.current = false
    }
    onChange(live)
  }, [live])

  // A new item comes in
  const reload = React.useCallback((msg) => {
    if (isLive.current) {
      const { testMode } = msg.data
      const currentEnv = {
        true: 'sandbox',
        false: 'live',
      }[testMode]

      if (currentEnv === env) throttledRefresh()
      dirty.current = false
    } else {
      dirty.current = true
    }
  }, [throttledRefresh])

  useTeamChannel({ event: 'errorSent', handler: reload })

  const icon = (() => {
    if (hover && live) return { name: 'pause', color: 'neutral-500' }
    if (live) return { name: 'circle-small', color: 'green-500' }
    if (hover) return { name: 'play', color: 'green-500' }
    return { name: 'pause', color: 'neutral-500' }
  })()

  const text = (() => {
    if (live) return 'Live Logs'
    if (hover) return 'Resume Live Logs'
    return 'Live Logs Paused'
  })()

  return (
    <Grid gap={5} templateColumns="auto 1fr auto" valign="top">
      <Button
        className="live-log-toggle"
        icon={icon}
        onClick={() => ToggleLive()}
        theme="light"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        data-live={(live && !hover) || null}
        data-hover={hover || null}
      >
        { text }
      </Button>
      <Shelf gap={4} className="message-filter-pills">
        <SdkVersionFilterPill params={params} />
        <DateFilterPill params={params} />
        <ErrorFilterPill params={params} />
      </Shelf>
      { items?.length || Object.keys(params).length ? (
        <Button
          theme="light"
          className="clear-message-filters"
          icon={{ name: 'x-circle', color: 'neutral-300' }}
          onClick={() => setParams({})}
          text="Clear Filters"
          disabled={!Object.keys(params)?.length}
        />
      ) : null }
    </Grid>
  )
}

const ErrorsInbox = () => {
  const { env } = useParams()
  const testMode = env !== 'live'
  const [selectedItem, setSelectItem] = React.useState(null)
  const selectItem = (item) => setSelectItem(item)
  const [scrolled, setScrolled] = React.useState(false)
  const scrollEl = React.useRef()

  const onScroll = ({ target }) => {
    if (target.scrollTop < 1) {
      scrollEl.current = null
      setScrolled(false)
    } else {
      if (!scrollEl.current) { setScrolled(true) }
      scrollEl.current = target
    }
  }

  const onChangeLive = React.useCallback((isLive) => {
    if (isLive && scrollEl.current) {
      scrollEl.current.scrollTop = 0
    }
  }, [scrolled])

  return (
    <ErrorTemplateProvider>
      <ErrorLogProvider
        testMode={testMode}
        loading={(
          <div className="message-log-empty">
            <Text tag="h4"><Loading text="Loading Messages" /></Text>
          </div>
        )}
      >
        <Stack gap={6} space={6} className="message-log">
          <LiveLogs enabled={!selectedItem && !scrolled} onChange={onChangeLive} />
          <Grid gap={5} templateColumns="auto 1fr auto" valign="top" />
          <ErrorsTable
            selectItem={selectItem}
            onScroll={onScroll}
          />
        </Stack>
      </ErrorLogProvider>
    </ErrorTemplateProvider>
  )
}

const ErrorLogIndex = () => {
  const defaultDate = toSearchString({
    date: 'pastTwoWeeks', createdAt: getDateRange({ span: 'pastTwoWeeks' }).map((d) => d.valueOf()),
  })
  const [search] = useSearchParams()
  const inboxNav = [
    { text: 'Live', to: `live?${search}` },
    { text: 'Sandbox', to: `sandbox?${search}`, color: 'purple-600' },
  ]
  return (
    <Main>
      <Header primary columns={3}>
        <Text tag="h2">Error Log</Text>
        <Spacer grow />
        <NavPanel navItems={inboxNav} />
        <Spacer grow />
      </Header>
      <Routes>
        <Route index element={<Navigate to={`./live?${defaultDate}`} replace />} />
        <Route path="/:env/*" element={<ErrorsInbox />} />
      </Routes>
    </Main>
  )
}

export {
  ErrorLogIndex,
}
