import React from 'react'

import { CheckFilter } from '@level'
import { MessageIcon } from '../icon'

const StatusFilter = () => {
  const options = [
    { value: 'queued', icon: <MessageIcon name="queued" /> },
    { value: 'sent', icon: <MessageIcon name="sent" /> },
    { value: 'delivered', icon: <MessageIcon name="delivered" /> },
    { value: 'failed', icon: <MessageIcon name="failed" /> },
  ]
  return (
    <CheckFilter
      name="status"
      options={options}
    />
  )
}

export {
  StatusFilter,
}
