import { Input } from '@level'
import { useProfile, useFormDialog } from '@app/hooks'
import { updateProfileQuery } from './settings'

const useAddPhoneDialog = () => {
  const { refresh, Profile } = useProfile()
  const sendDialog = useFormDialog({
    query: updateProfileQuery,
    onSuccess: refresh,
  })

  return () => sendDialog({
    title: 'Add Account Phone Number',
    confirmText: 'Add Phone',
    content: (
      <Input
        name="phone"
        label="Phone Number"
        placeholder="+1 222 153 1513"
        defaultValue={Profile.phone}
        required
      />
    ),
  })
}

export {
  useAddPhoneDialog,
}
