import { DragOverlay as Overlay, useDndMonitor } from '@dnd-kit/core'
import PropTypes from 'prop-types'
import React from 'react'
import { EmailTable } from '../../../helpers/email'
import { getTemplateElement, getTemplateSection } from '../../../helpers/template'
import { useTemplate } from '../useTemplate'
import Elements from './Element/Elements'
import { Section } from './Section/Section'

const DragElement = ({ config }) => {
  const { config: { variables } } = useTemplate()
  const Element = Elements[config.elementType]
  return (
    <EmailTable>
      <tbody>
        <tr className="enveloop-section" data-mode="builder">
          <Element
            mode="builder-drag-clone-element"
            element={config.element}
            varPath={`columns.${config.columnIndex}.elements.${config.elementIndex}.config`}
            sectionConfig={config.section.variables.config}
            columnConfig={config.section.variables.columns[config.columnIndex]}
            sectionId={config.section.variables.id}
            templateVariables={variables}
          />
        </tr>
      </tbody>
    </EmailTable>
  )
}

DragElement.propTypes = {
  config: PropTypes.object,
}

const DragSection = ({ config }) => {
  const { config: { variables } } = useTemplate()
  return (
    <EmailTable>
      <tbody>
        <Section
          mode="builder-drag-clone-section"
          variables={config.section.variables}
          sharedSectionId={config.section.sharedSectionId}
          templateVariables={variables}
        />
      </tbody>
    </EmailTable>
  )
}

DragSection.propTypes = {
  config: PropTypes.object,
}

const DragClone = ({ active }) => {
  if (active?.type === 'element') return <DragElement {...active} />
  if (active?.type === 'section') return <DragSection {...active} />
  return undefined
}

DragClone.propTypes = {
  active: PropTypes.object,
}

const DragOverlay = () => {
  const { config } = useTemplate()
  const [active, setActive] = React.useState(null)

  useDndMonitor({
    onDragStart: ({ active: { id } }) => {
      const type = id.match(/element/) ? 'element' : 'section'
      const obj = type === 'element'
        ? getTemplateElement({ config, elementId: id })
        : getTemplateSection({ config, sectionId: id })
      setActive({ id, type, config: obj })
    },
  })

  return (
    <Overlay>
      <DragClone active={active} />
    </Overlay>
  )
}

export { DragOverlay }
