import { useFormDialog } from './useFormDialog'

const useConfirmDelete = ({ onDelete, ...rest }) => {
  const sendDialog = useFormDialog({ onSuccess: onDelete, ...rest })

  return (props) => sendDialog({
    confirmText: 'Delete',
    ...props,
    destructive: true,
  })
}

export {
  useConfirmDelete,
}
