import { templateConfigDefaults, addThemeColors } from '@level'

const themeColors = {
  dark: {
    templateBg: '#1B1C1D',
    contentBg: '#303132',
    border: '#505153',
    heading: '#FFFFFF',
    tableBg: '#3d3e3f',
    text: '#C4C6CA',
    textSubtle: '#9A9B9F',
    textFooter: '#6C6D71',
  },
  light: {
    templateBg: '#F9F9F9',
    contentBg: '#FFFFFF',
    border: '#DEDFE2',
    tableBg: '#F9F9F9',
    heading: '#303132',
    text: '#454648',
    textSubtle: '#6F7074',
    textFooter: '#9A9B9F',
  },
}

const theme = ({ color, ...config }) => {
  const { _theme, ...colors } = addThemeColors({
    linkColor: '#06AC75',
    ...themeColors[color],
  })

  return ({
    colors,
    hasThemes: true,
    variables: {
      style: {
        ...templateConfigDefaults.template.style,
        color: colors.text,
        backgroundColor: colors.templateBg,
        contentBackgroundColor: colors.contentBg,
        linkColor: colors.linkColor,
        fontFamily: 'sans-serif',
        padding: '30px 10px 30px 10px',
        width: '640',
      },
      config: {
        ...templateConfigDefaults.template.config,
        ...config,
      },
      _theme,
      defaults: {
        section: {
          padding: '30px 60px 30px 60px',
        },
        Divider: {
          padding: '10px 0 10px 0',
        },
        Button: {
          cornerStyle: 'rounded',
          padding: '25px 0px 25px 0px',
          fontWeight: 600,
          fontSize: '12px',
        },
      },
    },
  })
}

const colors = Object.keys(themeColors)

export {
  theme,
  colors,
}
