import React from 'react'
import PropTypes from 'prop-types'

import { useParams } from 'react-router-dom'
import { QueryProvider, useProject } from '@app/hooks'
import { useSearchState, includeFilterParams } from '@level'
import { messagesQuery, operators } from './queries'

const templatesQuery = ({ teamId, projectId }) => (
  `templates(team: "${teamId}", project: "${projectId}") { id name slug type published }`
)

const TemplateContext = React.createContext()
const useMessageTemplates = () => React.useContext(TemplateContext)

const MessageTemplateProvider = (props) => {
  const { teamId, projectId } = useParams()

  const onData = React.useCallback(({ data }) => ({
    templates: data.templates.filter(({ published }) => published) || [],
  }), [])
  return (
    <QueryProvider
      query={templatesQuery({ teamId, projectId })}
      context={TemplateContext}
      onData={onData}
      {...props}
    />
  )
}

const templateLink = ({
  templates, template,
}) => {
  if (template?.slug && templates.find(({ slug }) => slug === template?.slug)) {
    return `templates/template/${template.slug}`
  }
  return undefined
}

const MessagesContext = React.createContext()
const MessageLogProvider = ({ testMode = false, ...rest }) => {
  const {
    url,
    Project: {
      team: { slug: teamId },
      slug: projectId,
    },
  } = useProject()

  const [searchState] = useSearchState()
  // This will prevent changes which do not affect the query from triggering a refresh
  // Some Params store UI state and do not impact the query.
  const filters = includeFilterParams(searchState, operators)
  const { templates } = useMessageTemplates()

  const queryVars = React.useMemo(() => ({
    teamId, projectId, testMode, ...filters,
  }), [messagesQuery({
    teamId, projectId, testMode, ...filters,
  })])

  const onData = React.useCallback(({ data }) => ({
    messages: data.messages?.map((message) => {
      const link = templateLink({
        teamId, projectId, templates, template: message.template,
      })
      return {
        ...message,
        templateLink: link ? url(link) : null,
        rowTheme: message.status === 'failed' ? 'failed' : 'default',
      }
    }) || [],
  }), [])

  return (
    <QueryProvider
      query={messagesQuery}
      context={MessagesContext}
      queryVars={queryVars}
      rowVariable="messages"
      onData={onData}
      limit={50}
      infinite
      {...rest}
    />
  )
}

const useMessageLog = () => React.useContext(MessagesContext)

MessageLogProvider.propTypes = {
  testMode: PropTypes.bool,
}

export {
  MessageLogProvider,
  useMessageLog,
  MessageTemplateProvider,
  useMessageTemplates,
}
