import {
  useProject, useUpdateProjectQuery, useConfirmDelete,
} from '@app/hooks'

const useDeleteIntegration = () => {
  const { refresh } = useProject()
  const updateProjectQuery = useUpdateProjectQuery()

  const confirmDelete = useConfirmDelete({
    query: updateProjectQuery,
    onSuccess: refresh,
    successMessage: 'Integration Deleted',
    redirect: '../',
  })

  return ({ name, type }) => confirmDelete({
    data: { [type]: null },
    title: `Delete ${name} integration?`,
    content: 'This will disable all activies currently using this integration. Are you sure?',
    confirmText: 'Delete Integration',
  })
}

export {
  useDeleteIntegration,
}
