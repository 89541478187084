import React from 'react'
import { useParams } from 'react-router-dom'

import { QueryProvider } from '@app/hooks'
import { useSearchState } from '@level'
import { dmarcQuery } from './queries'

const DmarcContext = React.createContext()

export const useDmarcReports = () => React.useContext(DmarcContext)

export const DmarcProvider = (props) => {
  const { teamId } = useParams()
  const [params] = useSearchState()
  const queryVars = React.useMemo(() => ({
    teamId, dateRange: params.dateRange, domain: params.domain,
  }), [teamId, params.dateRange, params.domain])
  const onData = React.useCallback(({ data }) => ({
    dmarcReports: data.dmarcReports?.map((report) => {
      const { metrics } = report
      return {
        ...report,
        id: report.reportId,
        emails: metrics.total,
        dmarc: (metrics.passed / metrics.total) * 100,
        dkim: (metrics.dkim.pass / metrics.total) * 100,
        spf: (metrics.spf.pass / metrics.total) * 100,
        date: report.startDate,
        records: report.dmarcReportRecords,
      }
    }) || [],
  }), [])

  return (
    <QueryProvider
      query={dmarcQuery}
      context={DmarcContext}
      queryVars={queryVars}
      onData={onData}
      {...props}
    />
  )
}
