import { toParams } from '@level'
import PropTypes from 'prop-types'
import React from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { QueryProvider } from './QueryProvider'
import { useTeam } from './useTeam'

const ProjectContext = React.createContext({})

const useProject = () => React.useContext(ProjectContext)

const projectQuery = ({ teamId, projectId }) => `{
  Project: project(team: "${teamId}", project: "${projectId}") {
    id name slug createdAt
    team {
      name slug
      plan { allowSms }
    }
    mailProvider { config provider }
    smsProvider { config provider }
  } 
  tokens: apiTokens(team: "${teamId}", project: "${projectId}") {
    id name token testMode active createdAt
  }
}`

const useUpdateProjectQuery = () => {
  const { Project } = useProject()
  const literals = ['provider']
  return (attributes) => `mutation {
  Project: updateProject(id: "${Project.id}", attributes: { ${toParams(attributes, { literals })} }) {
    id slug name
    team { name slug }
  }
}`
}

const ProjectProvider = ({ children }) => {
  const { Team, Team: { slug: teamId }, url: teamUrl } = useTeam()
  const { projectId } = useParams()

  const onData = React.useCallback(({
    data: { Project: { mailProvider, smsProvider } },
  }) => ({
    defaultFromEmail: Team.defaultFromEmail || mailProvider?.config.defaultFrom,
    defaultFromSms: smsProvider?.config.defaultFrom,
    url: (path = '') => {
      const base = teamUrl(`projects/${projectId}`)
      return path ? `${base}/${path}` : base
    },
  }), [Team?.defaultFromEmail])

  return (
    <QueryProvider
      query={projectQuery({ teamId, projectId })}
      context={ProjectContext}
      onData={onData}
      redirectOnError={teamUrl('projects')}
      clearDataOnFetch
    >
      {children || <Outlet />}
    </QueryProvider>
  )
}

ProjectProvider.propTypes = {
  children: PropTypes.node,
}

export {
  ProjectProvider, useProject, useUpdateProjectQuery
}
