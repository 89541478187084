import { theme } from './theme'

const userInvite = {
  name: 'User Invite',
  config: ({ color, Team = { name: 'CompanyName' }, Project = { name: 'ProductName' } }) => {
    const {
      colors: {
        templateBg,
        heading,
        text,
        textSubtle,
        accentColor,
        tableBg,
      },
      variables,
    } = theme({ color, defaultSubject: 'Hi {{{ name }}}, You\'ve been invited' })

    return {
      variables,
      sections: [
        {
          sharedSectionId: null,
          variables: {
            config: {
              align: 'left',
              valign: 'top',
              padding: '0px 0px 0px 0px',
              elementSpacing: '0px',
              backgroundColor: templateBg,
              name: 'Header',
            },
            columns: [
              {
                config: {},
                elements: [
                  {
                    type: 'Text',
                    config: {
                      content: Team.name,
                      color: accentColor,
                      align: 'center',
                      padding: '40px 0px 40px 0px',
                      fontSize: '24px',
                      fontWeight: '700',
                    },
                  },
                ],
              },
            ],
          },
        },
        {
          sharedSectionId: null,
          variables: {
            config: {
              align: 'left',
              valign: 'top',
              padding: '0px 0px 0px 0px',
              elementSpacing: '0px',
              name: 'Section Divider',
            },
            columns: [
              {
                config: {},
                elements: [
                  {
                    type: 'Divider',
                    config: {
                      color: accentColor,
                      borderStyle: 'solid',
                      borderWidth: '10px',
                      padding: '0 0 0 0',
                    },
                  },
                ],
              },
            ],
          },
        },
        {
          sharedSectionId: null,
          variables: {
            config: {
              name: 'Content',
              align: 'center',
              valign: 'top',
              padding: '15px 40px 40px 40px',
              elementSpacing: '15px',
            },
            columns: [
              {
                config: {},
                elements: [
                  {
                    type: 'Image',
                    config: {
                      src: `https://assets.enveloop.com/templates/gallery/invite-header-${color}.png`,
                      width: '170',
                      padding: '20px 0px 20px 0px',
                    },
                  },
                  {
                    type: 'Heading',
                    config: {
                      fontSize: '44px',
                      text: `{{sender}} has invited you to join ${Project.name}`,
                      color: heading,
                      padding: '10px 0px 10px 0px',
                      fontWeight: 700,
                    },
                  },
                  {
                    type: 'Text',
                    config: {
                      padding: '15px 0px 15px 0px',
                      fontSize: '16px',
                      fontWeight: 400,
                      content:
                        `**{{sender}}** with **{{team}}** has invited you to collaborate on their project: **${Project.name}**.`,
                      color: text,
                    },
                  },
                  {
                    type: 'Text',
                    config: {
                      content: '*“{{message}}”*',
                      color: '#636D87',
                      padding: '25px 20px 25px 20px',
                      fontSize: '14px',
                      fontWeight: 400,
                      backgroundColor: tableBg,
                    },
                  },
                  {
                    type: 'Button',
                    config: {
                      minWidth: '0%',
                      buttonPadding: '10px 45px 10px 45px',
                      padding: '25px 0px 25px 0px',
                      fontSize: '14px',
                      fontWeight: 600,
                      cornerStyle: 'round',
                      text: 'Accept Invitation',
                      url: '{{{account_url}}}',
                    },
                  },
                ],
              },
            ],
          },
        },
        {
          sharedSectionId: null,
          variables: {
            config: {
              align: 'left',
              valign: 'top',
              padding: '0px 0px 0px 0px',
              elementSpacing: '0px',
              name: 'Section Divider',
            },
            columns: [
              {
                config: {},
                elements: [
                  {
                    type: 'Divider',
                    config: {
                      color: templateBg,
                      borderStyle: 'solid',
                      borderWidth: '8px',
                      padding: '0 0 0 0',
                    },
                  },
                ],
              },
            ],
          },
        },
        {
          sharedSectionId: null,
          variables: {
            config: {
              align: 'left',
              valign: 'top',
              padding: '15px 40px 15px 40px',
              elementSpacing: '15px',
              name: 'Footer Link',
            },
            columns: [
              {
                config: {},
                elements: [
                  {
                    type: 'Text',
                    config: {
                      content:
                        'If you have trouble with the button above, copy and paste<br /> the URL below into your web browser.\n\n{{account_url}}',
                      color: textSubtle,
                      align: 'center',
                      padding: '25px 0px 25px 0px',
                      fontSize: '10px',
                      fontWeight: '400',
                    },
                  },
                ],
              },
            ],
          },
        },
        {
          sharedSectionId: null,
          variables: {
            config: {
              align: 'left',
              valign: 'top',
              padding: '25px 0px 25px 0px',
              elementSpacing: '15px',
              backgroundColor: templateBg,
              name: 'Footer',
            },
            columns: [
              {
                config: {},
                elements: [
                  {
                    type: 'Text',
                    config: {
                      content:
                        `© Copyright ${new Date().getFullYear()} ${Team.name}. All Rights Reserved.\n\n${Team.name}    |    123 Main St, Anytown, USA`,
                      color: textSubtle,
                      padding: '0 0 0 0',
                      fontSize: '10px',
                      fontWeight: '400',
                    },
                  },
                ],
              },
            ],
          },
        },
      ],
    }
  },
}

export { userInvite }
