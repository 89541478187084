import PropTypes from 'prop-types'

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useDndContext,
  useSensor,
  useSensors,
} from '@dnd-kit/core'

import {
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable'

const SortableDragContext = (props) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      {...props}
    />
  )
}
const DragContext = ({
  sortable = false,
  ...props
}) => {
  if (sortable) return <SortableDragContext {...props} />
  return (
    <DndContext
      collisionDetection={closestCenter}
      {...props}
    />
  )
}

DragContext.propTypes = {
  sortable: PropTypes.bool,
}

export {
  DragContext,
  useDndContext,
}
