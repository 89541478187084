import { templateConfigDefaults, addThemeColors } from '@level'

const themeColors = {
  templateBg: '#ffffff',
  text: '#303132',
  linkColor: '#0000FF',
  border: '#dedfe2',
}

const theme = (config) => {
  const { _theme, ...colors } = addThemeColors(themeColors)

  return ({
    colors,
    variables: {
      style: {
        ...templateConfigDefaults.template.style,
        color: colors.text,
        backgroundColor: colors.templateBg,
        linkColor: colors.linkColor,
        fontFamily: 'sans-serif',
        padding: '20px 20px 20px 20px',
        width: '100%',
      },
      config: {
        ...templateConfigDefaults.template.config,
        ...config,
      },
      _theme,
      defaults: {
        section: {
          padding: '0',
        },
        Divider: {
          padding: '10px 0 10px 0',
        },
        Button: {
          cornerStyle: 'rounded',
          padding: '25px 0px 25px 0px',
          fontWeight: 600,
          fontSize: 1,
        },
      },
    },
  })
}

export {
  theme,
}
