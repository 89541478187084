import { objToPythonString, copyObject, toPythonParams } from '@level'

const pythonSnippet = ({
  data: { from, templateVariables, ...data }, token,
}) => {
  const options = copyObject(data)
  if (typeof from !== 'undefined') options.from_address = from

  return `from enveloop_py import api

enveloop = api.connect("${token}")

enveloop.send_message(
    ${toPythonParams(options, { pretty: true })},
    template_variables=${objToPythonString(templateVariables, 2)}
)`
}

export {
  pythonSnippet,
}
