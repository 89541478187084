import React from 'react'
import PropTypes from 'prop-types'

import { toColor, validColor } from '../../helpers/color'
import { spaceToSize } from '../../helpers/space'

import './swatch.scss'

const Swatch = React.forwardRef(function Swatch({
  color,
  space,
  style = {},
  size,
  width = size,
  height = size,
  className,
  children,
  ...rest
}, ref) {
  const none = ['none', 'inherit', null, undefined].includes(color)
  let backgroundColor = '#FFFFFF'
  if (!none) {
    backgroundColor = validColor(color) ? color : toColor(color)
  }
  const s = {
    width: typeof width === 'number' ? `${width}px` : width,
    height: typeof height === 'number' ? `${height}px` : height,
    backgroundColor,
    padding: space ? spaceToSize(space) : null,
    ...style,
  }

  return (
    <div className={`${className || ''} level-swatch`} style={s} data-none={none || null} ref={ref} {...rest}>
      {none ? (<div className="level-swatch-none" />) : null}
      {children}
    </div>
  )
})

Swatch.propTypes = {
  ...spaceToSize.propTypes,
  style: PropTypes.object,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node,
}

export {
  Swatch,
}

