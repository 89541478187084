import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { searchToObject, toSearchString, filterEmptyValues } from '../helpers'

const useSearchState = (obj) => {
  const initial = obj ? toSearchString(obj) : obj
  const [params, setParams] = useSearchParams(initial)

  const paramsObj = React.useMemo(() => (
    searchToObject(params)
  ), [params])

  const value = React.useRef(paramsObj)

  React.useEffect(() => {
    value.current = paramsObj
  }, [params])

  const setValue = React.useMemo(() => {
    const getValue = (val) => (typeof (val) === 'function' ? val(value.current) : val)
    const set = (val) => {
      setParams(toSearchString(getValue(val)), { replace: true })
    }
    set.merge = (val) => {
      const newVal = { ...value.current, ...getValue(val) }
      setParams(toSearchString(filterEmptyValues(newVal)), { replace: true })
    }
    set.clear = (keys) => {
      const nulledKeys = keys.reduce((acc, key) => ({ ...acc, [key]: null }), {})
      set.merge(nulledKeys)
    }
    return set
  })

  return [paramsObj, setValue]
}

export {
  useSearchState,
}
