import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from '@level'

const messageIconProps = {
  queued: { name: 'circle-dashed', color: 'yellow-600' },
  sent: { name: 'paper-plane', color: 'blue-500' },
  deferred: { name: 'circle-right', color: 'yellow-600' },
  delivered: { name: 'circle-check', color: 'green-600' },
  opened: { name: 'envelope-open' },
  clicked: { name: 'arrow-pointer' },
  failed: { name: 'exclamation-triangle', color: 'red-600' },
  email: { name: 'envelope-solid', color: 'blue-400' },
  sms: { name: 'message-lines-solid', color: 'orchid-400' },
  all: { name: 'asterisk' },
  none: { name: 'ban' },
}

const MessageIcon = ({ name, ...props }) => (
  <Icon name={name} color="neutral-400" {...messageIconProps[name]} size={2} {...props} />
)

MessageIcon.propTypes = {
  name: PropTypes.string.isRequired,
}

export {
  MessageIcon,
}
