import PropTypes from 'prop-types'
import { spaceToSize, gapSize } from '../../helpers/space'

import { Stack } from '../Stack'
import { Icon } from '../Icon'
import { Text } from '../Text'
import { Button } from '../Button'

import './blank-slate.css'

const BlankSlate = ({
  space = 8,
  gap = space,
  className = '',
  icon,
  heading,
  description,
  style = {},
  buttonText,
  buttonAction,
  children,
}) => {
  const s = { padding: spaceToSize(space), ...style }
  return (
    <div
      className={`${className} level-blank-slate`}
      style={s}
    >
      <Stack gap={gap} align="center">
        {icon ? (
          <Icon name={icon} size={10} />
        ) : null}
        <Stack gap={6} className="level-blank-slate-text">
          {heading ? <Text tag="h3">{heading}</Text> : null}
          {description ? <Text tag="p">{description}</Text> : null}
          {children}
        </Stack>
        {buttonText ? (
          <Button size={4} theme="primary" text={buttonText} onClick={buttonAction} />
        ) : null}
      </Stack>
    </div>
  )
}

BlankSlate.propTypes = {
  space: spaceToSize.propTypes.space,
  gap: gapSize.propTypes.gap,
  className: PropTypes.string,
  icon: PropTypes.string,
  theme: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  buttonText: PropTypes.string,
  style: PropTypes.object,
  buttonAction: PropTypes.func,
  children: PropTypes.node,
}

export {
  BlankSlate,
}

