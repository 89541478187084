import PropTypes from 'prop-types'
import { spaceToSize } from '../../helpers/space'
import { ScrollPanel } from '../ScrollPanel'

const Body = ({
  scroll = false,
  space = 8,
  style: styleProp = {},
  children,
  footer,
  ...props
}) => {
  const style = { ...styleProp, padding: spaceToSize(space) }
  if (scroll) {
    return (
      <ScrollPanel
        footer={footer}
        style={style}
        {...props}
      >
        {children}
      </ScrollPanel>
    )
  }
  return (
    <div
      className="level-body"
      style={style}
      {...props}
    >
      {children}
    </div>
  )
}

Body.propTypes = {
  children: PropTypes.node.isRequired,
  scroll: PropTypes.bool,
  space: spaceToSize.propTypes.space,
  style: PropTypes.object,
  footer: PropTypes.any,
}

export {
  Body,
}

