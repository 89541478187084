import React from 'react'
import { useTeam, useFormQuery } from '@app/hooks'
import {
  Grid, Stack, Text, Input, SubmitButton, ButtonShelf, Card, Form, SettingsGrid,
  toParams, filterEmptyValues,
  getMailNameAddress,
} from '@level'

const toNamedEmail = ({ fromName, fromAddress }) => (
  fromName ? `${fromName} <${fromAddress}>` : fromAddress
)

const updateTeamQuery = ({
  Team, fromName, fromAddress,
}) => `mutation {
Team: updateTeam(team: "${Team.slug}", attributes: { ${toParams(filterEmptyValues({ defaultFromEmail: toNamedEmail({ fromName, fromAddress }) }))} }) {
    slug
  }
}`

export const DefaultFrom = () => {
  const { Team, Team: { defaultFromEmail }, refresh } = useTeam()

  const { onSubmit: updateTeam } = useFormQuery({
    query: updateTeamQuery,
    queryProps: { Team },
    onSuccess: () => refresh(),
  })

  const { name, address } = getMailNameAddress(defaultFromEmail)

  return (
    <SettingsGrid>
      <Stack gap={5}>
        <Text tag="h3">Default From</Text>
        <Text>
          Choose a default email address for your message templates.
          You can override these defaults on a template or when sending messages through the API.
        </Text>
      </Stack>
      <Card>
        <Form onSubmit={updateTeam}>
          <Stack gap={9}>
            <Grid columns={2} gap={5}>
              <Input
                name="fromName"
                defaultValue={name}
                placeholder={Team.name}
                pattern="[^<>]+$"
                required
              />
              <Input
                name="fromAddress"
                defaultValue={address}
                placeholder="your@email.com"
                pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+$"
                required
              />
            </Grid>
            <ButtonShelf>
              <SubmitButton text="Save Default From" />
            </ButtonShelf>
          </Stack>
        </Form>
      </Card>
    </SettingsGrid>
  )
}
