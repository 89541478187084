import PropTypes from 'prop-types'
import React from 'react'
import { useLocation, useResolvedPath } from 'react-router-dom'
import { Button } from '../Button'
import { Grid } from '../Grid'
import { Link } from '../Link'
import { NavList } from '../NavList'
import { Transition } from '../Transition'

import './nav-tree.scss'

const NavTree = React.forwardRef(function NavTree({ rootLink = {}, navItems = [], open: defaultOpen = false }, ref) {
  const { pathname } = useLocation()
  const { pathname: resolvedPath } = useResolvedPath(rootLink.to)
  const linkActive = !!pathname.match(new RegExp(`${resolvedPath}\\b`))
  const [open, setOpen] = React.useState(linkActive || defaultOpen)
  const containerRef = React.useRef()

  React.useEffect(() => { setOpen(linkActive || defaultOpen) }, [linkActive, defaultOpen])

  return (
    <div className="level-nav-tree" ref={ref}>
      <Grid
        data-active={linkActive}
        className="level-nav-tree-root"
        templateColumns="1fr min-content"
        valign="center"
      >
        <Link theme="nav-tree-root" {...rootLink} />
        <Button
          size={4}
          icon={{
            size: 1,
            name: open ? 'angle-down' : 'angle-right',
          }}
          theme="ghost"
          label="open nav"
          onClick={() => setOpen((a) => !a)}
        />
      </Grid>
      <Transition in={open} type="fade-in-down" nodeRef={containerRef}>
        {open ? (
          <NavList theme="nav-tree" navItems={navItems} ref={containerRef} />
        ) : null}
      </Transition>
    </div>
  )
})

NavTree.propTypes = {
  rootLink: PropTypes.object,
  navItems: NavList.propTypes.navItems,
  open: PropTypes.bool,
}

export {
  NavTree
}
