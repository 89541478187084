import { useSection } from '../helpers'
import { SectionConfig } from './Config'
import { SharedSectionConfig } from './Shared'

const ConfigureSection = () => {
  const section = useSection()

  if (!section) return null
  return section.sharedSectionId ? <SharedSectionConfig /> : <SectionConfig />
}

export { SectionConfigForm } from './Config'
export { ConfigureSection }

