import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import {
  Grid, Button, SubmitButton, Text, Card, Stack, P, IconList, Icon,
} from '@level'

const ExportDialog = ({
  onCancel,
  close,
  exportHtml,
  signUp,
}) => {
  const onExport = () => {
    close()
    exportHtml()
  }
  const onSignUp = async () => {
    await close({ timeout: 100 })
    signUp()
  }

  return (
    <Grid columns={2} templateColumns="280px 1fr">
      <Stack space={9} gap={8} className="try-dialog-colored-panel">
        <div>
          <Button className="try-dialog-dismiss" onClick={onCancel} label="dismiss" icon={{ name: 'x-light', size: 4 }} theme="wrapper" />
        </div>
        <Icon name="computer-old" width="130px" style={{ margin: '0 auto' }} />
        <Text tag="h1">Still storing your HTML email in your code?</Text>
        <Text tag="p">It&rsquo;s {new Date().getFullYear()} right?</Text>
      </Stack>
      <Stack space={9} gap={8}>
        <Card theme="info">
          <Stack gap={7}>
            <Text tag="h4">Use Enveloop</Text>
            <P>
              You can save this template and more to Enveloop for <b>free</b>.
              Plus it&rsquo;s easy to send with our simple API.
            </P>
            <IconList
              items={[
                'Send Messages with a single API',
                'Reduce deployments by getting email code out of your app',
                'Empower others on your team (or even your clients) to handle communication',
              ]}
            />
            <div style={{ textAlign: 'center' }}>
              <Button onClick={onSignUp} icon={{ name: 'enveloop-logomark-flat', size: 5, gap: 4 }} theme="primary" text="Save to Enveloop" />
            </div>
          </Stack>
        </Card>
        <Card>
          <Stack gap={5} align="center">
            <P>I&rsquo;m old school. Just give me the code.</P>
            <div>
              <Button onClick={onExport} icon={{ name: 'up-from-bracket', gap: 4 }} text="Export HTML" />
            </div>
          </Stack>
        </Card>
      </Stack>
    </Grid>
  )
}

ExportDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
}

export {
  ExportDialog,
}
