import React from 'react'

const getLastItem = (virtualizer) => (
  [...virtualizer.getVirtualItems()].reverse()[0]
)

export const useInfiniteFetch = ({
  virtualizer, hasMore, getMore, isLoading, items,
}) => {
  React.useEffect(() => {
    if (!getLastItem(virtualizer)) { return }

    // Delay calculation until after virtualizer has finished rendering
    setTimeout(() => {
      const lastItem = getLastItem(virtualizer)
      if (
        lastItem.index >= items.length - 1
      && hasMore
      && !isLoading
      ) {
        getMore()
      }
    }, 0)
  }, [
    hasMore,
    getMore,
    items.length,
    isLoading,
    virtualizer.getVirtualItems(),
  ])
}
