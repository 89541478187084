import React from 'react'
import PropTypes from 'prop-types'
import { Form } from '../../../Form'
import { Stack } from '../../../Stack'
import { Divider } from '../../../Divider'
import { Attribute } from './Attribute'
import { ConfigFormContext, useConfigFormContext } from './Provider'
import { useTemplateConfig } from './useTemplateConfig'
import { templateColors, sectionColors } from '../../../../helpers'

const ConfigForm = ({
  id,
  config,
  onChange,
  onChangeInput,
  configLayout,
  space,
  flush,
  context = {},
  focus,
  focusDelay,
  sharedSection,
  ...rest
}) => {
  // Use object.keys to define the order unless a config layout has been passed
  // configLayout is an array of config key names, or `---` to render a divider
  const layout = configLayout || Object.keys(config)
  const { theme = {}, config: templateConfig } = useTemplateConfig()
  const value = React.useMemo(() => ({
    ...context,
    theme: { ...theme, ...(rest.theme || {}) },
    colors: !sharedSection
      ? templateColors({ config: templateConfig })
      : sectionColors({ section: sharedSection }),
  }), [theme, rest.theme, config])

  return (
    <ConfigFormContext.Provider value={value}>
      <Form
        onChange={onChange}
        onChangeInput={onChangeInput}
        defaultValues={config}
        focus={focus}
        focusDelay={focusDelay}
        {...rest}
      >
        <Stack gap={6} space={space} style={{ overflow: 'hidden' }}>
          {layout.map((key, index) => {
            if (key === '---') {
              // Kind of irreverant, but passing '---' renders a divider.
              // Use space or flush prop to add negative margin to bring divider flush to container
              return <Divider flush={flush || space} key={`${id}-divider-${index}`} />
            }

            const { [key]: val } = config
            return <Attribute key={`${id}-${key}`} name={key} value={val} />
          })}
        </Stack>
      </Form>
    </ConfigFormContext.Provider>
  )
}

ConfigForm.propTypes = {
  id: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onChangeInput: PropTypes.func,
  configLayout: PropTypes.array,
  space: Stack.propTypes.space,
  flush: PropTypes.number,
  context: PropTypes.any,
  focus: PropTypes.string,
  theme: PropTypes.object,
  focusDelay: PropTypes.number,
  sharedSection: PropTypes.object,
}

export {
  ConfigForm,
  useConfigFormContext,
}
