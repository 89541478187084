import PropTypes from 'prop-types'
import { Shelf, Spacer } from '../Shelf'
import { CancelLink } from '../Link'

const ButtonShelf = ({
  onCancel, cancelTo, children, ...rest
}) => (
  <Shelf gap={5} reverse valign="center" {...rest}>
    {children}
    {cancelTo || onCancel ? <CancelLink to={cancelTo} onClick={onCancel} text="Cancel" /> : null}
    {rest.align !== 'center' ? <Spacer grow /> : null}
  </Shelf>
)

ButtonShelf.propTypes = {
  onCancel: PropTypes.func,
  cancelTo: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export {
  ButtonShelf,
}
