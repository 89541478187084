import { AsUserBanner, useProfile } from '@app/hooks'
import { Icon, Layout, Nav, Sidebar, Stack } from '@level'
import { InvitationsNotice } from '../invitations'
import { TeamSwitcher } from '../teams/switcher'
import { AccountNav } from './nav'

const AccountLayout = () => {
  const { invitations } = useProfile()

  return (
    <Layout
      root={<AsUserBanner />}
      sidebar={(
        <Sidebar
          header={(
            <>
              <Icon name="enveloop-logomark" className="level-sidebar-logo" fill="none" />
              <Nav>
                <Stack gap={6}>
                  {invitations?.length ? <InvitationsNotice /> : null}
                  <TeamSwitcher />
                </Stack>
              </Nav>
            </>
          )}
          footer={<AccountNav />}
        />
      )}
    />
  )
}

export {
  AccountLayout
}
