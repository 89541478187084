import PropTypes from 'prop-types'

const alignment = {
  left: 'start',
  right: 'end',
  start: 'start',
  end: 'end',
  center: 'center',
  split: 'space-between',
  around: 'space-around',
  reverse: 'row-reverse',
  stretch: 'stretch',
  normal: 'normal',
}

const valignment = {
  stretch: 'stretch',
  top: 'start',
  start: 'start',
  bottom: 'end',
  end: 'end',
  center: 'center',
  baseline: 'baseline',
  normal: 'normal',
}

const alignments = Object.keys(alignment)
const valignments = Object.keys(valignment)

const valign = (props) => valignment[props.valign]
const align = (props) => alignment[props.align]

const alignItemsStyle = (props) => ({
  alignItems: valign(props),
  justifyContent: align(props),
})

const alignSelfStyle = (props) => ({
  alignSelf: align(props),
  justifySelf: valign(props),
})

const alignPropTypes = {
  align: PropTypes.oneOf(alignments),
  valign: PropTypes.oneOf(valignments),
}

const flexFlow = ({
  nowrap,
  reverse,
  wrapReverse,
}) => {
  let direction = 'row'
  if (reverse) direction += '-reverse'

  let flexWrap = nowrap ? 'nowrap' : 'wrap'
  if (wrapReverse) flexWrap += '-reverse'

  return {
    flexFlow: `${direction} ${flexWrap}`,
  }
}

export {
  alignItemsStyle,
  alignSelfStyle,
  alignments,
  valignments,
  alignPropTypes,
  flexFlow,
}
