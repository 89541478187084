import PropTypes from 'prop-types'
import { Body } from '../Body'
import { Stack } from '../Stack'
import { P, Text } from '../Text'

const NotFound = ({ title = 'Not Found', text, children }) => (
  <Body>
    <Stack gap={6} className="level-not-found">
      <Text tag="h2">{title}</Text>
      {text ? <P>{text}</P> : null}
      {children}
    </Stack>
  </Body>
)

NotFound.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
}

export {
  NotFound
}
