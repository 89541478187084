import React from 'react'
import PropTypes from 'prop-types'

import {
  injectHtml,
  jsxToString,
  Raw,
} from './react'

const IfMso = ({
  tag: Tag = 'raw', mode, children, ...rest
}) => {
  const wrapper = (content) => `<!--[if mso]>${jsxToString(content)}<![endif]-->`
  const injectMso = (content) => injectHtml(wrapper(content))

  return ['render', 'preview-html'].includes(mode) ? (<Tag {...injectMso(children)} {...rest} />) : null
}

IfMso.propTypes = {
  tag: PropTypes.string,
  mode: PropTypes.string,
  children: PropTypes.node.isRequired,
}

const useSplitMsoWrapper = ({ wrapper: Wrapper, mode, ...props }) => {
  // convert wrapper into array of strings
  const [start, end] = jsxToString(<Wrapper {...props}>--split--</Wrapper>).split('--split--')

  // Surround wrapper begining and ending tags with with mso conditional comments
  const wrapper = (content) => `<!--[if mso]>${start}<![endif]-->
  ${jsxToString(content)}
<!--[if mso]>${end}<![endif]-->`

  // A function which returns props to set HTML content to the contents returned from wrapper
  // This is useful if you need to inject this html beneath some other component
  const injectMso = (content) => injectHtml(wrapper(content))

  // This component lets you set the tag and props of the element which will
  // contain the wrapped contents.
  // eslint-disable-next-line react/prop-types
  const MsoWrapper = ({ tag: Tag = 'div', children, ...rest }) => (
    !mode || ['render', 'preview-html'].includes(mode) ? (
      <Tag {...injectMso(children)} {...rest} />
    ) : children
  )

  return { MsoWrapper, injectMso }
}

const Style = ({ style }) => (style?.toString ? (
  <style>{style.toString().replace(/\/\*[^\0]+$/g, '')}</style>
) : null)

Style.propTypes = { style: PropTypes.string.isRequired }

// This component is used to inject a hidden preview text into the email
// https://www.litmus.com/blog/the-little-known-preview-text-hack-you-may-want-to-use-in-every-email
const PreviewText = ({ text }) => (text ? (
  <div data-preview style={{ display: 'none', maxHeight: '0px', overflow: 'hidden' }}>
    {'{{{ previewText }}}'}
    <div {...injectHtml(Array(80).fill('&#847; &zwnj; &nbsp; &#8199; &shy;').join(' '))} />
  </div>
) : null)

PreviewText.propTypes = { text: PropTypes.string }

const EmailTable = React.forwardRef(function EmailTable(props, ref) {
  return (
    <table
      role="presentation"
      cellSpacing="0"
      cellPadding="0"
      border="0"
      width="100%"
      ref={ref}
      {...props}
    />
  )
})

const RoundRect = (props) => {
  const Tag = 'v:roundrect'
  const attrs = {
    'xmlns:v': 'urn:schemas-microsoft-com:vml',
    'xmlns:w': 'urn:schemas-microsoft-com:office:word',
  }
  return <Tag {...attrs} {...props} />
}

const RoundEmailButton = ({
  text, fill = 'none', href, mode, color, fontSize = '13px', fontFamily, fontWeight, cornerStyle,
}) => {
  const style = {
    color,
    fontFamily,
    fontSize,
    fontWeight,
  }

  let arcsize = 0
  if (cornerStyle === 'rounded') arcsize = '12%'
  if (cornerStyle === 'round') arcsize = '100%'

  // Calculate height as a proportion of fontSize, this is based on fontSize: 13px -> height: 35px
  const height = `${Math.round(2.7 * Number.parseInt(fontSize, 10))}px`

  return (
    <IfMso mode={mode}>
      <RoundRect
        arcsize={arcsize}
        fillcolor={fill}
        href={href}
        style={{
          height,
          strokecolor: fill,
          vTextAnchor: 'middle',
          msoWrapStyle: 'none',
        }}
      >
        <Raw text="<w:anchorlock/>" />
        <center style={style}>{text}</center>
      </RoundRect>
    </IfMso>
  )
}

RoundEmailButton.propTypes = {
  text: PropTypes.node,
  href: PropTypes.string,
  fill: PropTypes.string,
  mode: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  fontFamily: PropTypes.string,
  fontWeight: PropTypes.string,
  cornerStyle: PropTypes.string,
}

export {
  IfMso,
  Style,
  EmailTable,
  PreviewText,
  RoundEmailButton,
  useSplitMsoWrapper,
}
