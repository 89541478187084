import React from 'react'
import PropTypes from 'prop-types'
import { useProfile } from '@app/hooks'
import {
  Main, P, SubmitButton, Grid, Stack, Input, Text,
  useRestQuery, useToast, useLocalStorage,
} from '@level'
import { Navigate } from 'react-router-dom'

import { TemplateTestContext, TemplateContext } from '../templates/context'
import { TestDrawer } from '../templates/test/drawer'
import { PreviewEmailTemplate } from '../templates/email/previewEmailTemplate'

const sendUrl = `${import.meta.env.VITE_API_BASE_URL}/try/send`

const useSendTestEmail = () => {
  const { anonymousId } = useProfile()
  return useRestQuery({
    url: sendUrl,
    headers: { anonymousId },
  }, [anonymousId])
}

const TestEmailTemplate = ({ template }) => {
  const [send, { isLoading }] = useSendTestEmail()
  const [toEmail, setToEmail] = useLocalStorage('try-to-email', null)
  const { sendSuccess, sendError } = useToast()

  const sendEmail = ({ to }) => (
    send({
      config: template.config,
      templateVariables: template.previewVars,
      to,
      subject: 'Enveloop Test Email',
    })
  )

  const onSubmit = async ({ data: { to } }) => {
    setToEmail(to)
    const response = await sendEmail({ to })
    if (response.data) {
      sendSuccess({ title: 'Test Email Sent', message: `Your test email was sent to ${to}.` })
    } else if (response.errors) {
      sendError({ title: 'Sending Test Email Failed', message: `Your test email could not be delivered to ${to}. Please contact support@enveloop.com if this problem continues.` })
    }
  }

  return (
    <Main drawer={(
      <TestDrawer
        title="Render Email Preview"
        onSubmit={onSubmit}
        templateVariables={template.previewTemplateVariables || []}
      >
        <Stack gap={6} space={6}>
          <Text tag="h4">Preview in your inbox</Text>
          <P>
            Send a test preview of this email {template.previewTemplateVariables?.length ? 'rendered with the variables above' : ''} to your email address.
          </P>
          <Grid templateColumns="1fr auto" gap={4}>
            <Input
              name="to"
              defaultValue={toEmail}
              label={false}
              placeholder="your@email.com"
              pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+$"
              required
            />
            <SubmitButton text="Send Email" isLoading={isLoading} />
          </Grid>
        </Stack>
      </TestDrawer>
    )}
    >
      <PreviewEmailTemplate {...template} mode="preview" />
    </Main>
  )
}

TestEmailTemplate.propTypes = {
  template: PropTypes.object.isRequired,
}

const TemplateTest = (props) => {
  const { template } = props
  const [previewVars, setPreviewVars] = useLocalStorage(`template-${template?.id}-editor-vars`, {})

  const value = React.useMemo(
    () => ({
      config: template.previewConfig,
      type: template.type,
      name: template.name,
      slug: template.slug,
      previewTemplateVariables: template.previewTemplateVariables,
      previewVars,
      setPreviewVars,
    }),
    [template, previewVars],
  )

  if (!template) return <Navigate to="./build" />

  return (
    <TemplateContext.Provider value={props} mode="preview">
      <TemplateTestContext.Provider value={value}>
        {template?.type === 'email' ? <TestEmailTemplate template={value} /> : null}
      </TemplateTestContext.Provider>
    </TemplateContext.Provider>
  )
}

export { TemplateTest }
