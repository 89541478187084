import PropTypes from 'prop-types'
import React from 'react'
import { toColor, toFontSize } from '../../helpers'
import { useDelayValue } from '../../hooks/useDelayValue'
import { Icon } from '../Icon'
import { Shelf } from '../Shelf'
import { useLoading } from './LoadingBar'

import './loading.css'

const LoadingDotsSvg = () => (
  <svg className="level-loading-dots" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 17">
    <circle cx="2.2" cy="15.4" r="1.6" />
    <circle cx="9.5" cy="15.4" r="1.6" />
    <circle cx="16.8" cy="15.4" r="1.6" />
  </svg>
)

const LoadingDots = ({ text }) => (
  !text ? <LoadingDotsSvg /> : (
    <React.Fragment>
      <div className="level-loading-text">{text}</div>
      <LoadingDotsSvg />
    </React.Fragment>
  )
)

LoadingDots.propTypes = {
  text: PropTypes.string,
}

const LoadingIcon = ({ text, size }) => (
  !text ? <Icon name="loader" color="neutral-500" size={size} /> : (
    <Shelf gap={3} valign="center" inline>
      <Icon name="loader" color="neutral-500" size={size} />
      <div className="level-loading-text">{text}</div>
    </Shelf>
  )
)

LoadingIcon.propTypes = {
  size: toFontSize.propTypes.size,
  text: PropTypes.string,
}


// Support for future loading types
const types = {
  icon: LoadingIcon,
  dots: LoadingDots,
}

const Loading = ({
  text, color = 'inherit', size = 'inherit', type = 'icon',
}) => {
  const Loader = types[type]
  const { start, stop } = useLoading()
  const show = useDelayValue(true)

  React.useEffect(() => {
    start()
    return () => stop()
  }, [start, stop])

  return show ? (
    <span className="level-loading" data-type={type} style={{ color: toColor(color), fontSize: toFontSize(size) }}>
      <Loader text={text} size={size} />
    </span>
  ) : text
}

Loading.propTypes = {
  type: PropTypes.oneOf(Object.keys(types)),
  size: toFontSize.propTypes.size,
  color: toColor.propTypes.color,
  text: PropTypes.string,
}

export {
  Loading
}
export {
  LoadingBar,
  LoadingProvider,
  useLoading
} from './LoadingBar'
