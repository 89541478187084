import React from 'react'
import { useNetworkStatus } from '../../hooks/useNetworkStatus'
import { useToast } from '../../hooks/useToast'
import { Banner } from '../Banner'
import { Shelf } from '../Shelf'
import { Text } from '../Text'

const OfflineBanner = () => {
  const { online, changed } = useNetworkStatus()
  const { sendSuccess } = useToast()

  React.useEffect(() => {
    if (changed && online) {
      sendSuccess({ title: 'Network Reconnected', message: 'And there was much rejoicing.' })
    }
  }, [changed])

  return !online ? (
    <Banner color="purple-700" border="purple-100" background="purple-50">
      <Shelf align="center">
        <Text icon="exclamation-triangle">Your Internet connection is offline.</Text>
      </Shelf>
    </Banner>
  ) : null
}

export {
  OfflineBanner
}
