import { useParams } from 'react-router-dom'
import { useTemplateConfig } from './useTemplateConfig'

const useSection = () => {
  const { config: { sections } } = useTemplateConfig()
  const { sectionId } = useParams()
  return sections.find(({ variables: { id } }) => id === sectionId)
}

export {
  useSection,
}
