export default {
  0: '11px',
  1: '12px',
  2: '14px',
  3: '16px',
  4: '18px',
  5: '22px',
  6: '28px',
  7: '32px',
  8: '48px',
  9: '64px',
  10: '96px',
  default: '13px',
  inherit: '1em',
}
