import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  useTeam, useProjects, useQuery,
} from '@app/hooks'

import {
  Input, P, toParams, useDialog,
} from '@level'

import { createApiTokenQuery } from './apiTokens'

const createProjectQuery = ({ slug, name, Team }) => `mutation {
  Project: createProject(team: "${Team.slug}", attributes: { ${toParams({ slug, name })}}) {
    slug
    team { slug }
  }
}`

const useCreateProject = ({ Team }) => {
  const [createApiToken] = useQuery(createApiTokenQuery)
  const [createProject] = useQuery(createProjectQuery)

  return async ({ data }) => {
    const { data: projectData } = await createProject({ Team, ...data })
    // Go ahead and generate test and production api keys
    await createApiToken({ ...projectData, name: 'production' })
    await createApiToken({
      ...projectData, name: 'test', testMode: true,
    })

    return projectData
  }
}

const useNewProjectDialog = () => {
  const sendDialog = useDialog()
  const navigate = useNavigate()
  const { projectsUrl, refresh } = useProjects()
  const { Team } = useTeam()
  const createProject = useCreateProject({ Team })

  const onSubmit = async ({ data }) => {
    const { Project } = await createProject({ data })
    await refresh()
    navigate(projectsUrl(Project.slug))
  }

  return () => {
    sendDialog({
      onConfirm: onSubmit,
      debounceSubmit: 5000,
      title: 'New Project',
      confirmText: 'Create Project',
      content: (
        <>
          <P>
            Projects are mapped to your applications.
            You can use a project to group all message templates and
            settings for a specific product or application.
          </P>
          <Input name="name" label="Project Name" placeholder="My Project" required />
        </>
      ),
    })
  }
}

export {
  useNewProjectDialog,
  useCreateProject,
}
