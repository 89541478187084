import {
  toParams,
} from '@level'

const teamAndProjectSlugs = ({ Project }) => `project: "${Project.slug}", team: "${Project.team.slug}"`

const sharedSectionDefaults = `
  id name published publishedAt createdAt updatedAt
  previewConfig { body variables }
  liveConfig { body variables }
`

const sharedSectionQuery = ({ Project, sharedSectionId }) => (
  `sharedSection(${teamAndProjectSlugs({ Project })}, id: ${sharedSectionId}) { ${sharedSectionDefaults} }`
)

const sharedSectionsQuery = ({ Project }) => (
  `sharedSections(${teamAndProjectSlugs({ Project })}) { ${sharedSectionDefaults} }`
)

const createSharedSectionQuery = ({
  Project, section: previewConfig, name, publish = false,
}) => `mutation {
  sharedSection: createSharedSection(
    ${teamAndProjectSlugs({ Project })}, publish: ${publish},
    attributes: { ${toParams({ name, previewConfig })} }
  ) { id name }
}`

const updateSectionQuery = ({ id, name, previewConfig }) => `mutation {
  sharedSection: updateSharedSection(id: ${id}, attributes: { ${toParams({ name, previewConfig })}}) {
    ${sharedSectionDefaults}
  }
}`

const deleteSharedSectionQuery = ({ id }) => (
  `mutation { deleteSharedSection(id: ${id}) { id } }`
)

const publishSharedSectionQuery = ({ id }) => (
  `mutation { sharedSection: publishSharedSection(id: ${id}) { ${sharedSectionDefaults} } }`
)
const revertSharedSectionQuery = ({ id }) => (
  `mutation { sharedSection: revertSharedSection(id: ${id}) { ${sharedSectionDefaults} } }`
)

export {
  createSharedSectionQuery,
  updateSectionQuery,
  deleteSharedSectionQuery,
  revertSharedSectionQuery,
  publishSharedSectionQuery,
  sharedSectionsQuery,
  sharedSectionQuery,
}
