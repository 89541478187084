import PropTypes from 'prop-types'
import React from 'react'
import { copyObject } from '../../../../../helpers'
import { MenuButton } from '../../../../MenuButton'
import { useTemplate } from '../../../useTemplate'
import { isActive } from '../helpers'

const useUpdateElement = () => {
  const { hooks: { updateElement } } = useTemplate()
  return updateElement
}

const columnAlign = {
  left: 'align-left',
  center: 'align-center',
  right: 'align-right',
}

const ColumnAlign = ({ value, onChange }) => {
  const items = React.useMemo(() => (
    Object.entries(columnAlign).map(([alignment, icon]) => ({
      text: alignment, iconAfter: icon, onSelect: () => onChange(alignment),
    }))
  ), [onChange])

  return (
    <MenuButton
      items={items}
      icon={columnAlign[value]}
      defaultValue={value}
      label="Alignment"
      iconAfter="angle-up-and-down"
      theme="ghost"
      space={0}
      tabIndex={0}
    />
  )
}

ColumnAlign.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

const AlignmentHeader = ({ tableConfig, element }) => {
  const update = useUpdateElement()
  const active = isActive({ elementId: element.id })

  if (!active) return null

  const onChange = ({ index, value }) => {
    const newConfig = copyObject(tableConfig)
    newConfig.columns[index].align = value
    update({ element, update: { tableConfig: newConfig } })
  }

  return (
    <thead>
      <tr>
        {tableConfig.columns.map((column, index) => (
          <td key={column.id}>
            <ColumnAlign
              value={column.align}
              onChange={(value) => onChange({ value, index })}
            />
          </td>
        ))}
      </tr>
    </thead>
  )
}

AlignmentHeader.propTypes = {
  tableConfig: PropTypes.object.isRequired,
  element: PropTypes.object.isRequired,
}

export {
  AlignmentHeader
}
