import React from 'react'
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
} from '@tanstack/react-table'

import { useVirtualizer } from '@tanstack/react-virtual'
import { camelToTitle } from '../helpers/text'

const getSelection = (table) => {
  const selectedRowModel = table.getSelectedRowModel()
  const selectedRow = selectedRowModel.rows[0]
  const selected = selectedRow?.original
  const deselectAll = () => table.toggleAllRowsSelected(false)
  if (!selectedRow) return {}

  const { rows } = table.getRowModel()
  const next = rows[selectedRow.index + 1]
  const prev = rows[selectedRow.index - 1]
  const select = (row) => {
    table.selectRow(row)
    table.scrollToRow(row)
  }
  return {
    selected,
    selectedRow,
    selectedRowModel,
    deselectAll,
    selectNext: next ? () => { select(next) } : null,
    selectPrevious: prev ? () => { select(prev) } : null,
  }
}

// Wrapping react-table for some optimizations
const useTable = ({
  columns,
  columnNames,
  initialState = {},
  hiddenColumns = [],
  singleRowSelection,
  state,
  ...props
}) => {
  const [sorting, setSorting] = React.useState([])
  const containerRef = React.useRef()

  // columnNames are not part of the normal Table spec, this is an extension
  // which allows us to set up columns more easily.
  // columnNames can be a mixxed array of strings or objects.
  // For example:
  //   columnNames = [ 'a', 'b', { c: { hidden: true }}]
  // Or:
  //   columnNames = [ 'id', 'name', { active: { Header: "Enabled" }} ]
  //
  const columnHelper = createColumnHelper()
  const tableColumns = React.useMemo(() => {
    // Ignore this whole optimization if you'd rather use columns
    if (!columnNames) return columns

    return columnNames.reduce((all, columnName) => {
      // If a string, convert to the proper table column format:
      // columnHelper.accessor('someString', {
      //   header: 'Some String'
      // })
      if (typeof columnName === 'string') {
        return [...all, columnHelper.accessor(columnName, {
          header: camelToTitle(columnName),
        })]
      }

      // Determine the type of column from the type key
      // Type can be accessor (default), display, or group
      // Accessor: columns are typical columns who's data can be sorted/filtered, etc
      // Display: columns is used for adding controls or other non-data features to a column
      // Group: columns have nested accessor columns
      // Using this config: { id: { ...options } }
      // we extract the name (here: 'id') and the type and options
      const [name, { type = 'accessor', hidden, ...options }] = Object.entries(columnName)[0]

      // If a column is hidden, skip and add to hidden columns array
      if (hidden) {
        hiddenColumns.push(name)
        return all
      }

      // Create a default header from accessor key
      const columnOptions = {
        header: camelToTitle(name),
        ...options,
      }

      if (type === 'display') {
        return [...all, columnHelper.display({ id: name, ...columnOptions })]
      }

      // Return options, setting an overridable default for Header
      return [...all, columnHelper.accessor(name, columnOptions)]

      // TODO: handle table grouping if we ever support that feature
    }, [])
  }, [columns, columnNames])

  // shim in the optimized settings (if used)
  const tableInstance = useReactTable({
    enableRowSelection: false,
    enableSorting: false,
    getSortedRowModel: props.enableSorting ? getSortedRowModel() : null,
    state: {
      sorting,
      ...state,
    },
    onSortingChange: setSorting,
    ...props,
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      hiddenColumns,
      ...initialState,
    },
  })

  const scrollToRow = (row) => {
    containerRef.current.querySelector(`tr[data-row-id="${row.id}"]`)?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
  }

  const selectRow = (row) => {
    if (singleRowSelection && !row.getIsSelected()) {
      tableInstance.setRowSelection({ [row.id]: true })
    } else {
      row.toggleSelected()
    }
  }

  return {
    ...tableInstance,
    containerRef,
    selectRow,
    scrollToRow,
    getSelection,
  }
}

const useVirtualTable = ({
  overscan = 15,
  estimateSize = () => 50,
  hasMore,
  ...props
}) => {
  const table = useTable(props)
  const virtualizer = useVirtualizer({
    count: table.getRowModel().rows.length,
    getScrollElement: () => table.containerRef.current,
    estimateSize,
    overscan,
  })
  virtualizer.hasMore = hasMore
  return {
    virtualizer,
    ...table,
  }
}

export {
  useTable,
  useVirtualTable,
}
