import React from 'react'
import PropTypes from 'prop-types'

import { Loading } from '../Loading'
import { isImageFile, imageData } from '../../helpers/object'

// Render an image from a File or src prop.
// This allows forms to render a preview of an image, or the default image using the same component.
const ImageFilePreview = ({ src: srcProp, file, ...props }) => {
  const [src, setSrc] = React.useState(srcProp)
  const isImage = file && isImageFile(file)

  React.useEffect(() => {
    (async () => {
      if (isImage) {
        const blob = await imageData(file)
        if (blob) setSrc(blob)
      }
    })()
  }, [file])

  React.useEffect(() => {
    setSrc(srcProp)
  }, [srcProp])

  if (!isImage && !src) return null

  return src ? (
    <img
      className="level-image-file-preview"
      alt="file preview"
      src={src}
      style={{ maxHeight: '150px' }}
      {...props}
    />
  ) : <Loading />
}

ImageFilePreview.propTypes = {
  src: PropTypes.string,
  file: PropTypes.object,
}

export {
  ImageFilePreview,
}
