import React from 'react'

const useCleanPaste = () => {
  const fixPaste = React.useCallback((e) => {
    // Escape paste in editor to avoid double content entry since
    // the editor handles its own paste cleaning
    if (e.target.closest('.level-editor')) return
    e.preventDefault()
    const text = e.clipboardData.getData('text')
    window.document.execCommand('insertText', false, text)
  })

  React.useEffect(() => {
    window.addEventListener('paste', fixPaste)

    return () => {
      window.removeEventListener('paste', fixPaste)
    }
  }, [])
}

export { useCleanPaste }
