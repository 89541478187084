import React from 'react'
import PropTypes from 'prop-types'
import {
  Text, Shelf, Card, Stack, PercentageCircle,
} from '@level'

const ComplianceCard = ({
  title, icon, number, percentage, percentageRange, negative,
}) => (
  <Card space={7}>
    <Stack gap={5}>
      <Text tag="p">{title}</Text>
      <Shelf align="split">
        <Shelf valign="center" gap={3}>
          <Text tag="h1">{number}</Text>
          {percentage ? <Text className="dmarc-percentage">{percentage}%</Text> : null}
        </Shelf>
        {icon}
        {percentage ? (
          <PercentageCircle
            percentage={percentage}
            lessThan={percentageRange[0]}
            greaterThan={percentageRange[1]}
            negative={negative}
          />
        ) : null}
      </Shelf>
    </Stack>
  </Card>
)

ComplianceCard.propTypes = {
  title: PropTypes.string.isRequired,
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.number,
  percentageRange: PropTypes.array,
  negative: PropTypes.bool,
  icon: PropTypes.node,
}

export {
  ComplianceCard,
}
