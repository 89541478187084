import PropTypes from 'prop-types'

import { toColor, cleanPercentage } from '../../helpers'

const PercentageBar = ({
  percentage,
  lessThan,
  greaterThan,
  className,
  negative,
  ...props
}) => {
  const {
    greaterThanColor = !negative ? 'green-600' : 'red-600',
    lessThanColor = !negative ? 'red-600' : 'green-600',
  } = props

  const pct = cleanPercentage(percentage)
  let fill = toColor('yellow-600')

  if (pct < lessThan) fill = (toColor(`${lessThanColor}`))
  if (pct > greaterThan) fill = (toColor(`${greaterThanColor}`))

  return (
    <svg width={100} height={10} className={`level-percentage-bar ${className || ''}`}>
      <g>
        <rect
          width="100%"
          height="10"
          x="0"
          y="0"
          fill={toColor('neutral-50')}
          rx="6"
        />
        <rect
          width={`${pct}%`}
          height="10"
          x="0"
          y="0"
          rx="6"
          style={{ transition: 'all 0.5s ease-in-out' }}
          fill={fill}
        />
      </g>
    </svg>
  )
}

PercentageBar.propTypes = {
  percentage: PropTypes.number.isRequired,
  lessThan: PropTypes.number.isRequired,
  greaterThan: PropTypes.number.isRequired,
  className: PropTypes.string,
  negative: PropTypes.bool,
  lessThanColor: PropTypes.string,
  greaterThanColor: PropTypes.string,
}

export { PercentageBar }
