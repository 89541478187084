import React from 'react'
import PropTypes from 'prop-types'

import { Button, Text, useDialog } from '@level'
import { useFormQuery, useQuery } from '@app/hooks'

import {
  updateEventWebhookQuery,
  deleteEventWebhookQuery,
  useEventWebhooks,
} from './queries'

import { useWebhookDialog } from './webhookDialog'

const useDeleteWebhook = ({ url, id }) => {
  const { refresh } = useEventWebhooks()
  const [deleteWebhook] = useQuery(deleteEventWebhookQuery({ id }))
  const sendDialog = useDialog()
  return async () => sendDialog({
    onConfirm: async () => {
      await deleteWebhook()
      refresh()
    },
    title: 'Delete Webhook?',
    content: (
      <Text>
        You are about to delete the webhook for <code>{ url }</code>.
        Are you sure you want to do that?
      </Text>
    ),
    confirmText: 'Delete Webhook',
    destructive: true,
  })
}

const EditWebhook = ({ webhook }) => {
  const deleteWebhook = webhook ? useDeleteWebhook(webhook) : undefined
  const { refresh } = useEventWebhooks()

  const { onSubmit: onConfirm } = useFormQuery({
    query: updateEventWebhookQuery,
    onSuccess: refresh,
    queryProps: { enabled: true },
    successMessage: 'Webhook updated',
    redirect: () => '../',
  })

  const editWebhook = useWebhookDialog({
    title: 'Edit Webhook',
    confirmText: 'Save Webhook',
    webhook,
    deleteWebhook,
    onConfirm,
  })

  return (
    <Button
      icon="pen"
      theme="wrapper"
      label="Edit Webhook"
      onClick={editWebhook}
      title="Edit Webhook"
    />
  )
}

EditWebhook.propTypes = {
  webhook: PropTypes.object.isRequired,
}

export {
  EditWebhook,
}
