// Return value if callback is not a function,
// otherwise return value if callback doesn't return anything.
const passthroughCallback = (callback, ...args) => {
  if (typeof callback !== 'function') return null
  return callback(...args)
}

const mergeEvents = (...objects) => {
  const listeners = {}

  for (const events of objects) {
    for (const event in events) {
      const listener = events[event]
      listeners[event] = listeners[event] ? [...listeners[event], listener] : [listener]
    }
  }

  const result = {}

  for (const event in listeners) {
    result[event] = (...arg) => {
      for (const listener of listeners[event]) {
        listener(...arg)
      }
    }
  }

  return result
}

export {
  mergeEvents,
  passthroughCallback,
}
