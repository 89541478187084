import React from 'react'
import {
  ButtonShelf, MenuButton, Button,
  useLiveDate, timeAgoOrDate,
  useNetworkStatus,
} from '@level'

import { useSharedSection } from './provider'
import { useCopyTheme } from './hooks'

const SharedSectionMenuActions = () => {
  const { online } = useNetworkStatus()

  const {
    sharedSection: { publishedAt, published },
    deleteSharedSection,
    revertSection,
    renameSharedSection,
    publishSection,
    sharedSection,
  } = useSharedSection()
  const copyTheme = useCopyTheme({ template: sharedSection })

  const date = useLiveDate({ date: publishedAt, format: timeAgoOrDate })

  const menuActions = React.useMemo(() => {
    const actions = [
      {
        text: 'Change Name',
        onSelect: async () => {
          renameSharedSection()
        },
        icon: 'pen-field',
      },
      {
        text: 'Copy Theme',
        onSelect: copyTheme,
        icon: 'share-from-square',
      },
    ]

    if (publishedAt && !published) {
      actions.push({
        text: `Revert (${date})`,
        onSelect: revertSection,
        icon: 'arrow-rotate-left',
      })
    }
    actions.push({
      text: 'Delete',
      onSelect: deleteSharedSection,
      icon: 'trash',
      destructive: true,
      divider: !!actions.length,
    })

    return actions
  }, [
    publishedAt,
    published,
    deleteSharedSection,
    revertSection,
  ])

  return (
    <ButtonShelf>
      <Button text="Publish" theme={published ? 'default' : 'primary-green'} onClick={publishSection} disabled={published || !online} />
      <MenuButton items={menuActions} size={4} icon="gear-solid" label="Template options" />
    </ButtonShelf>
  )
}

export {
  SharedSectionMenuActions,
}
