import React from 'react'
import { useQuery } from '@app/hooks'
import {
  Grid, Button, useToast, toParams,
} from '@level'

import { migrateEmailTemplate, migrateSharedSection } from './helpers'

const updateTemplateQuery = ({ templates }) => templates.map(({ id, previewConfig, liveConfig }) => `mutation {
  adminUpdateTemplate(id: "${id}", attributes: { ${toParams({ previewConfig, liveConfig })} }) {
    id
  }
}`)

const updateSharedSectionsQuery = ({ sharedSections }) => sharedSections.map(({ id, previewConfig, liveConfig }) => `mutation {
  adminUpdateSharedSection(id: "${id}", attributes: { ${toParams({ previewConfig, liveConfig })} }) {
    id
  }
}`)

const allTemplatesQuery = `
templates: adminTemplates {
  id
  type
  previewConfig {
    body
    variables
    sections {
      variables
      body
      sharedSectionId
    }
  }
  liveConfig {
    body
    variables
    sections {
      variables
      body
      sharedSectionId
    }
  }
}`

const allSharedSectionsQuery = `
sharedSections: adminSharedSections {
  id
  previewConfig {
    variables
    body
  }
  liveConfig {
    body
    variables
  }
}`

const MigrateEmailTemplates = () => {
  const [fetch] = useQuery(allTemplatesQuery)
  const [update] = useQuery(updateTemplateQuery)
  const { sendSuccess, sendError } = useToast()
  // Ensure all templates have previewConfig.variables style and config key

  const onClick = React.useCallback(async () => {
    const { data: { templates } } = await fetch()
    const emailTemplates = templates.filter(({ type }) => type === 'email')

    const updatedTemplates = emailTemplates.map(migrateEmailTemplate)
    const { errors } = await update({ templates: updatedTemplates })
    if (errors) {
      sendError({ title: 'Template migration failed', message: errors.toString() })
    }
    sendSuccess({ title: 'Template migration succeeded', message: `${emailTemplates.length} templates updated` })
  })

  return (
    <Button onClick={onClick} text="Migrate Templates" />
  )
}

const MigrateSharedSections = () => {
  const [fetch] = useQuery(allSharedSectionsQuery)
  const [update] = useQuery(updateSharedSectionsQuery)
  const { sendSuccess, sendError } = useToast()
  // Ensure all templates have previewConfig.variables style and config key

  const onClick = React.useCallback(async () => {
    const { data: { sharedSections } } = await fetch()

    const updatedSections = sharedSections.map(migrateSharedSection)
    const { errors } = await update({ sharedSections: updatedSections })
    if (errors) {
      sendError({ title: 'Template migration failed', message: errors.toString() })
    }
    sendSuccess({ title: 'Template migration succeeded', message: `${updatedSections.length} templates updated` })
  })

  return (
    <Button onClick={onClick} text="Migrate Shared Sections" />
  )
}

const Migrations = () => (
  <Grid gap={6} space={8}>
    <MigrateEmailTemplates />
    <MigrateSharedSections />
  </Grid>
)

export {
  Migrations,
}
