import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  SubmitButton, Card, Stack, Text, Icon, Form, Button, Block, Spacer, Shelf, Link,
  newTemplate,
} from '@level'

import {
  EmailTemplateGallery,
} from '../templates/gallery'

const TemplateNew = ({ updateTemplate }) => {
  const navigate = useNavigate()
  const onSubmit = ({ data }) => {
    const { config, ...rest } = data
    updateTemplate({
      ...rest,
      previewConfig: config || newTemplate({ type: rest.type }).config,
      slug: 'try',
      id: 'try',
      import: true,
      previewTemplateVariables: [],
    })
    navigate('../build')
  }

  return (
    <div className="try-new-layout" style={{ height: '100%' }}>
      <div className="try-main-wrapper">
        <Stack className="try-main" align="center" gap={7}>
          <Shelf align="split" valign="center" space={[0, 8]} style={{ width: '100%' }}>
            <Icon className="try-logo" name="enveloop-logomark" width={60} />
            <Button to="https://www.enveloop.com" text="Learn About Enveloop" />
          </Shelf>
          <Stack align="center" gap={7} style={{ textAlign: 'center' }}>
            <Text className="try-heading" tag="h1" size={8}>Email Template Builder</Text>
            <Link className="try-created-by" to="https://www.enveloop.com" theme="plain">
              <Shelf gap={3} valign="center" nowrap>
                <Text tag="p" nowrap>Created by</Text>
                <Icon name="enveloop-logo-text" height="1.2em" width={null} />
              </Shelf>
            </Link>
            <Text tag="p" size={3} style={{ maxWidth: '470px' }}>Use Enveloop&rsquo;s email builder to create free transational emails with a few simple prompts. Let&rsquo;s get started!</Text>
          </Stack>
          <Form className="try-template-form" onSubmit={onSubmit} defaultValues={{ theme: 'stellar', template: 'Confirm Account' }} focus="[name=theme]:checked">
            <Stack align="center" space={9} gap={10}>
              <Card className="try-builder-card" space={0}>
                <EmailTemplateGallery canChooseName={false} />
              </Card>
              <Block space={10}>
                <SubmitButton size={6} text="Start Building" iconAfter="arrow-right" />
              </Block>
            </Stack>
          </Form>
        </Stack>
        <div className="try-circles-wrapper">
          <Icon className="try-circles" name="circles" width={null} height={null} />
        </div>
      </div>
      <Stack gap={5} space={12} className="try-footer" align="center">
        <Text tag="h2" size={6} color="white">Ready to give Enveloop a try?</Text>
        <Text tag="p" size={2}>Visually build message templates using dynamic content.<br />Send with a simple API. Magic!</Text>
        <Spacer height={5} />
        <Button to="/auth/new" size={5} text="Get Started for Free" />
      </Stack>
      <Block space={7} className="try-copyright">&copy; Copyright {new Date().getFullYear()} Enveloop</Block>
    </div>
  )
}

export {
  TemplateNew,
}
