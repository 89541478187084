import React from 'react'

import { Authed } from '../auth'

import { Migrations } from './migrations'

const AdminIndex = () => (
  <Authed admin>
    <Migrations />
  </Authed>
)

export {
  AdminIndex,
}
