import React from 'react'
import {
  Navigate,
  Routes,
  Route,
  useSearchParams,
} from 'react-router-dom'

import {
  Main, NavPanel, Text, Spacer, Header,
} from '@level'

import { MessageInbox } from './inbox'

import './messages.scss'

const MessagesIndex = () => {
  const [search] = useSearchParams()
  const inboxNav = [
    { text: 'Live', to: `live?${search}` },
    { text: 'Sandbox', to: `sandbox?${search}`, color: 'purple-600' },
  ]
  return (
    <Main>
      <Header primary columns={3}>
        <Text tag="h2">Message Log</Text>
        <Spacer grow />
        <NavPanel navItems={inboxNav} />
        <Spacer grow />
      </Header>
      <Routes>
        <Route index element={<Navigate to="./live" replace />} />
        <Route path="/:env/*" element={<MessageInbox />} />
      </Routes>
    </Main>
  )
}

export {
  MessagesIndex,
}
