import PropTypes from 'prop-types'
import { Avatar } from '../Avatar'
import { Shelf } from '../Shelf'
import { Text } from '../Text'

const UserAvatar = ({
  avatarUrl, name, email, size = 1, ...rest
}) => (avatarUrl ? (
  <img src={avatarUrl} alt="user icon" style={{ width: '1em' }} role="presentation" title={name} />
) : (
  <Avatar name={name} email={email} size={size} {...rest} />
))

UserAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
  size: PropTypes.number,
}

const User = ({
  name, email, avatarUrl, showAvatar = true, avatarSize = 1, className, gap = 4,
}) => {
  const userText = name !== email ? (
    <Text className="level-user-text" title={`email: ${email}`}>{name}</Text>
  ) : (
    <Text className="level-user-text">{email}</Text>
  )

  if (!showAvatar) return userText

  return (
    <Shelf
      gap={gap}
      valign="center"
      className={`${className || ''} level-user`}
    >
      {avatarUrl ? (
        <img src={avatarUrl} alt="user icon" style={{ width: '1em' }} role="presentation" />
      ) : (
        <Avatar name={name} email={email} size={avatarSize} style={{ display: 'block' }} />
      )}
      {userText}
    </Shelf>
  )
}

User.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
  avatarSize: PropTypes.number,
  showAvatar: PropTypes.bool,
  className: PropTypes.string,
  gap: Shelf.propTypes.gap,
}

export {
  User,
  UserAvatar
}
