import React from 'react'
import PropTypes from 'prop-types'
import { useTeam, useFormQuery } from '@app/hooks'

import {
  Card, Stack,
  Input, Text, Shelf, Button, P,
  RadioInput,
  useDialog, CheckSwitch, SettingsGrid,
  useTable, Table, Avatar, MenuButton, Pill,
} from '@level'

const createTeamMemberQuery = ({ Team, admin, email }) => `mutation {
  teamMember: createTeamMember(team: "${Team.slug}", role: ${admin ? 'admin' : 'member'}, email: "${email}") {
    role
    email
    createdAt
  }
}`

const updateTeamMemberQuery = ({ id, role }) => `mutation {
  updateTeamMember(id: "${id}", role: ${role}) {
    id
  }
}`

const removeTeamMemberQuery = ({ id }) => `mutation {
  teamMember: deleteTeamMember(id: "${id}") { id }
}`

// Dialogform for updating a member
const useUpdateMember = () => {
  const sendDialog = useDialog()
  const { isProviderTeam } = useTeam()
  const {
    refresh, admins, isCurrentUser, isOwner,
  } = useTeam()
  const { onSubmit: update } = useFormQuery({
    query: updateTeamMemberQuery,
    successMessage: ({ formData: { role } }) => `Member role changed to ${role}.`,
    onSuccess: () => refresh(),
  })

  const changeRole = ({ id, role, user: { name, email } }) => sendDialog({
    title: `Change Role for ${name || email}`,
    confirmText: 'Change Role',
    onConfirm: ({ data }) => {
      update({ data: { ...data, id } })
    },
    content: (
      <Stack gap={5}>
        <RadioInput
          name="role"
          value="member"
          label={(
            <Stack gap={3}>
              <strong>Member</strong>
              Team members can add and remove projects, generate API keys, and manage templates.
            </Stack>
          )}
          defaultChecked={role === 'member'}
        />
        <RadioInput
          name="role"
          value="admin"
          label={(
            <Stack gap={3}>
              <strong>Admin</strong>
              Team admins can also manage team members for this team.
            </Stack>
          )}
          defaultChecked={role === 'admin'}
        />
        { isOwner && !isProviderTeam
          ? (
            <RadioInput
              name="role"
              value="owner"
              label={(
                <Stack gap={3}>
                  <strong>Owner</strong>
                  Team owner can also manage team members and billing for this team.
                  There can only be one owner so this will remove you as the team owner.
                </Stack>
              )}
              defaultChecked={role === 'owner'}
            />
          ) : null }
      </Stack>
    ),
  })

  const cannotChangeRole = () => sendDialog({
    title: 'Before You Change Your Role…',
    content: (
      <div>
        <Text tag="h4">Your team must have an admin</Text>
        <p>
          To change your role,
          invite a new admin or change the role of another team member to admin.
        </p>
      </div>
    ),
  })

  return (member) => (
    isCurrentUser(member) && admins.length < 2
      ? cannotChangeRole(member)
      : changeRole(member)
  )
}

// Dialog form for removing a member
const useRemoveMember = () => {
  const sendDialog = useDialog()
  const {
    refresh, members, admins, isAdmin, isCurrentUser,
  } = useTeam()

  const removeType = (member) => {
    if (isCurrentUser(member)) return 'leave'
    return member.acceptedAt
      ? 'remove'
      : 'cancel'
  }

  const { onSubmit: remove } = useFormQuery({
    query: removeTeamMemberQuery,
    successMessage: 'Member removed.',
    onSuccess: () => refresh(),
  })

  const removeMember = (member) => {
    const {
      id, user: { name, email }, acceptedAt,
    } = member

    const leaveOrRemove = {
      leave: 'leave this team',
      remove: (<>remove <strong>{name}</strong> ({email}) from this team</>),
      cancel: (<>cancel the invitation sent to <strong>{email}</strong></>),
    }[removeType(member)]

    const title = {
      leave: 'Leave Team',
      remove: `Remove ${name}`,
      cancel: 'Cancel Invitaiton',
    }[removeType(member)]

    sendDialog({
      title,
      destructive: true,
      confirmText: acceptedAt ? 'Remove Member' : 'Cancel Invitation',
      onConfirm: () => remove({ data: { id } }),
      content: <>You are about to {leaveOrRemove}. Are you sure you want to do this?</>,
    })
  }

  const cannotLeave = () => sendDialog({
    title: 'Before You Leave…',
    content: (
      <div>
        <Text tag="h4">Your team must have an admin</Text>
        { members.length === 1 ? (
          <p>To leave your team, invite a new team admin or delete this team.</p>
        ) : (
          <p>
            To leave your team,
            invite a new team admin or change the role of another team member.
          </p>
        )}
      </div>
    ),
  })

  return (member) => (
    isCurrentUser(member) && isAdmin && admins.length < 2
      ? cannotLeave()
      : removeMember(member)
  )
}

// Dialog form for inviting a member
const useInviteMember = () => {
  const sendDialog = useDialog()
  const { refresh, Team } = useTeam()

  const { onSubmit: invite } = useFormQuery({
    query: createTeamMemberQuery,
    queryProps: { Team },
    successMessage: ({ formData: { email } }) => `Invited ${email} to join your team.`,
    onSuccess: () => refresh(),
  })

  return () => sendDialog({
    title: 'Invite Team Member',
    confirmText: 'Send Invitation',
    onConfirm: invite,
    content: (
      <Stack gap={6}>
        Team members can add and remove projects, generate API keys, and manage templates.
        <Input name="email" label="Email Address" autoComplete={false} placeholder="email@team.com" required />
        <CheckSwitch
          name="admin"
          label={(
            <Stack gap={2}>
              <strong>Team Admin</strong>
              <Text color="neutral-500" size={1}>Can manage members and billing for this team.</Text>
            </Stack>
          )}
        />
      </Stack>
    ),
  })
}

// The Member cell
const Member = ({ cell: { renderValue } }) => {
  const { name, email, avatar } = renderValue()
  return (
    <Shelf gap={4} valign="center">
      <Avatar shape="circle" src={avatar?.url} size={3} name={name || email} email={email} gradient />
      <Stack>
        <strong>{ name || email}</strong>
        { name ? <Text color="neutral-500">{ email }</Text> : null }
      </Stack>
    </Shelf>
  )
}

Member.propTypes = {
  cell: PropTypes.shape({
    renderValue: PropTypes.func,
  }).isRequired,
}

// The Member's Role cell
const MemberRole = ({ row: { original: { acceptedAt, role } } }) => {
  const text = `${!acceptedAt ? 'Invited ' : ''}${role}`
  const theme = {
    invited: 'clear',
    owner: 'orange',
    admin: 'green',
    member: 'default',
  }[!acceptedAt ? 'invited' : role]

  return <Pill text={text} theme={theme} />
}

MemberRole.propTypes = {
  row: PropTypes.object.isRequired,
}

// TODO: Figure out how to add columns where there is no data

// The Cell for member actions (except current member)
const MemberActions = (props) => {
  const { row: { original: member } } = props
  const { isCurrentUser, isProviderTeam } = useTeam()
  const removeMember = useRemoveMember()
  const updateMember = useUpdateMember()

  if (isCurrentUser(member) && isProviderTeam) return null
  if (!isCurrentUser(member) && member.isOwner) return null

  const removeText = () => {
    if (!member.acceptedAt) return 'Cancel Invitation'
    return isCurrentUser(member) ? 'Leave Team' : 'Remove Member'
  }

  const items = [
    { text: 'Change role', onSelect: () => updateMember(member) },
    {
      text: removeText(),
      onSelect: () => removeMember(member),
      destructive: true,
    },
  ]

  return <MenuButton size={2} label="Actions" items={items} icon="angle-down" />
}

MemberActions.propTypes = {
  row: PropTypes.object.isRequired,
}

// The Table of team members
const TeamMembers = () => {
  const { members, isAdmin } = useTeam()

  const inviteMember = useInviteMember()

  const columns = React.useMemo(() => {
    const cols = [
      { user: { width: '70%', header: 'Member', cell: Member } },
      { admin: { header: 'Role', cell: MemberRole } },
    ]
    if (isAdmin) {
      cols.push({
        action: {
          type: 'display',
          width: '1%',
          header: '',
          cell: MemberActions,
        },
      })
    }
    return cols
  }, [members])

  const table = useTable({
    data: members,
    columnNames: columns,
  })

  return (
    <SettingsGrid>
      <Stack gap={5}>
        <Text tag="h3">Team Members</Text>
        <P>
          Invite members to your team and set their roles. <strong>Admins</strong> can
          invite other members, and manage everything on a team. <strong>Owners</strong> can
          do everything admins can do as well as manage billing.
        </P>
      </Stack>
      <Stack gap={6}>
        <Card space={0}>
          <Table table={table} theme="simple" />
        </Card>
        { isAdmin ? (
          <Button style={{ alignSelf: 'end' }} onClick={inviteMember}>Add Member</Button>
        ) : null }
      </Stack>
    </SettingsGrid>
  )
}

export {
  TeamMembers,
  useRemoveMember,
}
