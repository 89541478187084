import React from 'react'
import PropTypes from 'prop-types'

import { DateRangeInput } from '../DateInput'
import { useSearchState } from '../../hooks'
import { Form } from '../Form'
import { getDateRange } from '../../helpers'

// Get a date range from params
const parseDateParams = (name, params = {}) => {
  const {
    date, range, direction, datetime,
  } = params

  const newParams = {}
  if (date === 'range') {
    newParams.datetime = datetime
    newParams.direction = direction
    newParams.range = range
  }

  const dateRange = getDateRange({
    span: date, range, direction, datetime, utc: true,
  })
  // convert value from array to string
  const value = dateRange ? dateRange.map((d) => d.valueOf()).join(',') : null

  return {
    ...newParams,
    date,
    [name]: value,
  }
}

const DateRangeFilter = ({
  name = 'createdAt',
  defaults = {},
  rangeLabel = 'Show records within',
  ...rest
}) => {
  const [params, setParams] = useSearchState(parseDateParams(name, { ...defaults, datetime: '' }))
  const focusRef = React.useRef()
  const onFocus = ({ target }) => { focusRef.current = target }
  const focus = (value) => {
    const input = document.querySelector(`[name=date][value=${value}]`)
    if (!input) {
      setTimeout(() => focus(value), 100)
    } else {
      focusRef.current = input
    }
  }
  const onSubmit = React.useCallback(({ data }) => {
    setParams.merge(parseDateParams(name, data))
  }, [])

  React.useEffect(() => {
    if (params.date) focus(params.date)
  }, [params.date])

  React.useEffect(() => {
    setParams.merge(params)
  }, [])

  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={params}
      focus={focusRef.current ? `#${focusRef.current.id}` : null}
      className="level-filter-form"
    >
      <DateRangeInput
        onChangeDatetime={(e, formRef) => formRef.setValue('date', 'range')}
        onClear={() => setParams.merge({ datetime: '' })}
        onFocus={onFocus}
        rangeLabel={rangeLabel}
        {...rest}
      />
    </Form>
  )
}

DateRangeFilter.propTypes = {
  name: PropTypes.string,
  defaults: PropTypes.object,
  span: PropTypes.array,
  dateRange: PropTypes.array,
  rangeLabel: PropTypes.string,
}

export {
  DateRangeFilter,
  parseDateParams,
}
