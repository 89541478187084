import React from 'react'

import { RadioFilter } from '@level'
import { MessageIcon } from '../icon'
import { operators } from '../queries'

const ActionFilter = () => {
  const options = [
    { label: 'All', value: '', icon: <MessageIcon name="all" /> },
    { value: 'opened', icon: <MessageIcon name="opened" />, filter: [{ name: 'openedAt', value: 1 }] },
    { value: 'clicked', icon: <MessageIcon name="clicked" />, filter: [{ name: 'clickedAt', value: 1 }] },
    {
      value: 'none',
      icon: <MessageIcon name="none" />,
      filter: [
        { name: 'openedAt', value: 'none', operator: 'isNull' },
        { name: 'clickedAt', value: 'none', operator: 'isNull' },
      ],
    },
  ]
  return (
    <RadioFilter
      name="action"
      operators={operators}
      options={options}
    />
  )
}

export {
  ActionFilter,
}
