import { useClipboard } from '../../../hooks/useClipboard'
import { useToast } from '../../../hooks/useToast'

const useCopyTheme = ({ template, _theme = template?.previewConfig?.variables._theme }) => {
  const { copy } = useClipboard()
  const { sendNotice } = useToast()
  if (_theme) {
    return function copyTheme() {
      copy('theme', { _theme, sourceSlug: template?.slug })

      sendNotice({ title: 'Theme Copied' })
    }
  }
  return null
}

export {
  useCopyTheme
}
