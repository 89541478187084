import React from 'react'
import PropTypes from 'prop-types'

import { useDropZone, DropZoneContext } from '../../hooks/useDropZone'

const DropZone = ({
  type, onDrop, children, className, ...props
}) => {
  const {
    dragOver, data, clear, ref, setDrop,
  } = useDropZone({ type, onDrop })

  const value = React.useMemo(() => ({
    dragOver, data, clear, setDrop,
  }), [dragOver, data, clear])

  return (
    <div className={`level-drop-zone ${className || ''}`} data-drag-item-over={dragOver} ref={ref} {...props}>
      <DropZoneContext.Provider value={value}>
        {children}
      </DropZoneContext.Provider>
    </div>
  )
}

DropZone.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  onDrop: PropTypes.func,
  children: PropTypes.node,
}

export {
  DropZone,
}
