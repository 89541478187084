import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../Icon'
import { Grid } from '../Grid'
import { toTextStyle, gapSize, spaceToSize } from '../../helpers'

import './text.scss'

// This allows icon to be a string for an icon name,
// or an object to pass through to the icon component.
const getIconProps = (icon) => ((typeof icon === 'string') ? { name: icon } : icon)

const TextIcon = ({ icon, gridArea, ...rest }) => (
  <Icon {...rest} {...getIconProps(icon)} style={{ display: 'block', gridArea }} />
)

const truncatedStyle = ({
  style, truncate, truncateWidth, truncateLines,
}) => {
  if (truncateLines) {
    return {
      ...style,
      display: '-webkit-box',
      WebkitLineClamp: truncateLines,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
  }
  if (truncate || truncateWidth) {
    return {
      maxWidth: truncateWidth,
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      ...style,
    }
  }

  return style
}

const getIconGridProps = ({
  icon, iconGap, iconAfter, iconAfterGap, children,
}) => {
  const size = []
  const area = []
  if (icon) {
    size.push('min-content')
    area.push('icon')
  }
  if (children) {
    if (icon) {
      size.push(gapSize(typeof icon?.gap === 'number' ? icon?.gap : iconGap))
      area.push('.')
    }
    if (!iconAfter) {
      size.push('1fr')
    } else {
      size.push('auto')
    }
    area.push('content')
  }
  if (iconAfter) {
    size.push(gapSize(typeof icon?.afterGap === 'number' ? icon?.afterGap : iconAfterGap))
    size.push('1fr')
    size.push('min-content')
    area.push('.')
    area.push('.')
    area.push('iconAfter')
  }
  return {
    templateAreas: `"${area.join(' ')}"`,
    templateColumns: size.join(' '),
    align: 'left',
  }
}

const Text = React.forwardRef(function Text({
  children,
  icon,
  iconGap = 3,
  iconAfter,
  iconAfterGap = 6,
  className = '',
  size,
  align,
  valign = 'center',
  theme,
  color,
  tag = 'span',
  as,
  style,
  inline,
  forceWrap,
  truncate,
  truncateWidth,
  truncateLines,
  nowrap,
  iconSize = 'default',
  space,
  ...rest
}, ref) {
  const themeProps = toTextStyle({
    fontSize: size,
    theme,
    color,
    tag,
  })

  const altRef = React.useRef()
  const componentRef = ref || altRef
  const Component = as || tag
  const hasIcons = icon || iconAfter
  const spaceStyle = typeof space !== 'undefined' ? { padding: spaceToSize(space) } : {}

  const componentStyle = {
    ...themeProps.style,
    ...truncatedStyle({
      style,
      truncate,
      truncateLines,
      truncateWidth,
    }),
  }

  if (forceWrap === true) componentStyle.wordBreak = 'break-word'
  if (nowrap === true) componentStyle.whiteSpace = 'nowrap'
  if (!hasIcons && align) componentStyle.textAlign = align

  return hasIcons ? (
    <Grid
      tag={Component}
      {...getIconGridProps({
        icon,
        iconGap,
        iconAfter,
        iconAfterGap,
        children,
      })}
      inline={inline}
      align={align}
      valign={valign}
      data-theme={theme}
      className={`level-text ${className || ''}`}
      ref={componentRef}
      style={{ ...componentStyle, ...spaceStyle }}
      {...rest}
    >
      {icon ? <TextIcon gridArea="icon" icon={icon} size={iconSize} /> : null}
      {children ? <div style={{ gridArea: 'content', ...componentStyle }}>{children}</div> : null}
      {iconAfter ? <TextIcon gridArea="iconAfter" icon={iconAfter} size={iconSize} /> : null}
    </Grid>
  ) : (
    <Component style={{ ...componentStyle, ...spaceStyle }} {...rest} data-theme={theme} className={`level-text ${className || ''}`} ref={componentRef}>
      {children}
    </Component>
  )
})

const iconProps = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    ...Icon.propTypes,
    gap: gapSize.propTypes.gap,
    grow: PropTypes.bool,
  }),
])

TextIcon.propTypes = {
  icon: iconProps,
  gridArea: PropTypes.string,
}

Text.propTypes = {
  icon: iconProps,
  iconGap: gapSize.propTypes.gap,
  iconAfter: iconProps,
  iconAfterGap: gapSize.propTypes.gap,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node,
  ({ children, icon }) => (
    (!children && !icon) ? (new Error('Link requires either icon or a children prop.')) : null
  )]),
  valign: PropTypes.oneOf(['top', 'center', 'bottom', undefined]),
  align: PropTypes.oneOf(['left', 'center', 'right', undefined]),
  as: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
  nowrap: PropTypes.bool,
  forceWrap: PropTypes.bool,
  truncate: PropTypes.bool,
  truncateLines: PropTypes.number,
  truncateWidth: PropTypes.string,
  theme: PropTypes.string,
  iconSize: Icon.propTypes.size,
  inline: Grid.propTypes.inline,
  space: spaceToSize.propTypes.space,
  ...toTextStyle.propTypes,
}

const P = ({ style = {}, ...rest }) => (
  <Text tag="p" style={{ whiteSpace: 'pre-wrap', ...style }} forceWrap={true} {...rest} />
)

P.propTypes = {
  style: PropTypes.object,
  forceWrap: PropTypes.bool,
}

const SectionLabel = (props) => <Text tag="h5" size={0} color="neutral-300" style={{ textTransform: 'uppercase', letterSpacing: '0.05em' }} {...props} />

export {
  Text,
  TextIcon,
  SectionLabel,
  P,
}
