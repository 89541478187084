import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import { useQuery } from '@app/hooks'

import {
  Grid,
  useDialog,
} from '@level'

import { messageQuery } from '../queries'
import { MessageIcon } from '../icon'
import { MessageSidebar } from './sidebar'
import { Message } from './message'
import { MessageHeader } from './header'

const MessageModal = ({
  tableMessage,
  fetchMessage,
  ...rest
}) => {
  const [mode, setMode] = React.useState('preview')
  const [isLoading, setIsLoading] = React.useState(false)
  const [message, setMessage] = React.useState()

  React.useEffect(() => {
    (async () => {
      setIsLoading(true)
      const { data } = await fetchMessage(tableMessage)
      setIsLoading(false)
      setMessage(data?.message)
      setMode('preview')
    })()

    // Abort query if another loads before it returns
    return () => {
      if (fetchMessage?.abort) fetchMessage.abort()
    }
  }, [tableMessage?.id])
  return (
    <Grid templateRows="auto auto 1fr" style={{ height: '100%' }}>
      <MessageHeader
        message={tableMessage}
        {...rest}
      />
      <Grid templateColumns="280px 1fr" className="message-preview">
        {message ? (
          <MessageSidebar
            mode={mode}
            tableMessage={tableMessage}
            isLoading={isLoading}
            setMode={setMode}
            message={message}
          />
        ) : null}
        <Message
          mode={mode}
          message={message}
          isLoading={isLoading}
        />
      </Grid>
    </Grid>
  )
}

MessageModal.propTypes = {
  // Shallow messsage from table data
  tableMessage: PropTypes.object.isRequired,
  contentRef: PropTypes.object.isRequired,
  fetchMessage: PropTypes.func.isRequired,
}

const useMessageModal = ({ table }) => {
  const { env } = useParams()
  const {
    selected, selectPrevious, selectNext, deselectAll,
  } = table.getSelection(table)
  const [getMessage] = useQuery(messageQuery)
  const sendDialog = useDialog()

  const fetchMessage = (props) => (
    getMessage({ ...props, testMode: env !== 'live' })
  )

  // View Message when selected
  React.useEffect(() => {
    if (selected) {
      sendDialog({
        fetchMessage,
        element: MessageModal,
        tableMessage: selected,
        prevMessage: selectPrevious,
        nextMessage: selectNext,
        onClose: deselectAll,
        cancelText: 'close',
        width: '80vw',
        height: '80vh',
      })
    }
  }, [selected])
}

export {
  useMessageModal,
  MessageIcon,
}
