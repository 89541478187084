import React from 'react'

const useDelayValue = (value, options = {}) => {
  const { delay = 250, defaultValue = false } = options
  const [val, setVal] = React.useState(defaultValue)
  const timeoutRef = React.useRef()

  React.useEffect(() => {
    if (value) {
      timeoutRef.current = window.setTimeout(() => setVal(value), delay)
    } else {
      if (timeoutRef.current) window.clearTimeout(timeoutRef.current)
      setVal(defaultValue)
    }

    return () => clearTimeout(timeoutRef.current)
  }, [value])

  return val
}

export {
  useDelayValue,
}
