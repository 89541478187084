import { theme } from './theme'

const userInvite = {
  name: 'User Invite',
  config: ({ color, Team = { name: 'CompanyName' }, Project = { name: 'ProductName' } }) => {
    const {
      colors: {
        text,
        border,
      },
      variables,
    } = theme({ color, defaultSubject: 'Hi {{{ name }}}, You\'ve been invited' })

    return {
      variables,
      sections: [
        {
          sharedSectionId: null,
          variables: {
            config: {
              align: 'left',
              valign: 'top',
              padding: '0px 0px 0px 0px',
              elementSpacing: '10px',
              name: 'Content',
            },
            columns: [
              {
                config: {},
                elements: [
                  {
                    type: 'Heading',
                    config: {
                      text: `{{sender}} would like to collaborate on ${Project.name}`,
                      color: text,
                      padding: '0 0 0 0',
                      fontSize: '30px',
                      fontWeight: '600',
                    },
                  },
                  {
                    type: 'Text',
                    config: {
                      content: `{{sender}} with ${Team.name} has invited you to join ${Project.name}. Click the link below to setup your account and start collaborating.\n\n\n[Create my account](https://enveloop.com)\n\n\nIf you think this was sent in error, reply back to this email and let the sender, {{sender}}, know.`,
                      padding: '0 0 10px 0',
                      fontSize: '14px',
                      fontWeight: '400',
                    },
                  },
                  {
                    type: 'Divider',
                    config: {
                      color: border,
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      padding: '10px 0 10px 0',
                    },
                  },
                  {
                    type: 'Text',
                    config: {
                      content: 'If you have trouble with the link above, copy and paste the URL below into your web browser.\n\n{{account_url}}',
                      padding: '0 0 0 0',
                      fontSize: '10px',
                      fontWeight: '400',
                    },
                  },
                ],
              },
            ],
          },
        },
      ],
    }
  },
}

export { userInvite }
