import React from 'react'

import { useProfile } from '@app/hooks'
import {
  Shelf, Button, Text, useDialog, pluralize,
} from '@level'

import { useInvitations } from './useInvitations'
import { InvitationInputs } from './inputs'

import './notice.scss'

// A dialog for managing team invitations
const useInvitationsDialog = () => {
  const { invitations, onSubmit } = useInvitations()
  const sendDialog = useDialog()

  return () => sendDialog({
    title: pluralize('Team Invitation', invitations),
    content: (
      <>
        <Text tag="h4">You have been invited to join {pluralize('a team', `${invitations.length} Teams`, invitations)} on Enveloop.</Text>
        <InvitationInputs invitations={invitations} />,
      </>
    ),
    onConfirm: onSubmit,
    form: {
      focus: `[name="invitations.${invitations[0].id}.accept"][value=true]`,
    },
  })
}

// Displays the number of invitations (for use in the sidebar)
// Click opens a dialog form for managing invitations.
const InvitationsNotice = () => {
  const { invitations } = useProfile()
  const showInvitations = useInvitationsDialog()
  return invitations?.length
    ? (
      <Button onClick={showInvitations} theme="wrapper" className="invitations-notice">
        <Shelf className="invitations-notice-content" valign="center" align="split" gap={2} rowGap={0}>
          { pluralize('Team Invitation', invitations.length) }
          <div className="invitations-notice-count">{ invitations.length }</div>
        </Shelf>
      </Button>
    ) : null
}

export {
  InvitationsNotice,
}
