import React from 'react'
import {
  useTeam, useProfile, useFormQuery, useAuth,
} from '@app/hooks'

import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'

import {
  Main, Body, Card, Stack, SettingsGrid,
  Input, Form, ButtonShelf, SubmitButton, Divider, Button,
  Text, P, Shelf, Column, AvatarInput, NavTabs,
  toParams, useDialog, filterEmptyValues,
} from '@level'

import { ProjectHeader } from '../projects/header'
import { TeamMembers, useRemoveMember } from './members'
import { TeamPlan } from './plan'
import { TeamDisabled, DisableTeam } from './disabled'

const updateTeamQuery = ({
  teamId, avatar, name, slug, defaultFromEmail, defaultFromSms,
}) => `mutation {
  Team: updateTeam(team: "${teamId}", attributes: { ${toParams(filterEmptyValues({
  avatar, name, slug, defaultFromEmail, defaultFromSms,
}))} }) {
    slug
  }
}`

const deleteTeamQuery = ({ Team }) => `mutation {
  Project: deleteTeam(team: "${Team.slug}") {
    slug
  }
}`

const LeaveTeam = () => {
  const { isCurrentUser, members } = useTeam()
  const member = members.find((m) => isCurrentUser(m))
  const leaveTeam = useRemoveMember()

  return (
    <Stack gap={6}>
      <Text tag="h3">Leave Team</Text>
      <Card>
        <Form onSubmit={() => leaveTeam(member)}>
          <Stack gap={5}>
            <P> Leaving this team will remove your access to any of its projects. </P>
            <ButtonShelf valign="center">
              <SubmitButton text="Leave Team" theme="destructive" />
            </ButtonShelf>
          </Stack>
        </Form>
      </Card>
    </Stack>
  )
}

const DeleteTeam = () => {
  const { Team } = useTeam()
  const { hardRefresh: refreshProfile } = useProfile()
  const sendDialog = useDialog()

  const { onSubmit: deleteTeam } = useFormQuery({
    query: deleteTeamQuery,
    queryProps: { Team },
    onSuccess: () => refreshProfile(),
    redirect: '../../',
  })

  const confirmDelete = () => sendDialog({
    onConfirm: deleteTeam,
    title: `Delete "${Team.name}" team?`,
    content: (
      <Stack gap={8}>
        <Text>
          Deleting this team will remove access for all users and delete all projects.
          API tokens will be deleted and any email sent for any project on this
          team will cease. Deleting a team cannot be undone.
        </Text>
        <Input
          name="identity"
          label={`Type "${Team.name}" below to confirm.`}
          placeholder={Team.name}
          pattern={`^${Team.name.replace(/([+.])/g, '\\$1')}$`}
          messages={{ pattern: `Please type "${Team.name}" to confirm.` }}
          required
        />
      </Stack>
    ),
    confirmText: 'Delete Team',
    destructive: true,
  })

  return (
    <SettingsGrid>
      <Stack gap={6}>
        <Text tag="h3">Delete Team</Text>
        <P>Only team owners can delete a team.</P>
      </Stack>
      <Card>
        <Stack gap={5}>
          <P>
            Deleting this team will remove access for all users and delete all projects.
            API tokens will be deleted and any email sent for any project on this
            team will cease.
          </P>
          <ButtonShelf valign="center">
            <Button text="Delete Team" theme="destructive" onClick={confirmDelete} />
          </ButtonShelf>
        </Stack>
      </Card>
    </SettingsGrid>
  )
}

const Settings = () => {
  const { hardRefresh: refresh, teamUrl } = useProfile()
  const { asUser } = useAuth()
  const {
    Team, isOwner, isAdmin, isProviderTeam,
  } = useTeam()

  const { onSubmit: updateTeam } = useFormQuery({
    upload: 'avatar',
    query: updateTeamQuery,
    queryProps: { teamId: Team.slug },
    onSuccess: ({ formData }) => {
      if (formData.slug !== Team.slug
        || formData.name !== Team.name) {
        refresh()
      }
    },
    redirect: ({ formData }) => (
      (formData.slug !== Team.slug)
        ? teamUrl({ teamId: formData.slug, path: 'settings' }) : null
    ),
  })

  if (isProviderTeam) {
    return <TeamMembers />
  }

  return (
    <Stack gap={9}>
      <TeamMembers />
      <Divider flush={8} />
      {isAdmin ? (
        <>
          <SettingsGrid>
            <Stack gap={5}>
              <Text tag="h3">Team Name &amp; Slug</Text>
              <P>
                Change your team&rsquo;s name, the slug used for its urls,
                and the image that shows in the team picker.
              </P>
            </Stack>
            <Card>
              <Form onSubmit={updateTeam}>
                <Stack gap={9}>
                  <Shelf gap={7}>
                    <Column grow>
                      <Stack gap={5}>
                        <Input name="name" defaultValue={Team.name} required />
                        <Input name="slug" defaultValue={Team.slug} required />
                      </Stack>
                    </Column>
                    <AvatarInput
                      object={Team}
                      name="avatar"
                      label="Team Avatar"
                    />
                  </Shelf>
                  <ButtonShelf>
                    <SubmitButton text="Update Team" />
                  </ButtonShelf>
                </Stack>
              </Form>
            </Card>
          </SettingsGrid>
          <Divider flush={8} />
        </>
      ) : null}
      {isOwner ? <DeleteTeam /> : <LeaveTeam />}
      {asUser ? <DisableTeam /> : null}
    </Stack>
  )
}

const TeamSettings = () => {
  const { isOwner, Team: { disabled }, isProviderTeam } = useTeam()
  const navItems = React.useMemo(() => {
    if (isOwner && !isProviderTeam) {
      return [
        { text: 'Manage Team', to: './general' },
        { text: 'Plan + Pricing', to: './plan' },
      ]
    }
    return null
  }, [isOwner])

  return (
    <Main>
      {navItems ? (
        <div style={{ backgroundColor: '#fff' }}>
          <Stack gap={5}>
            <Text tag="h2" space={[6, 8, 0]}>Settings</Text>
            <NavTabs space={8} navItems={navItems} border />
          </Stack>
        </div>
      ) : <ProjectHeader title="Settings" />}
      <Body space={[9, 8]} scroll>
        <Stack gap={8}>
          {disabled ? <TeamDisabled /> : null}
          <Routes>
            <Route path="*" element={<Navigate to="general" replace />} />
            <Route path="general" element={<Settings />} />
            {isOwner && !isProviderTeam ? <Route path="plan" element={<TeamPlan />} /> : null}
          </Routes>
        </Stack>
      </Body>
    </Main>
  )
}

export {
  TeamSettings,
}
